import {Component, HostListener, Inject, Input, OnInit} from '@angular/core';
import {VirtualHandInBit} from "./virtual-hand-in.models";
import {HandInStatus} from "../../shared/models/bitmark.models";
import {BitbookMqService} from "../../reader/bitbook-mq.service";
import {BitbookApiService} from "../../reader/bitbook-api.service";
import {BitmarkConfig} from "../../bitmark.module";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'bitmark-virtual-hand-in',
  templateUrl: './virtual-hand-in.component.html',
  styleUrls: ['./virtual-hand-in.component.scss', '../bits.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*',  // Use '*' to adapt to content size
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        opacity: 0
      })),
      transition('open <=> closed', [
        animate('0.3s')
      ]),
    ]),
    trigger('fadeOut', [
      state('visible', style({
        opacity: 1
      })),
      state('invisible', style({
        opacity: 0
      })),
      transition('visible => invisible', [
        animate('0.5s')
      ])
    ])
  ]
})
export class VirtualHandInComponent implements OnInit {
  @Input() handInBit?: VirtualHandInBit;

  status: HandInStatus = HandInStatus.NotHandedIn;
  handInDate: Date;
  expertUser: any;
  hasExpertApiResponse: any;
  isDescriptionOpen = true;

  @HostListener('document:gmbUserDetailsDelivered', ['$event'])
  onUserDetailsDelivered(event: any) {
    if (event?.detail?.id == this.handInBit.handIn?.mainExpert?.externalId) {
      this.expertUser = event?.detail;
    } else if(event?.detail?.email === this.handInBit?.handIn?.mainExpert?.email || event?.detail?.ssoEmail === this.handInBit?.handIn?.mainExpert?.email){
      this.expertUser = event?.detail;
    }
    this.hasExpertApiResponse = true;
  }

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private bitbookMqService: BitbookMqService,
              private route: ActivatedRoute,
              private bitbookApiService: BitbookApiService) {}

  ngOnInit() {
    if(this.handInBit?.handIn?.status?.length && this.handInBit?.handIn?.status !== HandInStatus.Approved && this.handInBit?.handIn?.status !== HandInStatus.Rejected){
      // so the bit is in 'your hand-in was assigned' mode, without the button
      this.status = HandInStatus.HandedIn;
    }
    if(this.handInBit?.handIn?.createdAt){
      this.handInDate = new Date(this.handInBit?.handIn?.handedInAt);
    }
    if(this.handInBit?.handIn?.status === HandInStatus.Approved || this.handInBit?.handIn?.status === HandInStatus.Rejected){
      this.status = this.handInBit?.handIn?.status;
      this.handInDate = this.handInBit?.handIn?.returnedAt;
    }
    if(this.handInBit?.handIn?.mainExpert?.email){
      const ev = new CustomEvent('gmbUserDetailsRequested', {
        detail: {
          userEmail: this.handInBit?.handIn?.mainExpert?.email
        }, bubbles: true,
      });
      document.dispatchEvent(ev);
    }
  }

  handIn() {
    this.handInDate = new Date();
    //if the book was not handed in before
    const moduleId = this.route.snapshot.queryParams.moduleId;
    if(this.status === HandInStatus.NotHandedIn){
      this.bitbookApiService.createHandIn(this.bitmarkConfig.space, this.handInBit.bookId, moduleId).subscribe(() => {
        this.bitbookMqService.notifyBookIsHandedInReadonly(this.handInBit.bookId);
        this.status = HandInStatus.HandedIn;
      }, (err) => {
        this.status = HandInStatus.NotHandedIn;
        console.error('Error handing in book ', this.handInBit?.bookId, ' ', err);
        alert('There was a problem handing in your book');
      })
    }
    if(this.status === HandInStatus.Rejected){
      this.bitbookApiService.assignHandIn(this.bitmarkConfig.space, this.handInBit?.handIn?.id, this?.handInBit?.handIn?.mainExpert?.email).subscribe((res) => {
        this.status = HandInStatus.HandedIn;
        this.bitbookMqService.notifyBookIsHandedInReadonly(this.handInBit.bookId);
      }, (err) => {
        console.error('Error handing again in book ', this.handInBit?.bookId, ' ', err);
        alert('There was a problem handing in your book');
      })
    }
  }

  openAccordion() {
    this.isDescriptionOpen = !this.isDescriptionOpen;
  }

  protected readonly HandInStatus = HandInStatus;
}
