<div class="host"
     [ngClass]="brandingClass"
     [style.opacity]="brandingClass ? 1 : 0">
  <div class="d-flex align-items-center justify-content-center p-2 logo-container">
    <div class="logo-img"></div>
  </div>

  @if (error) {
    <div
      class="h-100 reader-content bits-wrapper d-flex flex-column align-items-center justify-content-center text-white">
      <bitmark-icon name="bookshelf" class="size-80px mb-2"></bitmark-icon>
      <div translate="Shop.BookNotFound"></div>
      <button class="btn-action btn-action--filled mt-6"
              (click)="closeBook()">
        <span>Ok</span>
      </button>
    </div>
  }
  <div class="h-100 row no-gutters">
    <div class="col-md-9 ml-auto mr-auto"
         [class.col-md-12]="!(isSearchSidebarVisible || isTableOfContentsVisible)">
      <div class="reader-book-header">
        @if (readerMode !== ReaderModes.Search && !isShopReader && hiddenElements?.indexOf(ReaderHiddenElements.Header) === -1) {
          <bitmark-reader-header [hideRightSide]="isTableOfContentsVisible"
                                 [hiddenElements]="hiddenElements"
                                 (close)="closeBook()"
                                 (toggleSearch)="toggleSearch()"
                                 (toggleToc)="toggleToc()"
                                 (viewBasket)="viewBasket()">
          </bitmark-reader-header>
        }
        @if (readerMode === ReaderModes.Search) {
          <bitmark-reader-search-header [isExternalSearch]="isExternalSearch"
                                        class="centered"
                                        (close)="toggleSearch()"
                                        (toggleSidebar)="toggleSearchSidebar()">
          </bitmark-reader-search-header>
        }
      </div>
      @if (readerMode === ReaderModes.Search) {
        <div class="py-5"></div>
      }
      <bitmark-reader-content [bitBook]="bitBook"
                              [readerMode]="readerMode"
                              [isExternalSearch]="isExternalSearch"
                              [externalLocation]="externalLocation"
                              [courseId]="courseId"
                              [hiddenElements]="hiddenElements"
                              [configuration]="configuration"
                              (sendBits)="sendBits.emit($event)"
                              (sendBitsToClass)="sendBitsToClass.emit($event)"
                              (saveBits)="saveBits.emit($event)"
                              (copyLinkToBit)="copyLinkToBit.emit($event)"
                              (findInBook)="findBitInBook($event)"
                              (toggleToc)="toggleToc()"
                              (close)="closeBook()"
                              (assignHandIn)="assignHandIn.emit($event)"
                              (unassignHandIn)="unassignHandIn.emit($event)"
                              (navigateToBook)="navigateToBook.emit($event)"
                              (navigateToProduct)='navigateToProduct.emit($event)'>
      </bitmark-reader-content>
      @if (readerMode !== ReaderModes.Search && !isShopReader && hiddenElements?.indexOf(ReaderHiddenElements.Progress) === -1) {
        <div class="progress-bar-wrapper">
          @if (bitBook?.externalId) {
            <bitmark-reader-progress class="centered paper-size"
                                     [bookExternalId]="bitBook?.externalId">
            </bitmark-reader-progress>
          }
        </div>
      }
    </div>
    @if (!isShopReader && hiddenElements?.indexOf(ReaderHiddenElements.Toc) === -1) {
      @if (isTableOfContentsVisible) {
        <div [@inOutAnimation]
             class="col-md-3 reader-book-sidebar">
          <bitmark-reader-toc-sidebar [bitBook]="bitBook"
                                      [readerMode]="readerMode"
                                      (toggleSearch)="toggleSearch()"
                                      (close)="toggleToc()">
          </bitmark-reader-toc-sidebar>
        </div>
      }
      @if (isSearchSidebarVisible) {
        <div [@inOutAnimation]
             class="col-md-3 reader-book-sidebar">
          <bitmark-reader-search-sidebar [isExternalSearch]="isExternalSearch"
                                         [bitBook]="bitBook"
                                         (close)="toggleSearchSidebar()">
          </bitmark-reader-search-sidebar>
        </div>
      }
    }
  </div>
</div>
