<div class="learning-path-book-header p-2"
     [ngClass]="{
      'learning-path-book-header-minimized': isHeaderMinimized,
      'learning-path-book-header-only': isHeaderOnly
     }">
  <div class="d-flex flex-row">
    @if (isContentVisible) {
      <div [ngClass]="(imageExists || isBeingEditedByUser) && 'learning-path-bit-image mr-2'">
        @if (imageExists) {
          <div class="position-relative h-100"
               [ngClass]="isBeingEditedByUser && 'image-editable'">
            <div class="image-container">
              <img [src]="bit.resource.image.src"
                   [alt]="bit.resource.image.alt"
                   (error)="handleImageLoadError()"/>
            </div>
            @if (isBeingEditedByUser) {
              <bitmark-icon name="trash-can-outline"
                            class="size-30px remove-image"
                            (click)="removeImage()">
              </bitmark-icon>
            }
          </div>
        }
        @if (!imageExists && isBeingEditedByUser) {
          <div class="d-flex align-items-center justify-content-center image-placeholder"
               [ngClass]="isBeingEditedByUser && 'image-editable'"
               (click)="isBeingEditedByUser && fileInput.click()">
            <bitmark-icon name="cloud-upload" class="size-30px"></bitmark-icon>
          </div>
        }
        <input accept="image/*" hidden type="file" [attr.multiple]="null" #fileInput (change)="uploadImage()">
      </div>
    }
    <div class="d-flex flex-column header-content">
      <div class="flex-grow-1 d-flex">
        <div class="mr-1 header-text">
          <div class="text-truncate">
            @if (!isBeingEditedByUser) {
              <div class="learning-path-item">
                @if (bit.item) {
                  <bitmark-text [text]="bit.item"></bitmark-text>
                }
              </div>
            } @else {
              <div class="learning-path-item">
                <bitmark-editable-text
                  (onChange)="bit.item = $event"
                  [text]="bit.item"
                  [placeholder]="'Bits.Item' | translate">
                </bitmark-editable-text>
              </div>
            }
            @if (!isBeingEditedByUser) {
              <div class="font-weight-bold learning-path-instruction">
                <bitmark-text [text]="bit.instruction"></bitmark-text>
              </div>
            } @else {
              <div class="font-weight-bold learning-path-instruction">
                <bitmark-editable-text
                  (onChange)="bit.instruction = $event"
                  [text]="bit.instruction"
                  [placeholder]="'Bits.Instruction' | translate">
                </bitmark-editable-text>
              </div>
            }
          </div>
          <div class="d-flex flex-row learning-path-header-details">
            @if (bit.action || isBeingEditedByUser) {
              <div class="mr-1"
                   [ngClass]="!isBeingEditedByUser && 'text-truncate'">
                @if (!isBeingEditedByUser) {
                  <bitmark-text [text]="bit.action"
                                [truncate]="true">
                  </bitmark-text>
                } @else {
                  <bitmark-editable-text (onChange)="bit.action = $event"
                                         [text]="bit.action"
                                         [placeholder]="'Bits.Action' | translate">
                  </bitmark-editable-text>
                }
              </div>
            }
            @if ((bit.action && bit.location) || isBeingEditedByUser) {
              <div class="mr-1">&bull;</div>
            }
            @if (bit.location || isBeingEditedByUser) {
              <div class="mr-1"
                   [ngClass]="!isBeingEditedByUser && 'text-truncate'">
                @if (!isBeingEditedByUser) {
                  <bitmark-text [text]="bit.location"
                                [truncate]="true">
                  </bitmark-text>
                } @else {
                  <bitmark-editable-text (onChange)="bit.location = $event"
                                         [text]="bit.location"
                                         [placeholder]="'Bits.Location' | translate">
                  </bitmark-editable-text>
                }
              </div>
            }
            @if (((bit.action || bit.location) && bit.duration) || isBeingEditedByUser) {
              <div class="mr-1">&bull;</div>
            }
            @if (bit.duration || isBeingEditedByUser) {
              <div class="mr-1 header-duration"
                   [ngClass]="!isBeingEditedByUser && 'text-truncate'">
                @if (!isBeingEditedByUser) {
                  <span>{{ bit.duration | amDuration }}</span>
                } @else {
                  <bitmark-duration-editor ngDefaultControl
                                           [(ngModel)]="bit.duration">
                  </bitmark-duration-editor>
                }
              </div>
            }
          </div>
        </div>
        @if (bit.answer?.isDone && isTracked && !bit.isInfoOnly) {
          <div class="learning-path-book-check d-flex align-items-center"
               (click)="reset($event)">
            <bitmark-icon name="check-circle-fill" class="size-30px"></bitmark-icon>
          </div>
        }
      </div>
      <!--      <div class="d-flex justify-content-end book-had-read">0:32 {{'Bits.HadRead' | translate}}</div>-->
    </div>
  </div>
</div>
@if (isContentVisible) {
  <div [ngClass]="isBeingEditedByUser && 'body-container-edit-mode'">
    <div>
      @if (bit?.body || isBeingEditedByUser) {
        <div class="bit-body p-2 bit-body-allow-highlight">
          @if (!isBeingEditedByUser) {
            <bitmark-text [text]="bit?.body"
                          [format]="getBodyFormat(bit.body, bit.format)">
            </bitmark-text>
          } @else {
            <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                        [bit]="bit"
                                        [bitContent]="bit.body | bitmark:bit.format:'json'"
                                        [updateContentEvent]="updateContentEventSubject"
                                        [isReadOnly]="false"
                                        [placeholder]="'Bits.PlaceholderBody' | translate"
                                        class="w-100">
            </bitmark-reader-text-editor>
          }
        </div>
      }
      @if (bit.book && !bit.isInfoOnly) {
        <div class="d-flex justify-content-center pb-4"
             [ngClass]="!bit?.body ? 'pt-4' : 'pt-2'">
          <button class="btn btn-action bit-button text--ellipsis d-inline-block"
                  (click)="open()"
                  [innerHTML]="'Shared.Open' | translate">
          </button>
          @if (bit.answer?.hasOpened && !bit.answer?.isDone && isTracked) {
            <button class="btn btn-action bit-button text--ellipsis d-inline-block ml-2"
                    [innerHTML]="'Shared.MarkAsDone' | translate"
                    (click)="markAsDone()">
            </button>
          }
        </div>
      }
    </div>
  </div>
}
@if (isBeingEditedByUser) {
  <div class="p-2 edit-container disable-annotations">
    <div class="d-flex flex-column align-items-end">
      <div class="d-flex w-100 px-2 py-1 align-items-center edit-input-container">
        <div class="edit-input-value text--ellipsis mr-1"
             [ngClass]="{'edit-input-value-placeholder': !selectedBookTitle}">{{ selectedBookTitle || 'Shared.Book' | translate }}
        </div>
        <button class="btn btn-action bit-button text--ellipsis d-inline-block state-button state-save-button"
                [innerHTML]="'Shared.ChooseContinue' | translate"
                (click)="openChooseBook()">
        </button>
      </div>
    </div>
    <div class="mt-2 d-flex flex-column align-items-end">

      <div class="d-flex w-100 px-2 py-1 align-items-center edit-input-container">
        <div class="edit-input-value text--ellipsis mr-1"
             [ngClass]="{'edit-input-value-placeholder': !selectedBookChapter}">{{ selectedBookChapter || 'Shared.Chapter' | translate }}
        </div>
        @if (selectedBookChapter) {
          <bitmark-icon name="trash-can-outline"
                        class="size-24px mr-1 cursor-pointer remove-book-btn"
                        (click)="removeChosenReference()">
          </bitmark-icon>
        }
        <button class="btn btn-action bit-button text--ellipsis d-inline-block state-button state-save-button"
                [innerHTML]="'Shared.ChooseContinue' | translate"
                [disabled]="!this.bit.book"
                (click)="openChooseReference()">
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-center m-2 mt-4">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
}
