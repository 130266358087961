<div class="bit-wrapper-header"
     [ngClass]="headerCss">
  <ng-content select="[header]"></ng-content>
</div>
<!--bit-shadow -->
<div #bitElement
     class="bit-wrapper1"
     [id]="'bit-' + bitWrapper.id"
     [ngClass]="wrapperCss"
     [style.margin]="context === 'timeline' && 0"
     [class.h-100]="fullScreen">
  <ng-content select="[dotmenu]"></ng-content>
  <!--  <div class="position-absolute" style="top:0; right: 50px; z-index: 999">{{bitWrapper.id}}</div>-->
  <div class="h-100 bit-wrapper-container">
    <!--bit-background blur-->
    <div #backgroundElement [ngClass]="backgroundCss"></div>
    @if (showShadow && context === 'notebook') {
      <div class="bit-shadow-filler"></div>
    }
    <bitmark-bit #bitmarkBitElement
                 [bitWrapper]="bitWrapper"
                 [bookId]="bookId"
                 [actions]="actions"
                 [options]="options"
                 [isEditable]="isBitEditable"
                 [isBeingEditedByUser]="isBeingEditedByUser"
                 [saveUserEdit]="saveUserEdit"
                 [allowAnswerSubmit]="allowAnswerSubmit"
                 [fontScale]="fontScale"
                 [context]="context"
                 [actionsCssClass]="actionsCss"
                 [queryParams]='queryParams'
                 [fullScreen]="fullScreen"
                 [focusedBit]="focusedBit"
                 [showFeedback]="context !== 'timeline'"
                 [isNavigateDirectlyToBook]="isNavigateDirectlyToBook"
                 [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                 [peerUser]="peerUser"
                 (openResource)="openResource.emit($event)"
                 (measureDisplaySize)="measureDisplaySize.emit($event)"
                 (toggleTocEvent)="toggleTocEvent.emit()"
                 (isBitBeingEdited)="onIsBitBeingEdited($event)"
                 (isBitAnnotationBeingEdited)="isBitAnnotationBeingEdited.emit($event)"
                 (editClosed)="editClosed.emit($event)"
                 (answerSubmitted)="answerSubmitted.emit($event)"
                 (navigateToBook)="navigateToBook.emit($event)"
                 (closeBook)="closeBook.emit()"
                 (isBookUnaccessibleToUser)='isBookUnaccessibleToUser.emit($event)'
                 (navigateToProduct)="navigateToProduct.emit($event)"
                 (bitEditInProgress)="bitEditInProgress.emit($event)"
                 (bitEditCompleted)="bitEditCompleted.emit($event)"
                 (assignHandIn)="assignHandIn.emit($event)"
                 (connectWithUser)="connectWithUser.emit($event)"
                 (editorCreated)="handleEditorCreated($event)">
    </bitmark-bit>

    <div class="bit-content-end position-relative w-100" style="height: 0"></div>

    @if (!hideActions) {
      <bitmark-bit-actions [ngClass]='{"invisible": isInvisibleActions}'
                           [bitWrapper]="bitWrapper"
                           [actions]="actions"
                           [iconActions]="iconActions"
                           [isBeingEditedByUser]="isBeingEditedByUser"
                           [cssClass]="actionsCss">
      </bitmark-bit-actions>
    }
    <ng-content select="[inside]"></ng-content>
  </div>
  <div class="bit-icon"></div>
</div>
<div class="w-100 bit-wrapper-child-content"
     [ngClass]="childContentCss">
  <ng-content select="[after]"></ng-content>
</div>
<div *ngIf="portal$ | async as portal">
  <ng-container [cdkPortalHost]="portal"></ng-container>
</div>
