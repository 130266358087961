@if (!additionalContent) {
  @for (action of actions; track action) {
    @if (!action.isHidden) {
      @if (!action.isSeparator && !action?.actions?.length && !action?.colors?.length && !action?.additionalContent) {
        <button preventDefault
                (click)="onOptionSelected(action)"
                [ngClass]="action.cssClass"
                class="dropdown-item-button py-1 px-2">
          @if (action.icon) {
            @if (action.icon.includes('://')) {
              <img [src]="action.icon"
                   [style.width.px]="20"
                   [style.height.px]="20"
                   [ngClass]="action.iconCssClass || 'text-black'"/>
            } @else {
              <app-icon [name]="action.icon"
                        [ngClass]="action.iconCssClass || 'text-black'">
              </app-icon>
            }
          }
          <span class="text-left pl-2"
                [innerHTML]="hasRawLabels ? action.label : (action.label | translate)">
        </span>
          <span class="pl-2 align-self-end ml-auto" [innerHTML]="action.shortcut"></span>
        </button>
      }
      @if (action.actions || action?.additionalContent) {
        <ng-template #subActions>
          <button class="dropdown-item-button py-1 px-2">
            @if (action.icon) {
              <app-icon [name]="action.icon"
                        [ngClass]="action.iconCssClass || 'text-black'">
              </app-icon>
            }
            <div class="text-left pl-2" [innerHTML]="hasRawLabels ? action.label : (action.label | translate)"></div>
            <app-icon name="chevron-right"
                      class="pl-2 align-self-end ml-auto"
                      [ngClass]="action.iconCssClass">
            </app-icon>
          </button>
        </ng-template>
        <app-dropdown class="d-inline-block ml-auto w-100"
                      dropdownClass="ml-1"
                      [placement]="['top-right', 'bottom-right']"
                      [actions]="action.actions"
                      [additionalContent]='action?.additionalContent'
                      [dropdownButton]="subActions"
                      (selected)="closed.emit()">
        </app-dropdown>
      }
      @if (action.isSeparator) {
        <div class="dropdown-separator"></div>
      }
    }
  }
}
@if (additionalContent) {
  <ng-container *ngTemplateOutlet="additionalContent"></ng-container>
}
