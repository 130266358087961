@if (emoticons) {
  <div class="emoticons d-flex flex-wrap justify-content-between p-1 pt-0 pb-0 unselectable">
    @for (emoticon of emoticons; track emoticon) {
      <button class="emoticons__item border-0 p-0 bg-transparent cursor-pointer"
              [innerHTML]="emoticon"
              (click)="emoticonSelected(emoticon)">
      </button>
    }
  </div>
}
