import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BitResourceAudio} from '../../bits.models';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-resource-audio',
  templateUrl: './resource-audio.component.html',
  styleUrls: ['./resource-audio.component.scss', '../../bits.scss']
})
export class ResourceAudioComponent implements OnInit, OnDestroy {
  @Input() bitResourceAudio?: BitResourceAudio;
  @Input() bitFormat?: BitmarkFormat;

  @ViewChild('audioElement') audioElement: ElementRef;

  onInViewportChange(evt: { target: Element, visible: boolean }) {
    const audioEl = this.audioElement.nativeElement as HTMLAudioElement;
    if (audioEl.src || !evt.visible) {
      return;
    }
    audioEl.src = this.bitResourceAudio.src;
    audioEl.load();
    // console.log('audio resource loaded', audioEl.src, audioEl.error);
  }

  ngOnInit() {
    if (!this.bitFormat) {
      this.bitFormat = BitmarkFormat.MM;
    }
  }

  ngOnDestroy() {
    this.audioElement = null;
  }
}

