<div class="contextual-menu-absolute pos-absolute px-1 cursor-pointer d-hidden" id="contextual-menu-absolute-code">
  <ng-template #tiptapCodeBubbleMenuDropdown>
    <div class="px-1">
      <span dropdownButton class="cursor-pointer contextual-menu-code-type contextual-menu-trigger">Code</span>
    </div>
  </ng-template>
  <bitmark-dropdown class="d-inline-block ml-auto float-right"
                    dropdownClass="lg reader-text-editor"
                    container="body"
                    [actions]="menuActions"
                    [dropdownButton]="tiptapCodeBubbleMenuDropdown">
  </bitmark-dropdown>
</div>

<ng-template #tiptapCodeBubbleMenu>
  <div class="tiptap-menu tiptap-bubble-menu-image text-left">
    <div>
      <button class="tiptap-bubble-item p-1 pl-4 w-100 text-left font-weight-bold"
              (click)="editor.chain().focus().setImageAlignmentClass('left').run()"
              [ngClass]="editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''">
        Code Coloring
      </button>
    </div>
    <div>
      <button class="tiptap-bubble-item p-1 pl-4 w-100 text-left"
              (click)="editor.chain().focus().toggleCodeBlock().setCodeBlock({language: 'csharp'}).run()"
              [ngClass]="editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''">
        Language: bitmark
      </button>
    </div>
    <div class="tiptap-menu-separator"></div>
    <div>
      <button class="tiptap-bubble-item p-1 pl-4 w-100 text-left" (click)="deleteNode()">
        Delete
      </button>
    </div>
  </div>
</ng-template>
