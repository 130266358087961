<div [class.survey-matrix-container-modal]="isInModal">
  <div class="w-100 d-flex survey-matrix-image-container" [class.flex-fill]="isInModal" [class.p-2]="isInModal">
    <div #matrix class="position-relative overflow-hidden" style="height: fit-content">
      <div [class.image-editing]="isEditing" (click)="setPointer($event)">
        <bitmark-resource-image [bitResourceImage]="bit.resource?.image"
                                [bit]="bit"
                                [hostBitType]="bit.type"
                                [bitFormat]="bit.format"
                                [isZoomable]="false"
                                class="d-block"
                                [style.max-width.%]="100">
        </bitmark-resource-image>
      </div>
      <div #pointer
           class="position-absolute step-pointer"
           [class.d-none]="!isPointerSet"
           [style.top]="tempPositionPointerTop || bit.answer?.position?.pointerTop || bit.pointerTop || 0"
           [style.left]="tempPositionPointerLeft || bit.answer?.position?.pointerLeft || bit.pointerLeft || 0">
        @if (isMe) {
          @if (currentUserDetails?.icon?.photoUrl) {
            <div [style.background-image]="'url(' + currentUserDetails?.icon?.photoUrl + ')'" class="is-me-pointer"></div>
          } @else {
            <bitmark-user-box-photo [userEmail]="currentUserDetails?.emails?.email || currentUserDetails?.emails?.ssoEmail" [fullName]="currentUserDetails?.icon?.fullName" [size]="'fit'"></bitmark-user-box-photo>
          }
        }
      </div>
    </div>
  </div>
  @if (isEditing) {
    <div class='d-flex justify-content-center pt-2' [class.mb-4]="isInModal">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancel()">
      </button>
      <button class='btn btn-action bit-button'
              (click)='save()'
              [innerText]="'Shared.Save' | translate">
      </button>
    </div>
  } @else if (allowAnswerSubmit && !bit.answer?.position) {
    <div class='d-flex justify-content-center pt-2'>
      <button class='btn btn-action bit-button'
              (click)='enableEdit()'
              [innerText]="bit.buttonCaption || ('Shared.SetContinue' | translate)">
      </button>
    </div>
  } @else if (bit.answer?.setDate) {
    <div class='pt-2'>
      <div class="survey-set-date">{{bit.answer.setDate | bitmarkDate:'shortDate'}}</div>
    </div>
  }
  @if (isInModal) {
    <div class="survey-matrix-close-modal">
      <bitmark-icon name="close"
                    class="size-20px cursor-pointer"
                    (click)="cancel()">
      </bitmark-icon>
    </div>
  }
</div>
