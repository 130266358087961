<ng-container>
  <button class='transparent-mask' [ngbPopover]='calendarContent'>
  </button>

  <ng-template #calendarContent>
    <div>
      @if (showTimePickerToggle) {
        <div>
          <button class='btn btn-block btn-outline-secondary d-flex align-items-center justify-content-center'
                  [ngbPopover]='calendarContent' type='button'
                  (click)='toggleDateTimeState($event)'>
            <bitmark-icon name="calendar-event" class="size-18px"></bitmark-icon>
          </button>
          <div class='mt-auto'>
            <ngb-timepicker #tp name='timepicker'
                            [ngModel]='datetime'
                            (ngModelChange)='onTimeChange($event)'
                            [seconds]='seconds'
                            [hourStep]='hourStep' [minuteStep]='minuteStep' [secondStep]='secondStep'></ngb-timepicker>
            <button (click)='onSave()'
                    class='btn btn-calm save-button w-100 mt-1'>Save
            </button>
          </div>
        </div>
      } @else {
        <div>
          <ngb-datepicker #dp name='datepicker' [ngModel]='datetime'
                          (ngModelChange)='onDateChange($event)'></ngb-datepicker>
          <button class='btn btn-block btn-outline-secondary d-flex align-items-center justify-content-center'
                  [disabled]='!datetime?.day' type='button'
                  (click)='toggleDateTimeState($event)'>
            <bitmark-icon name="clock-outline" class="text-manatee size-22px"></bitmark-icon>
          </button>
          <button (click)='onSave()'
                  class='btn btn-calm save-button w-100 mt-1'>Save
          </button>
        </div>
      }
    </div>
  </ng-template>
</ng-container>

<!--[ngbPopover]="timePickerContent"-->
