<div>
  @if (enableFilters) {
    <div class="d-flex flex-wrap py-5 filters-container">
      <button class="btn btn-action bit-button text--ellipsis"
              [class.selected]="readerLeContentService.currentFilterType === FilterTypes.CurrentWeek"
              (click)="filter(FilterTypes.CurrentWeek)">{{ 'Reader.LearningEvents.ThisWeek' | translate }}
        @if (filteredBitsCount && filteredBitsCount[FilterTypes.CurrentWeek] < 1) {
          <span class="ml-1">(0)</span>
        }
      </button>
      <button class="btn btn-action bit-button text--ellipsis"
              [class.selected]="readerLeContentService.currentFilterType === FilterTypes.Next14Days"
              (click)="filter(FilterTypes.Next14Days)">{{ 'Reader.LearningEvents.Next14Days' | translate }}
        @if (filteredBitsCount && filteredBitsCount[FilterTypes.Next14Days] < 1) {
          <span class="ml-1">(0)</span>
        }
      </button>
      <button class="btn btn-action bit-button text--ellipsis"
              [class.selected]="readerLeContentService.currentFilterType === FilterTypes.AllUpcoming"
              (click)="filter(FilterTypes.AllUpcoming)">{{ 'Reader.LearningEvents.AllUpcoming' | translate }}
        @if (filteredBitsCount && filteredBitsCount[FilterTypes.AllUpcoming] < 1) {
          <span class="ml-1">(0)</span>
        }
      </button>
      <button class="btn btn-action bit-button text--ellipsis"
              [class.selected]="readerLeContentService.currentFilterType === FilterTypes.Past"
              (click)="filter(FilterTypes.Past)">{{ 'Reader.LearningEvents.Past' | translate }}
        @if (filteredBitsCount && filteredBitsCount[FilterTypes.Past] < 1) {
          <span class="ml-1">(0)</span>
        }
      </button>
    </div>
  }

  <div class="bits-container">
    <div infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollUpDistance]="2"
         [infiniteScrollThrottle]="50"
         [alwaysCallback]="true"
         [infiniteScrollContainer]="'.infinite-scroll-container'"
         [scrollWindow]="false"
         (scrolled)="onScrollDown()"
         (scrolledUp)="onScrollUp()"
         [fromRoot]="true"
         class="bits-wrapper themed">
      <div class="d-flex flex-column"
           [style.opacity]="isLoading ? '0' : '1'">
        @if (enableFilters) {
          @if (bitBookContent?.length && bitBookContent[0].bit?.type === 'bit-book-summary') {
            <bitmark-bit-wrapper context="book"
                                 [bitWrapper]="bitBookContent[0]"
                                 [actions]="bitActions"
                                 [hideBitActions]="true"
                                 [isBeingEditedByUser]="false"
                                 [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                                 (toggleTocEvent)="toggleToc.emit($event)"
                                 (openResource)="onOpenResource($event)"
                                 (navigateToBook)="navigateToBook.emit($event)"
                                 (closeBook)="closeBook.emit()">
            </bitmark-bit-wrapper>
          }
          @for (entry of displayGroupedBitBookContent; track entry.title) {
            <div class="mt-4">
              <div class="d-flex align-items-center">
                @if (entry.bits?.length) {
                  <div class="d-flex cursor-pointer mr-1 toggle-btn" [class.rotate]="entry.isExpanded"
                       (click)="toggleDateEntry(entry)">
                    <bitmark-icon name="chevron-right" class="size-30px"></bitmark-icon>
                  </div>
                } @else {
                  <div class="size-30px mr-1"></div>
                }
                <div class="d-flex entry-date-title"
                     [class.cursor-pointer]="entry.bits?.length"
                     (click)="toggleDateEntry(entry)">
                  @if (entry.isToday) {
                    <div class="mr-1 today">{{ 'Timeline.Today' | translate }}</div>
                  }
                  <div>{{ entry.title }}</div>
                </div>
              </div>
              <div [(ngbCollapse)]="!entry.isExpanded">
                @for (bitWrapper of entry.bits; track getBitId(bitWrapper)) {
                  <bitmark-bit-wrapper context="book"
                                       [bitWrapper]="bitWrapper"
                                       [bookId]="bitBook.externalId"
                                       [actions]="bitActions"
                                       [hideBitActions]="true"
                                       [isBeingEditedByUser]="false"
                                       [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                                       (toggleTocEvent)="toggleToc.emit($event)"
                                       (openResource)="onOpenResource($event)"
                                       (navigateToBook)="navigateToBook.emit($event)"
                                       (closeBook)="closeBook.emit()">
                  </bitmark-bit-wrapper>
                }
              </div>
            </div>
          }
          <div class="p-6"></div>
        } @else {
          @for (bitWrapper of bitBookContent; track getBitId(bitWrapper); ) {
            <bitmark-bit-wrapper context="book"
                                 [bitWrapper]="bitWrapper"
                                 [bookId]="bitBook.externalId"
                                 [actions]="bitActions"
                                 [hideBitActions]="true"
                                 [isBeingEditedByUser]="false"
                                 [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                                 (toggleTocEvent)="toggleToc.emit($event)"
                                 (openResource)="onOpenResource($event)"
                                 (navigateToBook)="navigateToBook.emit($event)"
                                 (closeBook)="closeBook.emit()">
            </bitmark-bit-wrapper>
          }
        }
      </div>

      <div [hidden]="!isLoadingAdditionalContent"
           class="position-fixed w-100 d-flex justify-content-center"
           [class.d-flex]="isLoadingAdditionalContent"
           [style.top]="isLoadingAdditionalContentTop ? 0 : undefined"
           [style.bottom]="isLoadingAdditionalContentBottom ? 0 : undefined"
           style="left: 0; z-index: 100000">
        <div class="spinner delayed-spinner" style="width: fit-content">
          <bitmark-progress-spinner [size]="20"></bitmark-progress-spinner>
        </div>
      </div>
    </div>
    <div style="display: none">{{ bitbookContentVersion }}</div>
  </div>
</div>
