<div class="d-flex flex-column">
  <div class="d-flex justify-content-end align-items-start w-100 px-md-2 pt-md-1">
    <button class="bg-transparent border-0 p-0" (click)="dismiss()">
      <bitmark-icon name="close" class="size-20px text-black"></bitmark-icon>
    </button>
  </div>
  <div class="modal-body p-0">
    <div class="w-100 text-center"
         [ngStyle]="wrapperCssStyle">
      <img [src]="imageUrl"
           class="cursor-zoom-out"
           (click)="dismiss()"
           [ngStyle]="imageCssStyle"/>
    </div>
  </div>
</div>
