import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {JSONContent} from '@tiptap/core';
import {BaseBit} from '../bits.models';
import {BitmarkFormat} from '../../shared/models/bitmark.models';
import {BitmarkTextContentService, SubSink} from '../../shared';

@Component({
  selector: 'bitmark-bit-item-instruction',
  templateUrl: './bit-item-instruction.component.html',
  styleUrls: ['./bit-item-instruction.component.scss']
})
export class BitItemInstructionComponent implements OnInit, OnDestroy {
  @Input() bit?: BaseBit;
  @Input() isBeingEditedByUser: boolean;
  @Input() canBeEdited = false;
  @Input() brandingKey?: string;
  @Input() updateInstructionEvent?: Observable<any>;
  @Input() emitUpdates = false;
  @Output() onIsBeingEdited = new EventEmitter<BaseBit>();

  BitmarkFormats = BitmarkFormat;

  isLeadEmpty = false;
  isItemEmpty = false;
  isInstructionEmpty = false;
  isTechnicalTermEmpty = false;
  bitTypeBranding: string;

  private subSink = new SubSink();

  constructor(private bitmarkTextContentService: BitmarkTextContentService) {
  }

  ngOnInit() {
    this.bitTypeBranding = this.brandingKey || this.bit?.type;
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.isInstructionEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.instruction, BitmarkFormat.MM);
    this.isTechnicalTermEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.technicalTerm?.technicalTerm, BitmarkFormat.MM);

    this.subSink.sink = this.updateInstructionEvent?.subscribe(x => {
      this.isInstructionEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.instruction, BitmarkFormat.MM);
    });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  onItemChanged(bit: BaseBit, bitJson: JSONContent) {
    bit.item = bitJson?.content;

    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);

    if ((this.canBeEdited && !this.isBeingEditedByUser) || this.emitUpdates) {
      this.onIsBeingEdited.emit(this.bit);
    }
  }

  onLeadChanged(bit: BaseBit, bitJson: JSONContent) {
    bit.lead = bitJson?.content;

    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);

    if ((this.canBeEdited && !this.isBeingEditedByUser) || this.emitUpdates) {
      this.onIsBeingEdited.emit(this.bit);
    }
  }

  onInstructionChanged(bit: BaseBit, bitJson: JSONContent) {
    bit.instruction = bitJson?.content;

    this.isInstructionEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.instruction, BitmarkFormat.MM);

    if ((this.canBeEdited && !this.isBeingEditedByUser) || this.emitUpdates) {
      this.onIsBeingEdited.emit(this.bit);
    }
  }

  onTechnicalTermChanged(bit: BaseBit, bitJson: JSONContent) {
    bit.technicalTerm.technicalTerm = bitJson?.content;

    this.isTechnicalTermEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.technicalTerm.technicalTerm, BitmarkFormat.MM);

    if (this.canBeEdited && !this.isBeingEditedByUser) {
      this.onIsBeingEdited.emit(this.bit);
    }
  }
}
