<div class="resource-website-wrapper">
  @if (bitResource?.preview?.image) {
    <div class="resource-website-image"
         [ngClass]="cssClasses">
      @if (!bitResource?.websiteLink?.url) {
        <a [href]="bitResource?.websiteLink?.url || bitResource?.website?.url"
           target="_blank">
          <img [src]="bitResource?.preview?.image?.src || bitResource?.preview?.image?.src1x" alt="" (error)="onPreviewError()"/>
        </a>
      }
      @if (bitResource?.websiteLink?.url) {
        <img [src]="bitResource?.preview?.image?.src || bitResource?.preview?.image?.src1x" alt="" (error)="onPreviewError()"/>
      }
    </div>
  }
  @if (bitResource?.preview) {
    <div>
      <div class="resource-website-title" [innerHTML]="bitResource?.preview?.title | safeHtml"></div>
      <div class="resource-website-description" [innerHTML]="bitResource?.preview?.description | safeHtml"></div>
    </div>
  }
  <a [href]="bitResource?.websiteLink?.url || bitResource?.website?.url"
     target="_blank"
     class="d-inline-block resource-website-link">
    {{ 'Shared.Open' | translate }}
    <bitmark-icon name="box-arrow-up-right" class="size-13px"></bitmark-icon>
  </a>
</div>
