<div class="header-container">
  <bitmark-bit-item-instruction [bit]="bit"></bitmark-bit-item-instruction>
  @if (bit.resource) {
    <bitmark-resource [bitResource]="bit.resource"
                      [hostBit]="bit"
                      (open)="openResource.emit($event)">
    </bitmark-resource>
  }
</div>
<div class="body-container">
  <div class="bit-body bit-body-allow-highlight"
       [ngClass]="bit.type">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
</div>
<div class="footer-container">
  @if (bit?.footer) {
    <bitmark-text class="d-block bit-footer"
                  [text]="bit.footer"
                  [format]="bit.format">
    </bitmark-text>
  }
</div>
