@if (!isFinished) {
  <div class="d-flex justify-content-center pt-4 pb-4 flashcards-header"
       [class.flashcard-header-modal]="isInModal"
       #headerContainer>
    <div class="mr-6 d-flex">
      <div class="mr-1 flashcards-pot"
           [ngClass]="'pot-' + -1"
           [class.cursor-pointer]="appFlashcardsBit?.answer?.normal?.wrong?.length"
           [class.selected-flashcard-pot]="currentPotIndex === -1"
           (click)="appFlashcardsBit?.answer?.normal?.wrong?.length && changePot($event?.target, -1)">
        <div class="flashcards-pot-value">{{ appFlashcardsBit?.answer?.normal?.wrong?.length || 0 }}</div>
      </div>
      <div class="flashcards-wrong" #wrongEmoji></div>
    </div>
    <div class="mr-6 flashcards-pot flashcards-count"
         [ngClass]="'pot-' + 0"
         [class.cursor-pointer]="flashcardBits.length - ((appFlashcardsBit?.answer?.normal?.wrong?.length || 0) + (appFlashcardsBit?.answer?.normal?.right?.length || 0))"
         [class.selected-flashcard-pot]="currentPotIndex === 0"
         (click)="changePot($event?.target, 0)">
      <div class="flashcards-pot-value">
        {{ flashcardBits.length - ((appFlashcardsBit?.answer?.normal?.wrong?.length || 0) + (appFlashcardsBit?.answer?.normal?.right?.length || 0)) }}
      </div>
    </div>
    <div class="d-flex">
      <div class="flashcards-right" #rightEmoji></div>
      <div class="ml-1 flashcards-pot"
           [ngClass]="'pot-' + 1"
           [class.cursor-pointer]="appFlashcardsBit?.answer?.normal?.right?.length"
           [class.selected-flashcard-pot]="currentPotIndex === 1"
           (click)="appFlashcardsBit?.answer?.normal?.right?.length && changePot($event?.target, 1)">
        <div class="flashcards-pot-value">
          {{ appFlashcardsBit?.answer?.normal?.right?.length || 0 }}
        </div>
      </div>
    </div>
  </div>
  <div class="position-relative flashcards-content" [class.flashcards-content-modal]="isInModal">
    @if (!card) {
      <div class="d-flex justify-content-center align-items-center h-100 flashcards-cards-empty">No more cards left.
      </div>
    } @else {
      <div class="flashcards-card-container" #cardContainer>
        <bitmark-app-flashcards-card
          [cardState]="cardState"
          [frontCard]="card.front"
          [backCard]="card.back"
          [showActionButtons]="!isMobile || isInModal"
          [currentCardIndex]="cardIndex + 1"
          [cardsCount]="flashcardPoolBits.length"
          [markedAsRight]="appFlashcardsBit?.answer?.normal?.right?.includes(card.bitId)"
          [markedAsWrong]="appFlashcardsBit?.answer?.normal?.wrong?.includes(card.bitId)"
          (markAsRight)="markAsRight()"
          (markAsWrong)="markAsWrong()"
          (goToNext)="goToNext()">
        </bitmark-app-flashcards-card>
      </div>
    }
    @if (prevCard) {
      <div class="prev-flashcards-card-container" #prevCardContainer>
        <bitmark-app-flashcards-card
          [cardState]="'front'"
          [frontCard]="prevCard.front"
          [backCard]="prevCard.back"
          [showActionButtons]="!isMobile || isInModal"
          [currentCardIndex]="prevCardIndex"
          [cardsCount]="flashcardPoolBits.length"
          [markedAsRight]="appFlashcardsBit?.answer?.normal?.right?.includes(prevCard.bitId)"
          [markedAsWrong]="appFlashcardsBit?.answer?.normal?.wrong?.includes(prevCard.bitId)"
          (markAsRight)="markAsRight()"
          (markAsWrong)="markAsWrong()"
          (goToNext)="goToNext()">
        </bitmark-app-flashcards-card>
      </div>
    }
  </div>
  @if (!isMobile || isInModal) {
    <div class="d-flex justify-content-center mt-2 flashcards-navigation">
      <div class="d-flex justify-content-center align-items-center flashcards-navigation-content">
        <div class="arrows p-1 position-relative"
             [class.arrows-background]="!!this.flashcardPoolBits.length">
          @if (!!this.flashcardPoolBits.length) {
            <div class="arrows arrows-background left-arrow p-1">
              <bitmark-icon name="arrow-left"
                            class="size-25px d-block"
                            (click)="goToPrevious()">
              </bitmark-icon>
            </div>
          }
          <bitmark-icon name="arrow-left"
                        class="size-40px d-block right-arrow"
                        [style.visibility]="!!this.flashcardPoolBits.length ? 'visible' : 'hidden'"
                        (click)="goToNext()">
          </bitmark-icon>
        </div>
      </div>
    </div>
  }
} @else {
  <div>
    <div class="finished-header"></div>
    <div class="w-100 d-flex flex-column justify-content-center align-items-center finished-card-content">
      <div class="flashcards-finished-right-icon"></div>
      <div class="flashcards-finished-label">{{ appFlashcardsBit.answer?.normal?.right?.length }} out
        of {{ flashcardBits.length }}
      </div>
    </div>
    <div class="d-flex justify-content-center mt-2 flashcards-navigation">
      <div class="d-flex justify-content-center align-items-center  flashcards-navigation-content">
        <div class="p-1 arrows arrows-background">
          <bitmark-icon
            name="arrow-left"
            class="size-40px d-block right-arrow"
            (click)="restart()">
          </bitmark-icon>
        </div>
      </div>
    </div>
  </div>
}
