<div class="d-flex flex-column" [ngClass]="hostCss" [class.img-centered]="centered">
  @if (bitResourceImage?.src || bitResourceImage?.src1x || bitResourceImage?.src2x || bitResourceImage?.src3x) {
    <div [class.p-0]="fillUp"
         [class.cursor-zoom-in]="isZoomable"
         [style.max-width.%]="100"
         [style.max-height.%]="100"
         [class.w-100]="!imageWidth"
         [style.width.px]="fitMode === 'width' ? imageWidth : ''"
         [style.height.px]="fitMode === 'height' ? imageHeight : ''"
         [style.aspect-ratio]="imageWidth ? imageWidth/imageHeight : ''"
         [style.overflow-x]="fitMode === 'width' ? 'hidden' : 'auto'"
         style="overflow-y: hidden"
         class="position-relative d-flex flex-column"
         (click)="imageClicked.emit()">
      <img #imageElement
           class="h-100"
           [style.width]="setImgWidth ? fitMode === 'width' ? '100%' : 'min-content' : ''"
           [style.max-width]="setImgWidth ? fitMode === 'width' ? '100%' : 'min-content' : ''"
           [width]="imageWidth"
           [height]="imageHeight"
           loading="lazy"
           decoding="async"
           [alt]="bitResourceImage?.alt || ''"
           [src]="(bitResourceImage?.src || bitResourceImage?.src1x || bitResourceImage?.src2x || bitResourceImage?.src3x) | rewriteUrlResize:'reader-resource':hostBitType"
           (load)="onImageLoaded()"
           (error)="onImageLoadError($event)">
    </div>
  }
  @if (bitResourceImage?.caption && !fillUp && !isBeingEditedByUser) {
    <bitmark-text class="w-100 image-caption"
                  [text]="bitResourceImage.caption"
                  [format]="bitFormat">
    </bitmark-text>
  } @else if (isBeingEditedByUser) {
    <bitmark-reader-simple-text-editor class="w-100 image-caption"
                                       [bit]="bit"
                                       [bitContent]="bitResourceImage.caption"
                                       [placeholder]="'Shared.Caption' | translate"
                                       [isReadOnly]="false"
                                       (updateBit)="onCaptionChanged($event)">
    </bitmark-reader-simple-text-editor>
  }

  @if (!(bitResourceImage?.src || bitResourceImage?.src1x || bitResourceImage?.src2x || bitResourceImage?.src3x | rewriteUrlResize:'reader-resource':hostBitType)) {
    <div class="w-100 h-100">
      <ng-content></ng-content>
    </div>
  }
</div>

