import {Injectable} from '@angular/core';
import {AppConfig} from 'app.config';

export class Paginator {
  offset = 0;
  limit = 0;
  total = 0;

  constructor(total, limit, offset) {
    this.total = total;
    this.limit = limit;
    this.offset = offset;
  }

  goToPreviousPage(): number {
    const end = this.offset + this.limit;
    if (this.offset <= 0) {
      return this.offset;
    } else if (end === this.total) {
      this.offset = Math.max(1, this.offset - this.limit);
    } else {
      this.offset -= this.limit;
    }

    return this.offset;
  }

  goToNextPage(): number {
    const end = this.offset + this.limit;
    this.offset = Math.min(end, this.total);
    return this.offset;
  }
}

@Injectable({providedIn: 'root'})
export class PaginatorService {
  gridBreakpoints = AppConfig.gridBreakpoints;

  getPageItemsLimit(): number {
    let limit = 8;
    if (window.innerWidth > this.gridBreakpoints.md) {
      limit = 16;
    }

    if (window.innerWidth > this.gridBreakpoints.lg) {
      limit = 20;
    }
    return limit;
  }

  getPaginator(total, limit, offset = 0): Paginator {
    return new Paginator(total, limit, offset);
  }
}
