@if (user) {
  <div class="d-flex flex-row align-items-center pt-1 pb-1 overflow-hidden unselectable">
    <app-user-box-simple [user]="user"
                         class="user-box-peer__photo"
                         [photoSize]="photoSize"
                         [dotSize]="dotSize"
                         [canOpenAvatar]="canOpenAvatar">
    </app-user-box-simple>
    <div class="d-flex flex-column pl-2 user-box-peer"
         [ngClass]="[textClass, isBlocked ? 'text-gray' : 'text-white']">
      <div class="name-label"
           [class.one-line]="showEnrollment || user.isTyping"
           [innerHTML]="user.fullNameHtml || user.fullName">
      </div>
      @if (showEnrollment && !user.isTyping) {
        <div class="small" [innerHTML]="user.classNameHtml || user.enrollment?.className"></div>
      }
      @if (user.isTyping) {
        <div class="text-success font-weight-bold small">typing...</div>
      }
      <ng-content></ng-content>
    </div>
    <div class="mt-auto mb-auto ml-auto mr-0">
      @if (+user.unreadMessages && showUnreadMessages) {
        <div class="badge badge-pill badge-purple small f-12"
             [innerHTML]="user.unreadMessages">
        </div>
      }
    </div>
  </div>
}
