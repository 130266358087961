@if (product) {
  <div class='w-100 page-product-container d-flex flex-row'>
    <div class='page-product d-flex flex-column justify-content-center align-items-center'
         [ngClass]="{'entry-wide': isWideEntry, 'cursor-pointer': !product?.bitbookDoesNotExist, 'cursor-default': product?.bitbookDoesNotExist}">
      <div class='book-cover d-flex align-items-end ml-auto mr-auto position-relative'>
        <bitmark-book-cover (click)='openProductPage()'
                            (onIsWideImage)='setWideImage()'
                            class='w-100 h-100'
                            [coverImage]="product?.media?.length && product?.media[0].file.url"
                            [isFullyBranded]='true'
                            [isWideCoverAllowed]='true'
                            [isProductCover]="true"
                            [isNotExist]="product?.productDoesNotExist || product?.isInWrongSpace"
                            [title]='product?.title'>
        </bitmark-book-cover>
<!--        <ng-template #dropdownButton>-->
<!--          <div class='p-1 cursor-pointer'>-->
<!--            <div class='dot calm'></div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--        <bitmark-dropdown class='d-inline-block ml-auto book-menu'-->
<!--                          dropdownClass='auto-width'-->
<!--                          [placement]="['top-left']"-->
<!--                          [actions]='statuses'-->
<!--                          [dropdownButton]='dropdownButton'>-->
<!--        </bitmark-dropdown>-->
      </div>
      <div class='mt-2 book-texts px-lg-1 px-0' (click)='openProductPage()'>
        @if (publisher) {
          <div class='book-publisher mb-0 text-center'
               [innerText]='publisher'>
          </div>
        }
        @if (title) {
          <div class='book-title text-center line-clamp'
               [innerText]='title + (product?.bitbookDoesNotExist && !product?.productDoesNotExist ? "&nbsp;🔒" : "")'>
          </div>
        }
        @if (product?.meta) {
          <div>
            @if (BookType.Paperbook === product.meta.type) {
              <div class='text-center book-subtitle'>Paper</div>
            } @else if (BookType.PDFBook === product.meta.type) {
              <div class='text-center book-subtitle'>PDF</div>
            }
          </div>
        }
        @if (product?.meta?.handInStatus) {
          <div class="text-center hand-in-emoji">
            @if (product?.meta?.handInStatus === HandInStatus.NotHandedIn) {
              ✏️
            }
            @if (product?.meta?.handInStatus === HandInStatus.Rejected) {
              ❌
            }
            @if (product?.meta?.handInStatus === HandInStatus.Approved) {
              🏅
            }
            @if (product?.meta?.handInStatus === HandInStatus.HandedIn || product?.meta?.handInStatus === HandInStatus.Assigned) {
              ✋
            }
          </div>
        }
      </div>
    </div>
  </div>
}
