import packageInfo from '../../../../package.json';

export const environment = {
  version: packageInfo.version,
  name: 'App',
  production: true,
  logLevel: 4,
  baseUrl: 'timeline-api/api',
  wsUrl: '/',
  heartBeatInterval: 5000,
  facebookClientId: '118696756175245',
  googleClientId: '948948245236-02cue8fg2q0leafm5epcfot18n4jg45u.apps.googleusercontent.com',
  appleClientId: 'com.getmorebrain.login',
  graphQlEndpoint: 'https://unchained.bitmark.cloud/graphql',
  bitbookApiEndpoint: '/bitmark-api/v2',
  openAiApiUrl: '/timeline-api/api',
  ltiHandlingApiBaseUrl: '/timeline-api/api',
  stripeClientId: 'pk_live_u3Cp2FUABonZnpM3GOw9K9G700Ly3VkwZm',
  hotjarSiteId: 3476127,
  VAPID_PUBLIC_KEY: 'BPjLZefW5tgtBvNTdRQP5WcSwcVHtT7UPiSQPg-DGniQjZNpAPUncQ05-S5SwyMBf6ZPJpzlUBvZPmr1a4dccDk',
  devCycleServerKey: 'dvc_client_5f47ccb1_b5ed_47d0_bee8_9e420f0dbfe7_0f4a2d6',
  stripePublishableKey: 'pk_test_1AvG6Ghbcdyvrw4rcqZxGUUY00SNTDIydd'
};
