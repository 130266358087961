import {Injectable} from "@angular/core";

@Injectable({providedIn: "root"})
export class WindowService {
  getBaseUrl(): string {
    const port = [80, undefined, ""].indexOf(window.location.port) !== -1
      ? "" :
      `:${window.location.port}`;
    return `${window.location.protocol}//${window.location.hostname}${port}`;
  }

  getHostName(): string {
    return window.location.hostname;
  }

  getQueryParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
