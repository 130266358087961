import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BrowserStorageService} from '../../../shared/browser-storage/browser-storage.service';

export enum FilterTypes {
  CurrentWeek = 'current-week',
  Next14Days = 'next-14',
  AllUpcoming = 'all-upcoming',
  Past = 'past'
}

@Injectable({providedIn: 'root'})
export class ReaderLeContentService {
  public currentFilterType: FilterTypes = null;

  private readonly READER_COLLAPSED_DATES_PREFIX = 'reader-le-collapsed-dates-';
  private readonly READER_EXPANDED_BITS_PREFIX = 'reader-expanded-bits-';
  private readonly READER_COLLAPSED_BITS_PREFIX = 'reader-current-week-collapsed-bits-';
  private readonly READER_LAST_FILTER_PREFIX = 'reader-le-last-filter-';

  private readersCollapsedDateToggles: { [key: string]: Array<string> } = {};
  private readersExpandedBits: { [key: string]: Array<string> } = {};
  private readersCurrentWeekCollapsedBits: { [key: string]: Array<string> } = {};

  constructor(private browserStorageService: BrowserStorageService) {
  }

  loadCollapsedDateToggles(bookId: string) {
    if (!bookId) {
      return;
    }

    const sub$ = this.browserStorageService.idb.get(`${this.READER_COLLAPSED_DATES_PREFIX}${bookId}`).subscribe(data => {
      this.readersCollapsedDateToggles[bookId] = data || [];

      sub$.unsubscribe();
    });
  }

  loadExpandedBits(bookId: string) {
    if (!bookId) {
      return;
    }

    const sub$ = this.browserStorageService.idb.get(`${this.READER_EXPANDED_BITS_PREFIX}${bookId}`).subscribe(data => {
      this.readersExpandedBits[bookId] = data || [];

      sub$.unsubscribe();
    });
  }

  loadCollapsedBits(bookId: string) {
    if (!bookId) {
      return;
    }

    const sub$ = this.browserStorageService.idb.get(`${this.READER_COLLAPSED_BITS_PREFIX}${bookId}`).subscribe(data => {
      this.readersCurrentWeekCollapsedBits[bookId] = data || [];

      sub$.unsubscribe();
    });
  }

  storeLastFilter(bookId: string, lastFilter: string) {
    if (!bookId) {
      return;
    }

    const sub$ = this.browserStorageService.idb.store(`${this.READER_LAST_FILTER_PREFIX}${bookId}`, lastFilter).subscribe(() => {
      sub$.unsubscribe();
    });
  }

  getLastFilter(bookId: string): Observable<string> {
    if (!bookId) {
      return;
    }

    return this.browserStorageService.idb.get(`${this.READER_LAST_FILTER_PREFIX}${bookId}`);
  }

  isDateToggleCollapsed(bookId: string, date: Date): boolean {
    return this.readersCollapsedDateToggles[bookId]?.includes(date.toLocaleDateString('en-US'));
  }

  isBitExpanded(bookId: string, bitId: string): boolean {
    if (this.currentFilterType === FilterTypes.CurrentWeek) {
      return !this.readersCurrentWeekCollapsedBits[bookId]?.includes(bitId);
    }
    return this.readersExpandedBits[bookId]?.includes(bitId);
  }

  addCollapsedDateToggle(bookId: string, date: Date) {
    if (!bookId) {
      return;
    }

    if (!this.readersCollapsedDateToggles[bookId]) {
      this.readersCollapsedDateToggles[bookId] = [];
    }
    this.readersCollapsedDateToggles[bookId].push(date.toLocaleDateString('en-US'));

    const sub$ = this.browserStorageService.idb.store(`${this.READER_COLLAPSED_DATES_PREFIX}${bookId}`, this.readersCollapsedDateToggles[bookId])
      .subscribe(() => {
        sub$.unsubscribe();
      });
  }

  removeCollapsedDateToggle(bookId: string, date: Date) {
    if (!bookId) {
      return;
    }

    if (!this.readersCollapsedDateToggles[bookId]) {
      this.readersCollapsedDateToggles[bookId] = [];
      this.browserStorageService.idb.clear(`${this.READER_COLLAPSED_DATES_PREFIX}${bookId}`);
    } else {
      this.readersCollapsedDateToggles[bookId] = this.readersCollapsedDateToggles[bookId].filter(x => x !== date.toLocaleDateString('en-US'));
    }

    const sub$ = this.browserStorageService.idb.store(`${this.READER_COLLAPSED_DATES_PREFIX}${bookId}`, this.readersCollapsedDateToggles[bookId])
      .subscribe(() => {
        sub$.unsubscribe();
      });
  }

  toggleBitExpandState(bookId: string, bitId: string, isExpanded: boolean) {
    if (this.currentFilterType === FilterTypes.CurrentWeek) {
      if (isExpanded) {
        this.removeFromCollection(this.readersCurrentWeekCollapsedBits, this.READER_COLLAPSED_BITS_PREFIX, bookId, bitId);
      } else {
        this.addToCollection(this.readersCurrentWeekCollapsedBits, this.READER_COLLAPSED_BITS_PREFIX, bookId, bitId);
      }
    } else {
      if (isExpanded) {
        this.addToCollection(this.readersExpandedBits, this.READER_EXPANDED_BITS_PREFIX, bookId, bitId);
      } else {
        this.removeFromCollection(this.readersExpandedBits, this.READER_EXPANDED_BITS_PREFIX, bookId, bitId);
      }
    }
  }

  private addToCollection(collection: { [key: string]: Array<string> }, prefix: string, bookId: string, bitId: string) {
    if (!bookId) {
      return;
    }

    if (!collection[bookId]) {
      collection[bookId] = [];
    }
    collection[bookId].push(bitId);

    const sub$ = this.browserStorageService.idb.store(`${prefix}${bookId}`, collection[bookId])
      .subscribe(() => {
        sub$.unsubscribe();
      });
  }

  private removeFromCollection(collection: { [key: string]: Array<string> }, prefix: string, bookId: string, bitId: string) {
    if (!bookId) {
      return;
    }

    if (!collection[bookId]) {
      collection[bookId] = [];
      this.browserStorageService.idb.clear(`${prefix}${bookId}`);
    } else {
      collection[bookId] = collection[bookId].filter(x => x !== bitId);
    }

    const sub$ = this.browserStorageService.idb.store(`${prefix}${bookId}`, collection[bookId])
      .subscribe(() => {
        sub$.unsubscribe();
      });
  }
}
