<div class="d-flex flex-column position-relative">
  @if (modelHasValue) {
    <span class="cursor-pointer" (click)="time.click()">{{ _ngModel | bitmarkDate:'shortTime' }}</span>
  } @else {
    <span class="cursor-pointer placeholder" (click)="time.click()">{{ placeholder }}</span>
  }
  <input
    [(ngModel)]="_ngModel"
    [ngbPopover]="timePopTemplate"
    container="body"
    [autoClose]="'outside'"
    [placement]="['bottom', 'top']"
    #time
    class="edit-input align-self-center"/>
  <ng-template #timePopTemplate>
    <ngb-timepicker
      size='small'
      [(ngModel)]="_ngModel"
      [meridian]="!is24HourTimeFormat"
      (ngModelChange)="modelChanged($event)"
    ></ngb-timepicker>
  </ng-template>
</div>
