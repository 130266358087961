@if (bitResource) {
  <div class="bit-resource-container" [ngClass]="isBeingEditedByUser && 'position-relative p-2 resource-edit'">
    @if (bitResource?.article && showArticle) {
      <bitmark-resource-article [bitResourceArticle]="bitResource?.article"
                                class="d-block pb-1">
      </bitmark-resource-article>
    }
    @if (bitResource?.audio) {
      <bitmark-resource-audio [bitResourceAudio]="bitResource?.audio"
                              [bitFormat]="hostBit?.format"
                              class="d-block w-100"
                              [class.mt-1]="bitResource?.image"
                              [class.resource-edit-audio]="isBeingEditedByUser">
      </bitmark-resource-audio>
    }
    @if (bitResource?.image && !(isUploadingRequest || isProcessingRequest)) {
      <bitmark-resource-image [bit]="hostBit"
                              [bitResourceImage]="bitResource?.image"
                              [hostBitType]="hostBit.type"
                              [bitFormat]="hostBit?.format"
                              [uploadDetails]="bitResource?.private?.uploadDetails"
                              [fillUp]="fillUp"
                              [isBeingEditedByUser]="isBeingEditedByUser"
                              [isZoomable]="isImageZoomable"
                              class="d-block"
                              [style.max-width.%]="100"
                              (measureDisplaySize)="onImageResourceMeasured($event)"
                              (imageClicked)="onImageResourceClicked()"
                              (captionChanged)="onCaptionChanged('image', $event)">
      </bitmark-resource-image>
      @if (imageBit && canBeEdited && showReplaceButton) {
        <div class="replace-image-button">
          <button class="btn btn-action bit-button"
                  [innerHTML]="'Shared.ReplaceContinue' | translate"
                  (click)="fileImageInput.nativeElement.click()">
          </button>
        </div>
      }
    }
    @if (bitResource?.imageLink || bitResource?.imageOnline) {
      <bitmark-resource-image-link [bitResourceImageLink]="bitResource?.imageLink || bitResource?.imageOnline"
                                   (click)="onImageResourceClicked()">
      </bitmark-resource-image-link>
    }
    @if (bitResource?.video) {
      <bitmark-resource-video [bit]="hostBit"
                              [bitResourceVideo]="bitResource?.video"
                              [bitFormat]="hostBit?.format"
                              [hostBitType]="hostBit.type"
                              [isBeingEditedByUser]="isBeingEditedByUser"
                              (captionChanged)="onCaptionChanged('video', $event)">
      </bitmark-resource-video>
    }
    @if (bitResource?.videoLink || bitResource?.videoOnline || bitResource?.videoEmbed) {
      <bitmark-resource-video-link [bit]="hostBit"
                                   [bitResource]="bitResource"
                                   [bitFormat]="hostBit?.format"
                                   [hostBitType]="hostBit.type"
                                   [isBeingEditedByUser]="isBeingEditedByUser"
                                   class="w-100"
                                   (captionChanged)="onCaptionChanged((bitResource?.videoLink && 'videoLink') || (bitResource?.videoOnline && 'videoOnline') || (bitResource?.videoEmbed && 'videoEmbed'), $event)">
      </bitmark-resource-video-link>
    }
    @if (bitResource?.websiteLink || bitResource?.website) {
      <bitmark-resource-website-link [bitResource]="bitResource"
                                     class="d-inline p-0">
      </bitmark-resource-website-link>
    }
    @if (bitResource?.document) {
      <bitmark-resource-document-link [bitResource]="bitResource"></bitmark-resource-document-link>
    }
    @if (isBeingEditedByUser) {
      <div class="btn btn-action empty-resource-btn bg-calm flex-grow-0 flex-shrink-0"
           [class.empty-resource-btn-for-audio]="bitResource?.audio"
           (click)="deleteResource()">
        <bitmark-icon name="trash-fill" class="trash-icon"></bitmark-icon>
      </div>
    }
  </div>
}

@if ((isBeingEditedByUser && !bitResource) || (imageBit && canBeEdited)) {
  <div>
    @if (!(isUploadingRequest || isProcessingRequest || isRecordingAudio || isRecordingVideo) && (isBeingEditedByUser && !bitResource)) {
      <div class="d-flex flex-wrap justify-content-center edit-choice-container">
        @for (option of editOptions; track option) {
          <bitmark-resource-button [type]="option.type"
                                   [title]="option.title"
                                   [subtitle]="option.subtitle"
                                   [actions]="option.actions">
          </bitmark-resource-button>
        }
      </div>
    }
    @if (isUploadingRequest || isProcessingRequest) {
      <div class="position-relative progress-status"
           [ngClass]="{'progress-status-video': isUploadingVideo}">
        @if (!isUploadingVideo) {
          <bitmark-document-icon [document]="uploadPreviewDocument"
                                 class="d-block mt-4 mb-5">
          </bitmark-document-icon>
        }
        @if (isUploadingVideo) {
          <div class="px-6 progress-youtube-logo">
            <img [src]="'../../../assets/images/logo-simple-youtube.png'" alt="Video"/>
            <div class="mt-1">{{ 'Shared.Video' | translate }}</div>
          </div>
        }
        <div class="progress-container">
          <div class="progress">
            <div class="progress-bar" [ngClass]="{'progress-bar-striped': progressIsIndeterminate}"
                 [style.width.%]="progressPercentage"></div>
          </div>
        </div>

        @if (isUploadingRequest) {
          <p class="mt-2 text-center dropzone-converting-label">Uploading...</p>
        }
        @if (isProcessingRequest) {
          <p class="mt-2 text-center dropzone-converting-label">Processing...</p>
        }

        <div class="btn btn-action empty-resource-btn bg-calm flex-grow-0 flex-shrink-0"
             (click)="cancelUpload()">
          <bitmark-icon name="close"></bitmark-icon>
        </div>
      </div>
    }
    @if (isRecordingAudio) {
      <bitmark-resource-audio-recording-edit [isUploadingRecording]="isUploadingAudioRecording"
                                             (onStop)="audioRecordingStopped($event)"
                                             (onCancel)="cancelAudioRecording()">
      </bitmark-resource-audio-recording-edit>
    }
    @if (isRecordingVideo) {
      <bitmark-resource-video-recording-edit [isUploadingRecording]="isUploadingVideoRecording"
                                             (onStop)="videoRecordingStopped($event)"
                                             (onCancel)="cancelVideoRecording()">
      </bitmark-resource-video-recording-edit>
    }

    <input accept="image/*" hidden type="file" [attr.multiple]="null" #fileImageInput (change)="uploadImage()">
    <input accept="audio/*" hidden type="file" [attr.multiple]="null" #fileAudioInput (change)="uploadAudio()">
    <input accept="video/*" hidden type="file" [attr.multiple]="null" #fileVideoInput (change)="uploadVideo()">
    <input accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.mp3,.ogg,.mp4,.avi,.mov,.aac,.png,.gif,.jpg,.txt,.zip" hidden
           type="file" [attr.multiple]="null" #fileDocumentInput
           (change)="uploadDocument()">
  </div>
}
