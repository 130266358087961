<div class="position-absolute w-100">
  <svg class="w-100 h-100">
    <g id="sticker_fav_no_shadow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="sticker_fav">
        <path
          d="M0,60.7894737 C48.8230026,59.5964912 115.753121,62.2107031 200.790356,68.6321093 C285.82759,75.0535155 322.531203,79.8428124 310.901196,83 L310.901196,19.0677419 L173.748429,19.0677419 L0,16 L0,60.7894737 Z"
          id="Path-2" fill="#000000" opacity="0.145228795"
          transform="translate(156.500000, 50) scale(-1, 1) translate(-156.500000, -51) "></path>
        <rect id="Rectangle"
              x="13"
              y="0"
              width="337"
              height="60"
              class="favorite-rect"
              [ngStyle]="{ fill: bit.color?.primary }">
        </rect>
        <rect id="Rectangle"
              x="320"
              y="0"
              width="50"
              height="60"
              class="favorite-polygon"
              [ngStyle]="{ fill: bit.color?.secondary }">
        </rect>
        <image id="icons8-heart-100" x="330" y="15" width="30" height="30"
               href="assets/images/annotations/favorite-image.png">
        </image>
      </g>
    </g>
  </svg>
</div>
<div class="position-relative p-1 ml-4 reader-text-editor annotation-body">
  @if (!canBeEdited) {
    <bitmark-text [text]="$any(bit.content)"
                  [format]="BitmarkFormats.MM">
    </bitmark-text>
  } @else {
    <bitmark-reader-simple-text-editor #editorComponent
                                       (updateBit)="onUpdate($event)"
                                       (click)="enterEditMode()"
                                       [bit]="bit"
                                       [bitContent]="bit.content"
                                       [isReadOnly]="false"
                                       [height]="50"
                                       [hasLists]="true"
                                       class="w-100"
                                       (created)="editorCreated.emit(editorComponent)">
    </bitmark-reader-simple-text-editor>
  }
  @if(user){
    <p style="position: absolute; bottom: -42px; right: -50px">{{user?.fullName || user.email}}</p>
  }
</div>
