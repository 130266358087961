<div>
  <bitmark-article [isBeingEditedByUser]="isBeingEditedByUser"
                   [canBeEdited]="canBeEdited"
                   [isResourceType]="true"
                   [bit]="bit"
                   [bitSubtype]="BitType.ImagesLogoGrave"
                   (cancelBitChanges)="cancelBitChanges.emit($event)"
                   (saveInlineBit)="saveInlineBit.emit($event)"
                   (editorCreated)="editorCreated.emit($event)">
  </bitmark-article>
  <div class="d-flex flex-row flex-wrap justify-content-center">
    @for (imageLogo of imageLogos; track imageLogo; let i = $index) {
      <div class="d-flex flex-column image-logo-grave">
        <bitmark-resource [bitResource]="imageLogo"
                          [hostBit]="bit"
                          class="d-flex justify-content-center align-items-center">
        </bitmark-resource>
        <bitmark-text [text]="imageCaptions[i]"
                      [format]="bit?.format">
        </bitmark-text>
      </div>
    }
  </div>
</div>
