<div class="position-relative d-flex flex-column resource-video-wrapper"
     [class.resource-video-wrapper-size]="!isStreaming">
  <div class="w-100 resource-video-recorder-stream-wrapper" [class.switch-camera]="isCameraSwitching">
    <video #videoStream
           [hidden]="isCameraSwitching"
           playsinline
           muted
           [volume]="0"
           class="w-100"
           [style.aspect-ratio]="aspectRatio">
      Your browser does not support the video element.
    </video>
    <canvas #videoCanvas
            [hidden]="!isCameraSwitching"
            class="video-canvas"
            [style.aspect-ratio]="aspectRatio">
    </canvas>
  </div>
  <div class="flex-grow-1"></div>
  <div class="resource-audio-visualizer">
    <canvas class="w-100 h-100" #visualizer></canvas>
  </div>
  <div class="resource-video-device-name">{{ currentDevice?.label }}</div>
  <div class="resource-video-time">{{ recordedTime }}</div>
  @if (initialized) {
    @if (!isUploadingRecording) {
      <div class="btn btn-action empty-resource-btn bg-calm" (click)="cancelRecording()">
        <bitmark-icon name="close"></bitmark-icon>
      </div>
    }
    @if (canSwitchCamera && !isRecording) {
      <div class="btn btn-action bg-calm repeat-btn" (click)="revertCamera()">
        <bitmark-icon name="arrow-repeat"></bitmark-icon>
      </div>
    }
  }
</div>
<div class="d-flex justify-content-center mt-4 resource-video-action-buttons-wrapper">
  <input type="file" accept="video/*" hidden [attr.capture]="videoCameraConfigOptions.video.deviceId"
         [attr.multiple]="null" #fileVideoInput
         (change)="uploadMobileVideo()"/>
  @if (isUploadingRecording) {
    <div>
      <bitmark-progress-spinner [size]="36"></bitmark-progress-spinner>
    </div>
  }
  @if (!initialized) {
    <div class="btn btn-action resource-video-action-btn"
         (click)="startVideoStream()">
      <bitmark-icon
        name="camera-video"
        class="resource-video-action-btn-icon">
      </bitmark-icon>
    </div>
  } @else if (!isRecording && !isUploadingRecording) {
    <div class="btn btn-action resource-video-action-btn"
         (click)="start()">
      <bitmark-icon
        name="record-fill"
        class="resource-video-action-btn-icon">
      </bitmark-icon>
    </div>
  }
  @if (isRecording && !isUploadingRecording) {
    <div class="btn btn-action resource-video-action-btn"
         (click)="stop()">
      <bitmark-icon
        name="stop-fill"
        class="resource-video-action-btn-icon">
      </bitmark-icon>
    </div>
  }
</div>
