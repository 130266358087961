import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_annotate,
  locale_en_gb,
  plugin_annotate_locale_en_gb,
  markup_editor_locale_en_gb,
  createDefaultShapePreprocessor,
  markup_editor_defaults,
  ImageSource,
  createMarkupEditorToolStyle,
  createMarkupEditorToolStyles,
  createDefaultStrokeWidthOptions,
  PinturaImageState, PinturaDefaultImageWriterResult, PinturaDefaultImageReaderResult,
} from '@pqina/pintura';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AnnotationEditorTranslationsService} from '../../../reader/annotation-editor-translations.service';
import {SubSink} from "../../../shared";
import {BitResource} from '../../bits.models';
import {AnnotationNoteBit} from './annotation-handwritten.models';

setPlugins(plugin_annotate);

@Component({
  selector: 'bitmark-annotation-handwritten',
  templateUrl: './annotation-handwritten.component.html',
  styleUrls: ['./annotation-handwritten.component.scss'],
})
export class AnnotationHandwrittenComponent implements OnInit, OnDestroy {
  @Input() bit?: AnnotationNoteBit;
  @Input() isBeingEdited = true;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() onIsBeingEdited = new EventEmitter<{ imageState: any }>();

  @ViewChild('editor') editor?: ElementRef;

  initialState: any;
  pinturaSrc: ImageSource = 'assets/images/annotations/transparent-bg.png';
  pinturaOptions: any;
  annotationEditorOptions: any = [['sharpie', 'Sharpie', {disabled: false}]];
  markupEditorToolOptions: any;

  private updates = new Subject();
  private hasLoaded = false;
  private subSink = new SubSink();

  constructor(private translationsSvc: AnnotationEditorTranslationsService) {

  }

  ngOnInit() {
    // console.log(createDefaultStrokeWidthOptions());
    this.markupEditorToolOptions = createMarkupEditorToolStyles({
      line: createMarkupEditorToolStyle('line', {
        strokeColor: [1, 0, 0]
      }),
      sharpie: createMarkupEditorToolStyle('path', {
        strokeWidth: '0.25%'
      })
    });

    this.pinturaOptions = {
      imageReader: createDefaultImageReader(),
      imageWriter: createDefaultImageWriter(),
      ...markup_editor_defaults,
      shapePreprocessor: createDefaultShapePreprocessor(),
      locale: this.translationsSvc.getLocale(),
    };
    this.initialState = Object.assign({}, this.bit);
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  private emitUpdate(imageState: any) {
    this.onIsBeingEdited.emit(imageState);
  }

  onUpdate(imageState: PinturaImageState) {
    if ((imageState?.annotation && JSON.stringify(imageState?.annotation) !== JSON.stringify(this.bit?.annotation)) || this.hasLoaded) {
      this.updates.next(imageState);
    }
  }

  enterEditMode() {
    this.isBeingEdited = true;
  }

  onLoadImage(event: PinturaDefaultImageReaderResult) {
    this.subSink.sink = this.updates
      .pipe(debounceTime(500))
      .subscribe(e => this.emitUpdate(e));
    // best way I found to stop the saving on page load, editor saving status shown and requests made on every load without this
    setTimeout(() => {
      this.hasLoaded = true;
    }, 4000);
  }

  handleEvent(event: any) {
    if (event === 'update') {
    }
  }

  handleEditorProcess(imageState: PinturaDefaultImageWriterResult): void {
  }
}
