<div class="d-flex flex-column justify-content-center align-items-center">
  <div class="card-wrapper card-wrapper-front" [class.rotate]="!isMobile">
    <div class="bit-inner-icon card-logo"></div>
    <div class="card card-front bit-body">
      @if (!canBeEdited) {
        <bitmark-text [text]="bit?.cards?.length ? bit?.cards[0]?.question : ''"
                      [format]="bit?.format"
                      class="d-block overflow-auto">
        </bitmark-text>
      } @else {
        <bitmark-reader-simple-text-editor #questionEditor
                                           (updateBit)="onUpdateQuestion(bit, $event)"
                                           [bit]="bit"
                                           [bitContent]="(bit?.cards?.length ?  bit?.cards[0]?.question: '') | bitmark:bit.format:'json'"
                                           [isReadOnly]="false"
                                           [hasLists]="true"
                                           class="w-100"
                                           (created)="editorCreated.emit(questionEditor)">
        </bitmark-reader-simple-text-editor>
      }
    </div>
  </div>
  <div class="card-wrapper card-wrapper-back" [class.rotate]="!isMobile">
    <div class="card card-back bit-body">
      <div class="bit-inner-icon card-logo"></div>
      @if (!canBeEdited) {
        <bitmark-text [text]="bit?.cards?.length ? bit?.cards[0]?.answer : ''"
                      [format]="bit?.format"
                      class="d-block overflow-auto">
        </bitmark-text>
      } @else {
        <bitmark-reader-simple-text-editor (updateBit)="onUpdateAnswer(bit, $event)"
                                           [bit]="bit"
                                           [bitContent]="(bit?.cards?.length ? bit?.cards[0]?.answer : '') | bitmark:bit.format:'json'"
                                           [isReadOnly]="false"
                                           [hasLists]="true"
                                           class="w-100">
        </bitmark-reader-simple-text-editor>
      }
    </div>
  </div>
</div>

@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit.footer"
                [format]="bit.format">
  </bitmark-text>
}
