import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {BitBranding} from '../shared/models/bitmark.models';
import {TocItem} from './reader-book/reader-toc-sidebar/reader-toc-sidebar.component';
import {DropdownItemModel} from '../shared';
import {BitsViewPortVisibility} from '../bits/bits.models';
import {InternalLinkBit} from '../bits/internal-link/internal-link.models';
import {ReaderTextEditorComponent} from './reader-editor/reader-text-editor/reader-text-editor.component';
import {ReaderSimpleTextEditorComponent} from './reader-editor/reader-simple-text-editor/reader-simple-text-editor.component';

@Injectable({providedIn: 'root'})
export class BitbookMqService {
  private subject = new Subject<any>();

  private pub(queue: string, payload: any = null): void {
    return this.subject.next({queue, payload});
  }

  private sub(queue: string): Observable<any> {
    return this.subject.asObservable().pipe(
      filter(m => {
        return m.queue === queue;
      }),
      map(m => {
        return m.payload;
      }));
  }

  onSaveAllBits(): Observable<any> {
    return this.sub('save-all-bits');
  }

  onSendAllBits(): Observable<any> {
    return this.sub('send-all-bits');
  }

  notifyBasketChange() {
    this.pub('reader-basket-change');
  }

  onBasketChange(): Observable<any> {
    return this.sub('reader-basket-change');
  }

  notifyBasketCountUpdated(basketCount: number) {
    this.pub('reader-basket-count-updated', basketCount);
  }

  onBasketCountUpdated(): Observable<any> {
    return this.sub('reader-basket-count-updated');
  }

  notifyBookBinChanged(id: string) {
    this.pub(`reader-book-${id}-bin-changed`);
  }

  onBookBinChanged(id: string): Observable<void> {
    return this.sub(`reader-book-${id}-bin-changed`);
  }

  notifyBookBinCountUpdated(id: string, count: number) {
    this.pub(`reader-book-${id}-bin-count-updated`, count);
  }

  onBookBinCountUpdated(id: string): Observable<number> {
    return this.sub(`reader-book-${id}-bin-count-updated`);
  }

  notifySearchFiltersChanged(activeFilters: any) {
    this.pub('reader-search-filters-changed', activeFilters);
  }

  onSearchFiltersChanged(): Observable<any> {
    return this.sub('reader-search-filters-changed');
  }

  notifySearchHistorySelected(historyItem: { searchQuery: string, activeFilters: any }) {
    this.pub('reader-search-history-selected', historyItem);
  }

  onSearchHistorySelected(): Observable<any> {
    return this.sub('reader-search-history-selected');
  }

  notifySearchQueryChanged(searchQuery: string) {
    this.pub('reader-search-query-changed', searchQuery);
  }

  onSearchQueryChanged(): Observable<string> {
    return this.sub('reader-search-query-changed');
  }

  notifySearchQuerySet(searchQuery: string) {
    this.pub('reader-search-query-set', searchQuery);
  }

  onSearchQuerySet(): Observable<string> {
    return this.sub('reader-search-query-set');
  }

  notifySearchSideBarVisibleSet(isVisible: boolean) {
    this.pub('reader-search-side-bar-visible-set', isVisible);
  }

  onSearchSideBarVisibleSet(): Observable<boolean> {
    return this.sub('reader-search-side-bar-visible-set');
  }

  notifyReaderBitSelected(bitId: string) {
    this.pub('reader-bit-selected', bitId);
  }

  onReaderBitSelected(): Observable<number> {
    return this.sub('reader-bit-selected');
  }

  notifyReaderSearchMade() {
    this.pub('reader-search-made');
  }

  onSearchMade(): Observable<any> {
    return this.sub('reader-search-made');
  }

  notifyReaderSearchFilterRemoved(filterToRemove: any) {
    this.pub('reader-search-filter-removed', filterToRemove);
  }

  onReaderSearchFilterRemoved(): Observable<any> {
    return this.sub('reader-search-filter-removed');
  }

  notifyReaderSearchFiltersInitialSet(activeFilters: any) {
    this.pub('reader-search-filters-initial-set', activeFilters);
  }

  onReaderSearchFiltersInitialSet(): Observable<any> {
    return this.sub('reader-search-filters-initial-set');
  }

  notifyReaderScrolledToBit(bitId: string, bitIndex: number) {
    this.pub('reader-scrolled-to-bit', {bitId, bitIndex});
  }

  onReaderScrolledToBit(): Observable<any> {
    return this.sub('reader-scrolled-to-bit');
  }

  notifyBookContentInvalid() {
    this.pub('book-content-invalid');
  }

  onBookContentInvalid(): Observable<any> {
    return this.sub('book-content-invalid');
  }

  notifyReaderInvalidateBookCache(bookExternalId: string) {
    this.pub('invalidate-book-cache', bookExternalId);
  }

  onReaderInvalidateBookCache(): Observable<string> {
    return this.sub('invalidate-book-cache');
  }

  notifyReaderContentBitsScrollEnd(bitIndex: number) {
    this.pub('reader-content-bits-scroll-end', bitIndex);
  }

  onReaderContentBitsScrollEnd(): Observable<boolean> {
    return this.sub('reader-content-bits-scroll-end');
  }

  notifyReaderBitRemoved(bitId: string) {
    this.pub('reader-bit-removed', bitId);
  }

  onReaderBitRemoved(): Observable<boolean> {
    return this.sub('reader-bit-removed');
  }

  notifyReaderEditorAction(bitId?: string) {
    this.pub('reader-editor-action', bitId);
  }

  onReaderEditorAction(): Observable<boolean> {
    return this.sub('reader-editor-action');
  }

  notifySetTocAndReload(toc: Array<TocItem>) {
    this.pub('reader-set-toc-and-reload', toc);
  }

  onSetTocAndReload(): Observable<Array<TocItem>> {
    return this.sub('reader-set-toc-and-reload');
  }

  notifyRefreshToc(payload: { id?: string, shouldInitializeBitVisibility?: boolean }) {
    this.pub('reader-refresh-toc', payload);
  }

  onReaderRefreshToc(): Observable<boolean> {
    return this.sub('reader-refresh-toc');
  }

  notifyRefreshBit(payload: { id?: string, tocEntry: TocItem }) {
    this.pub('reader-refresh-bit', payload);
  }

  onReaderRefreshBit(): Observable<boolean> {
    return this.sub('reader-refresh-bit');
  }

  notifyRefreshNotebook(notebookId?: string) {
    this.pub('reader-refresh-notebook', notebookId);
  }

  onReaderRefreshNotebook(): Observable<boolean> {
    return this.sub('reader-refresh-notebook');
  }

  notifyReaderTocHasReloaded() {
    return this.sub('reader-toc-reloaded');
  }

  onReaderTocHasReloaded(): Observable<any> {
    return this.sub('reader-toc-reloaded');
  }

  notifyReaderChapterDeleted(notebookId: string, chapterId: string) {
    this.pub('reader-chapter-deleted', {notebookId, chapterId});
  }

  onReaderChapterDeleted(): Observable<boolean> {
    return this.sub('reader-chapter-deleted');
  }

  notifyReaderBrandingSet(branding: BitBranding) {
    return this.pub('reader-branding', branding);
  }

  onReaderBrandingSet(): Observable<BitBranding> {
    return this.sub('reader-branding');
  }

  notifyQuickNoteShow() {
    return this.pub('reader-quick-note-show');
  }

  onQuickNoteShow(): Observable<BitBranding> {
    return this.sub('reader-quick-note-show');
  }

  notifyQuickNoteHidden() {
    return this.pub('reader-quick-note-hidden');
  }

  onQuickNoteHidden(): Observable<BitBranding> {
    return this.sub('reader-quick-note-hidden');
  }

  notifyReaderInternalLinkClicked(reference: InternalLinkBit) {
    return this.pub('reader-internal-link-clicked', reference);
  }

  onInternalLinkClicked(): Observable<InternalLinkBit> {
    return this.sub('reader-internal-link-clicked');
  }

  notifyReaderMenuReady(actions: Array<DropdownItemModel>) {
    return this.pub('reader-menu-ready', actions);
  }

  onReaderMenuReady(): Observable<Array<DropdownItemModel>> {
    return this.sub('reader-menu-ready');
  }

  notifyBitsScrollVisibility(visibleBits: BitsViewPortVisibility) {
    return this.pub('reader-bits-scroll-visibility', visibleBits);
  }

  onBitsScrollVisibility(): Observable<BitsViewPortVisibility> {
    return this.sub('reader-bits-scroll-visibility');
  }

  notifyBitsVisibility(visibleBits: BitsViewPortVisibility) {
    return this.pub('reader-bits-visibility', visibleBits);
  }

  onBitsVisibility(): Observable<BitsViewPortVisibility> {
    return this.sub('reader-bits-visibility');
  }

  notifyEditorCreated(editor: ReaderTextEditorComponent | ReaderSimpleTextEditorComponent) {
    return this.pub('reader-editor-created', editor);
  }

  onEditorCreated(): Observable<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent> {
    return this.sub('reader-editor-created');
  }

  notifyBitShuffle(bitId: string) {
    return this.pub(`reader-bit-${bitId}-shuffle`);
  }

  onBitShuffle(bitId: string): Observable<void> {
    return this.sub(`reader-bit-${bitId}-shuffle`);
  }

  notifyBitReplaceImage(bitId: string) {
    return this.pub(`reader-bit-${bitId}-replace-image`);
  }

  onBitReplaceImage(bitId: string): Observable<void> {
    return this.sub(`reader-bit-${bitId}-replace-image`);
  }

  notifyShopProductPurchased(productId: string) {
    return this.pub('shop-product-purchased', productId);
  }

  onShopProductPurchased(): Observable<string> {
    return this.sub('shop-product-purchased');
  }

  notifyBitGenerated(bitId: string) {
    return this.pub('bitmark-bit-generated', bitId);
  }

  onBitGenerated(): Observable<string> {
    return this.sub('bitmark-bit-generated');
  }

  notifyBitCodeLanguageChanged(bitId: string, lang: string) {
    return this.pub(`reader-bit-${bitId}-language-changed`, lang);
  }

  onBitCodeLanguageChanged(bitId: string): Observable<string> {
    return this.sub(`reader-bit-${bitId}-language-changed`);
  }

  notifyBookIsHandedInReadonly(bookId: string) {
    return this.pub(`reader-book-is-handed-in-readonly`, bookId);
  }

  onBookIsHandedInReadonly(): Observable<string> {
    return this.sub(`reader-book-is-handed-in-readonly`);
  }

  notifyHandInIsAssigned(handInId: number, expertUser: {email: string, ssoEmail: string, id: number}) {
    return this.pub(`reader-hand-in-is-assigned`, {handInId, expertUser});
  }

  onHandInIsAssigned(): Observable<{handInId: number, expertUser: {email: string, ssoEmail: string, id: number}}> {
    return this.sub(`reader-hand-in-is-assigned`);
  }

  notifyHandInIsReAssigned(handInId: number, expertUser: {email: string, ssoEmail: string, id: number}) {
    return this.pub(`reader-hand-in-is-reassigned`, {handInId, expertUser});
  }

  onHandInIsReAssigned(): Observable<{handInId: number, expertUser: {email: string, ssoEmail: string, id: number}}> {
    return this.sub(`reader-hand-in-is-reassigned`);
  }

  notifyHandInIsUnAssigned(handInId: number) {
    return this.pub(`reader-hand-in-is-unassigned`, {handInId});
  }

  onHandInIsUnAssigned(): Observable<{handInId: number}> {
    return this.sub(`reader-hand-in-is-unassigned`);
  }

  notifyCurrentStripeSessionRequested() {
    return this.pub(`stripe-current-stripe-session-requested`, null);
  }

  onCurrentStripeSessionRequested(): Observable<any> {
    return this.sub(`stripe-current-stripe-session-requested`);
  }

  notifyCurrentStripeSessionResolved(subscriptionRes: {customerSession?: any}) {
    return this.pub(`stripe-current-stripe-session-resolved`, subscriptionRes);
  }

  onCurrentStripeSessionResolved(): Observable<{customerSession?: any}> {
    return this.sub(`stripe-current-stripe-session-resolved`);
  }
}
