import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BookReferenceBit} from './book-reference.models';
import {BitResource} from '../bits.models';

@Component({
  selector: 'bitmark-book-reference',
  templateUrl: './book-reference.component.html',
  styleUrls: ['./book-reference.component.scss']
})
export class BookReferenceComponent implements OnInit {
  @Input() bit?: BookReferenceBit;
  @Output() openResource = new EventEmitter<BitResource>();

  hasRefs = false;

  ngOnInit() {
    this.hasRefs = !!(this.bit?.refBookTitle
      || this.bit?.refAuthor?.length
      || this.bit?.refPublisher?.length);
  }
}
