<div class="position-relative d-flex flex-column resource-audio-wrapper">
  <div class="flex-grow-1 position-relative">
    <div class="resource-audio-action-buttons-wrapper">
      @if (isUploadingRecording) {
        <div>
          <bitmark-progress-spinner [size]="36"></bitmark-progress-spinner>
        </div>
      }
      @if (!isRecording && !isUploadingRecording) {
        <div class="btn btn-action resource-audio-action-btn"
             (click)="start()">
          <bitmark-icon
            name="mic-fill"
            class="resource-audio-action-btn-icon">
          </bitmark-icon>
        </div>
      }
      @if (isRecording && !isUploadingRecording) {
        <div class="btn btn-action resource-audio-action-btn"
             (click)="stop()">
          <bitmark-icon
            name="stop-fill"
            class="resource-audio-action-btn-icon">
          </bitmark-icon>
        </div>
      }
    </div>
  </div>
  <div class="resource-audio-visualizer">
    <canvas class="w-100 h-100" #visualizer></canvas>
  </div>
  <div class="resource-audio-time">{{ recordedTime }}</div>
</div>
