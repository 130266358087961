@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
<div class="bit-body bit-body-allow-highlight"
     [ngClass]="bit.type">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
<div class="d-none d-sm-flex row no-gutters my-1 font-weight-bold">
  <div class="col-8"></div>
  <div class="col-2 text-pre-wrap text-center bit-label">
    <bitmark-text [text]="bit?.labelTrue || ('Shared.True' | translate)"
                  [format]="bit.format">
    </bitmark-text>
  </div>
  <div class="col-2 text-pre-wrap text-center bit-label">
    <bitmark-text [text]="bit?.labelFalse || ('Shared.False' | translate)"
                  [format]="bit.format">
    </bitmark-text>
  </div>
</div>
<div class="row no-gutters mb-2">
  <div class="col-sm-8 col-12 px-sm-1 pb-1 pb-sm-0 mb-4 mb-sm-0 bit-body"
       [ngClass]="bit.type">
    <bitmark-text [text]="bit.statement"
                  [format]="bit.format">
    </bitmark-text>
  </div>
  <div class="col-sm-2 col-12 bit-radio text-sm-center"
       [ngClass]="answerTrueCss">
    <input [id]="'i' + bitId + '-true'"
           type="radio"
           [disabled]="bit.isExample || readOnly || !allowAnswerSubmit"
           (change)="change(true)"
           [checked]="!!bit.answer?.choice"
           [name]="'answer' + bitId"/>
    <label [for]="'i' + bitId + '-true'">
      <span class="d-block d-sm-none font-weight-bold">
        <bitmark-text [text]="bit?.labelTrue || ('Shared.True' | translate)"
                      [format]="bit.format">
        </bitmark-text>
      </span>
    </label>
  </div>
  <div class="col-sm-2 col-12 bit-radio text-sm-center"
       [ngClass]="answerFalseCss">
    <input [id]="'i' + bitId + '-false'"
           type="radio"
           [disabled]="bit.isExample || readOnly || !allowAnswerSubmit"
           (change)="change(false)"
           [checked]="bit.answer && !bit.answer?.choice"
           [name]="'answer' + bitId "/>
    <label [for]="'i' + bitId + '-false'">
      <span class="d-block d-sm-none font-weight-bold">
        <bitmark-text [text]="bit?.labelFalse || ('Shared.False' | translate)"
                      [format]="bit.format">
        </bitmark-text>
      </span>
    </label>
  </div>
</div>
