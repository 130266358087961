<div class="arrow-pointer"></div>
<div class="wrapper" [class.is-edit]="isBeingEditedByUser">
  <div class="px-4 py-2 bot-action-send-container">
    <span class="mr-1">🤖</span>
    <span class="mr-1">{{ 'Bits.SendAt' | translate }}</span>
    <div class="d-inline-flex">
      <div class="d-flex flex-column">
      <span
        class="mr-1"
        [ngClass]="isBeingEditedByUser && 'cursor-pointer'"
        (click)="isBeingEditedByUser && d.toggle()">{{ (bit.date | bitmarkDate:'E, d.M') || '?' }}</span>
        <input
          [(ngModel)]="bit.date"
          ngbDatepicker
          container="body"
          #d="ngbDatepicker"
          class="edit-input"/>
      </div>
    </div>
    <span class="mr-1">&bull;</span>
    <div class="d-inline-flex">
      <div class="d-flex flex-column">
        @if (isBeingEditedByUser) {
          <bitmark-time-editor [(ngModel)]="botActionSendBitTime"
                               placeholder="{{'Bits.To' | translate}}"
                               ngDefaultControl></bitmark-time-editor>
        } @else {
          <span>{{ bit.date | bitmarkDate:'shortTime' }}</span>
        }
      </div>
    </div>
  </div>
  @if (isBeingEditedByUser) {
    <div class="p-2 edit-container">
      <div class="d-flex justify-content-center m-2">
        <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
                [innerHTML]="'Shared.Cancel' | translate"
                (click)="cancelChanges()">
        </button>
        <button class="btn btn-action bit-button state-button state-save-button"
                [innerHTML]="'Shared.Save' | translate"
                (click)="saveChanges()">
        </button>
      </div>
    </div>
  }
</div>
