import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {BookEntity} from '../../reader/reader.models';
import {BookType, NotebookCoverType, NotebookTypes} from '../models/bitmark.models';

@Component({
  selector: 'bitmark-notebook-cover',
  templateUrl: './notebook-cover.component.html',
  styleUrls: ['./notebook-cover.component.scss']
})
export class NotebookCoverComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() notebook: BookEntity;
  @Input() size: 'sm' | 'md' | 'lg' = 'sm';
  @Input() adaptSize = false;
  @Output() onClick = new EventEmitter<any>();
  @Output() loaded = new EventEmitter<boolean>();
  @Output() isWide = new EventEmitter<boolean>();
  @ViewChild('imageElement') imageElement: ElementRef;

  coverImageWebp?: string;
  coverImageJpg?: string;
  showcaseLetter: string;

  draftCoverImageWidth = 140;
  draftCoverImageHeight = 200;

  isEmoji: boolean;
  isLoaded = false;

  NotebookTypes = NotebookTypes;
  NotebookCoverTypes = NotebookCoverType;

  @ViewChild('notebookDefaultCover') notebookDefaultCover: ElementRef;
  @ViewChild('notebookDefaultLetter') notebookDefaultLetter: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.adaptSize) {
      this.resizeDefaultCover();
    }
  }

  ngOnInit() {
    const coverImage = this.notebook?.coverImage?.src1x || this.notebook?.coverImage?.src2x || this.notebook?.coverImage?.src3x;
    if (coverImage?.indexOf('.webp') !== -1) {
      this.coverImageWebp = coverImage?.replace('.jpg', '.webp') || '';
    }
    this.coverImageJpg = coverImage?.replace('.webp', '.jpg') || '';
    if (!coverImage && !this.coverImageJpg && !this.coverImageWebp) {
      this.setAsLoaded();
    }
    setTimeout(() => {
      this.setAsLoaded();
    }, 200);

    if (this.notebook.title) {
      let isFlagEmoji;
      this.isEmoji = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(this.notebook.title.slice(0, 2));
      if (this.isEmoji) {
        isFlagEmoji = /[\uD83C][\uDDE6-\uDDFF][\uD83C][\uDDE6-\uDDFF]/.test(this.notebook.title.slice(0, 4));
      }
      this.showcaseLetter = isFlagEmoji ? String.fromCodePoint(this.notebook.title.codePointAt(0), this.notebook.title.codePointAt(2)) : String.fromCodePoint(this.notebook.title.codePointAt(0));
    }
    if(this.notebook.subtype === NotebookTypes.DraftBook){
      if((this.notebook?.branding?.coverType === NotebookCoverType.CustomCover || !this.notebook?.branding?.coverType) && this.notebook?.coverImage?.width && this.notebook?.coverImage?.height){
        if(this.notebook?.coverImage.width / this.notebook.coverImage.height > 1.2) {
          this.draftCoverImageHeight = 480;
          this.draftCoverImageWidth = 840;
        }
      }
    }

    if (this.notebook?.type === BookType.Collection && this.notebook?.subtype === NotebookTypes.Flashcard) {
      this.isWide.emit(true);
    }
  }

  ngAfterViewInit() {
    if (this.adaptSize) {
      this.resizeDefaultCover();
    }
  }

  ngOnDestroy() {
    this.imageElement = null;
  }

  onImageLoad(data: { imageElem: any, checkIfWide: boolean }) {
    this.setAsLoaded();

    if (!data.checkIfWide) {
      return;
    }
    if (data.imageElem?.width && data.imageElem?.height) {
      if (data.imageElem?.width / data.imageElem?.height > 1.2) {
        this.isWide.emit(true);
      } else{
        this.isWide.emit(false);
      }
    } else{
      this.isWide.emit(false);
    }
  }

  setAsLoaded() {
    if (!this.isLoaded) {
      this.loaded.emit(true);
      this.isLoaded = true;
    }
  }

  fallbackToSupportedCover($event) {
    if ($event.srcElement.currentSrc === this.coverImageWebp) {
      this.coverImageWebp = this.coverImageJpg;
    } else {
      // fallback to default notebook cover, it means the jpg item is missing
      this.notebook.coverImage = null;
    }
    this.setAsLoaded();
  }

  resizeDefaultCover() {
    if (!this.notebookDefaultCover) {
      return;
    }
    const viewportWidth = document.body.clientWidth;

    if (viewportWidth > 576) {
      this.notebookDefaultCover.nativeElement.classList.remove('w-100');
      this.notebookDefaultCover.nativeElement.classList.add('w-100');
      return;
    }

    const width = Math.min(viewportWidth - 100, 375);
    const height = width * 1.33333;

    this.notebookDefaultCover.nativeElement.classList.remove('w-100');
    this.notebookDefaultCover.nativeElement.style.height = height + 'px';
    this.notebookDefaultCover.nativeElement.style.width = width + 'px';
    if (this.notebookDefaultLetter?.nativeElement) {
      this.notebookDefaultLetter.nativeElement.style.fontSize = width * 0.72 + 'px';
    }
  }
}
