import {Component, ElementRef, EventEmitter, Inject, Input, Output, Renderer2} from '@angular/core';
import {VendorStripePricingTableBit} from './vendor-stripe-pricing-table.models';
import {BitResource, BitResourceSize} from '../bits.models';
import {BitmarkConfig} from "../../bitmark.module";
import {BitbookMqService} from "../../reader/bitbook-mq.service";
import {SubSink} from "../../shared";

@Component({
  selector: 'bitmark-vendor-stripe-pricing-table',
  templateUrl: './vendor-stripe-pricing-table.component.html',
  styleUrls: ['../bits.scss']
})
export class VendorStripePricingTableComponent {
  @Input() bit?: VendorStripePricingTableBit;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() measureDisplaySize = new EventEmitter<BitResourceSize>();
  currentSubscription: any;
  subscriptionLoaded = false;
  private subSink = new SubSink();

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private renderer: Renderer2,
              private bitbookMqService: BitbookMqService,
              private el: ElementRef){

  }

  ngOnInit(){
    this.subSink.sink = this.bitbookMqService.onCurrentStripeSessionResolved().subscribe((data) => {
      this.handleCurrentUserSubscriptionResult(data);
    });
    this.bitbookMqService.notifyCurrentStripeSessionRequested();
  }

  handleCurrentUserSubscriptionResult(subscriptionRes?: {customerSession?: any}){
    this.loadStripePricingTable(subscriptionRes);
  }

  loadStripePricingTable(subscriptionResult?: any): void {
    const script = this.renderer.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    script.onload = () => {
      // Example: Initialize the pricing table
      const pricingTable = document.createElement('stripe-pricing-table');
      pricingTable.setAttribute('pricing-table-id', 'prctbl_1Q2rcVJ6tOaBdFnaA94NaZia');
      pricingTable.setAttribute('publishable-key', this.bit?.stripePublishableKey || this.bitmarkConfig.stripePublishableKey);
      pricingTable.setAttribute('client-reference-id', this.bitmarkConfig.userId?.toString());
      if(subscriptionResult?.customerSession?.client_secret){
        pricingTable.setAttribute('customer-session-client-secret', subscriptionResult?.customerSession?.client_secret);
      }
      if(!subscriptionResult?.customerSession?.client_secret){
        pricingTable.setAttribute('customer-email', this.bitmarkConfig.userEmails[0]);
      }

      // Add any other necessary attributes

      const container = this.el.nativeElement.querySelector('#stripe-pricing-table-container');
      this.renderer.appendChild(container, pricingTable);
    };
    this.renderer.appendChild(this.el.nativeElement, script);
  }

  ngOnDestroy(){
    this.subSink.unsubscribe();
  }
}
