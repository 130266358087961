<div class="bit-body">
  <bitmark-text [text]="bit?.body" [format]="bit?.format"></bitmark-text>
</div>
<div class="d-flex flex-wrap page-footer-sections-container">
  @for (section of sections; track $index) {
    <div class="page-footer-section">
      <bitmark-bit-item-instruction [bit]="section.bit" [brandingKey]="'page-footer-section'"></bitmark-bit-item-instruction>
      <bitmark-text [text]="section.data.body" [format]="bit.format"></bitmark-text>
    </div>
  }
</div>
