<div class='d-flex flex-row flex-wrap page-product-container'>
  @for (product of products; track product) {
    @if (product) {
      <div class='page-product d-flex flex-column justify-content-start align-items-center'
           [ngClass]="{'entry-wide': isWideEntry, 'cursor-pointer': !product?.doesNotExist, 'cursor-default': product?.doesNotExist}">
        <div class='book-cover d-flex align-items-end ml-auto mr-auto position-relative'>
          <bitmark-book-cover (click)='openProductPage(product)'
                              (onIsWideImage)='setWideImage()'
                              [cssClass]='"page-product-video-list-cover"'
                              class='w-100 h-100'
                              [coverImage]="product?.media?.length && product?.media[0].file.url"
                              [isFullyBranded]='true'
                              [isWideCoverAllowed]='true'
                              [isProductCover]="true"
                              [isNotExist]="product?.productDoesNotExist || product?.isInWrongSpace"
                              [title]='product?.title'>
          </bitmark-book-cover>
          <div class='video-length'>{{ product?.videoLength }}</div>
        </div>
        <div class='book-details d-flex flex-row mx-0 mt-1'>
          @if (product?.publisherAvatar) {
            <img class='book-publisher-avatar mr-1' [attr.alt]='product?.publisher' [src]='product?.publisherAvatar'/>
          }
          <div class='book-texts px-lg-1 px-0' (click)='openProductPage(product)'>
            @if (product?.title) {
              <div class='book-title line-clamp mb-0'
                   [innerText]='product?.title + (product?.bitbookDoesNotExist && !product?.productDoesNotExist ? "&nbsp;🔒" : "")'>
              </div>
            }
            @if (product?.publisher) {
              <div class='book-publisher mb-0'
                   [innerText]='product?.publisher'>
              </div>
            }
            @if (product?.meta?.handInStatus) {
              <div class="text-center hand-in-emoji">
                @if(product?.meta?.handInStatus === HandInStatus.NotHandedIn){
                  ✏️
                }
                @if(product?.meta?.handInStatus === HandInStatus.Rejected){
                  ❌
                }
                @if(product?.meta?.handInStatus === HandInStatus.Approved){
                  🏅
                }
                @if(product?.meta?.handInStatus === HandInStatus.HandedIn || product?.meta?.handInStatus === HandInStatus.Assigned){
                  ✋
                }
              </div>
            }
          </div>
        </div>
      </div>
    }
  }
</div>
