<div class="reader-shadow bg-white modal-container">
  @if (websiteLinkResource) {
    <div class="p-4 position-relative">
      @if (isLoading) {
        <div class="preview-spinner">
          <bitmark-progress-spinner></bitmark-progress-spinner>
        </div>
      }
      <bitmark-resource-website-link [class.preview-hidden]="isLoading"
                                     [bitResource]="websiteLinkResource">
      </bitmark-resource-website-link>
    </div>
  } @else {
    <div class="p-4 position-relative website-placeholder">
      @if (isLoading) {
        <div class="preview-spinner">
          <bitmark-progress-spinner></bitmark-progress-spinner>
        </div>
      }
      <bitmark-resource-website-link [class.preview-hidden]="isLoading"
                                     [bitResource]="websiteLinkPlaceholderResource">
      </bitmark-resource-website-link>
    </div>
  }
  <div class="p-2 edit-container">
    <input type="text"
           class="form-control mb-1"
           placeholder="https://..."
           [(ngModel)]="websiteLink"
           (ngModelChange)="onLinkChanged()"/>
    <div class="d-flex justify-content-center m-2 mt-4">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              [disabled]="isLoading"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
</div>
