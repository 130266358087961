<div class="contextual-menu-absolute pos-absolute px-1 cursor-pointer d-hidden" id="contextual-menu-absolute-image">
  <ng-template #tiptapImageBubbleMenuDropdown>
    <div class="px-1">
      <span dropdownButton class="cursor-pointer contextual-menu-trigger">Image</span>
    </div>
  </ng-template>
  <bitmark-dropdown class="d-inline-block ml-auto float-right"
                    dropdownClass="lg reader-text-editor"
                    container="body"
                    [actions]="menuActions"
                    [dropdownButton]="tiptapImageBubbleMenuDropdown">
  </bitmark-dropdown>
</div>
