<div class="d-flex flex-column position-relative">
  @if (modelHasValue) {
    <span class="cursor-pointer" (click)="date.toggle()">{{ _ngModel | bitmarkDate: (format || 'EE dd MMMM yyyy') }}</span>
  } @else {
    <span class="cursor-pointer placeholder" (click)="date.toggle()">{{ placeholder }}</span>
  }
  <input
    [(ngModel)]="_ngModel"
    (ngModelChange)="modelChanged($event)"
    ngDefaultControl
    ngbDatepicker
    #date="ngbDatepicker"
    container="body"
    [placement]="['bottom', 'top']"
    class="edit-input"/>
</div>
