import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {SubSink} from '../../../shared';
import {BitType} from '../../../bits/bits.models';
import {ReaderTextEditorComponent} from '../reader-text-editor/reader-text-editor.component';

@Component({
  selector: 'bitmark-json-editor',
  templateUrl: './json-editor.component.html',
  styleUrls: ['./json-editor.component.scss']
})
export class ReaderJsonEditorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('editorComponent') editorComponent: ReaderTextEditorComponent;
  @ViewChild('codeEditor') codeEditorElement: ElementRef;
  @Input() bookExternalId: string;
  @Input() isOpen = false;
  @Output() close = new EventEmitter();
  leftJson = '';
  private subSink = new SubSink();
  pasteListener: () => void;
  @Input() isBeingEdited = true;
  @Input() canBeEdited = false;
  BitType = BitType;

  ngAfterViewInit() {
    this.subSink.sink = fromEvent(this.codeEditorElement.nativeElement, 'input')
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe();
  }

  ngOnDestroy() {
    if (this.pasteListener) {
      this.pasteListener();
    }

    this.subSink.unsubscribe();

    this.editorComponent = null;
    this.codeEditorElement = null;
  }

  leftToRight() {
    try {
      const txt = this.codeEditorElement.nativeElement.value;
      let parsed = JSON.parse(txt);
      if (parsed?.bit) {
        // parsed = ;
        parsed = parsed.bit;
      }
      this.editorComponent.editor.commands.setContent(parsed);
    } catch (e) {
      console.log('error: ', e);
    }
  }

  rightToLeft() {
    try {
      this.leftJson = JSON.stringify({bit: this.editorComponent.editor.getJSON().content});
      this.codeEditorElement.nativeElement.value = JSON.stringify({bit: this.editorComponent.editor.getJSON().content});

    } catch (e) {
      console.log('error: ', e);
    }
  }

  onSave() {
    // const txt = this.codeEditorElement.nativeElement.value;
    this.close.emit();
  }

  enterEditMode() {
    if (this.canBeEdited) {
      this.isBeingEdited = true;
      this.editorComponent.enterEditMode();
    }
  }
}
