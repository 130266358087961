<div class="pos-relative">
  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollUpDistance]="2"
       [infiniteScrollThrottle]="50"
       [alwaysCallback]="true"
       [infiniteScrollContainer]="'.infinite-scroll-container'"
       [scrollWindow]="false"
       (scrolled)="onScrollDown()"
       (scrolledUp)="onScrollUp()"
       [fromRoot]="true"
       [ngClass]="{'reader-read-only': isReadOnlyReaderStudent}"
       class="bits-wrapper themed book">
    <div class="d-flex flex-column pos-relative"
         [style.opacity]="isLoading ? '0' : '1'">
      @if(virtualHandInBit){
        <div class="hand-in-sticky">
          <bitmark-bit-wrapper context="book"
                               [bitWrapper]="virtualHandInBit"
                               [actions]="bitBook?.handIn?.status !== HandInStatus.HandedIn ? handInReviewActions : null"
                               [fontScale]="fontScale"
                               [focusedBit]="focusedBitId"
                               [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                               (toggleTocEvent)="toggleToc.emit($event)"
                               (assignHandIn)="assignHandInAndAugmentModuleId($event)"
                               (openResource)="onOpenResource($event)"
                               (navigateToBook)="navigateToBook.emit($event)"
                               (navigateToProduct)="navigateToProduct.emit($event)">
          </bitmark-bit-wrapper>
        </div>
      }
      @for (bitWrapper of bitBookContent; track bitWrapper.id) {
        @if (bitWrapper.annotations?.length || bitWrapper.externalAnnotations?.length) {
          @for (bitAnnotation of getTopAnnotations(bitWrapper.annotations, bitWrapper.externalAnnotations); track bitAnnotation.id) {
            <bitmark-bit-wrapper context="notebook"
                                 [ngClass]="{'mt-3 mb-5': bitAnnotation?.user, 'my-3': !bitAnnotation?.user}"
                                 [bitWrapper]="getApiWrapperFromAnnotation(bitAnnotation)"
                                 [hideBitActions]="bitAnnotation?.user"
                                 [actions]="getBitAnnotationActions(bitAnnotation)"
                                 [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                                 (isBitAnnotationBeingEdited)="saveEditedAnnotation($event)"
                                 (navigateToBook)="navigateToBook.emit($event)">
            </bitmark-bit-wrapper>
          }
        }
        <bitmark-bit-wrapper context="book"
                             [bitWrapper]="bitWrapper"
                             [actions]="bitActions"
                             [fontScale]="fontScale"
                             [focusedBit]="focusedBitId"
                             [hideBitActions]="isReadOnlyReaderStudent"
                             [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                             (toggleTocEvent)="toggleToc.emit($event)"
                             (openResource)="onOpenResource($event)"
                             (assignHandIn)="assignHandIn.emit($event)"
                             (navigateToBook)="navigateToBook.emit($event)"
                             (navigateToProduct)="navigateToProduct.emit($event)"
                             (closeBook)="closeBook.emit()">
        </bitmark-bit-wrapper>
        @if (bitWrapper.annotations?.length || bitWrapper.externalAnnotations?.length) {
          @for (bitAnnotation of getBottomAnnotations(bitWrapper.annotations, bitWrapper.externalAnnotations); track bitAnnotation.id) {
            <bitmark-bit-wrapper context="notebook"
                                 [ngClass]="{'mt-3 mb-5': bitAnnotation?.user, 'my-3': !bitAnnotation?.user}"
                                 [bitWrapper]="getApiWrapperFromAnnotation(bitAnnotation)"
                                 [hideBitActions]="bitAnnotation?.user"
                                 [actions]="getBitAnnotationActions(bitAnnotation)"
                                 [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                                 (isBitAnnotationBeingEdited)="saveEditedAnnotation($event)"
                                 (navigateToBook)="navigateToBook.emit($event)"
                                 (navigateToProduct)="navigateToProduct.emit($event)">
            </bitmark-bit-wrapper>
          }
        }
      }
    </div>
    <div [hidden]="!isLoadingAdditionalContent"
         class="position-fixed w-100 justify-content-center"
         [class.d-flex]="isLoadingAdditionalContent"
         [style.top]="isLoadingAdditionalContentTop ? 0 : undefined"
         [style.bottom]="isLoadingAdditionalContentBottom ? 0 : undefined"
         style="left: 0; z-index: 100000">
      <div class="spinner delayed-spinner" style="width: fit-content">
        <bitmark-progress-spinner [size]="20"></bitmark-progress-spinner>
      </div>
    </div>
  </div>
  <div id="annotation-menu" #annotationsMenuTemplate class="annotations-menu" style="display: none">
    <div #annotationsMenuPopup class="d-flex flex-column align-items-start annotations-menu-popup">
      <div #annotationsMenuHighlightTemplate class="w-100" style="display: none">
        <div class="p-1 d-flex">
          <button (click)="toggleAnnotationHighlight()"
                  class="pl-1 mr-3 annotation-btn-img">
            <img src="assets/images/annotations/marker-pen.png" class="cursor-pointer"
                 style="max-width: 25px; height: auto;"/>
          </button>
        </div>
        <div class="separator"></div>
      </div>
      <div class="d-flex flex-row justify-content-around p-1">
        <button (click)="createAnnotation('bookmark')"
                class="annotation-btn-img">
          <img src="assets/images/annotations/annotation-bookmark.png" class="mr-3 cursor-pointer"
               style="max-width: 100px; height: auto;"/>
        </button>
        <button (click)="createAnnotation('favourite')"
                class="annotation-btn-img">
          <img src="assets/images/annotations/annotation-favorite.png" class="cursor-pointer"
               style="max-width: 100px; height: auto;"/>
        </button>
      </div>
      <div class="separator"></div>
      <div class="d-flex flex-row justify-content-between p-1 w-100">
        <button (click)="createAnnotation('note')"
                class="annotation-btn-img">
          <img src="assets/images/annotations/annotation-note.png" style="max-width: 100px; height: auto;"/>
        </button>
        <button (click)="createAnnotation('handwritten')"
                class="annotation-btn-img">
          <img src="assets/images/annotations/annotation-handwritten.png" style="max-width: 100px; height: auto;"/>
        </button>
      </div>
    </div>
  </div>
  <div id="selection-ref" style="position: absolute;"></div>
</div>
<div style="display: none">{{ bitbookContentVersion }}</div>
