@if (bit?.body) {
  <div class="bit-body bit-body-allow-highlight"
       [ngClass]="bit.type">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
<div class='d-flex justify-content-center pt-2'>
  <button class='btn btn-action bit-button'
          (click)='closeBook.emit()'
          [innerText]="bit.buttonCaption || ('Shared.Close'| translate)">
  </button>
</div>
