<bitmark-resource [bitResource]="bit.resource"
                  [hostBit]="bit"
                  [fillUp]="true"
                  (open)="openResource.emit($event)"
                  (measureDisplaySize)="measureDisplaySize.emit($event)">
</bitmark-resource>
@if (bit?.caption) {
  <div class="w-100 table-image-caption">
    <bitmark-text [text]="bit.caption"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
