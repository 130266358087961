<div class="reader-shadow bg-white pt-2 pb-4 language-picker-container">
  <div class="d-flex align-items-start justify-content-between px-3">
    @if(!customTitle){
      <h4 class="m-0 pt-4 font-weight-medium">{{ 'Reader.Translate.ChooseLanguage' | translate }}</h4>
    } @else{
      <h5 class="m-0 pt-4 font-weight-medium">{{customTitle}}</h5>
    }
    <bitmark-icon name="close" class="size-20px cursor-pointer text-black pt-1" (click)="closeModal()"></bitmark-icon>
  </div>
  <div class="pt-3 pb-2 pl-5 pr-3">
    <div class="d-flex align-items-center pl-2">
      <input class="border-0 flex-grow-1 flex-shrink-1 search-input"
             [placeholder]="'Reader.Translate.SearchLanguages' | translate"
             (input)="searchLanguage($event)"/>
    </div>
  </div>
  <div class="dropdown-separator"></div>
  <div class="pt-2 px-3 language-list">
    @if (!isSearching && recentLanguages?.length) {
      <div>
        <h6 class="mb-0 language-category">{{ 'Reader.Translate.RecentLanguages' | translate }}</h6>
        @for (language of recentLanguages; track language.languageCode) {
          <div (click)="chooseLanguage(language)"
               class="d-flex align-items-center cursor-pointer px-3 py-2">
            <div class="ml-2 w-100">
              <div class="text--ellipsis language-name">
                {{ language.displayName }}
                @if (language.displayNameEnglish) {
                  <span class="ml-1 language-english-name">{{ language.displayNameEnglish }}</span>
                }
              </div>
            </div>
          </div>
        }
      </div>
      <div class="dropdown-separator mx-n3"></div>
    }
    <div class="d-flex flex-column pt-2">
      <h6 class="mb-0 language-category">{{ 'Reader.Translate.AllLanguages' | translate }}</h6>
      @for (language of displayLanguages; track language.languageCode) {
        <div (click)="chooseLanguage(language)"
             class="d-flex align-items-center cursor-pointer px-3 py-2">
          <div class="ml-2 w-100">
            <div class="text--ellipsis language-name">
              {{ language.displayName }}
              @if (language.displayNameEnglish) {
                <span class="ml-1 language-english-name">{{ language.displayNameEnglish }}</span>
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
