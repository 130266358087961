import {Component, Input} from '@angular/core';
import {environment} from 'environment';

@Component({
  selector: 'app-icon',
  styleUrls: ['./icon.component.scss'],
  templateUrl: './icon.component.html'
})
export class IconComponent {
  @Input() name: string;
  @Input() color?: string;
  protected readonly environment = environment;
}
