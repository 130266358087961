@if (isSearchResult) {
  @if (simulatedPrice){
    <div class='book-price'>
      @if (simulatedPrice > 0) {
        <div>CHF {{ simulatedPrice | centCurrency }}</div>
      } @else if (simulatedPrice === 0) {
        <div class="price-discounted" [innerHtml]="'Shared.Free' | translate"></div>
      }
    </div>
  } @else{
    <div class='book-price'>
      @if (catalogPrice > 0) {
        <div>CHF {{ catalogPrice | centCurrency }}</div>
      } @else if (catalogPrice === 0) {
        <div class="price-discounted" [innerHtml]="'Shared.Free' | translate"></div>
      }
    </div>
  }
}
@if (isBookDetails) {
  <span class='book-price'>
    @if (simulatedPrice <= 0) {
      <span>
        <span class="price-discounted pr-1" [innerHtml]="'Shared.Free' | translate"></span>
        @if (catalogPrice > 0) {
          <del>CHF {{ catalogPrice | centCurrency }}</del>
        }
      </span>
    } @else if (simulatedPrice > 0) {
      <span>
        <span>CHF {{ simulatedPrice | centCurrency }}</span>
        @if (catalogPrice && (catalogPrice !== simulatedPrice)) {
          <del>CHF {{ catalogPrice | centCurrency }}</del>
        }
      </span>
    }
</span>
}
