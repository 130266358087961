<div class='annotation-container annotation-handwritten-container'>
    <pintura-editor #editor
                    (load)='onLoadImage($event)'
                    (update)='onUpdate($event)'
                    (process)="handleEditorProcess($event)"
                    [imageState]='initialState'
                    [src]='pinturaSrc'
                    [handleEvent]='handleEvent'
                    [options]='pinturaOptions'
                    [enableZoomControls]='false'
                    [enableZoom]='false'
                    [enableButtonClose]='false'
                    [enableButtonResetHistory]='false'
                    [enableNavigateHistory]='false'
                    [enableButtonRevert]='false'
                    [markupEditorToolbar]="annotationEditorOptions"
                    [markupEditorToolStyles]='markupEditorToolOptions'
                    [enableButtonExport]='false'>
    </pintura-editor>
</div>
<!--[markupEditorShapeStyleControls]='markupEditorShapeStyleControls'-->
