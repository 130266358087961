<div class="d-flex flex-column justify-content-start align-items-center hand-in-container w-100">
  @if (status === HandInStatus.HandedIn) {
    <div>
      {{ 'HandIn.BookWasHandedIn' | translate }} {{ handInDate | smartDate }} <span [@openClose]="isDescriptionOpen ? 'open' : 'closed'">{{ 'HandIn.By' | translate }}</span>
    </div>
    <bitmark-icon (click)="openAccordion()"
                  [name]="isDescriptionOpen ? 'chevron-down' : 'chevron-right'"
                  class="flex-shrink-0 cursor-pointer text-white view-resources-toggle"></bitmark-icon>
    <div [@openClose]="isDescriptionOpen ? 'open' : 'closed'">
      <div
        class="p-1 d-flex flex-row justify-content-center align-items-center text-white person-container">
        <div class="person-avatar mr-3">
          <bitmark-user-box-photo [userEmail]="studentUser?.email || studentUser?.ssoEmail"
                                  [fullName]="studentUser?.fullName"
                                  [iconUrl]="studentUser?.photoUrl"></bitmark-user-box-photo>
        </div>
        <div class="person-name">
          {{ studentUser?.fullName }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <div class='buy-button-container'>
          <button class="btn-action btn-action--filled mr-3 buy-button"
                  (click)="assignToMe()"
                  [innerHTML]="'HandIn.AssignToMe' | translate">
            <bitmark-icon name="send"></bitmark-icon>
          </button>
        </div>
        <div class="buy-button-container">
          <button class="btn-action btn-action--filled buy-button"
                  (click)="assignToOther()"
                  [innerHTML]="'HandIn.Assign' | translate">
            <bitmark-icon name="send"></bitmark-icon>
          </button>
        </div>
      </div>
    </div>
  }

  @if (status === HandInStatus.Assigned || status === HandInStatus.InReview) {
    @if (studentUser?.fullName || studentUser?.photoUrl) {
      <div>
        {{ 'HandIn.BookWasHandedIn' | translate }} {{ handInDate | smartDate }} <span [@openClose]="isDescriptionOpen ? 'open' : 'closed'">{{ 'HandIn.By' | translate }}</span>
      </div>
      <bitmark-icon (click)="openAccordion()"
                    [name]="isDescriptionOpen ? 'chevron-down' : 'chevron-right'"
                    class="flex-shrink-0 cursor-pointer text-white view-resources-toggle"></bitmark-icon>
      <div [@openClose]="isDescriptionOpen ? 'open' : 'closed'">
        <div
          class="p-1 d-flex flex-row justify-content-center align-items-center text-white person-container">
          <div class="person-avatar mr-3">
            <bitmark-user-box-photo [userEmail]="studentUser?.email || studentUser?.ssoEmail"
                                    [fullName]="studentUser?.fullName"
                                    [iconUrl]="studentUser?.photoUrl"></bitmark-user-box-photo>
          </div>
          <div class="person-name">
            {{ studentUser?.fullName }}
          </div>
        </div>

        @if (currentUserIsAssignedExpert) {
          <div class="d-flex align-items-center justify-content-center">
            <div class='buy-button-container'>
              <button (click)="resolveHandIn(true)" style="background-color: green !important"
                      class='btn-background-calm btn-background-medium buy-button d-flex flex-row align-items-center justify-content-center mr-3'>
                <bitmark-icon name="send" class="mr-1 size-20px"></bitmark-icon>
                {{ 'Shared.Accept'| translate }}
              </button>
            </div>
            <div class='buy-button-container'>
              <button (click)="resolveHandIn(false)" style="background-color: red !important"
                      class='btn-background-calm btn-background-medium buy-button d-flex flex-row align-items-center justify-content-center'>
                <bitmark-icon name="close" class="mr-1 size-24px"></bitmark-icon>
                {{ 'Shared.Reject'| translate }}
              </button>
            </div>
          </div>
        }
      </div>
    }
  }
  @if (status === HandInStatus.Approved) {
    @if(expertUser){
      <p>{{ 'HandIn.YouHaveApprovedThis' | translate }} {{ handInDate | smartDate }} {{ 'HandIn.For' | translate }}</p>
      <div class="p-3 d-flex flex-row justify-content-center align-items-center text-white person-container">
        <div class="person-avatar mr-3">
          <bitmark-user-box-photo [userEmail]="studentUser?.email || studentUser?.ssoEmail"
                                  [fullName]="studentUser?.fullName"
                                  [iconUrl]="studentUser?.photoUrl"></bitmark-user-box-photo>
        </div>
        <div class="person-name">
          {{ studentUser?.fullName }}
        </div>
      </div>
      <p style="font-size: 80px" class="mt-3">👍</p>
    } @else {
      <p>{{ 'HandIn.YouHaveApprovedThis' | translate }} {{ handInDate | smartDate }} {{ 'HandIn.For' | translate }}</p>
      <div class="p-3 d-flex flex-row justify-content-center align-items-center text-white person-container">
        <div class="person-avatar mr-3">
          <bitmark-user-box-photo [userEmail]="studentUser?.email || studentUser?.ssoEmail"
                                  [fullName]="studentUser?.fullName"
                                  [iconUrl]="studentUser?.photoUrl"></bitmark-user-box-photo>
        </div>
        <div class="person-name">
          {{ studentUser?.fullName }}
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <div class="buy-button-container">
          <button class="btn-action btn-action--filled buy-button"
                  (click)="reassignToOther()"
                  [innerHTML]="'HandIn.Assign' | translate">
            <bitmark-icon name="send"></bitmark-icon>
          </button>
        </div>
      </div>
    }
  } @else if (status === HandInStatus.Rejected) {
    <p>{{ 'HandIn.YouHaveRejectedThis' | translate }} - {{ handInDate | smartDate }}</p>

    <p style="font-size: 80px" class="mt-3">😔</p>
  }
</div>

