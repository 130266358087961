<div class="d-flex ml-auto mr-auto cover-image"
     [class.position-relative]="showTitleOverCover"
     #imageCoverElement
     [ngClass]="[cssClass]"
     [style.background]="isImageLoaded && 'transparent'"
     [style.width]="coverImageWidth"
     [style.height]="coverImageHeight"
     [style.maxWidth]="coverMaxWidth">
  @if (coverImage && !isBadImage) {
    <img #imageElement
         [class.invisible]="!isImageLoaded || isBadImage"
         [src]="coverImage | rewriteUrlResize:'book-cover':coverSize"
         [style.filter]="bookShadowFilter"
         [attr.height]="coverSize === 'md' && !isDetail ? (isWide ? 140 : 200) : 'auto'"
         [attr.width]="coverSize === 'md' && !isDetail ? (isWide ? 200 : 140) : 'auto'"
         (load)="onImageLoad()"
         (error)="setBadImageStatus()"/>
    @if (showTitleOverCover) {
      <div class="w-100 p-2 cover-image-title">{{title}}</div>
    }
  } @else {
    @if (bookSubtype === GraphBookTypes.Pdf) {
      <img src="../../../assets/images/PDF-3x.png"/>
    } @else if (bookSubtype === GraphBookTypes.Flashcards) {
      <img src="../../../assets/images/notebook/notebook-flashcards-cover.png"/>
    } @else if (bookSubtype === GraphBookTypes.Worksheet || bookSubtype === GraphBookTypes.Quiz) {
      <img src="../../../assets/images/worksheets-2x.png"/>
    } @else {
      <bitmark-book-default-cover [text]="title" class="w-100"
                                  [isNotExist]="isNotExist"
                                  [isProductCover]="isProductCover"
                                  [coverColor]='brandingPrimaryColor || coverColor'
                                  [style.height]='defaultCoverHeight'>
      </bitmark-book-default-cover>
    }
  }
</div>
