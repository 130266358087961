@if (messages?.length) {
  <div class="feedback-container">
    <div class="d-flex align-items-center mb-2">
      <div class="rounded-circle avatar position-relative">
        <div class="triangle-wrapper">
          <bitmark-icon name="arrow-up-fill" class="triangle size-18px"></bitmark-icon>
        </div>
      </div>
      <div class="ml-2 avatar-text"></div>
    </div>

    @for (m of messages; track m; let first = $first; let isLast = $last) {
      <div class="d-flex w-100 position-relative feedback-entry-bubble-wrapper"
           [class.mb-2]="!isLast">
        <div class="feedback-entry-bubble bg-fill rounded">
          <div class="w-100 d-inline-flex flex-wrap position-relative">
            <div class="position-relative bit-entry-content w-100 text--select">
              <bitmark-message [bit]="m"></bitmark-message>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
}
