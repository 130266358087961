@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
@if (wordBankItems?.length) {
  <ul class="solution-instruction-grouped p-2 mb-2">
    @for (item of wordBankItems; track item.text) {
      <li>
        <div class="d-inline cursor-pointer"
             (click)="toggleWordBankSolution(item)"
             [class.used-solution]="item.used">
          <bitmark-text [text]="item.text"
                        [format]="bit.format"
                        [cssClass]="'d-inline-elements'">
          </bitmark-text>
        </div>
      </li>
    }
  </ul>
}
<div class="bit-body bit-body-allow-highlight"
     [ngClass]="bit.type">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
@if (bit.answer?.video && !isUploading) {
  <div class="mt-2">
    <bitmark-resource-video [bitResourceVideo]="bit.answer.video"
                            [bitFormat]="bit.format">
    </bitmark-resource-video>
  </div>
} @else {
  <div class="mt-2">
    <bitmark-record-video-edit [isUploadingRecording]="isUploading"
                               (onStop)="recordingStopped($event)"
                               (onCancel)="recordingCancelled()">
    </bitmark-record-video-edit>
  </div>
}
