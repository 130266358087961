@if (size) {
  <div class="svg-loading" [style.height]="size * 2 + 'px'" [style.width]="size * 2 + 'px'">
    <svg [attr.viewBox]="'0 0 ' + size * 2 + ' ' + size * 2">
      <circle [attr.cx]="size" [attr.cy]="size" [attr.r]="size/2+3" stroke-linecap="square" fill="none"></circle>
    </svg>
  </div>
} @else {
  <div class="svg-loading">
    <svg viewBox="0 0 48 48">
      <circle cx="24" cy="24" r="15" stroke-linecap="square" fill="none"></circle>
    </svg>
  </div>
}
