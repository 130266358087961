import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {RouterModule} from '@angular/router';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {AngularResizeEventModule} from 'angular-resize-event';
import {ClipboardModule} from 'ngx-clipboard';
import {NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AutosizeModule} from 'ngx-autosize';
import {NgxDayjs9Module} from 'ngx-dayjs9';
import {TranslateModule} from '@ngx-translate/core';
import * as barrel from '.';
import {BitmarkModule} from 'bitmark';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ClipboardModule,
    NgbDropdownModule,
    NgbModule,
    InfiniteScrollModule,
    NgxDayjs9Module,
    AutosizeModule,
    AngularResizeEventModule,
    ImageCropperModule,
    FormsModule,
    TranslateModule,
    BitmarkModule
  ],
  declarations: [...barrel.components],
  exports: [
    CommonModule,
    ClipboardModule,
    NgbDropdownModule,
    NgbModule,
    FormsModule,
    InfiniteScrollModule,
    NgxDayjs9Module,
    ReactiveFormsModule,
    AutosizeModule,
    // CompileModule,
    AngularResizeEventModule,
    ImageCropperModule,
    TranslateModule,
    ...barrel.components
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static LocalParser = {
    enabled: false
  };

  static EditorMenusFromTheme = {
    enabled: false
  };

  static UserSpace() {
    let volatile: any = window.localStorage.getItem('gmb-cosmic.volatile');
    if (volatile?.length) {
      volatile = JSON.parse(volatile);
      const user = volatile.user;
      return user?.claims?.loginSpace || user?.claims?.spaces[0] || 'personal';
    }
    return null;
  }

  static IsProUser() {
    let volatile: any = window.localStorage.getItem('gmb-cosmic.volatile');
    if (volatile?.length) {
      volatile = JSON.parse(volatile);
      const user = volatile.user;
      return user?.isProUser;
    }
    return null;
  }

  static UserId() {
    let volatile: any = window.localStorage.getItem('gmb-cosmic.volatile');
    if (volatile?.length) {
      volatile = JSON.parse(volatile);
      const user = volatile.user;
      return user?.id;
    }
    return null;
  }

  static HasChatGpt() {
    let volatile: any = window.localStorage.getItem('gmb-cosmic.volatile');
    if (volatile?.length) {
      volatile = JSON.parse(volatile);
      const user = volatile.user;
      return user?.claims?.hasChatGpt;
    }
    return null;
  }

  static UserPhoto(): { photoUrl: string, fullName: string } {
    let volatile: any = window.localStorage.getItem('gmb-cosmic.volatile');
    if (volatile?.length) {
      volatile = JSON.parse(volatile);
      const user = volatile.user;
      return {
        photoUrl: user?.photoUrl,
        fullName: user?.fullName
      };
    }
    return null;
  }

  static UserLanguage(): string {
    let volatile: any = window.localStorage.getItem('gmb-cosmic.volatile');
    if (volatile?.length) {
      volatile = JSON.parse(volatile);
      const user = volatile.user;
      return user?.language || 'en';
    }
    return null;
  }

  static UserEmails(): { email: string, ssoEmail: string } {
    let volatile: any = window.localStorage.getItem('gmb-cosmic.volatile');
    if (volatile?.length) {
      volatile = JSON.parse(volatile);
      const user = volatile.user;
      return {
        email: user?.email,
        ssoEmail: user?.ssoEmail
      };
    }
    return null;
  }
}
