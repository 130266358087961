@if (!additionalContent) {
  @for (action of actions; track action) {
    @if (!action.isHidden) {
      @if (action.isSeparator) {
        <div class="dropdown-separator"></div>
      }
      @if (!action.isSeparator && !action?.actions?.length && !action?.colors?.length) {
        <button (click)="onOptionSelected(action)"
                class="dropdown-item-button py-1 px-2"
                [disabled]="action.isDisabled">
          @if (action.icon) {
            <bitmark-icon [name]="action.icon" [ngClass]="action.iconCssClass || 'text-black'"></bitmark-icon>
          }
          @if (action.image) {
            <div class='d-inline-block dropdown-item-image'>
              <img [src]="action.image"/>
            </div>
          }
          @if (action.prefix) {
            <span class="text-left pl-2 prefix" [innerHTML]="action.prefix"></span>
          }
          <span class="text-left text--ellipsis" [ngClass]="{'pl-2': !action?.image}" [innerHTML]="action.label"></span>
          <span class="pl-2 align-self-end ml-auto" [innerHTML]="action.shortcut"></span>
        </button>
      }
      @if (!action.isSeparator && !action?.actions?.length && action?.colors?.length) {
        <div class="p-1 dropdown-item-colors-container">
          @for (color of action.colors; track color) {
            <div class="m-1 cursor-pointer dropdown-item-color"
                 [ngStyle]="{backgroundColor: color.primary}"
                 (click)="onColorOptionSelected(action, color)">
              @if (color.isSelected) {
                <div class="dropdown-item-color-selected"></div>
              }
            </div>
          }
        </div>
      }
      @if (action.actions) {
        <ng-template #subActions>
          <button class="dropdown-item-button py-1 px-2">
            @if (action.icon) {
              <bitmark-icon [name]="action.icon"
                            [ngClass]="action.iconCssClass || 'text-black'">
              </bitmark-icon>
            }
            <div class="text-left pl-2" [innerHTML]="action.label"></div>
            <bitmark-icon name="chevron-right" class="pl-2 align-self-end ml-auto"></bitmark-icon>
          </button>
        </ng-template>
        <bitmark-dropdown class="d-block ml-auto w-100"
                          dropdownClass="ml-1 auto-width scrollable"
                          [placement]="['right', 'left']"
                          [actions]="action.actions"
                          [dropdownButton]="subActions"
                          [scrollAntiBlockStopPropagation]="true"
                          (selected)="closed.emit()">
        </bitmark-dropdown>
      }
      @if (action.additionalContent) {
        <ng-container *ngTemplateOutlet="action.additionalContent"></ng-container>
      }
    }
  }
} @else {
  <ng-container *ngTemplateOutlet="additionalContent"></ng-container>
}
