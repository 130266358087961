<div class="learning-event-header">
  @if (isBeingEditedByUser) {
    <div class="d-flex align-items-center">
      <div class="learning-event-item">
        <bitmark-editable-text [text]="bit.item"
                               [placeholder]="'Bits.Item' | translate"
                               [cssClass]="'is-editing'"
                               (onChange)="bit.item = $event; saveChanges();">
        </bitmark-editable-text>
      </div>
      <div class="d-flex learning-event-lead-dash-before"></div>
      <div class="learning-event-lead">
        <bitmark-editable-text [text]="bit.lead"
                               [placeholder]="'Bits.Lead' | translate"
                               [cssClass]="'is-editing'"
                               (onChange)="bit.lead = $event; saveChanges();">
        </bitmark-editable-text>
      </div>
      <div class="d-flex learning-event-lead-dash-after"></div>
    </div>
  } @else if (!isItemEmpty || !isLeadEmpty) {
    <div class="d-flex align-items-center">
      @if (!isItemEmpty) {
        <div class="learning-event-item">
          <bitmark-text [text]="bit.item"></bitmark-text>
        </div>
      }
      @if (!isItemEmpty && !isLeadEmpty) {
        <div class="d-flex learning-event-lead-dash-before"></div>
      }
      @if (!isLeadEmpty) {
        <div class="learning-event-lead">
          <bitmark-text [text]="bit.lead"></bitmark-text>
        </div>
      }
      @if (!isItemEmpty && !isLeadEmpty) {
        <div class="d-flex learning-event-lead-dash-after"></div>
      }
    </div>
  }
  <div class="d-flex position-relative">
    @if (isBeingEditedByUser) {
      <div class="learning-event-instruction">
        <bitmark-editable-text
          (onChange)="bit.instruction = $event; saveChanges();"
          [text]="bit.instruction"
          [placeholder]="'Bits.Instruction' | translate"
          [cssClass]="'is-editing'">
        </bitmark-editable-text>
      </div>
    } @else if (bit.instruction) {
      <div class="learning-event-instruction">
        <bitmark-text [text]="bit.instruction"></bitmark-text>
      </div>
    }
    @if (!isBodyEmpty || isBeingEditedByUser) {
      <div class="d-flex cursor-pointer toggle-btn" [class.rotate]="!isBodyCollapsed"
           (click)="toggleBody(bit.id, isInPast)">
        <bitmark-icon name="chevron-right" class="size-30px"></bitmark-icon>
      </div>
    }
  </div>
  <div class="d-flex align-items-center flex-wrap learning-event-header-details">
    @if (isBeingEditedByUser) {
      <div class="mt-2 mr-2 learning-event-activity-type">
        <bitmark-editable-text
          (onChange)="bit.activityType = $event; saveChanges();"
          [text]="bit.activityType"
          [placeholder]="'Bits.ActivityType' | translate"
          class="py-1 px-2"
          [cssClass]="'is-editing'">
        </bitmark-editable-text>
      </div>
    } @else if (bit.activityType) {
      <div class="mr-2 learning-event-activity-type" [class.mt-2]="bit.item || bit.instruction">
        <bitmark-text [text]="bit.activityType" class="py-1 px-2"></bitmark-text>
      </div>
    }
    @if (isBeingEditedByUser) {
      <div class="d-flex align-items-center mr-2 learning-event-date"
           [class.mt-2]="bit.item || bit.instruction">
        <bitmark-icon name="calendar-event" class="size-18px mr-1"></bitmark-icon>
        <bitmark-date-picker [(ngModel)]="bit.date"
                             (ngModelChange)="saveChanges()"
                             placeholder="{{'Bits.Date' | translate}}"
                             format="{{'EE dd MMMM yyyy'}}"
                             ngDefaultControl
                             [class.is-editing]="isBeingEditedByUser">
        </bitmark-date-picker>
        <span class="mx-1">|</span>
        <bitmark-time-editor [(ngModel)]="bitTime"
                             (ngModelChange)="saveChanges()"
                             placeholder="{{'Bits.From' | translate}}"
                             ngDefaultControl
                             [class.is-editing]="isBeingEditedByUser">
        </bitmark-time-editor>
        <span class="mx-1">-</span>
        <bitmark-time-editor [(ngModel)]="bitEndTime"
                             (ngModelChange)="saveChanges()"
                             placeholder="{{'Bits.To' | translate}}"
                             ngDefaultControl
                             [class.is-editing]="isBeingEditedByUser">
        </bitmark-time-editor>
      </div>
    } @else if (bit.date | bitmarkDate) {
      <div class="d-flex align-items-center mr-2 learning-event-date"
           [class.mt-2]="bit.item || bit.instruction">
        <bitmark-icon name="calendar-event" class="size-18px mr-1"></bitmark-icon>
        <span [class.is-closed]="isInPast">{{ bit.date | bitmarkDate: 'EE dd MMMM yyyy' }}</span>
        <span class="mx-1">|</span>
        <span [class.is-closed]="isInPast">{{ bit.date | bitmarkDate: 'shortTime' }}</span>
        @if (bit.dateEnd | bitmarkDate) {
          <span class="mx-1">-</span>
          <span [class.is-closed]="isInPast">{{ bit.dateEnd | bitmarkDate:'shortTime' }}</span>
        }
      </div>
    }
    @if (!bit.isInfoOnly && !isInPast && bit.date | bitmarkDate) {
      <div class="d-flex align-items-center cursor-pointer learning-event-calendar"
           [class.mt-2]="bit.item || bit.instruction"
           (click)="downloadAddToCalendar()">
        <bitmark-icon name="plus-square" class="size-18px mr-1"></bitmark-icon>
        <span>{{ 'Shared.AddToCalendar' | translate }}</span>
      </div>
    }
  </div>
</div>
<div [class.overflow-hidden]="!isBeingEditedByUser" [@slideToggleIsDone]="toggleStateIsDone">
  @if (bit.videoCallLink && !bit.isInfoOnly) {
    <div class="d-flex justify-content-center mt-4 learning-event-join">
      <button class="btn btn-action bit-button text--ellipsis d-inline-block"
              (click)="join()"
              [innerHTML]="bit?.buttonCaption || ('Shared.Join' | translate)">
      </button>
    </div>
  }
  @if (isBeingEditedByUser) {
    <div class="flex-fill bit-body bit-body-allow-highlight">
      <div class="mt-4 is-editing-multi-line">
        <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                    [bit]="bit"
                                    [bitContent]="bit.body | bitmark:bit.format:'json'"
                                    [isReadOnly]="false"
                                    [placeholder]="'Bits.PlaceholderBody' | translate">
        </bitmark-reader-text-editor>
      </div>
    </div>
  } @else if (!isBodyEmpty) {
    <div class="flex-fill bit-body bit-body-allow-highlight">
      <div class="mt-4">
        <bitmark-text [text]="bit.body" [format]="bit.format"></bitmark-text>
      </div>
    </div>
  }

  @if (isBeingEditedByUser) {
    <div class="mt-2"></div>
    <div class="p-2 edit-container">
      <input type="text"
             class="form-control mb-1"
             placeholder="https://..."
             [(ngModel)]="bit.videoCallLink"
             (ngModelChange)="saveChanges()"/>
    </div>
  }
</div>
