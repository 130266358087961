<div class="d-inline-flex align-items-center">
  <div class="input-wrapper d-inline-block">
    <input autocomplete="off"
           autocorrect="false"
           spellcheck="false"
           type="text"
           class="d-inline"
           [(ngModel)]="defaultText"
           (input)="onChange($event)"/>
  </div>
  <div class="placeholder-instruction text-pre-wrap">
  </div>
  <bitmark-icon name="help-circle-outline"
                class="placeholder-hint text-calm cursor-pointer"
                triggers="click:blur"
                container="body">
  </bitmark-icon>
</div>
