<div #readerTextEditor
     class="reader-text-editor">
  <div class="pos-relative text-editor-actions">
    <bitmark-contextual-menu-code (onDeleteNode)="deleteNode()"
                                  (onLanguageChanged)="updateLanguage.emit($event)"
                                  [editor]="editor">
    </bitmark-contextual-menu-code>
  </div>
  <tiptap-editor [editor]="editor"></tiptap-editor>
  <div class="tiptap-bubble-menu-list-empty"></div>
</div>
