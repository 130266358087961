<div class="reader-shadow bg-white pt-2 pb-4 choose-book-container">
  <div class="d-flex align-items-start justify-content-between px-3">
    <h4 class="m-0 pt-4 font-weight-medium">{{ 'Shared.ChooseBook' | translate }}</h4>
    <bitmark-icon name="close" class="size-20px cursor-pointer text-black pt-1" (click)="closeModal()"></bitmark-icon>
  </div>
  <div class="pt-3 px-3">
    <div class="d-flex align-items-center">
      <input class="border-0 flex-grow-1 flex-shrink-1 search-input"
             [placeholder]="'Search.SearchBook' | translate"
             (input)="searchBook($event)"/>
    </div>
  </div>
  <div class="d-flex flex-column pt-1 books-list">
    @for (book of displayBooks; track book.id) {
      <div (click)="chooseBook(book)"
           class="d-flex align-items-center cursor-pointer px-3">
        <bitmark-book-cover
          [coverImage]="book.coverImage"
          [coverColor]='book?.coverColor'
          [bookSubtype]="book.subtype"
          [title]="book.title"
          [isDetail]="true"
          coverSize="sm"
          coverImageHeight="50px"
          defaultCoverSize='xs'
          class="py-1">
        </bitmark-book-cover>
        <div class="ml-2 book-details">
          <div class="text--ellipsis book-title">{{ book.title }}</div>
          <div class="text--ellipsis book-subtitle">{{ book.subtitle }}</div>
        </div>
      </div>
    }
  </div>
</div>
