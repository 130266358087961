<div class='d-flex flex-row flex-wrap page-product-container'>
  @for (product of products; track product) {
    @if (product) {
      <div class='page-product d-flex flex-column justify-content-start align-items-center'
           [ngClass]="{'entry-wide': isWideEntry, 'cursor-pointer': !product?.bitbookDoesNotExist, 'cursor-default': product?.bitbookDoesNotExist}">
        <div class='book-cover d-flex align-items-end ml-auto mr-auto position-relative'>
          <bitmark-book-cover (click)='openProductPage(product)'
                              (onIsWideImage)='setWideImage()'
                              class='w-100 h-100'
                              [coverImage]="product?.media?.length && product?.media[0].file.url"
                              [isFullyBranded]='true'
                              [isWideCoverAllowed]='true'
                              [isProductCover]="true"
                              [isNotExist]="product?.productDoesNotExist || product?.isInWrongSpace"
                              [title]='product?.title'>
          </bitmark-book-cover>
          <!--        <ng-template #dropdownButton>-->
          <!--          <div class='p-1 cursor-pointer'>-->
          <!--            <div class='dot calm'></div>-->
          <!--          </div>-->
          <!--        </ng-template>-->
          <!--        <bitmark-dropdown class='d-inline-block ml-auto book-menu'-->
          <!--                          dropdownClass='auto-width'-->
          <!--                          [placement]="['top-left']"-->
          <!--                          [actions]='statuses'-->
          <!--                          [dropdownButton]='dropdownButton'>-->
          <!--        </bitmark-dropdown>-->
        </div>
        <div class='mt-2 book-texts px-lg-1 px-0' (click)='openProductPage(product)'>
          @if (product?.publisher) {
            <div class='book-publisher mb-0 text-center'
                 [innerText]='product.publisher'>
            </div>
          }
          @if (product?.title) {
            <div class='book-title text-center line-clamp'
                 [innerText]='product.title + (product?.bitbookDoesNotExist && !product?.productDoesNotExist ? "&nbsp;🔒" : "")'>
            </div>
          }
          @if (product?.meta) {
            <div>
              @if (BookType.Paperbook === product.meta.type) {
                <div class='text-center book-subtitle'>Paper</div>
              } @else if (BookType.PDFBook === product.meta.type) {
                <div class='text-center book-subtitle'>PDF</div>
              }
            </div>
          }
          @if (product?.meta?.handInStatus) {
            <div class="text-center hand-in-emoji">
              @if(product?.meta?.handInStatus === HandInStatus.NotHandedIn){
                ✏️
              }
              @if(product?.meta?.handInStatus === HandInStatus.Rejected){
                ❌
              }
              @if(product?.meta?.handInStatus === HandInStatus.Approved){
                🏅
              }
              @if(product?.meta?.handInStatus === HandInStatus.HandedIn || product?.meta?.handInStatus === HandInStatus.Assigned){
                ✋
              }
            </div>
          }
        </div>
      </div>
    }
  }
</div>
