import {AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList} from '@angular/core';
import {TransitionGroupItemDirective} from 'shared/transition-group/transition-group-item.directive';
import {Subscription} from 'rxjs';

// https://stackoverflow.com/questions/43928524/how-to-implement-item-reorder-shuffle-animations-with-angulars-ngfor

@Component({
  selector: 'app-transition-group',
  template: '<ng-content></ng-content>'
})
export class TransitionGroupComponent implements AfterContentInit, OnDestroy {
  @Input() className: string;

  @ContentChildren(TransitionGroupItemDirective) items: QueryList<TransitionGroupItemDirective>;

  private sub: Subscription;

  ngAfterContentInit() {
    this.refreshPosition('prevPos');
    this.sub = this.items.changes.subscribe(items => {
      items.forEach(item => {
        item.prevPos = item.newPos || item.prevPos;
      });

      items.forEach(this.runCallback);
      this.refreshPosition('newPos');
      items.forEach(this.applyTranslation);

      // force reflow to put everything in position
      const offSet = document.body.offsetHeight;
      this.items.forEach(this.runTransition.bind(this));
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  runCallback(item: TransitionGroupItemDirective) {
    if (item.moveCallback) {
      item.moveCallback();
    }
  }

  runTransition(item: TransitionGroupItemDirective) {
    if (!item.moved) {
      return;
    }
    setTimeout(() => {
      const cssClass = this.className + '-move';
      const el = item.el;
      const style: any = el.style;
      el.classList.add(cssClass);
      style.transform = style.WebkitTransform = style.transitionDuration = '';
      el.addEventListener('transitionend', item.moveCallback = (e: any) => {
        if (!e || /transform$/.test(e.propertyName)) {
          el.removeEventListener('transitionend', item.moveCallback);
          item.moveCallback = null;
          el.classList.remove(cssClass);
        }
      });
    }, 100);
  }

  refreshPosition(prop: string) {
    this.items.forEach(item => {
      item[prop] = {
        top: item.el.offsetTop,
        left: item.el.offsetLeft
      };
    });
  }

  applyTranslation(item: TransitionGroupItemDirective) {
    item.moved = false;
    const dx = item.prevPos.left - item.newPos.left;
    const dy = item.prevPos.top - item.newPos.top;
    if (dx || dy) {
      item.moved = true;
      const style: any = item.el.style;
      style.transform = style.WebkitTransform = 'translate(' + dx + 'px,' + dy + 'px)';
      style.transitionDuration = '0s';
    }
  }
}
