<div [class]="containerCssClass">
  <div class='editable-label-container'>
    <div class='d-flex mb-0 justify-content-space-between align-items-center'
         [ngClass]='{"translated-label": !isEditing,
        "orange-editable-field": isEditing,
        "align-items-start": isLargeField && isEditing,
        "line-clamp": firstRowOnly,
        "align-items-center": !isLargeField || !isEditing, cssClass}'>
      <span #editableLabel
            class="editable-label-text"
            [contentEditable]='isEditing'
            [ngClass]='{"w-100": isEditing, "min-height-200": isLargeField && isEditing}'>
        {{ label }}
      </span>
      @if (!isEditing && !label?.trim()?.length) {
        <span class='text-gray'>{{ placeholder | translate }}</span>
      }
      @if (isOrganizer && !isEditing) {
        <img (click)='startEditing()'
             src='assets/images/ts-editor/pen.png'
             class='translated-label-icon ml-2'/>
      }
      @if (isOrganizer && isEditing) {
        <div class='d-flex flex-row align-items-center'
             [ngClass]='{" pt-1": isLargeField}'
             style='min-width: 54px'>
          <app-icon (click)='onCancel()'
                    name="close"
                    color="#ff8000"
                    class="orange-close ml-2">
          </app-icon>
          <img (click)='saveChanges()'
               src='assets/images/ts-editor/ribbon-save.png'
               class='translated-label-icon mx-1'/>
        </div>
      }
    </div>
  </div>
</div>

