@if (!pair.isExample) {
  <textarea class="w-100 m-0 input short"
            [ngClass]="answerCssClass"
            autocomplete="off"
            autocorrect="false"
            spellcheck="false"
            [readOnly]="readOnly"
            [value]="pair.answer?.text || ''"
            [placeholder]="pair.instruction | bitmark:format:'text'"
            (input)="change($event)">
</textarea>
} @else {
  <bitmark-text class="d-block w-100 m-0 input short correct"
                style="overflow-y: auto"
                [text]="pair.values[0] || pair.example || ''"
                [format]="format">
  </bitmark-text>
}
