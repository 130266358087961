import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {FloatingMenuDirective} from 'ngx-tiptap';
import {Editor, JSONContent} from '@tiptap/core';
import {BitApiWrapper, BitType} from '../../../bits/bits.models';
import {BitbookApiService} from '../../bitbook-api.service';
import {ProsemirrorBit} from '../../../bits/prosemirror/prosemirror.models';
import {ReaderTipTapTapService, TipTapEditorType} from '../../tiptap/reader-tiptap.service';
import {SubSink} from '../../../shared';

@Component({
  selector: 'bitmark-reader-simple-text-editor',
  templateUrl: './reader-simple-text-editor.component.html',
  styleUrls: ['./reader-simple-text-editor.component.scss'],
  viewProviders: [FloatingMenuDirective]
})
export class ReaderSimpleTextEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  private updates = new Subject();
  @ViewChild('readerTextEditor') textEditorRef: ElementRef;
  @Input() isReadOnly = true;
  @Input() height: any;
  @Input() bitContent: any;
  @Input() bit: ProsemirrorBit;
  @Input() bitWrapper: BitApiWrapper;
  @Input() placeholder?: string;
  @Input() updateContentEvent?: Observable<any>;
  @Input() hasLists?: boolean;
  @Input() hasStrike?: boolean = true;
  @Input() hasLight?: boolean = true;
  @Input() hasHighlight?: boolean = true;
  @Input() hasScript?: boolean = true;
  @Output() save = new EventEmitter<any>();
  @Output() debounceUpdate = new EventEmitter();
  @Output() updateBit = new EventEmitter<JSONContent>();
  @Output() created = new EventEmitter<Editor>();
  @ViewChild('fileInput') fileInput: ElementRef;
  editor: Editor = {} as any;
  bitmark = '';
  value = ``;
  isRendered = false;
  isSpellcheckOn = false;
  BitType = BitType;

  private subSink = new SubSink();
  private isUpdateFromOutside = false;

  @HostListener('document:mousedown', ['$event'])
  mouseout(event) {
    if (typeof event?.target?.className !== 'string') {
      return false;
    }
    if ((this.editor.view as any).docView.dom.parentElement.parentElement.contains(event.target)) {
      // if the click is outside the editor
      if (event.target?.className?.length && event.target?.className?.indexOf('contextual-menu-trigger') === -1 &&
        event.target?.className?.indexOf('tiptap-bubble-item') === -1 &&
        event.target?.className?.indexOf('dropdown-item-button') === -1 &&
        event.target?.parent?.className?.indexOf('dropdown-item-button') === -1 &&
        event.target?.parentElement.parentElement.querySelectorAll('.menu-bubble-item').length === 0 &&
        ((this.editor.view as any).docView.dom.parentElement.parentElement.querySelectorAll('.contextual-menu-absolute .show').length ||
          // if there is an open menu, just close the menu and keep the current selection
          [].filter.call((this.editor.view as any).docView.dom.parentElement.parentElement.querySelectorAll('#contextual-menu-general'), function (div) {
            return !div.className.match(/\bd-hidden\b/) && div.style.display !== 'none';
          }).length)) {
        event.preventDefault();
        this.readerTipTapService.closeOpenedMenus(this.editor);
      } else {
        // just a normal click inside, should show contextual menu
        [].forEach.call((this.editor.view as any).docView.dom.parentElement.parentElement.querySelectorAll('.contextual-menu-absolute'), function (div) {
          div.style.display = 'inline-block';
        });
      }
    } else {
      this.readerTipTapService.setEditorReadonly(this.editor);
      this.isSpellcheckOn = false;
      const keyboards = document.getElementsByClassName('ML__keyboard');
      for (let i = 0; i < keyboards.length; i++) {
        keyboards[i].className = keyboards[i].className.replace(/\bis-visible\b/g, '');
      }
    }
  }

  constructor(private bitbookApiService: BitbookApiService,
              private readerTipTapService: ReaderTipTapTapService) {
  }

  ngOnInit() {
    this.subSink.sink = this.updates
      .pipe(debounceTime(500))
      .subscribe(() => {
        if (!this.isUpdateFromOutside) {
          this.emitUpdate();
        }
        this.isUpdateFromOutside = false;
      });

    if (this.updateContentEvent) {
      this.subSink.sink = this.updateContentEvent.subscribe((content) => {
        if (content) {
          this.editor.commands.setContent(content);
          this.isUpdateFromOutside = true;
        }
      });
    }
    this.editor = new Editor({
      editable: !this.isReadOnly,
      onCreate: () => this.created.emit(this.editor),
      onTransaction: ({editor, transaction}) => {
        if (transaction.steps.length && this.isRendered) {
          this.updates.next(event);
        }
      },
      editorProps: this.readerTipTapService.getEditorProps(),
      enableInputRules: false,
      enablePasteRules: false,
      extensions: this.readerTipTapService.getExtensions(TipTapEditorType.BitmarkMinus, () => {
        return this.editor;
      }, this.placeholder, this.hasLists, this.bit?.id)
    });
    if (this.bitContent) {
      this.editor.commands.setContent(this.bitContent);
    }
  }

  ngAfterViewInit() {
    this.isRendered = true;
    if (this.height) {
      const proseMirrorElem = this.textEditorRef.nativeElement.querySelector('.ProseMirror');
      if (proseMirrorElem) {
        proseMirrorElem.style.height = `${this.height}px`;
        proseMirrorElem.style.overflow = 'hidden';
      }
    }
  }

  ngOnDestroy() {
    this.updates?.unsubscribe();
    this.subSink.unsubscribe();
    this.editor.extensionManager.extensions = null;
    this.editor.extensionStorage = null;
    this.editor.view.destroy();
    this.editor.view.state = null;
    this.editor.view = null;
    this.editor.destroy();
    this.editor = null;
  }

  uploadImage() {
    if (!this.fileInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileInput.nativeElement.files[0];
    this.bitbookApiService.uploadResource(file)
      .subscribe((resourceUrl: { url: string }) => {
        this.editor.chain().focus().setImage({src: resourceUrl.url}).run();
      }, (err) => {
        this.fileInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload image');
      });
  }

  focus() {
    this.textEditorRef.nativeElement.querySelector('.ProseMirror').focus();
    this.isSpellcheckOn = true;
  }

  selectAll() {
    this.editor.commands.selectAll();
  }

  emitUpdate() {
    this.updateBit.emit(this.editor.getJSON());
  }

  deleteNode(nodeName: string) {
    this.readerTipTapService.deleteNode(this.editor, nodeName);
  }

  clearHeader() {
    this.editor.commands.clearNodes();
  }
}
