<div class='bit-body d-flex flex-row no-gutters  flex-wrap mb-1' [ngClass]='bit.type'>
  @for (source of bit?.body; track source; let i = $index) {
    <div (click)='navigateToSource(source)'
         class='no-gutters cursor-pointer book-source branded-bs d-flex flex-row justify-content-between align-items-center'>
      <div class="branded-bs-left">
        @if (!source.displayChapterElement) {
          <bitmark-text [text]='source?.pageContent'
                        [format]='BitmarkFormat.PP'
                        cssClass='f-12 book-source-content'>
          </bitmark-text>
        }
        @if (source.displayChapterElement) {
          <div class='book-source-title branded-bst'>
            <p class="d-flex flex-row">
              @if (source.displayChapterLead) {
                <span [innerHTML]='source.displayChapterLead | safeHtml'
                      class='no-wrap d-inline pr-1 font-weight-bold'>
                </span>
              }
              @if (source.displayChapterElement) {
                <span [innerHTML]='source.displayChapterElement | safeHtml'
                      class='no-wrap d-inline font-weight-bold' style="text-overflow: ellipsis;overflow: hidden;">
                </span>
              }
            </p>
          </div>
          <div class='book-source-content branded-bsc d-flex flex-row'>
            @if (source.displayContentLead) {
              <span [innerHTML]='source.displayContentLead | safeHtml'
                    class='no-wrap d-inline pr-1 font-weight-bold'>
              </span>
            }
            @if (source.displayContentElement) {
              <span [innerHTML]='source.displayContentElement | safeHtml'
                    class='no-wrap d-inline font-weight-bold'>
              </span>
            }
          </div>
        }
      </div>
      <bitmark-icon
        name='arrow-right'
        class='size-20px d-block bg-primary text-white btn-circle branded-bs-right'>
      </bitmark-icon>
    </div>
  }
</div>
