<div class="learning-event-header">
  <div class="position-relative" [class.is-editing-instruction]="isBeingEditedByUser">
    <bitmark-bit-item-instruction [bit]="bit"
                                  [isBeingEditedByUser]="isBeingEditedByUser"
                                  [emitUpdates]="true"
                                  (onIsBeingEdited)="saveChanges()">
    </bitmark-bit-item-instruction>
    @if (bit.body || bit.resource || isBeingEditedByUser) {
      <div class="d-flex cursor-pointer toggle-btn" [class.rotate]="!isBodyCollapsed"
           (click)="toggleBody(bit.id)">
        <bitmark-icon name="chevron-right" class="size-30px"></bitmark-icon>
      </div>
    }
  </div>
</div>
<div class="overflow-hidden" [@slideToggleIsDone]="toggleStateIsDone">
  <div class="d-flex align-items-center flex-wrap learning-event-header-details">
    @if (isBeingEditedByUser) {
      <div class="mt-2 mr-2 learning-event-activity-type">
        <bitmark-editable-text
          (onChange)="bit.activityType = $event; saveChanges();"
          [text]="bit.activityType"
          [placeholder]="'Bits.ActivityType' | translate"
          class="py-1 px-2"
          [cssClass]="'is-editing'">
        </bitmark-editable-text>
      </div>
    } @else if (bit.activityType) {
      <div class="mr-2 learning-event-activity-type"
           [class.mt-2]="bit.item || bit.instruction">
        <bitmark-text [text]="bit.activityType" class="py-1 px-2"></bitmark-text>
      </div>
    }
    @if (isBeingEditedByUser) {
      <div class="d-flex align-items-center mt-2 mr-2 learning-event-duration">
        <bitmark-icon name="stopwatch" class="size-18px mr-1"></bitmark-icon>
        <bitmark-duration-editor ngDefaultControl
                                 [(ngModel)]="bit.duration"
                                 class="is-editing"
                                 (ngModelChange)="saveChanges()">
        </bitmark-duration-editor>
      </div>
    } @else if (bit.duration) {
      <div class="d-flex align-items-center mr-2 learning-event-duration"
           [class.mt-2]="bit.item || bit.instruction || isBeingEditedByUser">
        <bitmark-icon name="stopwatch" class="size-18px mr-1"></bitmark-icon>
        <span>{{ bit.duration | amDuration }}</span>
      </div>
    }
    @if (isBeingEditedByUser) {
      <div class="d-flex align-items-center mt-2 mr-2 learning-event-location">
        <bitmark-icon name="geo-alt" class="size-18px mr-1"></bitmark-icon>
        <bitmark-editable-text
          (onChange)="bit.location = $event; saveChanges();"
          [text]="bit.location"
          [placeholder]="'Bits.Location' | translate"
          [cssClass]="'is-editing'">
        </bitmark-editable-text>
      </div>
    } @else if (bit.location) {
      <div class="d-flex align-items-center mr-2 learning-event-location"
           [class.mt-2]="bit.item || bit.instruction">
        <bitmark-icon name="geo-alt" class="size-18px mr-1"></bitmark-icon>
        <bitmark-text [text]="bit.location"></bitmark-text>
      </div>
    }
  </div>
  <div class="bit-body bit-body-allow-highlight overflow-hidden" [@slideToggle]="toggleState">
    @if (bit.resource) {
      <bitmark-resource [bitResource]="bit.resource" [hostBit]="bit" class="d-block mt-4">
      </bitmark-resource>
    }
    @if (isBeingEditedByUser) {
      <div class="mt-4 is-editing-multi-line">
        <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                    [bit]="bit"
                                    [bitContent]="bit.body | bitmark:bit.format:'json'"
                                    [isReadOnly]="false"
                                    [placeholder]="'Bits.PlaceholderBody' | translate"
                                    class="w-100">
        </bitmark-reader-text-editor>
      </div>
    } @else if (bit.body || bit.resource) {
      @if (bit.body) {
        <div class="mt-4">
          <bitmark-text [text]="bit.body" [format]="bit.format"></bitmark-text>
        </div>
      }
    }
  </div>
</div>
