<div class="cover-default p-0 text-center text-white"
     [ngClass]="size"
     [class.full-cover-image]="notebook?.coverImage && appliedCoverType === NotebookCoverTypes.FullCover"
     [class.cover-fill]="!notebook?.coverImage && appliedCoverType !== NotebookCoverTypes.CustomCover"
     [class.no-cover-type-image]="notebook?.coverImage && appliedCoverType === NotebookCoverTypes.CustomCover">
  <div class="h-100 position-relative d-flex align-items-end"
       [ngClass]="'notebook-' + appliedCoverType + '-mask'"
       [class.cover-image]="notebook?.coverImage && appliedCoverType !== NotebookCoverTypes.CustomCover"
       [style.background]="background"
       (click)="onClick.emit($event)">
    @if (notebook.coverImage) {
      <picture class="d-flex align-self-center cover-picture">
        @if (coverImageWebp) {
          <source type="image/webp" [attr.srcset]="coverImageWebp | rewriteUrlResize:'notebook-cover':size">
        }
        <source type="image/jpg" [attr.srcset]="coverImageJpg | rewriteUrlResize:'notebook-cover':size">
        <img #imageElement
             (load)="onImageIsLoaded()"
             [attr.alt]="notebook.title"
             [src]="coverImageJpg | rewriteUrlResize:'notebook-cover':size"
             [attr.height]='displayHeight'
             [attr.width]='displayWidth'
             (error)="fallbackCover($event)"
             loading='lazy'/>
      </picture>
    }
    @if (!notebook.coverImage) {
      <div class="w-100 h-100 d-flex align-items-center justify-content-center showcase">
        <div
          #notebookDefaultLetter
          class="unselectable"
          [ngClass]="{'showcase-letter-emoji': isEmoji, 'showcase-letter': !isEmoji}"
          [innerHTML]="showcaseLetter">
        </div>
      </div>
    }
    <div class="position-absolute h-100 w-100" style="top: 0">
      @if (notebookCoverTypesWithMask.includes(appliedCoverType)) {
        <svg width="100%" height="100%" [attr.viewBox]="'0 0 280 400'" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="notebook_cover_overlay">
              <polygon id="Bookmark"
                       [ngStyle]="{fill: 'red'}"
                       class="bookmark-shadow"
                       points="200 0 236 0 236 54 218 43.875 200 54"></polygon>
              <g id="back">
                <image id="Back" x="0" y="0" width="40" height="400"
                       href="assets/images/notebook/notebook-cover-margin.png"></image>
                <rect id="Rectangle" fill="#FFFFFF" opacity="0.302711124" x="8" y="0" width="2" height="400"></rect>
                <rect id="Rectangle" fill="#000000" opacity="0.780459449" x="9" y="0" width="2" height="400"></rect>
                <rect id="Rectangle" fill="#FFFFFF" opacity="0.195731027" x="0" y="0" width="1" height="400"></rect>
              </g>
            </g>
          </g>
        </svg>
      }
    </div>
  </div>
</div>
