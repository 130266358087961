<div class="pt-5">&nbsp;</div>
<div class="summary-wrapper">
  <div class="w-100">
    <div class="summary-cover-container mb-4">
      @if (bitBook.type === bookTypes.Collection) {
        <div class="mx-1 mx-lg-0 mb-5">
          <bitmark-notebook-cover
            [notebook]="bitBook"
            [size]="'lg'"
            [adaptSize]="true">
          </bitmark-notebook-cover>
        </div>
      } @else {
        <bitmark-reader-cover
          [coverImage]="bitBook?.coverImage?.src1x || bitBook?.coverImage?.src2x || bitBook?.coverImage?.src3x | rewriteUrlResize:'book-cover':'xl'"
          [coverColor]='bitBook?.coverColor'
          [title]="bitBook?.title"
          [width]="bitBook?.coverImage?.width"
          [height]="bitBook?.coverImage?.height">
        </bitmark-reader-cover>
      }
    </div>

    <div class="summary-title-container mt-4">
      <h1 class="title" [innerText]="bitBook?.title"></h1>
      <h3 class="subtitle" [innerText]="bitBook?.subtitle"></h3>
    </div>
  </div>
</div>
