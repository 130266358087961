import {Inject, Injectable} from '@angular/core';
import {BrowserStorageService} from "../browser-storage/browser-storage.service";
import {BitmarkConfig} from "../../bitmark.module";
import {RouterExtService} from "../ng/ng-router-ext.service";
import {BitApiWrapper} from "../../bits/bits.models";
import {BitEvalService} from "../../bits/bit-eval.service";

@Injectable()
export class AnalyticsService {
  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private browserStorageService: BrowserStorageService,
              private routerExtService: RouterExtService,
              private bitEvalService: BitEvalService) {
  }

  record(eventType: string, data: any = {}) {
    const user = this.getUser();
    const payload = {
      eventType,
      timeStamp: Date.now(),
      userId: user.id.toString(),
      data: {
        ...data,
        user: {
          id: user.id.toString(),
          loginProvider: user.loginProvider,
          loginRole: user.loginRole,
          loginSpace: user.claims.loginSpace,
          school: user.claims.school ? {
            code: user.claims.school.code,
            name: user.claims.school.name
          } : null,
          enrollment: user.claims.enrollment ? {
            level: user.claims.enrollment.level,
            language: user.claims.enrollment?.language,
            classId: user.claims.enrollment?.classId,
            className: user.claims.enrollment?.className,
            campusCode: user.claims.enrollment?.campusCode,
            campusSchoolName: user.claims.enrollment?.campusSchoolName,
            teacherEmail: user.claims.enrollment?.teacherEmail,
            teacherName: user.claims.enrollment?.teacherName
          } : null,
          language: user.language || "en"
        },
        navigator: {
          language: navigator.language,
          userAgent: navigator.userAgent,
        },
        url: location.href,
        prevUrl: `${location.origin}${this.routerExtService.getPreviousUrl()}`,
        session: `${user.id}.${this.getSessionId()}`
      }
    };

    const hostName = window.location.hostname.toLowerCase();
    if (!hostName.startsWith('localhost')) {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(payload)
      };

      const url = this.bitmarkConfig.productionEnvironment
        ? `/events?clientId=app-client-xAjz1&clientSecret=6c5771495813301525e36a1a31a30139`
        : `/events?clientId=cosmic&clientSecret=0bb82219e9192070394a1954279d2e2f`;

      fetch(url, requestOptions)
        .then();
    } else {
      console.log('analytics', payload);
    }
  }

  recordBitAnswerAnalytics(bookBit: BitApiWrapper, evtType: 'bit-answered' | 'bit-user-input') {
    const answerEval = this.bitEvalService.evalAnswer(bookBit);
    if (!answerEval && evtType === 'bit-answered') {
      return;
    }
    this.record(evtType, {
      bitId: bookBit.id,
      bitType: bookBit.bit?.type,
      bitInstanceId: bookBit?.bitInstanceId,
      bookId: bookBit?.meta?.thisBook?.id || bookBit?.meta?.originBook?.id,
      bookExternalId: bookBit?.meta?.thisBook?.externalId || bookBit?.meta?.originBook?.externalId,
      answer: answerEval,
      language: bookBit.bit?.lang || bookBit.meta?.language,
      learningLanguage: bookBit.meta?.learningLanguage,
      chapterPath: bookBit.chapterPath,
      tag: bookBit.tags,
      analyticsTag: bookBit.analyticsTag,
      reductionTag: bookBit.reductionTag
    });
  }

  private getUser() {
    return this.browserStorageService.volatile.retrieve('user') || {
      id: -1,
      loginProvider: "",
      loginRole: "",
      language: "en",
      claims: {
        loginSpace: "",
        school: {
          code: "",
          name: ""
        },
        enrollment: {
          language: "en",
          level: "",
          classId: "",
          className: "",
          campusCode: "",
          campusSchoolName: "",
          teacherEmail: "",
          teacherName: ""
        }
      }
    };
  }

  private getSessionId(): string {
    const ret = sessionStorage.getItem('gmb_sessionId');
    if (!ret) {
      sessionStorage.setItem('gmb_sessionId', Date.now().toString());
    }
    return sessionStorage.getItem('gmb_sessionId');
  }
}

