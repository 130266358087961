@if (bit?.body) {
  <div class="bit-body mb-2 bit-body-allow-highlight">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4 mb-2">
  </bitmark-resource>
}
@if (bit?.heading?.forKeys || bit?.heading?.forValues) {
  <div class="row no-gutters mb-1 font-weight-bold">
    <div class="col-sm-6 col-12 text-pre-wrap" [class.order-2]="isReverse">
      @if (bit?.heading?.forKeys) {
        <span [innerText]="bit?.heading?.forKeys"></span>
      }
    </div>
    <div class="col-sm-6 col-12 text-pre-wrap">
      @if (bit?.heading?.forValues) {
        <span [innerText]="bit?.heading?.forValues"></span>
      }
    </div>
  </div>
}
@for (pair of bit?.pairs; track pair) {
  <div class="row no-gutters mb-2">
    <div class="col-sm-6 col-12 bit-body"
         [class.order-2]="isReverse"
         [ngClass]="bit.type">
      <div class="d-block input-label short">
        @if (pair.item) {
          <bitmark-text [text]="pair.item"
                        [format]="bit.format"
                        class="sub-item"
                        cssClass="sub-item-match text-nowrap">
          </bitmark-text>
        }
        @if (pair.key) {
          <bitmark-text [text]="pair.key"
                        [format]="bit.format"
                        [cssClass]="'d-inline-elements'">
          </bitmark-text>
        }
      </div>
    </div>
    <div class="match-container p-0 col-sm-6 col-12">
      <bitmark-match-gap-placeholder [pair]="pair"
                                     [format]="bit.format"
                                     [readOnly]="readOnly || !allowAnswerSubmit"
                                     [hasFeedback]="hasFeedback"
                                     (changed)="changed.emit()">
      </bitmark-match-gap-placeholder>
    </div>
  </div>
}

@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit.footer"
                [format]="bit.format">
  </bitmark-text>
}
