export const ApiConfig = {
  apiRoutesMap: {
    'publishers': 'publishers',
    'publishers/{publisherId}': 'publishers/:publisherId',
    'client/books': 'books',
    'client/books/visited': `books/visited`,
    'client/books/basket': 'books/basket',
    'client/books/basket/count': 'books/basket/count',
    'client/books/bought': 'books/bought',
    'client/books/borrowed': 'books/borrowed',
    'client/books/coach-sessions': 'books/coach-sessions',
    'client/books/collections': 'books/collections',
    'client/books/exists': 'books/exists',
    'client/books/space-permissions': 'books/space-permissions',
    'client/gmb/workspace': 'gmb/workspace',
    'client/books/trash/collections': 'books/trash/collections',
    'client/books/space': 'books/space',
    'client/books/content/file': 'books/content/file',
    'client/books/content/file/bitmark': 'books/content/file/bitmark',
    'client/books/jobs/{id}': 'books/jobs/:id',
    'client/books/trash': 'books/trash',
    'client/books/trash/{bookExternalId}': 'books/trash/:bookExternalId',
    'client/books/trash/{bookExternalId}/count': 'books/trash/:bookExternalId/count',
    'client/books/trash/{bookExternalId}/restore': 'books/trash/:bookExternalId/restore',
    'client/books/trash/{bookExternalId}/bits': 'books/trash/:bookExternalId/bits',
    'client/books/{bookExternalId}/visited': 'books/:bookExternalId/visited',

    'client/sessions/{sessionId}/book': 'sessions/:sessionId/book',
    'client/sessions/{bookExternalId}/content': 'sessions/:bookExternalId/content',
    'client/sessions/{bookExternalId}/visited': 'sessions/:bookExternalId/visited',

    'public/gmb/books/{bookExternalId}': 'public/gmb/books/:bookExternalId',
    'public/gmb/books/{bookExternalId}/content': 'public/gmb/books/:bookExternalId/content',
    'client/books/{bookExternalId}': 'books/:bookExternalId',
    'client/books/{bookExternalId}/basic': 'books/:bookExternalId/basic',
    'client/books/open-content/{bookExternalId}': 'books/open-content/:bookExternalId',
    'client/books/{bookExternalId}/open-content': 'books/:bookExternalId/open-content',
    'client/books/{bookExternalId}/courseId': 'books/:bookExternalId?courseId={courseId}',
    'client/books/{bookExternalId}/content': 'books/:bookExternalId/content',
    'client/books/{bookExternalId}/content/file': 'books/:bookExternalId/content/file',
    'client/books/{bookExternalId}/content/bit': 'books/:bookExternalId/content/bit',
    'client/books/{bookExternalId}/content/trashed': 'books/:bookExternalId/content/trashed',
    'client/books/{bookExternalId}/cover': 'books/:bookExternalId/cover',
    'client/books/{bookExternalId}/bits': 'books/:bookExternalId/bits',
    'client/books/{bookExternalId}/clone': 'books/:bookExternalId/clone',
    'client/books/{bookExternalId}/bits/content/{bitId}': 'books/:bookExternalId/bits/content/:bitId',
    'client/books/{bookExternalId}/bits/content': 'books/:bookExternalId/bits/content',
    'client/books/{bookExternalId}/bits/content/{bitId}/courseId': 'books/:bookExternalId/bits/content/:bitId?courseId={courseId}',
    'client/books/{bookExternalId}/bits/content/courseId': 'books/:bookExternalId/bits/content?courseId={courseId}',
    'client/books/{bookExternalId}/content/start/{bitId}': 'books/:bookExternalId/content/start/:bitId',
    'client/books/{bookExternalId}/content/end/{bitId}': 'books/:bookExternalId/content/end/:bitId',
    'client/books/{bookExternalId}/content/middle/{bitId}': 'books/:bookExternalId/content/middle/:bitId',
    'client/books/{bookExternalId}/content/index/middle/{bitIndex}': 'books/:bookExternalId/content/index/middle/:bitIndex',
    'client/books/{bookExternalId}/permission': 'books/:bookExternalId/permission',
    'client/modules/marketing-page/{bookExternalId}': 'modules/marketing-page/{bookExternalId}',
    'client/books/{learningPathId}/akeneo/{courseId}': 'books/:learningPathId/akeneo/:courseId',
    'client/books/{learningPathId}/akeneo/{courseId}/space': 'books/:learningPathId/akeneo/:courseId?spaceId={spaceId}',
    'client/collections/{bookExternalId}': 'books/collections/:bookExternalId',
    'client/gmb/my-library': 'gmb/my-library',
    'client/bits': 'bits',
    'client/bits/cut': 'bits/cut',
    'client/bits/copy': 'bits/copy',
    'client/bits/clone': 'bits/clone',
    'client/bits/suggest': 'bits/suggest',
    'client/bits/{bitId}/bitmark': 'bits/:bitId/bitmark',
    'client/bits/{bitId}/permission': 'bits/:bitId/permission',
    'client/bits/{bitId}/partial': 'bits/:bitId/partial',
    'client/bits/{bitId}/chapter': 'bits/:bitId/chapter',
    'client/books/{bookExternalId}/paste': 'books/:bookExternalId/paste',
    'client/books/{bookExternalId}/persist-content': 'books/:bookExternalId/persist-content',
    'client/books/{bookExternalId}/bitmark-bits': 'books/:bookExternalId/bitmark-bits',
    'client/books/{bookExternalId}/content/ids': 'books/:bookExternalId/content/ids',
    'client/books/{bookExternalId}/progress/done': 'books/:bookExternalId/progress/done',
    'client/books/{bookExternalId}/progress': 'books/:bookExternalId/progress',
    'client/books/progress': 'books/progress',
    'client/{bookExternalId}/progress': '{bookExternalId}/progress',
    'client/bits/paste': 'bits/paste',
    'client/bits/{bitId}/content': 'bits/:bitId/content',
    'client/bits/{bitId}': 'bits/:bitId',
    'client/bits/{bitId}/courseId': 'bits/:bitId?courseId={courseId}',
    'client/bits/{bitId}/instances': 'bits/:bitId/instances',
    'client/bits/{bitId}/instances/feedback': 'bits/:bitId/instances/feedback',
    'client/bits/instances/{instanceId}/feedback': 'bits/instances/:instanceId/feedback',
    'client/bits/trash/{bitId}': 'bits/trash/:bitId',
    'client/bits/trash/{bitId}/restore': 'bits/trash/:bitId/restore',
    'client/bits/trash/restore': 'bits/trash/restore',
    'client/bits/instances/{instanceId}': 'bits/instances/:instanceId',
    'client/annotations/bits/{bitId}': 'annotations/bits/:bitId',
    'client/annotations/{bitId}': 'annotations/:bitId',
    'client/search': 'bits',
    'client/filters/{bookExternalId}': 'books/:bookExternalId/filters',
    'client/health': 'health',
    'client/resource': 'bits/resource',
    'client/public/books/suggest': 'public/books/suggest',
    'client/public/books': 'public/books',
    'client/private/books/suggest': 'books/suggest',
    'client/private/books': 'books/search',

    'client/sessions/trainings/{trainingId}': 'sessions/trainings/:trainingId',
    'client/sessions/courses/{trainingSessionId}': 'sessions/courses/:trainingSessionId',
    'client/sessions/{sessionId}': 'sessions/:sessionId',
    'client/courses/{courseId}': 'courses/:courseId',
    'client/courses/trainings/{trainingId}': 'courses/trainings/:trainingId',
    'client/trainings/{trainingId}/image': 'trainings/:trainingId/image',
    'client/trainings/{trainingId}': 'trainings/:trainingId',
    'client/trainings/{trainingId}/purchase-status/{trainingSessionId}': 'trainings/:trainingId/purchase-status/:trainingSessionId',
    'client/{productId}/purchase-status': 'modules/:productId/purchase-status',
    'client/courses/trainings/{trainingId}/changes': 'courses/trainings/:trainingId/changes',
    'client/sessions/{leSessionId}/changes': 'sessions/:leSessionId/changes',
    'client/sessions/trainings/{trainingId}/changes': 'sessions/trainings/:trainingId/changes',
    'client/sessions/{sessionId}/image': 'sessions/:sessionId/image',
    'client/modules/{xModuleId}/image': 'modules/:xModuleId/image',
    'client/modules/{xModuleId}': 'modules/:xModuleId',
    'client/modules': 'modules',

    'client/books/{bookExternalId}/releases': 'books/:bookExternalId/releases',
    'client/books/{releaseId}/releases': 'books/:releaseId/releases',
    'client/books/releases/{releaseId}': 'books/releases/:releaseId',
    'client/books/releases/{releaseId}/scheduled-publish': 'books/releases/:releaseId/scheduled-publish',
    'client/books/releases/{releaseId}/publish': 'books/releases/:releaseId/publish',
    'client/books/releases/{releaseId}/persist-bitmark': 'books/releases/:release_id/persist-bitmark',
    'client/books/{bookExternalId}/releases/latest': 'books/:bookExternalId/releases/latest',

    'client/books/content/flashcards': 'books/content/flashcards?bookIds={bookIds}',

    'client/users/{userId}/space-admin': 'users/:userId/space-admin',

    'client/parser': 'public/parser',

    'client/hand-ins/expert': 'hand-ins/expert',
    'client/hand-ins/student': 'hand-ins/student',
    'client/hand-ins/{handInId}/assign': 'hand-ins/:handInId/assign',
    'client/hand-ins/{handInId}/reassign': 'hand-ins/:handInId/reassign',
    'client/hand-ins/{handInId}/unassign': 'hand-ins/:handInId/unassign',
    'client/modules/for-book/{bookExternalId}': 'modules/for-book/:bookExternalId',
    'client/hand-ins/{handInId}': 'hand-ins/:handInId',
    'client/hand-ins/books/{bookExternalId}': 'hand-ins/books/:bookExternalId',
    'client/hand-ins/{handInId}/approve': 'hand-ins/:handInId/approve',
    'client/hand-ins/{handInId}/reject': 'hand-ins/:handInId/reject',
    'client/hand-ins/{handInId}/archive': 'hand-ins/:handInId/archive',
    'client/hand-ins/{handInId}/unarchive': 'hand-ins/:handInId/unarchive',
    'client/hand-ins/{handInId}/visit': 'hand-ins/:handInId/visit',

    'branding': `branding`,
    'branding/{themeId}/{publisherId}': `branding/:themeId/:publisherId`,
    'branding/{themeId}/{publisherId}/history': `branding/:themeId/:publisherId/history`,
    'branding/{themeId}/{publisherId}/history/{timeStamp}': `branding/:themeId/:publisherId/history/:timeStamp`,

    'lti/resource/open': 'lti/resource/open?bit={bit}&link={link}&jwt={jwt}',
    'lti/deeplink/open': 'lti/deeplink/open?bit={bit}&jwt={jwt}',
    'lti/resource/bit/{id}': 'lti/resource/bit/:id',
    'learning-paths/link-info': 'learning-paths/link-info',
    'scorm/courses': 'scorm/courses',
    'scorm/courses/{courseId}/registration': 'scorm/courses/:courseId/registration',

    'roles/taught-classes': 'roles/taught-classes',

    'openai/generate-notebook': 'openai/generate-notebook',
    'openai/generate-notebook-bitmark': 'openai/generate-notebook-bitmark',
    'openai/quizzes-from-bitmark': 'openai/quizzes-from-bitmark',
    'openai/quizzes-from-bitmark-stream': 'openai/quizzes-from-bitmark-stream',
    'openai/transcribe-media-file': 'openai/transcribe-media-file',
    'openai/translate-content': 'openai/translate-content',
    'openai/translate/languages/{displayLanguage}': 'openai/translate/languages/:displayLanguage',
    'openai/book-summary': 'openai/book-summary',
    'openai/summary-from-bitmark-stream': 'openai/summary-from-bitmark-stream',
    'openai/poll-ai-notebook-job': 'openai/poll-ai-notebook-job',
    'openai/translate-book': 'openai/translate-book',
    'openai/bit-translate-jobs': 'openai/bit-translate-jobs',
    'openai/translate-short-text': 'openai/translate-short-text',
    'openai/translate-bare-content': 'openai/translate-bare-content',
    'openai/detect-language-content': 'openai/detect-language-content',
    'openai/feedback/generate': 'openai/feedback/generate'
  }
};
