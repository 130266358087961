<div class="d-flex flex-column">
  @if ((ngModel | amDuration)) {
    <span class="cursor-pointer" (click)="time.click()">{{ ngModel | amDuration : hoursLabel : minutesLabel }}</span>
  } @else {
    <span class="cursor-pointer placeholder" (click)="time.click()">{{ 'Bits.Duration' | translate }}</span>
  }
  <input
    [(ngModel)]="_ngModel"
    [ngbPopover]="timePopTemplate"
    [autoClose]="'outside'"
    container="body"
    placement="bottom"
    #time
    class="edit-input align-self-center"/>
  <ng-template #timePopTemplate>
    <ngb-timepicker
      [(ngModel)]="_ngModel"
      (ngModelChange)="modelChanged($event)"
    ></ngb-timepicker>
  </ng-template>
</div>
