<div [ngClass]="bit.type">
  <details [attr.open]="isBeingEditedByUser ? true : null">
    <summary (click)="summaryClicked($event)"
             [style.cursor]="isBeingEditedByUser ? 'auto' : null">
      <bitmark-bit-item-instruction [bit]="bit"
                                    [isBeingEditedByUser]="isBeingEditedByUser"
                                    [updateInstructionEvent]="updateInstructionSubject"
                                    class="item-lead-instruction-container"
                                    (onIsBeingEdited)="saveInlineBit.emit($event)">
      </bitmark-bit-item-instruction>
    </summary>
    <bitmark-article [bit]="bit"
                     [bitSubtype]="bit?.type"
                     [isBeingEditedByUser]="isBeingEditedByUser"
                     [canBeEdited]="canBeEdited"
                     [isEditable]="isEditable"
                     (openResource)="openResource.emit($event)"
                     (instructionChanged)="updateInstructionSubject.next($event)"
                     (saveInlineBit)="saveInlineBit.emit($event)"
                     (cancelBitChanges)="cancelBitChanges.emit($event)"
                     (editorCreated)="editorCreated.emit($event)">
    </bitmark-article>
  </details>
</div>
