<div class="split split-left p-3 d-flex flex-column justify-content-between" style="will-change: transform">
  <textarea #codeEditor class="split-bitmark-editor h-100 flex-grow-1 p-3" scrollAntiBlock
            style="overscroll-behavior: contain; will-change: transform"></textarea>
  <div>
    <div class="pt-2 d-flex align-items-center justify-content-center">
      <div (click)="leftToRight()" class="btn btn-background-calm py-1 px-4 w-auto" style="max-width: 160px !important;">
        Left to Right ->
      </div>
      <div (click)="close.emit()" class="mx-4 text-center text-calm cursor-pointer font-weight-bold">
        Close
      </div>
      <div (click)="rightToLeft()" class="btn btn-background-calm py-1 px-4 w-auto" style="max-width: 160px !important;">
        <- Right to Left
      </div>
    </div>
  </div>
</div>

<div class="split split-right p-3" style="overscroll-behavior: contain; will-change: transform" scrollAntiBlock>
  <ng-container>
    <bitmark-reader-text-editor #editorComponent
                                (click)="enterEditMode()"
                                [isReadOnly]="false"
                                class="w-100">
    </bitmark-reader-text-editor>
  </ng-container>
</div>
