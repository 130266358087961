<div>
  <div class="bit-body bit-body-allow-highlight">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>

  <div class="d-flex align-items-center mb-4 servings-container">
    <div>
      <div class="servings-input-container">
        <span class="input inline hidden-input" #inputHidden>{{ displayedServings }}</span>
        <input [id]="bit?.id + 'portions'"
               type="number"
               inputmode="decimal"
               step="0.5"
               min="0"
               [(ngModel)]="displayedServings"
               (ngModelChange)="recomputeQuantities()"
               class="flex-grow-1 flex-shrink-1 servings-input"
               [style.width]="inputHidden.clientWidth + 10 + 15 + 'px'"/>
        <label [for]="bit?.id + 'portions'"
               class="mb-0 ml-1 cursor-pointer"
               (click)="changeUnitDisplay($event)">
          {{
            displayUnitAbbr
              ? (bit?.servings?.unitAbbr || bit?.servings?.unit)
              : (bit?.servings?.unit || bit?.servings?.unitAbbr)
          }}
        </label>
      </div>
      @if (bit?.servings?.hint) {
        <div class="servings-hint"><span>{{ bit.servings.hint }}</span></div>
      }
    </div>
  </div>
  <div class="ingredient-grid">
    @for (ingredient of bit?.ingredients; track ingredient.item; let index = $index) {
      @if (displayedQuantities[index].shouldShow || ingredient?.item) {
        <div class="ingredient-checkbox"
             [ngClass]="index % 2 === 0 ? 'ingredient-even' : 'ingredient-odd'"
             [style.grid-row-start]="index + 1">
          @if (ingredient?.title) {
            <div class="d-block ingredient-content-title">&nbsp;</div>
          }
          <div>
            <input [id]="'i' + bit?.id + index"
                   type="checkbox"
                   [checked]="ingredient.answer !== undefined ? ingredient.answer : ingredient.checked"
                   (change)="onIngredientChecked(ingredient, $event)"/>
            <label [for]="'i' + bit?.id + index"></label>
          </div>
        </div>
      }
      @if (displayedQuantities[index].shouldShow || ingredient?.item) {
        <div class="pl-2 cursor-pointer ingredient-unit"
             [ngClass]="index % 2 === 0 ? 'ingredient-even' : 'ingredient-odd'"
             [style.grid-row-start]="index + 1"
             (click)="changeUnitDisplay($event)">
          @if (ingredient?.title) {
            <div class="d-block ingredient-content-title">&nbsp;</div>
          }
          @if (displayedQuantities[index].shouldShow) {
            <bitmark-text [text]="displayedUnits[index]"
                          [format]="bit?.format"
                          [cssClass]="'d-inline-elements'">
            </bitmark-text>
          }
        </div>
        <div class="pl-2 ingredient-quantity"
             [ngClass]="index % 2 === 0 ? 'ingredient-even' : 'ingredient-odd'"
             [style.grid-row-start]="index + 1">
          @if (ingredient?.title) {
            <div class="d-block ingredient-content-title">&nbsp;</div>
          }
          @if (displayedQuantities[index].shouldShow) {
            <span>{{ displayedQuantities[index].shouldShow ? parseFloat(displayedQuantities[index].value.toFixed(ingredient.decimalPlaces || 1)) : '' }}</span>
          }
        </div>
      }
      <div class="text-body ingredient-content"
           [ngClass]="index % 2 === 0 ? 'ingredient-even' : 'ingredient-odd'"
           [class.pl-2]="displayedQuantities[index].shouldShow || ingredient?.item"
           [class.ingredient-content-full-row]="!displayedQuantities[index].shouldShow && !ingredient?.item"
           [style.grid-row-start]="index + 1">
        @if (ingredient?.title) {
          <bitmark-text [text]="ingredient?.title"
                        [format]="bit?.format"
                        class="d-block ingredient-content-title">
          </bitmark-text>
        }
        <bitmark-text [text]="ingredient?.item"
                      [format]="bit?.format"
                      [cssClass]="'d-inline-elements'">
        </bitmark-text>
      </div>
    }
  </div>

  @if (bit?.footer) {
    <bitmark-text class="d-block bit-footer"
                  [text]="bit.footer"
                  [format]="bit.format">
    </bitmark-text>
  }
</div>
