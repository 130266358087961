@if (coverImage && !isBadImageDimensions) {
  <img class="cover-shadow {{format}}"
       [style.height.px]="format === 'portrait' ? undefined : height"
       [style.width.px]="format === 'landscape' ? undefined : width"
       [src]="coverImage+'?width='+coverSizeQueryWidth"
       (error)="setBadImageStatus()"
       (load)="onImageLoad()"/>
}
@if (isBadImage || isBadImageDimensions) {
  <div class="position-relative justify-content-left cover-shadow cover-default"
       [ngStyle]="{background: 'linear-gradient(rgba(255,255,255,0),rgba(0,0,0,0.5)),' + brandingPrimaryColor || coverColor}">
    <div class="inside position-absolute p-4 d-flex align-items-end ">
      <h1 class="text-black text-left w-100 d-flex align-items-end"
          [innerText]="title">
      </h1>
    </div>
  </div>
}
