@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
<div class="bit-body bit-body-allow-highlight"
     [ngClass]="bit.type">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
