import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {TemplatePortal} from '@angular/cdk/portal';
import {JSONContent} from '@tiptap/core';
import {ChapterBit} from './chapter.models';
import {BitPortalService} from '../../shared/portals/bit-portal.service';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../reader';
import {BitmarkFormat} from '../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss']
})
export class ChapterComponent implements OnInit, OnDestroy {
  @Input() bit?: ChapterBit;
  @Input() isEditable = false;
  @Input() bitId: string;
  @Output() onIsBeingEdited = new EventEmitter<ChapterBit>();
  @Output() onLevelChanged = new EventEmitter<ChapterBit>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();

  @ViewChild('chapter') chapterRef: ElementRef;
  @ViewChild('editorComponent') editorComponent: ReaderTextEditorComponent;
  @ViewChild('chapterLevelsPicker') chapterLevelsPicker: TemplateRef<HTMLElement>;

  titleFormat = BitmarkFormat.MM;
  private _isBeingEdited = false;
  private clickListener: () => void;

  @Input()
  set isBeingEdited(value: boolean) {
    this._isBeingEdited = value;
    if (value) {
      this.bitPortalService.setPortal(this.bitId, new TemplatePortal(this.chapterLevelsPicker, undefined));
    } else {
      this.bitPortalService.setPortal(this.bitId, undefined);
    }
  }

  get isBeingEdited() {
    return this._isBeingEdited;
  }

  levels = [1, 2, 3, 4, 5, 6, 7];
  selectedLevel?: number;

  constructor(private renderer: Renderer2,
              private bitPortalService: BitPortalService) {
  }

  ngOnInit() {
    if (this.bitId) {
      this.bit.id = this.bitId;
    }
    this.selectedLevel = this.bit?.level || 1;
    if (this.isEditable && this.isBeingEdited) {
      this.enterEditMode();
    }
  }

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
      delete this.clickListener;
    }

    this.chapterRef = null;
    this.chapterLevelsPicker = null;
    this.editorComponent = null;
  }

  changeLevel(level: number) {
    this.selectedLevel = level;
    this.bit.level = level;
    this.onLevelChanged.emit(this.bit);
  }

  changeText($event: JSONContent) {
    this.bit.id = this.bitId;
    this.bit.title = $event?.content;
    this.bit.format = BitmarkFormat.MM;
    this.onIsBeingEdited.emit(this.bit);
  }

  enterEditMode() {
    if (!this.isEditable) {
      return;
    }
    this.isBeingEdited = true;
    if (this.editorComponent?.enterEditMode) {
      this.editorComponent.enterEditMode();
    }
    this.clickListener = this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.chapterRef.nativeElement.contains(e.target) && !this.chapterRef.nativeElement.contains(document.activeElement)) {
        this.isBeingEdited = false;
      }
    });
  }
}
