@if (bit?.body || bit?.instruction || isBeingEditedByUser) {
  <div class="p-2 learning-path-closing-header"
       [ngClass]="(bit.isInfoOnly || !isTracked) && 'learning-path-closing-header-minimized'">
    <div class="text-truncate">
      @if (isBeingEditedByUser) {
        <div class="learning-path-item">
          <bitmark-editable-text
            (onChange)="bit.item = $event"
            [text]="bit.item"
            [placeholder]="'Bits.Item' | translate">
          </bitmark-editable-text>
        </div>
        <div class="font-weight-bold learning-path-instruction">
          <bitmark-editable-text
            (onChange)="bit.instruction = $event"
            [text]="bit.instruction"
            [placeholder]="'Bits.Instruction' | translate">
          </bitmark-editable-text>
        </div>
      } @else {
        <div class="learning-path-item">
          @if (bit.item) {
            <bitmark-text [text]="bit.item"></bitmark-text>
          }
        </div>
        <div class="font-weight-bold learning-path-instruction">
          <bitmark-text [text]="bit.instruction"></bitmark-text>
        </div>
      }
    </div>
    @if (bit?.body || isBeingEditedByUser) {
      <div class="bit-body py-2 bit-body-allow-highlight">
        @if (isBeingEditedByUser) {
          <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                      [bit]="bit"
                                      [bitContent]="bit.body | bitmark:bit.format:'json'"
                                      [isReadOnly]="false"
                                      [placeholder]="'Bits.PlaceholderBody' | translate"
                                      class="w-100">
          </bitmark-reader-text-editor>
        } @else {
          <bitmark-text [text]="bit?.body"
                        [format]="getBodyFormat(bit.body, bit.format)">
          </bitmark-text>
        }
      </div>
    }
  </div>
} @else if (!(!bit.isInfoOnly && isTracked)) {
  <div class="p-4 learning-path-closing-header">
    <!--Empty header to display when there is no content for the bit to display-->
    <!--Negates both (top, bottom) conditions-->
  </div>
}
@if (!bit.isInfoOnly && isTracked) {
  <div [ngClass]="isBeingEditedByUser && 'body-container-edit-mode'">
    @if (bit.answer?.isDone) {
      <div class="d-flex flex-column align-items-center justify-content-center pt-4">
        <bitmark-icon name="check-circle-fill" class="size-80px"></bitmark-icon>
        <div class="font-weight-bold pt-2">{{ 'Bits.ModuleDone' | translate }}</div>
      </div>
    }
    <div class="d-flex justify-content-center py-4">
      <button class="btn btn-action mr-1 bit-button text--ellipsis d-inline-block"
              [ngClass]="bit.answer?.isDone && 'learning-path-closing-btn-undo'"
              (click)="onClick()"
              [innerHTML]="(bit.answer?.isDone ? 'Shared.Undo' : 'Shared.MarkAsDone') | translate">
      </button>
    </div>
  </div>
}
@if (isBeingEditedByUser) {
  <div class="p-2 edit-container">
    <div class="d-flex justify-content-center m-2">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button text--ellipsis d-inline-block state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
}
