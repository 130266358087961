<svg width="100%" height="100%" [attr.viewBox]="viewBoxProp" version="1.1" xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="learning_path" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path
      d="M59.3893503,75.125 C84.2355654,77.8450672 98.208917,75.499094 103.064636,87.5 C110.711562,106.399372 14.9434275,118.790964 4.51934661,153.832982 C-5.90473427,188.875 69.3136056,198 69.3136056,198"
      id="Path" stroke="#FFFFFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
    <g id="lp_icon" transform="translate(0.000000, 18.000000)"
       [ngStyle]="{fill: primaryColor || '#FF007C'}"
       stroke="#000000" stroke-width="2">
      <g id="Bitmap">
        <path
          d="M70,1 C89.0536662,1 106.303461,8.7337962 118.78988,21.237461 C131.276798,33.7416257 139,51.0160009 139,70.0966804 C139,95.6316364 115.636966,131.766703 69.2273147,178.569761 C23.8448477,131.766731 1,95.6307912 1,70.0966804 C1,51.0160009 8.72320152,33.7416257 21.2101199,21.237461 C33.6965391,8.7337962 50.9463338,1 70,1 Z M70.5,41 C62.6299423,41 55.5049423,44.1899712 50.3474567,49.3474567 C45.1899712,54.5049423 42,61.6299423 42,69.5 C42,77.3700577 45.1899712,84.4950577 50.3474567,89.6525433 C55.5049423,94.8100288 62.6299423,98 70.5,98 C78.3700577,98 85.4950577,94.8100288 90.6525433,89.6525433 C95.8100288,84.4950577 99,77.3700577 99,69.5 C99,61.6299423 95.8100288,54.5049423 90.6525433,49.3474567 C85.4950577,44.1899712 78.3700577,41 70.5,41 Z"
          id="Mask-2"></path>
      </g>
    </g>
  </g>
</svg>
