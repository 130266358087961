<div class="position-relative overflow-hidden">
  <bitmark-article [isBeingEditedByUser]="isBeingEditedByUser"
                   [canBeEdited]="canBeEdited"
                   [isResourceType]="true"
                   [bit]="bit"
                   [bitSubtype]="BitType.StepImageScreenshotWithPointer"
                   (cancelBitChanges)="cancelBitChanges.emit($event)"
                   (saveInlineBit)="saveInlineBit.emit($event)"
                   (editorCreated)="editorCreated.emit($event)">
  </bitmark-article>
  @if (bit.pointerTop && bit.pointerLeft) {
    <div class="position-absolute step-pointer"
         [style.top]="bit.pointerTop || 0"
         [style.left]="bit.pointerLeft || 0">
    </div>
  }
</div>

