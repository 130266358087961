<ng-container>
  <bitmark-text class="buy-button-body"
                [text]="bit?.body"
                [format]="bit?.format">
  </bitmark-text>
  <div class='buy-button-container'>
    @if (!isAlreadyOwned) {
      @if (simulatedPrice > 0 || simulatedPrice === 0) {
        @if (!bit?.buttonCaption) {
          <button class='btn-background-calm btn-background-medium buy-button'
                  [disabled]="isClosedModule"
                  [style.background-color]='branding?.primaryActionButtonColor'
                  [style.color]='branding?.primaryActionButtonFontColor'
                  (click)='buyProduct()'>
            <bitmark-product-price [catalogPrice]='catalogPrice'
                                   [simulatedPrice]='simulatedPrice'
                                   [isBookDetails]='true'>
            </bitmark-product-price>
            @if(isClosedModule){
              🔒
            }
          </button>
        } @else {
          <button class='btn-background-calm btn-background-medium buy-button button-caption'
                  [disabled]="isClosedModule"
                  [style.background-color]='branding?.primaryActionButtonColor'
                  [style.color]='branding?.primaryActionButtonFontColor'
                  (click)='buyProduct()'
                  [innerHTML]="(bit?.buttonCaption || ('Shared.Buy'| translate)) + (isClosedModule ? ' 🔒' : '')">
          </button>
        }
      } @else if (simulatedPrice === -100) {
        <div class='d-flex justify-content-center justify-content-md-start align-items-center cursor-pointer'
             (click)='buyProduct()'>
          <button [style.background-color]='branding?.primaryActionButtonColor'
                  [style.color]='branding?.primaryActionButtonFontColor'
                  [disabled]="isClosedModule"
                  class="add-to-library buy-button">
            <bitmark-icon name="plus" class="p-0" [style.color]='branding?.primaryActionButtonFontColor'></bitmark-icon>
          </button>
          <span class='ml-2 pr-5'>My Library</span>
          @if(isClosedModule){
            🔒
          }
        </div>
      }
    } @else {
      <button (click)='openBook()'
              class='btn-background-calm btn-background-medium buy-button'
              [style.background-color]='branding?.primaryActionButtonColor'
              [style.color]='branding?.primaryActionButtonFontColor'
              [innerHTML]="'Shared.Open'| translate">
      </button>
    }
  </div>
  @if (!isInMyLibrary && title && simulatedPrice !== null) {
    <div class="d-flex justify-content-center mt-2 product-title">{{ title }}</div>
  }
  @if (bit?.footer) {
    <bitmark-text class="d-block bit-footer"
                  [text]="bit?.footer"
                  [format]="bit.format">
    </bitmark-text>
  }
</ng-container>

