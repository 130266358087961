import {Inject, Injectable, Injector} from '@angular/core';
import {findParentNode} from 'prosemirror-utils';
import StarterKit from '@tiptap/starter-kit';
import {Editor, Extensions, getNodeType} from '@tiptap/core';
import {TextAlign} from '@tiptap/extension-text-align';
import {Dropcursor} from '@tiptap/extension-dropcursor';
import {Gapcursor} from '@tiptap/extension-gapcursor';
import {Placeholder} from '@tiptap/extension-placeholder';
import {FloatingMenu} from '@tiptap/extension-floating-menu';
import {BitmarkLink} from './nodes/link/link.node';
import {OrderedList} from '@tiptap/extension-ordered-list';
import {Color} from '@tiptap/extension-color';
import {TextStyle} from '@tiptap/extension-text-style';
import {Table} from '@tiptap/extension-table';
import {TableHeader} from '@tiptap/extension-table-header';
import {TableRow} from '@tiptap/extension-table-row';
import {TableCell} from '@tiptap/extension-table-cell';
import {Subscript} from '@tiptap/extension-subscript';
import {Superscript} from '@tiptap/extension-superscript';
import {TaskList} from '@tiptap/extension-task-list';
import {BubbleMenu} from '@tiptap/extension-bubble-menu';
import {Inserted} from './nodes/inserted/inserted.node';
import {Deleted} from './nodes/deleted/deleted.node';
import GapComponentExtension from './nodes/gap/gap.node';
import MultipleChoiceComponentExtension from './nodes/multiple-choice/multiple-choice.node';
import VideoComponentExtension from './nodes/video/video.node';
import AudioComponentExtension from './nodes/audio/audio.node';
import MathComponentExtension from './nodes/math/math.node';
import {BulletList, NoBulletList} from './nodes/no-bullet-list/no-bullet-list.node';
import {ContextualBubbleMenu} from './nodes/contextual-bubble-menu/contextual-bubble-menu.extension';
import CustomImage from './nodes/image/image.node';
import {BitmarkQuote} from './nodes/quote/quote.node';
import BitmarkHeader from './nodes/header/header.node';
import {BitmarkParagraph} from './nodes/text-warning/text-warning.extension';
import {Light} from './nodes/light/light.node';
import CodeBlockLowlight from './nodes/codeblock';
import {Var} from './nodes/var/var.node';
import {ParagraphCode} from './nodes/codeblock/paragraph-code.node';
import {UserHighlight} from './nodes/user-highlight/user-highlight.node';
import BitmarkTaskItem from './nodes/task-item/task-item.node';
import HandlePaste from './nodes/handle-paste/handle-paste';
import {DoubleUnderline, UserDoubleUnderline} from './nodes/underline/double-underline.node';
import {Underline} from '@tiptap/extension-underline';
import {UserStrike} from './nodes/strike/user-strike.node';
import {Ins} from './nodes/ins/ins.node';
import {Del} from './nodes/deleted/del.node';
import {Circle, UserCircle} from './nodes/circle/circle.node';
import {LanguageEm} from './nodes/language-em/language-em.node';
import {TextHighlight, UserTextHighlight} from './nodes/user-highlight/text-highlight.node';
import {UserUnderline} from './nodes/underline/user-underline.node';
import {RefNode} from './nodes/ref/ref.node';
import {ReaderTiptapExtensionsService} from './reader-tiptap-extensions.service';
import {BitmarkConfig} from '../../bitmark.module';
import {SimpleList} from './nodes/lists/simple-list.node';
import {LetteredList, LetteredListLower, OrderedListRomanLower, OrderedListRoman} from './nodes/lists/ordered-lists.node';
import {CrossRefNode} from './nodes/ref/cross-ref.node';
import {FootnoteNode} from './nodes/footnote/footnote.node';
import {FootnoteNumericNode} from './nodes/footnote/footnote-numeric.node';

export enum TipTapEditorType {
  BitmarkPlus = 'bitmark++',
  BitmarkMinus = 'bitmark--',
  Code = 'code'
}

@Injectable()
export class ReaderTipTapTapService {
  private extensionsMap = new Map();

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private injector: Injector,
              readerTiptapExtensionsService: ReaderTiptapExtensionsService) {
    this.extensionsMap.set(TipTapEditorType.BitmarkPlus, (editorFn: () => Editor, placeholder = '...', hasLists = false, bitId?: string): Extensions => {
      return [
        StarterKit.configure({
          // Disable an included extension
          bulletList: false,
          orderedList: false,
          paragraph: false,
          blockquote: false,
          heading: false,
          codeBlock: false,
          dropcursor: false,
          gapcursor: false,
          strike: {
            HTMLAttributes: {
              class: 'bitmark-text-strike'
            }
          }
          // hardBreak: false
        }),
        BitmarkLink,
        BitmarkHeader.configure({
          levels: [1, 2],
        }),
        BitmarkQuote,
        SimpleList.configure({
          HTMLAttributes: {
            class: 'no-bullet-list',
          }
        }),
        LetteredListLower.configure({
          HTMLAttributes: {
            class: 'lettered-list-lower'
          }
        }),
        LetteredList.configure({
          HTMLAttributes: {
            class: 'lettered-list'
          }
        }),
        OrderedListRomanLower.configure({
          HTMLAttributes: {
            class: 'ordered-list-roman-lower'
          }
        }),
        OrderedListRoman.configure({
          HTMLAttributes: {
            class: 'ordered-list-roman'
          }
        }),
        OrderedList.configure({
          HTMLAttributes: {
            class: 'ordered-list',
          },
        }),
        BulletList.configure({
          HTMLAttributes: {
            class: 'bullet-list',
          }
        }),
        NoBulletList.configure({
          HTMLAttributes: {
            class: 'no-bullet-list',
          }
        }),
        TextStyle,
        Table.configure({
          resizable: true,
          cellMinWidth: 25,
        }),
        TaskList.configure({
          HTMLAttributes: {
            class: 'task-list',
          },
        }),
        BitmarkTaskItem.configure({
          nested: true
        }),
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          placeholder: placeholder || '',
        }),
        TableCell,
        BitmarkParagraph,
        TableRow,
        TableHeader,
        Superscript.configure({
          HTMLAttributes: {
            class: 'bitmark-text-superscript'
          }
        }),
        Subscript.configure({
          HTMLAttributes: {
            class: 'bitmark-text-subscript'
          }
        }),
        Inserted,
        Dropcursor,
        Gapcursor,
        Deleted,
        CustomImage,
        UserHighlight,
        Light.configure({
          HTMLAttributes: {
            class: 'bitmark-text-light'
          }
        }),
        TextHighlight,
        UserTextHighlight,
        Underline.configure({
          HTMLAttributes: {
            class: 'bitmark-text-underline'
          }
        }),
        UserUnderline,
        DoubleUnderline,
        UserDoubleUnderline,
        UserStrike,
        Ins,
        Del,
        Circle,
        UserCircle,
        LanguageEm,
        RefNode,
        CrossRefNode,
        FootnoteNode,
        FootnoteNumericNode,
        Var,
        HandlePaste.configure({
          bitId: bitId,
          bitbookClientApiUrl: this.bitmarkConfig.bitbookClientApiUrl
        }),
        TextAlign.configure({
          types: ['image'],
          alignments: ['left', 'right', 'center'],
        }),
        CodeBlockLowlight.configure({
          lowlight: readerTiptapExtensionsService.getLowlightInstance(),
          defaultLanguage: 'plaintext',
          languageClassPrefix: 'language-',
        }),
        Color.configure({
          types: ['textStyle'],
        }),
        GapComponentExtension(this.injector),
        MathComponentExtension(this.injector),
        MultipleChoiceComponentExtension(this.injector),
        VideoComponentExtension(this.injector),
        AudioComponentExtension(this.injector),
        // https://github.com/ueberdosis/tiptap/issues/3621
        ContextualBubbleMenu.extend({name: 'contextualBubbleMenu-bubbleMenuImage'}).configure({
          element: document.querySelector('.tiptap-bubble-menu-list-empty'),
          pluginKey: 'bubbleMenuImage',
          pluginType: 'image',
          shouldShow: () => {
            if (!editorFn) {
              return false;
            }
            const editor = editorFn.apply(null);
            return editor.isActive('image');
          },
        }),
        // https://github.com/ueberdosis/tiptap/issues/3621
        ContextualBubbleMenu.extend({name: 'contextualBubbleMenu-bubbleMenuList'}).configure({
          element: document.querySelector('.tiptap-bubble-menu-list-empty'),
          pluginKey: 'bubbleMenuList',
          pluginType: 'list',
          shouldShow: () => {
            if (!editorFn) {
              return false;
            }
            const editor = editorFn.apply(null);
            return editor.isActive('bulletList') ||
              editor.isActive('orderedList') ||
              editor.isActive('noBulletList') ||
              editor.isActive('letteredList') ||
              editor.isActive('letteredListLower') ||
              editor.isActive('orderedListRoman') ||
              editor.isActive('orderedListRomanLower') ||
              editor.isActive('taskList');
          },
        }),
        // https://github.com/ueberdosis/tiptap/issues/3621
        ContextualBubbleMenu.extend({name: 'contextualBubbleMenu-bubbleMenuHeader'}).configure({
          element: document.querySelector('.tiptap-bubble-menu-list-empty'),
          pluginKey: 'bubbleMenuHeader',
          pluginType: 'header',
          shouldShow: () => {
            if (!editorFn) {
              return false;
            }
            const editor = editorFn.apply(null);
            return !editor.isActive('bulletList') &&
              !editor.isActive('orderedList') &&
              !editor.isActive('letteredList') &&
              !editor.isActive('letteredListLower') &&
              !editor.isActive('orderedListRoman') &&
              !editor.isActive('orderedListRomanLower') &&
              !editor.isActive('noBulletList') &&
              !editor.isActive('taskList') &&
              !editor.isActive('codeBlock') &&
              !editor.isActive('blockquote') &&
              !editor.isActive('image');
          },
        }),
        // https://github.com/ueberdosis/tiptap/issues/3621
        ContextualBubbleMenu.extend({name: 'contextualBubbleMenu-bubbleMenuCode'}).configure({
          element: document.querySelector('.tiptap-bubble-menu-list-empty'),
          pluginKey: 'bubbleMenuCode',
          pluginType: 'code',
          shouldShow: () => {
            if (!editorFn) {
              return false;
            }
            const editor = editorFn.apply(null);
            return editor.isActive('codeBlock');
          },
        }),
        BubbleMenu.configure({
          element: document.querySelector('.contextual-menu-general'),
          pluginKey: 'general-menu',
          tippyOptions: {
            appendTo: () => document.body,
            offset: [100, 0],
          },
          shouldShow: () => {
            if (!editorFn) {
              return false;
            }
            const editor = editorFn.apply(null);
            return !(editor.view.state.selection.empty || editor.isActive('codeBlock') || editor.isActive('image'));
          },
        }),
        FloatingMenu.configure({
          element: document.querySelector('.tiptap-floating-menu'),
          tippyOptions: {
            appendTo: () => document.body,
          },
          shouldShow: () => {
            if (!editorFn) {
              return false;
            }
            const editor = editorFn.apply(null);
            const {selection} = editor.state;
            const {$anchor, empty} = selection;
            const isRootDepth = $anchor.depth === 1;
            const isEmptyTextBlock = $anchor.parent.isTextblock
              && !$anchor.parent.type.spec.code
              && !$anchor.parent.textContent;

            return !(!editor.view.hasFocus()
              || !empty
              || !isRootDepth
              || !isEmptyTextBlock);
          },
        })
      ];
    });
    this.extensionsMap.set(TipTapEditorType.Code, (editorFn: () => Editor, placeholder = '...', hasLists = false, bitId?: string, language = 'plaintext'): Extensions => {
      return [
        StarterKit.configure({
          // Disable an included extension
          bulletList: false,
          orderedList: false,
          paragraph: false,
          blockquote: false,
          heading: false,
          codeBlock: false
        }),
        ParagraphCode.configure({
          lowlight: readerTiptapExtensionsService.getLowlightInstance(),
          defaultLanguage: language,
          languageClassPrefix: 'language-',
        }),
        ContextualBubbleMenu.configure({
          element: document.querySelector('.tiptap-bubble-menu-list-empty'),
          pluginKey: 'bubbleMenuCode',
          pluginType: 'code',
          shouldShow: () => {
            if (!editorFn) {
              return false;
            }
            const editor = editorFn.apply(null);
            return editor.isActive('paragraph');
          },
        }),
        BubbleMenu.configure({
          element: document.querySelector('.contextual-menu-general'),
          pluginKey: 'general-menu',
          tippyOptions: {
            appendTo: () => document.body,
            offset: [100, 0],
          },
          shouldShow: () => {
            if (!editorFn) {
              return false;
            }
            const editor = editorFn.apply(null);
            return !(editor.view.state.selection.empty || editor.isActive('paragraph') || editor.isActive('image'));
          },
        }),
      ];
    });
    this.extensionsMap.set(TipTapEditorType.BitmarkMinus, (editorFn: () => Editor, placeholder = '...', hasLists = false, bitId?: string): Extensions => {
      let extraExtensions = [];
      if (hasLists) {
        extraExtensions = extraExtensions.concat([OrderedList.configure({
          HTMLAttributes: {
            class: 'ordered-list',
          },
        }),
          BulletList.configure({
            HTMLAttributes: {
              class: 'bullet-list',
            }
          }),
          HandlePaste.configure({
            bitId: bitId,
            bitbookClientApiUrl: this.bitmarkConfig.bitbookClientApiUrl
          }),
          NoBulletList.configure({
            HTMLAttributes: {
              class: 'no-bullet-list',
            }
          }),
          LetteredList.configure({
            HTMLAttributes: {
              class: 'lettered-list',
            }
          })]);
      }
      return [
        StarterKit.configure({
          // Disable an included extension
          bulletList: false,
          orderedList: false,
          paragraph: false,
          blockquote: false,
          heading: false,
          codeBlock: false
          // hardBreak: false
        }),
        BitmarkLink,
        TextStyle,
        Superscript,
        Subscript,
        UserHighlight,
        Light.configure({
          HTMLAttributes: {
            class: 'bitmark-text-light'
          }
        }),
        TextHighlight,
        Underline.configure({
          HTMLAttributes: {
            class: 'bitmark-text-underline'
          }
        }),
        DoubleUnderline,
        UserDoubleUnderline,
        BitmarkParagraph,
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          placeholder: placeholder || '...',
        }),
        Color.configure({
          types: ['textStyle'],
        }),
        BubbleMenu.configure({
          element: document.querySelector('.contextual-menu-general'),
          pluginKey: 'general-menu',
          tippyOptions: {
            appendTo: () => document.body,
            offset: [100, 0],
          },
          shouldShow: () => {
            if (!editorFn) {
              return false;
            }
            const editor = editorFn.apply(null);
            return !(editor.view.state.selection.empty || editor.isActive('codeBlock') || editor.isActive('image'));
          },
        }),
        ContextualBubbleMenu.configure({
          element: document.querySelector('.tiptap-bubble-menu-list-empty'),
          pluginKey: 'bubbleMenuHeader',
          pluginType: 'header',
          shouldShow: () => {
            if (!editorFn) {
              return false;
            }
            const editor = editorFn.apply(null);
            return !editor.isActive('codeBlock') &&
              !editor.isActive('blockquote') &&
              !editor.isActive('image');
          }
        })
      ].concat(extraExtensions);
    });
  }

  getExtensions(ttEditorType: TipTapEditorType, editorFn: () => Editor = null, placeholder = '...', hasLists = false, bitId?: string, language = '', staticText = false): Extensions {
    const extensionsFn: (editor: Editor, placeholder: string) => Extensions = this.extensionsMap.get(ttEditorType);
    const extensions: Extensions = extensionsFn.apply(null, [editorFn, placeholder, hasLists, bitId, language]);

    return staticText
      ? extensions.filter(x => !['contextualBubbleMenu', 'bubbleMenu', 'floatingMenu', 'handlePaste', 'dropcursor', 'gapcursor'].includes(x.name))
      : extensions;
  }

  getEditorProps(): any {
    return {
      transformPastedText: (text) => {
        text = this.parseStringRemoveColors(text);
        return text.replace(/\u00AD/g, '').replace(/&shy;/g, '');
      },
      transformPastedHTML: (html) => {
        html = this.parseStringRemoveColors(html);
        return html.replace(/\u00AD/g, ''.replace(/&shy;/g, ''));
      },
    };
  }

  private removeStyleFromString(text: string, styleName: string): string {
    while (text.indexOf(styleName + ':') !== -1) {
      const idx = text.indexOf(styleName + ':');
      const remainingText = text.slice(idx, -1);
      const nextEndingIndex = [remainingText.indexOf(';'), remainingText.indexOf('\''), remainingText.indexOf('"')].filter((i) => i > -1).reduce((prev, curr) => {
        if (curr < prev || (prev === -1 && curr !== -1)) {
          return curr;
        }
        return prev;
      }, -1);
      if (nextEndingIndex > -1) {
        text = text = text.slice(0, idx) + text.slice(idx + nextEndingIndex);
      } else {
        break;
      }
    }
    return text;
  }

  parseStringRemoveColors(text: string) {
    ['background-color', 'color', 'background'].forEach((c) => {
      text = this.removeStyleFromString(text, c);
    });
    return text;
  }

  closeOpenedMenus(editor: Editor) {
    // [].forEach.call((editor.view as any)?.docView?.dom?.parentElement?.parentElement?.querySelectorAll('.show'), function (div) {
    //   div.className = div.className.replace('show', '');
    // });
    // [].forEach.call((editor.view as any)?.docView?.dom?.parentElement?.parentElement?.querySelectorAll('#contextual-menu-general'), function (div) {
    //   div.style.display = 'none';
    // });
  }

  setEditorReadonly(editor: Editor) {
    setTimeout(() => {
      [].forEach.call(((editor.view as any)?.docView?.dom?.parentElement?.parentElement?.querySelectorAll('.contextual-menu-absolute') || []), function (div) {
        div.style.display = 'none';
      });
      [].forEach.call(((editor.view as any)?.docView?.dom?.parentElement?.parentElement?.querySelectorAll('#contextual-menu-general') || []), function (div) {
        div.style.display = 'none';
      });
    }, 150);
  }

  deleteNode(editor: Editor, nodeName: string) {
    const nodeType = getNodeType(nodeName, editor.state.schema);
    let header = findParentNode(node => node.type === nodeType)(editor.view.state.selection);
    if (!header) {
      header = findParentNode(node => node.type !== null)(editor.view.state.selection);
      if (!header) {
        return true;
      }
    }
    const tr = editor.view.state.tr;
    const pos = header.pos;
    tr.delete(pos, pos + header.node.nodeSize);
    editor.view.dispatch(tr);
    setTimeout(() => {
      editor.view.dom.focus();
    }, 1000);
  }
}
