<div class="my-5 d-flex flex-column justify-content-center align-items-center rating-container">
  @if(markBookAsReadBit){
    <div>
      <ng-template #t let-fill="fill">
        <span class="star m-2" [class.full]="fill" [class]="wiggleClass">
          @if(fill){
            <svg class="star-filled" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>star_filled</title>
                  <g id="star_filled" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path d="M25,4.25955146 L31.6832501,17.8012953 L46.6274518,19.9728149 L35.8137259,30.5135925 L38.3665003,45.3974093 L25,38.3702243 L11.6334997,45.3974093 L14.1862741,30.5135925 L3.37254823,19.9728149 L18.3167499,17.8012953 L25,4.25955146 Z" id="Star" stroke="#FFFFFF" stroke-width="2" fill="#FFFFFF"></path>
              </g>
            </svg>
          } @else{
            <svg class="star-empty" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <title>star</title>
                <g id="star" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M25,4.25955146 L31.6832501,17.8012953 L46.6274518,19.9728149 L35.8137259,30.5135925 L38.3665003,45.3974093 L25,38.3702243 L11.6334997,45.3974093 L14.1862741,30.5135925 L3.37254823,19.9728149 L18.3167499,17.8012953 L25,4.25955146 Z" id="Star" stroke="#FFFFFF" stroke-width="2"></path>
                </g>
            </svg>
          }
        </span>
      </ng-template>
      <ngb-rating [starTemplate]="t" [max]="5" (rateChange)="updateRating($event)" [rate]="markBookAsReadBit.rating" [readonly]="markBookAsReadBit?.isBookRead">

      </ngb-rating>
    </div>
    @if(!markBookAsReadBit?.isBookRead){
      <div class='buy-button-container'>
        <button (click)='markBookAsRead()'
                class='btn-background-calm btn-background-medium buy-button'
                [innerHTML]="'Shared.RateAndMarkAsDone'| translate">
        </button>
      </div>
    }
  }
</div>

