<div class="d-flex align-items-center search-box pr-2 pl-2" [ngClass]="size">
  <label class="d-inline-flex align-items-center justify-content-center cursor-pointer m-0" [for]="searchInputId">
    <app-icon name="magnify" class="text-white"></app-icon>
  </label>
  <input #inputEl
         [id]="searchInputId"
         type="search"
         autocomplete="off"
         spellcheck="false"
         class="d-inline pt-1 pb-1 text-white"
         [attr.placeholder]="placeholder || (('Shared.Search' | translate) + '...')"
         (keydown.escape)="onKeydownEscape($event)"
         (input)="searchHandler($event)"
         (focusin)="onFocusIn($event)">
</div>
