<app-text-context-menu-desktop [actions]="actions"
                               [triangleOffsetStyle]="triangleOffsetStyle"
                               (canceled)="cancel()"
                               class="d-none d-md-block">
</app-text-context-menu-desktop>

<app-dropdown-mobile [actions]="actions"
                     (canceled)="cancel()"
                     class="d-block d-md-none">
</app-dropdown-mobile>

