<div class="d-inline-flex align-items-center ">
  <div class="placeholder d-inline-flex align-items-center align-content-between" style="width: 160px">
    <bitmark-dropdown class="d-inline-block ml-auto"
                      id="topDropdown"
                      container="body"
                      [placement]="['bottom-right', 'bottom-left', 'top-right', 'top-left', 'left', 'right']"
                      [actions]="selectPlaceholderBitActions"
                      [dropdownButton]="dropdownButton"
                      dropdownClass="lg">
    </bitmark-dropdown>
    <ng-template #dropdownButton>
      <div class="d-flex align-items-center align-content-between cursor-pointer">
        <div class="answer flex-grow-1"
             [title]="selectedAnswer"
             [innerHTML]="selectedAnswer || '&nbsp;'">
        </div>
        <bitmark-icon name="chevron-down" class="flex-shrink-0"></bitmark-icon>
      </div>
    </ng-template>
  </div>
  <bitmark-icon (click)="openPopup()"
                name="help-circle-outline"
                class="placeholder-hint text-calm cursor-pointer"
                triggers="click:blur"
                container="body">
  </bitmark-icon>
</div>

