<div class="reader-shadow bg-white modal-container">
  @if (imageLinkResource) {
    <div class="p-4 preview">
      <bitmark-resource-image-link [bitResourceImageLink]="imageLinkResource.imageLink"></bitmark-resource-image-link>
    </div>
  }
  @else {
    <div class="p-4 image-placeholder">
      <img src="../../../../../assets/images/resources/image-placeholder.png" alt="Image Link"/>
    </div>
  }
  <div class="p-2 edit-container">
    <input type="text"
           class="form-control mb-1"
           placeholder="https://..."
           [(ngModel)]="imageLink"
           (ngModelChange)="onLinkChanged()"/>
    <div class="d-flex justify-content-center m-2 mt-4">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
</div>
