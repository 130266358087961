<div class="position-relative">
  @for (image of bit.images; track image.image.src; let index = $index; let last = $last) {
    <div [class.invisible]="activeImageIndex !== index"
         [class.position-absolute]="activeImageIndex !== index"
         [class.cursor-pointer]="!last">
      <bitmark-resource-image [bit]="bit"
                              [bitResourceImage]="image.image"
                              [hostBitType]="bit.type"
                              [style.max-width.%]="100"
                              (imageClicked)="onImageClicked()">
      </bitmark-resource-image>
    </div>
  }
</div>
<div class="bit-body bit-body-allow-highlight">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
