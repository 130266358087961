@if (hiddenElements?.indexOf(ReaderHiddenElements.Close) === -1) {
  <div class="button-bar left">
    <bitmark-icon name="close"
                  class="size-20px cursor-pointer"
                  (click)="close.emit()">
    </bitmark-icon>
  </div>
} @else {
  <div class="left"></div>
}

@if (!hideRightSide) {
  <div class="button-bar right">
    @if (actions && hiddenElements?.indexOf(ReaderHiddenElements.HeaderMenu) === -1) {
      <ng-template #dropdownButton>
        <div class="d-flex align-items-center justify-content-center size-20px cursor-pointer mr-1">
          <div class="dot blue-magenta"></div>
        </div>
      </ng-template>
      <bitmark-dropdown class="d-inline-block ml-auto"
                        dropdownClass="sm"
                        [placement]="['bottom-right', 'bottom']"
                        [actions]="actions"
                        [dropdownButton]="dropdownButton">
      </bitmark-dropdown>
    }
    @if (hiddenElements?.indexOf(ReaderHiddenElements.Search) === -1) {
      <bitmark-icon name="magnify"
                    class="px-1 size-20px cursor-pointer"
                    (click)="toggleSearch.emit()">
      </bitmark-icon>
    }
    @if (hiddenElements?.indexOf(ReaderHiddenElements.Basket) === -1) {
      <div class="mx-2 px-2 badge basket-counter"
           [innerText]="basketCount || 0"
           (click)="viewBasket.emit()">
      </div>
    }
    @if (hiddenElements?.indexOf(ReaderHiddenElements.Toc) === -1) {
      <bitmark-icon name="table-of-contents"
                    class="pl-1 size-20px cursor-pointer"
                    (click)="toggleToc.emit()">
      </bitmark-icon>
    }
  </div>
}
