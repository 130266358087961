import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ScrollbarService} from 'core';

@Component({
  selector: 'app-modal-wrapper',
  templateUrl: './modal-wrapper.component.html'
})
export class ModalWrapperComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<any>();

  constructor(private scrollbarService: ScrollbarService) {
  }

  ngOnInit() {
    this.scrollbarService.lockBodyScrollbar();
  }

  ngOnDestroy() {
    this.scrollbarService.unLockBodyScrollbar();
  }

  close() {
    this.closed.emit();
  }
}
