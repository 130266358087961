<div class="toc-header-gradient">
  <div class="d-flex align-items-center justify-content-between py-2 toc-header">
    <bitmark-icon name="magnify"
                  class="px-2 size-20px cursor-pointer"
                  (click)="toggleSearch.emit()">
    </bitmark-icon>
    <div class="d-flex align-items-center">
      <bitmark-icon name="close"
                    class="px-2 size-20px cursor-pointer"
                    (click)="close.emit()">
      </bitmark-icon>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between py-2 px-2 toc-chapter-selector-section">
    <ng-template #dropdownButton>
      <div class="d-flex align-items-center toc-chapter-selector-wrapper">
        <input readonly
               type="text"
               class="form-control toc-chapter-selector px-2"
               [ngModel]="selectedChapter?.label"
               alt="Chapter"/>
        <bitmark-icon name="chevron-down"
                      class="size-20px cursor-pointer px-1">
        </bitmark-icon>
      </div>
    </ng-template>
    @if (chapterMenuItems.length > 1) {
      <bitmark-dropdown class="d-inline-block w-100"
                        dropdownClass="w-100 reader-toc-dropdown"
                        [placement]="['bottom']"
                        [actions]="chapterMenuItems"
                        [dropdownButton]="dropdownButton">
      </bitmark-dropdown>
    }
    @if (tableOfContents?.length && chapterMenuItems.length < 2) {
      <div class="book-title font-weight-normal text--ellipsis"
           [innerText]="bitBook.title">
      </div>
    }
  </div>
</div>
<div class="h-100 pb-0 pt-0 d-flex flex-column justify-content-between toc-content"
     scrollAntiBlock>
  <div class="toc-content-scrollable">
    @if (tableOfContents) {
      <div>
        @if (annotationTypes.indexOf(selectedChapter?.type) === -1) {
          <div class="toc-chapter-link mb-6 mx-3 mt-1"
               (click)="selectChapter(previousChapter)">
            @if (previousChapter) {
              <bitmark-icon name="chevron-up" class="size-20px"></bitmark-icon>
              @if (previousChapter.label) {
                <div class='text-center' [innerText]="previousChapter.label"></div>
              }
            }
          </div>
        }
        @if (annotationTypes.indexOf(selectedChapter?.type) !== -1 && selectedChapter?.type !== BitType.AnnotationHandwritten) {
          <div class='dropdown-item-colors-container my-2 justify-content-center'>
            @for (color of annotationColors; track color) {
              <div class="m-1 cursor-pointer dropdown-item-color"
                   [ngStyle]="{backgroundColor: color.primary, border: color.primary === '#fff' ? '1px solid #585858' : 'none'}"
                   (click)='selectAnnotationFilterColor(color)'>
                @if (color.isSelected) {
                  <div class="dropdown-item-color-selected"></div>
                }
              </div>
            }
          </div>
        }
        @if (selectedChapter?.type === BitType.AnnotationHandwritten) {
          <div class='pt-5'></div>
        }
        <div class="mx-0" [ngClass]="{'mb-1 pb-5': !nextChapter}">
          @for (item of tableOfContents; track item.ref) {
            <div class="cursor-pointer px-3"
                 [id]="'toc-bit-' + item.ref"
                 [ngClass]="{'toc-selected': item.isVisible}">
              @if (item?.allAnnotations?.length && !item?.isTrashed) {
                @for (annotation of item.annotationsObj?.prefixAnnotations; track annotation.id) {
                  @if ((selectedChapter?.type === annotation?.type && (annotation?.color?.primary === selectedColor || !selectedColor)) || (annotationTypes.indexOf(selectedChapter?.type) === -1)) {
                    <div
                      class="w-100 mb-0 cursor-pointer d-flex flex-row justify-content-start toc-annotation-prefix align-items-center toc-bit"
                      [ngClass]='{"toc-annotation-prefix": annotationTypes.indexOf(selectedChapter?.type) === -1, "toc-bit-margin": (!item.isHidden || item.allAnnotations?.length) && !item?.trashedAt}'
                      (click)="selectBit(item, annotation.id)">
                      <div class='toc-annotation-icon'>
                        @if (annotation?.type === BitType.AnnotationBookmark) {
                          <div>
                            <svg width="20px" height="23px" viewBox="0 0 20 23" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <polygon id="Path" [ngStyle]="{ fill: annotation.color?.secondary }"
                                         points="0 5 5.86666667 5 16 11.5 5.86666667 18 0 18"></polygon>
                              </g>
                            </svg>
                          </div>
                        } @else if (annotation?.type === BitType.AnnotationFavorite) {
                          <div>
                            <svg width="20px" height="23px" viewBox="0 0 20 23" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg"
                                 xmlns:xlink="http://www.w3.org/1999/xlink">
                              <defs>
                                <rect x="0" y="0" width="17.037037" height="15" id="rect-1"></rect>
                              </defs>
                              <g id="fav_icon_v7" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="icons8-heart-100" transform="translate(0.000000, 4.000000)">
                                  <image x="0" y="0" width="17.037037" height="15"
                                         href="assets/images/annotations/favorite-green-image.png"></image>
                                  <use [ngStyle]="{ fill: annotation.color?.primary }" fill-rule="evenodd"
                                       href="#rect-1"></use>
                                </g>
                              </g>
                            </svg>
                          </div>
                        }
                      </div>
                      @if(annotation?.user && annotation?.isExpert){
                        <div class="mr-1">
                          🧐
                        </div>
                      }
                      @if(annotation?.user && !annotation?.isExpert){
                        <div class="mr-1">
                          🧑‍🎓
                        </div>
                      }
                      <div class="line-clamp line-clamp-single">
                        {{ annotation.title }}
                      </div>
                    </div>
                  }
                }
              }
              @if (annotationTypes.indexOf(selectedChapter?.type) === -1 && !item.isHidden && (item.displayLead || !item.isPlaceholder)) {
                <div
                  class="w-100 cursor-pointer d-flex flex-column justify-content-start toc-bit-margin toc-bit-margin-1 position-relative"
                  [ngClass]="{'toc-chapter': item.level,
                'lvl1': item.level === 1,
                'lvl2': item.level === 2,
                'lvl3': item.level === 3,
                'lvl4': item.level === 4,
                'lvl5': item.level === 5,
                'lvl6': item.level === 6,
                'lvl7': item.level === 7,
                'pt-4': item.isBookTitle,
                'toc-bit': !item.level}"
                  (click)="selectBit(item)">
                  @if (item.displayLead && !item.isItemHidden) {
                    <div [innerHTML]="item.displayLead | safeHtml"
                         [class.generic-title-bit]="item.isGenericTitle"
                         class="no-wrap d-block toc-bit-item">
                    </div>
                  }
                  @if (!item.isPlaceholder) {
                    <div class="line-clamp toc-chapter-content"
                         [class.generic-title-bit]="item.isGenericTitle"
                         [innerHTML]='item.displayElement'>
                    </div>
                  }
                </div>
              }
              @if (item?.allAnnotations?.length && !item?.isTrashed) {
                @for (annotation of item.annotationsObj?.postfixAnnotations; track annotation.id) {
                  @if ((selectedChapter?.type === annotation.type && (annotation?.color?.primary === selectedColor || !selectedColor)) || (annotationTypes.indexOf(selectedChapter?.type) === -1)) {
                    <div
                      class="w-100 cursor-pointer d-flex flex-row justify-content-start align-items-center toc-bit toc-bit-margin"
                      [ngClass]='{"toc-annotation-postfix": annotationTypes.indexOf(selectedChapter?.type) === -1}'
                      (click)="selectBit(item, annotation.id)">
                      @if (annotation?.type === BitType.AnnotationNote) {
                        <div class='toc-annotation-icon'>
                          <svg width="20px" height="23px" viewBox="0 0 20 23" version="1.1"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="note_icon_v7" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect id="Rectangle" [ngStyle]="{ fill: annotation.color?.primary }" x="0" y="4"
                                    width="15"
                                    height="15" rx="7.5"></rect>
                            </g>
                          </svg>
                        </div>
                      }
                      @if (annotation?.type === BitType.AnnotationHandwritten) {
                        <div class='toc-annotation-icon'>
                          <svg width="20px" height="23px" viewBox="0 0 20 23" version="1.1"
                               xmlns="http://www.w3.org/2000/svg"
                               xmlns:xlink="http://www.w3.org/1999/xlink">
                            <defs>
                              <rect x="6.0561047" y="1.71145857" width="8" height="11" id="rect-1"></rect>
                            </defs>
                            <g id="note_scribble_v7" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g id="Group" transform="translate(0.000000, 4.000000)">
                                <rect id="Rectangle" [ngStyle]="{ fill: annotation.color?.primary }" x="0" y="0"
                                      width="15"
                                      height="15" rx="7.5"></rect>
                                <g id="icons8-sign-up-100"
                                   transform="translate(10.056105, 7.211459) rotate(12.000000) translate(-10.056105, -7.211459) ">
                                  <image x="6.0561047" y="1.71145857" width="8" height="11"
                                         href="assets/images/annotations/handwritten-image.png"></image>
                                  <use fill="none" fill-rule="evenodd" href="#rect-1"></use>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      }
                      @if(annotation?.user && annotation?.isExpert){
                        <div class="mr-1">
                          🧐
                        </div>
                      }
                      @if(annotation?.user && !annotation?.isExpert){
                        <div class="mr-1">
                          🧑‍🎓
                        </div>
                      }
                      <div class="line-clamp line-clamp-single">
                        {{ annotation?.type === BitType.AnnotationHandwritten ? 'Scribble' : annotation.title }}
                      </div>
                    </div>
                  }
                }
              }
            </div>
          }
        </div>
      </div>
    }
  </div>
  @if (nextChapter) {
    <div class="toc-chapter-link mt-6 mx-3 mb-1"
         (click)="selectChapter(nextChapter)">
      @if (nextChapter.label) {
        <div class='text-center' [innerText]="nextChapter.label"></div>
      }
      <bitmark-icon name="chevron-down" class="size-20px"></bitmark-icon>
    </div>
  }
</div>
