<div class='d-flex page-buy-button-promotion-container'>
  @if(bit?.instruction){
    <div class='bitmark-instruction bitmark-instruction instruction-bit instruction-bit-page-buy-button-promotion'>
      <bitmark-text [text]="bit?.instruction"
                    [format]="BitmarkFormat.PP">
      </bitmark-text>
    </div>
  }
  <div class='buy-button-body' [innerHTML]='bit?.body'></div>
  <div class='buy-button-container'>
    @if (!isAlreadyOwned) {
      @if (simulatedPrice > 0 || simulatedPrice === 0) {
        @if (!bit?.buttonCaption) {
          <button class='btn-background-calm btn-background-medium buy-button'
                  [style.background-color]='branding?.primaryActionButtonColor'
                  [style.color]='branding?.primaryActionButtonFontColor'
                  [disabled]="isClosedModule"
                  (click)='buyProduct()'>
            <bitmark-product-price [catalogPrice]='catalogPrice'
                                   [simulatedPrice]='simulatedPrice'
                                   [isBookDetails]='true'>
            </bitmark-product-price>
            @if(isClosedModule){
              🔒
            }
          </button>
        } @else {
          <button class='btn-background-calm btn-background-medium buy-button button-caption'
                  [style.background-color]='branding?.primaryActionButtonColor'
                  [style.color]='branding?.primaryActionButtonFontColor'
                  [disabled]="isClosedModule"
                  (click)='buyProduct()'
                  [innerHTML]="bit?.buttonCaption || ('Shared.Buy'| translate)">
          </button>
        }
      } @else if (simulatedPrice === -100) {
        <div class='d-flex justify-content-center justify-content-md-start align-items-center cursor-pointer'
             (click)='buyProduct()'>
          <button [style.background-color]='branding?.primaryActionButtonColor'
                  [style.color]='branding?.primaryActionButtonFontColor'
                  [disabled]="isClosedModule"
                  class='add-to-library buy-button'>
            <bitmark-icon name='plus' class='p-0' [style.color]='branding?.primaryActionButtonFontColor'></bitmark-icon>
          </button>
          <span class='ml-2 pr-5'>My Library</span>
          @if(isClosedModule){
            🔒
          }
        </div>
      }
    } @else {
      <button (click)='openBook()'
              class='btn-background-calm btn-background-medium buy-button'
              [style.background-color]='branding?.primaryActionButtonColor'
              [style.color]='branding?.primaryActionButtonFontColor'
              [innerHTML]="'Shared.Open'| translate">
      </button>
    }
  </div>
</div>

