import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {AppCodeEditorBit} from './app-code-editor.models';
import {CodeBit} from '../../code/code.models';
import {BitbookMqService} from '../../../reader/bitbook-mq.service';
import {SubSink} from '../../../shared';

@Component({
  selector: 'bitmark-app-code-editor',
  templateUrl: './app-code-editor.component.html'
})
export class AppCodeEditorComponent implements OnInit, OnDestroy {
  @Input() bit?: AppCodeEditorBit;
  @Output() saveInlineBit = new EventEmitter<CodeBit>();

  updateLanguageEventSubject: Subject<string> = new Subject<string>();

  private subSink = new SubSink();

  constructor(private bitbookMqService: BitbookMqService) {
  }

  ngOnInit() {
    this.subSink.sink = this.bitbookMqService.onBitCodeLanguageChanged(this.bit.id)
      .subscribe(lang => {
        this.updateLanguageEventSubject.next(lang);
        this.updateLanguage(lang);
      });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
    this.updateLanguageEventSubject.complete();
    this.updateLanguageEventSubject.unsubscribe();
  }

  saveChanges() {
    this.saveInlineBit.emit(this.bit);
  }

  updateLanguage(language: string) {
    this.bit.computerLanguage = language;
    this.saveChanges();
  }

  onUpdateContent() {
    this.saveChanges();
  }
}
