@if (bitBookFilters) {
  <div class="d-flex align-items-start justify-content-between py-2 bg-black">
    <div class="pl-2 m-0 search-title h5" [innerText]="bitBook?.title"></div>
    <bitmark-icon name="close"
                  class="px-2 py-1 size-20px cursor-pointer"
                  (click)="close.emit()">
    </bitmark-icon>
  </div>
  <div class="p-2 reader-sidebar__content">
    @if (bitBookFilters?.groups?.length) {
      <div class="pb-2">
        <div class="h5 mb-0" [innerHTML]="'Reader.Filters.Quiz' | translate"></div>
        <div *ngFor="let v of bitBookFilters?.groups"
             class="d-inline-block mr-1 btn-filter"
             (click)="toggleFilter('g', v)"
             [ngClass]="{'btn-filter-active': activeFilters?.g && activeFilters?.g?.indexOf(v) !== -1}">
          {{ v.text }} ({{ v.count }})
        </div>
      </div>
    }
    @if (bitBookFilters?.resourceGroups?.length) {
      <div class="pb-2">
        <div class="h5 mb-0" [innerHTML]="'Reader.Filters.Resources' | translate"></div>
        <div *ngFor="let v of bitBookFilters?.resourceGroups"
             class="d-inline-block mr-1 btn-filter"
             (click)="toggleFilter('rg', v)"
             [innerText]="v.text"
             [ngClass]="{'btn-filter-active': activeFilters?.rg && activeFilters?.rg?.indexOf(v) !== -1}">
        </div>
      </div>
    }
    @if (bitBookFilters?.grade?.length) {
      <div class="pb-2">
        <div class="h5 mb-0" [innerHTML]="'Reader.Filters.Grade' | translate"></div>
        <div *ngFor="let v of bitBookFilters?.grade"
             class="d-inline-block mr-1 btn-filter"
             (click)="toggleFilter('grade', v)"
             [innerText]="v"
             [ngClass]="{'btn-filter-active': activeFilters?.grade?.indexOf(v) !== -1}">
        </div>
      </div>
    }
    @if (bitBookFilters?.complexity?.length) {
      <div class="pb-2">
        <div class="h5 mb-0" [innerHTML]="'Reader.Filters.Complexity' | translate"></div>
        <div *ngFor="let v of bitBookFilters.complexity"
             class="d-inline-block mr-1 btn-filter"
             (click)="toggleFilter('complexity', v)"
             [innerText]="v | titlecase"
             [ngClass]="{'btn-filter-active': activeFilters?.complexity?.indexOf(v) !== -1}">
        </div>
      </div>
    }
    @if (bitBookFilters?.tags?.length) {
      <div class="pb-2">
        <div class="h5 mb-0" [innerHTML]="'Reader.Filters.Tags' | translate"></div>
        <div *ngFor="let v of bitBookFilters.tags"
             class="d-inline-block mr-1 btn-filter"
             (click)="toggleFilter('tags', v)"
             [innerText]="v.text"
             [ngClass]="{'btn-filter-active': activeFilters?.tags && activeFilters?.tags?.indexOf(v) !== -1}">
        </div>
      </div>
    }
    @if (bitBookFilters?.skills?.length) {
      <div class="pb-2">
        <div class="h5 mb-0" [innerHTML]="'Reader.Filters.Skills' | translate"></div>
        <div *ngFor="let v of bitBookFilters.skills"
             class="d-inline-block mr-1 btn-filter"
             (click)="toggleFilter('skills', v)"
             [innerText]="v | titlecase"
             [ngClass]="{'btn-filter-active': activeFilters?.skills?.indexOf(v) !== -1}">
        </div>
      </div>
    }
    @if (bitBookFilters?.topics?.length) {
      <div class="pb-2">
        <div class="h5 mb-0" [innerHTML]="'Reader.Filters.Topics' | translate"></div>
        <small *ngFor="let v of bitBookFilters.topics"
               class="cursor-pointer mr-1"
               [innerText]="v">
        </small>
      </div>
    }
    @if (lastSearchTexts?.length) {
      <div class="last-searched">
        <!--        <span class="header text-center">Last Searched</span>-->
        <div class="h5 mb-0 mt-4" [innerHTML]="'Reader.Search.LastSearched' | translate"></div>
        @for (historyItem of lastSearchTexts; track historyItem) {
          <div class="font-weight-bold cursor-pointer d-block mt-1"
               [innerHTML]="historyItem.searchQuery"
               (click)="historyItemClickHandler(historyItem)">
          </div>
        }
      </div>
    }
    <div class="mt-5">
      <!--        <div class="cursor-pointer"><small class="font-weight-bold">👍 Best Rated</small></div>-->
      <!--        <div class="cursor-pointer"><small class="font-weight-bold">👌 Most Popular</small></div>-->
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center py-5 px-2 text-center">
    <div class="cursor-pointer font-weight-bold"
         [innerHTML]="'Reader.ShowAll' | translate"
         (click)="resetSearch()">
    </div>
  </div>
}

