<div class="bot-action-response-container"
     inViewport
     (inViewportAction)="onInViewportChange($event)">
  <div class="bot-avatar-container">
    <div class="d-flex align-items-center mb-3">
      <div class="rounded-circle avatar position-relative"></div>
      <div class="ml-2 avatar-text"></div>
    </div>
  </div>
  @if (instructionMessage) {
    <div class="position-relative">
      <div [class.animation-typing]="showFirstTimeAnimation && animationActions.start"
           class="position-absolute bot-action-response-message bot-action-response-typing-message">
        <div class="bot-action-response-message-arrow">
          <bitmark-icon name="arrow-up-fill" class="size-18px d-block"></bitmark-icon>
        </div>
        <span></span>
        <span class="ml-1"></span>
        <span class="ml-1"></span>
      </div>
      <div [class.animation-init]="showFirstTimeAnimation"
           [ngClass]="{'animation-animate transition-delay-2s': showFirstTimeAnimation && animationActions.start}"
           class="position-relative mt-2 bot-action-response-instruction bot-action-response-message">
        <div class="bot-action-response-message-arrow">
          <bitmark-icon name="arrow-up-fill" class="size-18px d-block"></bitmark-icon>
        </div>
        <bitmark-message [bit]="instructionMessage" [fullWidth]="true"></bitmark-message>
      </div>
    </div>
  }

  @if (bodyMessage) {
    <div class="position-relative">
      <div [ngClass]="{'animation-typing animation-delay-2s': showFirstTimeAnimation && animationActions.start}"
           class="position-absolute bot-action-response-message bot-action-response-typing-message">
        <span></span>
        <span class="ml-1"></span>
        <span class="ml-1"></span>
      </div>
      <div [class.animation-init]="showFirstTimeAnimation"
           [ngClass]="{'animation-animate transition-delay-4s': showFirstTimeAnimation && animationActions.start}"
           class="mt-2 bot-action-response-message">
        <bitmark-message [bit]="bodyMessage" [fullWidth]="true"></bitmark-message>
      </div>
    </div>
  }

  @if (bit?.responses?.length && !bit.answer?.choice) {
    <div [class.animation-init]="showFirstTimeAnimation"
         [ngClass]="showFirstTimeAnimation && animationActions.start ? responsesTransitionClass : ''"
         class="d-flex flex-column align-items-center justify-content-center mt-4 bot-action-response-options">
      @for (option of bit?.responses; track option) {
        <div (click)="selectAnswer(option)"
             class="d-flex align-items-center justify-content-center cursor-pointer bot-action-option">
          <bitmark-text [text]="option.response"></bitmark-text>
          @if (option.item) {
            <div class="ml-2 px-2 bot-action-option-item">{{ option.item }}</div>
          }
        </div>
      }
    </div>
  }

  <div [ngClass]="bit.answer?.choice && 'mt-4'">
    <div [class.animation-init]="!animationActions.hasAnswered"
         [class.animation-animate]="animationActions.showAnswerAnimation"
         class="d-flex flex-column align-items-end">
      @if (bit.answer?.choice) {
        <div>
          <div class="d-flex justify-content-end bot-check-container">
            <bitmark-user-box-photo
              [userEmail]="currentUserDetails?.emails?.email || currentUserDetails?.emails?.ssoEmail"
              [iconUrl]="currentUserDetails?.icon?.photoUrl"
              [fullName]="currentUserDetails?.icon?.fullName">
            </bitmark-user-box-photo>
          </div>
          <div class="position-relative mt-3 bot-action-response-instruction bot-action-user-response-message">
            <div class="bot-action-user-answer-message-arrow">
              <bitmark-icon name="arrow-up-fill" class="size-18px d-block"></bitmark-icon>
            </div>
            <bitmark-message [bit]="answerMessage" [fullWidth]="true"></bitmark-message>
          </div>
        </div>
      }
    </div>
    @if (feedbackMessage) {
      <div class="position-relative">
        <div [ngClass]="{'animation-typing animation-delay-1s': animationActions.showAnswerAnimation}"
             class="position-absolute bot-action-response-message bot-action-response-typing-message">
          <span></span>
          <span class="ml-1"></span>
          <span class="ml-1"></span>
        </div>
        <div [class.animation-init]="!animationActions.hasAnswered"
             [ngClass]="{'animation-animate transition-delay-3s': animationActions.showAnswerAnimation}"
             class="mt-2 bot-action-response-message">
          @if (bit.answer?.choice) {
            <div>
              <bitmark-message [bit]="feedbackMessage" [fullWidth]="true"></bitmark-message>
            </div>
          }
        </div>
      </div>
    }
    @if (bit.footer) {
      <div class="position-relative">
        <div
          [ngClass]="{'animation-typing': animationActions.showAnswerAnimation, 'animation-delay-1s': animationActions.showFooterAnimation, 'animation-delay-4s': animationActions.showFooterWithAnswerAnimation}"
          class="position-absolute bot-action-response-message bot-action-response-typing-message">
          <span></span>
          <span class="ml-1"></span>
          <span class="ml-1"></span>
        </div>
        <div [class.animation-init]="!animationActions.hasAnswered"
             [ngClass]="{'animation-animate': animationActions.showAnswerAnimation, 'transition-delay-3s': animationActions.showFooterAnimation, 'transition-delay-6s': animationActions.showFooterWithAnswerAnimation}"
             class="mt-2 bot-action-response-message">
          @if (footerMessage) {
            <div>
              <bitmark-message [bit]="footerMessage" [fullWidth]="true"></bitmark-message>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
