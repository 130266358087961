<div infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollUpDistance]="2"
     [infiniteScrollThrottle]="50"
     [alwaysCallback]="true"
     [infiniteScrollContainer]="'.infinite-scroll-container'"
     [scrollWindow]="false"
     (scrolled)="onScrollDown()"
     (scrolledUp)="onScrollUp()"
     [fromRoot]="true"
     class="bits-wrapper themed">
  <div class="d-flex flex-column"
       [style.opacity]="isLoading ? '0' : '1'">
    @for (bitWrapper of bitBookContent; track getBitId(bitWrapper); let bitIndex = $index) {
      <div class="position-relative">
        <bitmark-bit-wrapper context="book"
                             [bitWrapper]="bitWrapper"
                             [actions]="bitActions"
                             [isBeingEditedByUser]="bitWrapper.isBeingEditedByUser"
                             [saveUserEdit]="bitWrapper.saveUserEdit"
                             [options]="{courseId}"
                             [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                             (toggleTocEvent)="toggleToc.emit($event)"
                             (openResource)="onOpenResource($event)"
                             (isBitBeingEdited)="onIsBitBeingEdited($event)"
                             (editClosed)="closeBitIsEditedByUser(bitWrapper)"
                             (navigateToBook)="navigateToBook.emit($event)"
                             (navigateToProduct)="navigateToProduct.emit($event)"
                             (bitEditInProgress)="showBitEditProgress()"
                             (bitEditCompleted)="showBitEditCompleted($event)"
                             (closeBook)="closeBook.emit()">
        </bitmark-bit-wrapper>
        @if (bitWrapper?.bit?.type !== BitType.BitBookEnding && bitWrapper?.bit?.type !== BitType.VirtualMarkBookAsRead) {
          <bitmark-bit-actions [bitWrapper]="bitWrapper?.bit?.type===BitType.BitBookSummary ? null : bitWrapper"
                               [actions]="bitEditorActions"
                               [isCustomIcon]="bitBookContent[bitIndex + 1]?.bit?.type === BitType.BitBookEnding"
                               class="text-center"
                               dropdownClass="sm"
                               [cssClass]="'position-relative ml-auto mr-auto absolute-menu'">
          </bitmark-bit-actions>
        }
      </div>
    }

    @if (bitEditorStatus) {
      <span class="position-fixed reader-editor-status overflow-hidden">
        @switch (bitEditorStatus) {
          @case ('ready') {
            <img src="../../../../../assets/images/editor-status/saved.svg"/>
          }
          @case ('updating') {
            <img src="../../../../../assets/images/editor-status/wait.svg"/>
          }
          @case ('failed') {
            <img src="../../../../../assets/images/editor-status/error.svg"/>
          }
        }
      </span>
    }
  </div>

  <div [hidden]="!isLoadingAdditionalContent"
       class="position-fixed w-100 d-flex justify-content-center"
       [class.d-flex]="isLoadingAdditionalContent"
       [style.top]="isLoadingAdditionalContentTop ? 0 : undefined"
       [style.bottom]="isLoadingAdditionalContentBottom ? 0 : undefined"
       style="left: 0; z-index: 100000">
    <div class="spinner delayed-spinner" style="width: fit-content">
      <bitmark-progress-spinner [size]="20"></bitmark-progress-spinner>
    </div>
  </div>

  <!--d-inline-block-->
  <div class="d-inline-block ml-auto mr-3 absolute-dropdown"
       (click)="navigateToTopBottom()">
    <div class="btn btn-calm btn-action btn-circle position-fixed page-scroll-action"
         [ngClass]="isScrolledToBottom ? 'page-scroll-top' : 'page-scroll-bottom'">
      <bitmark-icon name="chevron-up" class="p-0"></bitmark-icon>
    </div>
  </div>
</div>
<div style="display: none">{{ bitbookContentVersion }}</div>
@if (showBitEditor) {
  <bitmark-bit-editor [bookExternalId]="bitBook.externalId"
                      [publisherId]="bitBook.publisherId"
                      [editingBitBitmark]="bitEditorCurrentEditedBit?.bitmark"
                      [bitBitmarkVersion]="bitEditorCurrentEditedBit?.system?.bitmarkVersion"
                      [bitData]="bitEditorCurrentEditedBit?.bit"
                      [isEditingWholeBook]="isEditingWholeBook"
                      (save)="saveBitFromEditor($event, this.courseId)"
                      (close)="onBitEditorClose()">
  </bitmark-bit-editor>
}
