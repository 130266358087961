import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BitmarkBookTypes, NotebookTypes} from '../models';
import {UrlRewriteService} from '../url-rewrites/url-rewrite.service';

@Component({
  selector: 'bitmark-book-cover',
  templateUrl: './book-cover.component.html',
  styleUrls: ['./book-cover.component.scss'],
})
export class BookCoverComponent implements OnInit, OnDestroy {
  @Input() cssClass: string;
  @Input() coverImage: string;
  @Input() coverImageHeight = '200px !important';
  @Input() coverImageWidth = 'auto';
  @Input() coverFullWidth = false;
  @Input() isFullyBranded = false;
  @Input() coverColor?: string;
  @Input() coverSize: 'sm' | 'md' | 'lg' | 'z' | '' = '';
  @Input() defaultCoverSize: 'xs' | 'sm' | 'md' | 'lg' | 'z' | '' = '';
  @Input() coverMask?: 'class-mask' | '' = '';
  @Input() bookSubtype?: BitmarkBookTypes | NotebookTypes;
  @Input() brandingPrimaryColor = '#ffce00';
  @Input() title: string;
  @Input() isDetail = false;
  @Input() isNotExist: boolean;
  @Input() isProductCover = false;
  @Input() isWideCoverAllowed = false;
  @Input() shopBranding: any;
  @Input() showTitleOverCover = false;
  GraphBookTypes = BitmarkBookTypes;

  @ViewChild('imageElement') imageElement: ElementRef;
  @ViewChild('imageCoverElement') imageCoverElement: ElementRef;
  @ViewChild('defaultTitle') defaultTitleElement: ElementRef;
  @Output() onIsWideImage: EventEmitter<any> = new EventEmitter<boolean>();
  coverMaxWidth: string;
  isBadImage = false;
  isImageLoaded = false;
  isWide = false;
  defaultCoverHeight: string;
  bookShadowFilter: string;
  coverSizeQueryWidth: number;
  coverSizeToWidthDictionary = {
    'xs': 140,
    'sm': 140,
    'md': 200,
    'lg': 360
  };

  constructor(private urlRewriteService: UrlRewriteService) {

  }

  ngOnInit() {
    // TEMPORARY FIX, this is to test it works, once we migrate to cdn this will be removed
    if (this.coverImage) {
      this.coverImage = this.urlRewriteService.rewriteUrl(this.coverImage);
    }

    if (!this.coverImage) {
      this.coverImageHeight = 'auto';
    }
    if (this.defaultCoverSize === 'xs') {
      this.defaultCoverHeight = '50px';
    }

    if (this.cssClass) {
      this.cssClass = this.cssClass?.indexOf('align-items') === -1 ? this.cssClass + ' align-items-end' : this.cssClass;
    } else {
      this.cssClass = 'align-items-end';
    }
    if (this.isNotExist) {
      this.cssClass += ' cover-bg-transparent';
    }

    if (this.coverMask) {
      this.cssClass += ` ${this.coverMask}`;
    }

    if (this.isDetail && this.imageElement?.nativeElement) {
      this.imageCoverElement.nativeElement.width = 'auto';
      this.imageCoverElement.nativeElement.height = 'auto';
    }

    this.coverMaxWidth = this.coverSize === 'md' && !this.isDetail ? '140px' : 'auto';
    this.coverSizeQueryWidth = this.coverSize ? this.coverSizeToWidthDictionary[this.coverSize] : this.coverSizeToWidthDictionary['md'];
    this.bookShadowFilter = +this.shopBranding?.borderWidth > 0 ? `drop-shadow(${this.shopBranding?.borderWidth}px 0 0 ${this.shopBranding?.borderColor}) drop-shadow(0 ${this.shopBranding?.borderWidth}px 0 ${this.shopBranding?.borderColor}) drop-shadow(-${this.shopBranding?.borderWidth}px 0 0 ${this.shopBranding?.borderColor}) drop-shadow(0 -${this.shopBranding?.borderWidth}px 0 ${this.shopBranding?.borderColor})` : '';

    if (this.isFullyBranded) {
      this.coverImageWidth = 'auto';
      this.coverImageHeight = '100%';
      this.coverMaxWidth = 'auto';
      return;
    }
  }

  ngOnDestroy() {
    this.imageElement = null;
    this.imageCoverElement = null;
    this.defaultTitleElement = null;
  }

  setBadImageStatus() {
    this.isBadImage = true;
  }

  onImageLoad() {
    this.isBadImage = !this.isDimensionAccepted();
    this.isImageLoaded = true;

    if (this.isFullyBranded) {
      return;
    }

    if (this.isWideCoverAllowed && this.imageElement?.nativeElement?.width && this.imageElement?.nativeElement?.height) {
      if (this.imageElement?.nativeElement?.width / this.imageElement?.nativeElement?.height > 1.2) {
        this.onIsWideImage.emit(true);
        this.isWide = true;
        this.coverImageWidth = '200px';
        if (this.imageCoverElement?.nativeElement) {
          this.imageCoverElement.nativeElement.style.maxWidth = '200px !important';
          this.coverMaxWidth = '200px';

          if (!this.isDetail) {
            const imgElementHeight = this.coverSize === 'md' ? 140 : 'auto';
            const imgElementWidth = this.coverSize === 'md' ? 200 : 'auto';
            this.imageCoverElement.nativeElement.width = imgElementWidth;
            this.imageCoverElement.nativeElement.height = imgElementHeight;
          }
        }
      } else if (!this.isDetail) {
        const imgElementHeight = this.coverSize === 'md' ? 200 : 'auto';
        const imgElementWidth = this.coverSize === 'md' ? 140 : 'auto';
        this.imageCoverElement.nativeElement.width = imgElementWidth;
        this.imageCoverElement.nativeElement.height = imgElementHeight;
      }
    }

    if (this.isDetail && this.imageElement?.nativeElement) {
      this.imageCoverElement.nativeElement.width = 'auto';
      this.imageCoverElement.nativeElement.height = 'auto';
    }

    if (this.coverImageHeight === '200px !important') {
      this.coverImageHeight = 'auto !important';
    }
  }

  private isDimensionAccepted(): boolean {
    const naturalWidth = +this.imageElement.nativeElement.naturalWidth;
    return naturalWidth >= 138;
  }
}
