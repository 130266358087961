import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {of, Subject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {cloneDeep} from 'lodash';
import {LearningPathBookBit} from './learning-path-book.model';
import {BitResource, BitResourceType} from '../../bits.models';
import {BitbookApiService} from '../../../reader/bitbook-api.service';
import {ProductFamily} from '../../../shared/models/bitmark.models';
import {ChooseBookComponent} from './choose-book/choose-book.component';
import {ChooseBookAnchorComponent} from './choose-book-anchor/choose-book-anchor.component';
import {LearningPathCommon} from '../learning-path.common';
import {BitmarkTextContentService} from '../../../shared';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {ReaderContentService} from '../../../reader/reader-book/reader-content/reader-content.service';

@Component({
  selector: 'bitmark-learning-path-book',
  templateUrl: './learning-path-book.component.html',
  styleUrls: ['./learning-path-book.component.scss', '../learning-path.common.scss', '../../bits.scss']
})
export class LearningPathBookComponent extends LearningPathCommon implements OnInit, OnDestroy {
  @Input() bit?: LearningPathBookBit;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<LearningPathBookBit>();
  @Output() navigateToBook = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }>();
  @Output() isBookUnaccessibleToUser = new EventEmitter<{ bookId: string }>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    this.trimHeader(this.bit);

    if (value) {
      this.isContentVisible = true;
      this.prevLearningPathBookBit = cloneDeep(this.bit);

      if (this.bit.book) {
        this.bitBookApiService.getBookById(this.bit.book)
          .subscribe(book => {
            this.selectedBookTitle = book.title;
            if (this.bit.reference) {
              const tocEntry = book.toc.find(x => x.anchor === this.bit.reference);
              if (tocEntry) {
                this.selectedBookChapter = tocEntry.item ?
                  `${tocEntry.item} - ${tocEntry.title}` :
                  tocEntry.title;
              }
            }
          }, (err) => {
            console.error(err);
          });
      }
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }


  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }

  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }


  @ViewChild('fileInput') fileInput: ElementRef;

  public isContentVisible = true;
  public selectedBookTitle: string;
  public selectedBookChapter: string;
  private prevLearningPathBookBit?: LearningPathBookBit;
  updateContentEventSubject: Subject<any> = new Subject<any>();

  get isHeaderMinimized(): boolean {
    return !this.isContentVisible ||
      (this.bit.isInfoOnly && !this.bit.book && !this.bit.body);
  }

  get isHeaderOnly(): boolean {
    if (this.bit.isInfoOnly && !this.bit.body && !this.isBeingEditedByUser) {
      return true;
    }

    return !this.bit.body && !this.isBeingEditedByUser && !this.bit.book;
  }

  get isTracked(): boolean {
    return this.bit.isTracked ?? true;
  }

  constructor(private readerContentService: ReaderContentService,
              private bitBookApiService: BitbookApiService,
              readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              private ngbModal: NgbModal) {
    super(readerTipTapService, bitmarkTextContentService);
  }

  ngOnInit() {
    this.handleImage(this.bit);
  }

  ngOnDestroy() {
    this.updateContentEventSubject.complete();
    this.updateContentEventSubject.unsubscribe();
  }

  cancelChanges() {
    this.bit = cloneDeep(this.prevLearningPathBookBit);
    this.selectedBookTitle = '';
    this.selectedBookChapter = '';
    this.editClosed.emit();
  }

  saveChanges() {
    this.trimHeader(this.bit);
    this.saveInlineBit.emit(this.bit);
  }

  uploadImage() {
    if (!this.fileInput.nativeElement.files?.length) {
      return;
    }
    const file = this.fileInput.nativeElement.files[0];
    this.bitBookApiService.uploadResource(file)
      .subscribe((resourceUrl: { url: string }) => {
        if (!this.bit.resource) {
          this.bit.resource = {
            type: BitResourceType.Image
          };
        }
        this.bit.resource.image = {
          src: resourceUrl.url,
          alt: this.bit.instruction
        };
        this.imageExists = true;
      }, (err) => {
        this.fileInput.nativeElement.value = null;
        console.error(err);
        alert('Could not upload image');
      });
  }

  removeImage() {
    if (this.bit.resource) {
      this.bit.resource = null;
    }
    this.imageExists = false;
  }

  open() {
    if (!this.bit.answer?.hasOpened && this.isTracked) {
      this.bit.answer = {
        ...this.bit.answer,
        hasOpened: true
      };
      this.changed.emit();
    }

    if (this.bit.book) {
      (this.bit.reference
          ? this.readerContentService.getBookBit(this.bit.book, this.bit.reference)
          : of(undefined)
      ).subscribe(bit => {
        this.navigateToBook.emit({bookId: this.bit.book, fragment: bit?.ref});
      }, () => {
        alert('You do not have access to this book.');
        this.isBookUnaccessibleToUser.emit({bookId: this.bit.book})
      });
    }
  }

  markAsDone() {
    this.bit.answer = {
      ...this.bit.answer,
      isDone: true
    };
    this.changed.emit();
  }

  reset(event) {
    event.stopPropagation();
    this.bit.answer = {
      hasOpened: false,
      isDone: false
    };
    this.changed.emit();
  }

  openChooseBook() {
    const modalRef = this.ngbModal.open(ChooseBookComponent, {
      windowClass: 'transparent-modal',
      animation: false
    });

    const sub = modalRef.componentInstance.bookChosen.subscribe((bookCover) => {
      sub.unsubscribe();
      if (this.bit.book === bookCover.externalId) {
        return;
      }
      this.bit.book = bookCover.externalId;
      this.bit.reference = '';
      this.selectedBookTitle = bookCover.title;
      this.selectedBookChapter = '';

      if (!this.imageExists) {
        if (!this.bit.resource) {
          this.bit.resource = {
            type: BitResourceType.Image
          };
        }
        this.bit.resource.image = {
          src: bookCover.coverImage,
          alt: bookCover.title
        };
      }

      if (!this.bit.instruction) {
        this.bit.instruction = bookCover.title;
      }
    });
  }

  removeChosenReference() {
    this.bit.reference = null;
    this.selectedBookChapter = '';
  }

  openChooseReference() {
    const modalRef = this.ngbModal.open(ChooseBookAnchorComponent, {
      windowClass: 'transparent-modal',
      animation: false
    });

    modalRef.componentInstance.bookId = this.bit.book;

    const sub = modalRef.componentInstance.anchorChosen.subscribe(({id, title}) => {
      sub.unsubscribe();
      this.bit.reference = id;
      this.selectedBookChapter = title;

      if (!this.bit.body || !this.bitmarkTextContentService.getTextFromJson(this.bit.body, this.bit.format)) {
        this.bit.body = title;
        this.updateContentEventSubject.next(this.bitmarkTextContentService.getBodyContent(this.bit.body, this.bit.format));
      }
    });
  }
}
