import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {JSONContent} from '@tiptap/core';
import {BaseBit, BitResourceImage, BitResourceSize, BitResourceUploadDetails, BitType} from '../../bits.models';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-resource-image',
  templateUrl: './resource-image.component.html',
  styleUrls: ['./resource-image.component.scss', '../../bits.scss']
})
export class ResourceImageComponent implements OnInit, OnDestroy {
  @Input() bit?: BaseBit;
  @Input() bitResourceImage?: BitResourceImage;
  @Input() hostBitType: BitType;
  @Input() fillUp = false;
  @Input() imageMaxWidth = 740;
  @Input() uploadDetails?: BitResourceUploadDetails | any;
  @Input() bitFormat?: BitmarkFormat;
  @Input() centered = false;
  @Input() isBeingEditedByUser = false;
  @Input() isZoomable = false;
  @Output() measureDisplaySize = new EventEmitter<BitResourceSize>();
  @Output() imageClicked = new EventEmitter<any>();
  @Output() captionChanged = new EventEmitter<BitResourceImage>();

  @ViewChild('imageElement') imageElement: ElementRef;
  imageWidth = 0;
  imageHeight = 0;
  fitMode: 'width' | 'height';
  hostCss: string;
  setImgWidth: boolean;

  ngOnInit() {
    this.hostCss = `host-${this.hostBitType}`;
    this.fitMode = [BitType.ImageSuperWide].indexOf(this.hostBitType) !== -1
      ? 'height'
      : 'width';
    this.imageWidth = this.bitResourceImage?.width
      || this.uploadDetails?.width
      || this.uploadDetails?.displayWidth
      || 0;
    this.imageHeight = this.bitResourceImage?.height
      || this.uploadDetails?.height
      || this.uploadDetails?.displayHeight
      || (this.fitMode === 'width' ? 0 : 100);
    if ([BitType.DetailsImage].includes(this.hostBitType)) {
      const aspectRatio = this.imageWidth && this.imageHeight ? this.imageWidth / this.imageHeight : 1;
      this.fitMode = aspectRatio < 1 ? 'height' : 'width';
      this.setImgWidth = false;
    } else {
      this.setImgWidth = true;
    }
    if (!this.bitFormat) {
      this.bitFormat = BitmarkFormat.MM;
    }
  }

  ngOnDestroy() {
    this.imageElement = null;
  }

  onImageLoaded() {
    const resWidthNative = this.bitResourceImage?.widthNative
      || this.bitResourceImage?.width
      || this.uploadDetails?.width
      || this.uploadDetails?.displayWidth
      || 0.0001;
    const resHeightNative = this.bitResourceImage?.heightNative
      || this.bitResourceImage?.height
      || this.uploadDetails?.height
      || this.uploadDetails?.displayHeight
      || 0.0001;

    const displayWidth = this.imageElement.nativeElement.clientWidth || this.bitResourceImage?.width;
    const displayHeight = this.fitMode === 'width'
      ? this.imageElement.nativeElement.clientHeight || this.bitResourceImage?.height
      : this.bitResourceImage?.height || this.imageElement.nativeElement.clientHeight;

    this.measureDisplaySize.emit({
      width: displayWidth,
      height: displayHeight,
      shrinkFactor: Math.min(displayWidth / resWidthNative, displayHeight / resHeightNative),
      zoomDisabled: this.bitResourceImage.zoomDisabled
    });
  }

  onImageLoadError($event: ErrorEvent) {
    ($event.target as HTMLElement).style.display = 'none';
  }

  onCaptionChanged(content: JSONContent) {
    this.captionChanged.emit({
      ...this.bitResourceImage,
      caption: content?.content
    });
  }
}
