<figure class="text-center resource-container">
  @if (embeddedVideoHtml) {
    <div [style.aspect-ratio]="aspectRatio"
         [innerHTML]="embeddedVideoHtml"
         [ngClass]="videoCss">
    </div>
  }
  @if (bitResource?.preview) {
    <div [ngClass]="videoCss">
      <div class="video-title py-4" [innerHTML]="bitResource?.preview?.title | safeHtml"></div>
      <div class="video-description text-gray" [innerHTML]="bitResource?.preview?.description | safeHtml"></div>
      @if (videoLogo) {
        <div class="video-logo py-2">
          <img [src]="'../../../../../assets/images/logo-' + videoLogo + '.png'">
        </div>
      }
    </div>
  }
  @if (videoCaption && !isBeingEditedByUser) {
    <figcaption class="resource-video-caption">
      <bitmark-text [text]="$any(videoCaption)"
                    [format]="bitFormat">
      </bitmark-text>
    </figcaption>
  } @else if (isBeingEditedByUser) {
    <figcaption class="resource-video-caption">
      <bitmark-reader-simple-text-editor class="w-100 image-caption"
                                         [bit]="bit"
                                         [bitContent]="videoCaption"
                                         [placeholder]="'Shared.Caption' | translate"
                                         [isReadOnly]="false"
                                         (updateBit)="onCaptionChanged($event)">
      </bitmark-reader-simple-text-editor>
    </figcaption>
  }
  @if (videoCopyright) {
    <figcaption class="resource-video-caption">
      <bitmark-text [text]="videoCopyright"
                    [format]="bitFormat">
      </bitmark-text>
    </figcaption>
  }
</figure>
