import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {of, Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {JSONContent} from '@tiptap/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LearningEventCommon, toggleAnimations} from '../learning-event.common';
import {LearningEventReadBookBit} from './learning-event-read-book.models';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {BitmarkTextContentService} from '../../../shared';
import {BitmarkFormat, ProductFamily} from '../../../shared/models/bitmark.models';
import {ReaderContentService} from '../../../reader/reader-book/reader-content/reader-content.service';
import {BitbookApiService} from '../../../reader/bitbook-api.service';
import {ReaderLeContentService} from '../../../reader/reader-book/reader-content/reader-le-content.service';
import {BookCoverEntity, BookEntity} from '../../../reader/reader.models';
import {ChooseBookComponent} from '../../learning-path/learning-path-book/choose-book/choose-book.component';
import {BitResourceType} from '../../bits.models';
import {ChooseBookAnchorComponent} from '../../learning-path/learning-path-book/choose-book-anchor/choose-book-anchor.component';

@Component({
  selector: 'bitmark-learning-event-read-book',
  templateUrl: './learning-event-read-book.component.html',
  styleUrls: ['./learning-event-read-book.component.scss', '../learning-event.common.scss'],
  animations: toggleAnimations
})
export class LearningEventReadBookComponent extends LearningEventCommon implements OnInit, OnDestroy {
  @Input() bit?: LearningEventReadBookBit;
  @Output() changed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<LearningEventReadBookBit>();
  @Output() navigateToBook = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }>();
  @Output() isBookUnaccessibleToUser = new EventEmitter<{ bookId: string }>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    if (value) {
      setTimeout(() => {
        this.toggleState = 'expanded';
        this.toggleStateIsDone = 'expanded';
        this.isBodyCollapsed = false;
      });
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  protected readonly BitmarkFormat = BitmarkFormat;

  isLeadEmpty = false;
  isItemEmpty = false;
  isBodyEmpty = false;
  imageExists = false;
  isDataLoaded = false;
  hasAccessToBook = true;
  bookCoverImageSrc = '';
  bookTitle = '';
  bookChapter: string;
  inViewportChange: Subject<boolean> = new Subject<boolean>();
  updateContentEventSubject: Subject<any> = new Subject<any>();

  private viewportSubscription: Subscription;

  constructor(private readerContentService: ReaderContentService,
              private bitbookApiService: BitbookApiService,
              private ngbModal: NgbModal,
              readerLeContentService: ReaderLeContentService,
              readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService) {
    super(readerTipTapService, bitmarkTextContentService, readerLeContentService);
  }

  ngOnInit() {
    this.initFlags();
    this.toggleStateIsDone = this.bit?.answer?.isDone ? 'collapsed' : 'expanded';

    this.initExpandedBit(this.bit.id, this.bit.answer?.isDone);

    this.viewportSubscription = this.inViewportChange
      .pipe(debounceTime(500))
      .subscribe((inViewport: boolean) => {
        if (!inViewport) {
          return;
        }

        if (this.isDataLoaded) {
          this.viewportSubscription.unsubscribe();
          return;
        }

        this.loadBookData();
        this.viewportSubscription.unsubscribe();
      });
  }

  ngOnDestroy() {
    this.viewportSubscription?.unsubscribe();
    this.updateContentEventSubject.complete();
    this.updateContentEventSubject.unsubscribe();
  }

  onInViewportChange(evt: { target: Element, visible: boolean }) {
    this.inViewportChange?.next(evt.visible);
  }

  toggleCheckbox() {
    if (!this.bit.answer) {
      this.bit.answer = {};
    }

    this.bit.answer.isDone = !this.bit.answer.isDone;

    if (this.isBodyCollapsed) {
      if (this.bit.answer?.isDone) {
        this.toggleStateIsDone = 'collapsed';
      } else {
        this.toggleState = 'collapsed';
        this.toggleStateIsDone = 'expanded';
      }
    } else {
      if (this.bit.answer?.isDone) {
        this.toggleState = 'collapsed';
        this.toggleStateIsDone = 'collapsed';
        this.isBodyCollapsed = true;
      }
    }

    this.changed.emit();
  }

  open() {
    if (!this.hasAccessToBook) {
      return;
    }

    if (this.bit.book) {
      (this.bit.reference
          ? this.readerContentService.getBookBit(this.bit.book, this.bit.reference)
          : of(undefined)
      ).subscribe(bit => {
        this.navigateToBook.emit({bookId: this.bit.book, fragment: bit?.ref});
      }, () => {
        alert('You do not have access to this book.');
        this.isBookUnaccessibleToUser.emit({bookId: this.bit.book});
      });
    }
  }

  openChooseBook() {
    const modalRef = this.ngbModal.open(ChooseBookComponent, {
      windowClass: 'transparent-modal',
      animation: false
    });

    const sub = modalRef.componentInstance.bookChosen.subscribe((bookCover) => {
      sub.unsubscribe();
      if (this.bit.book === bookCover.externalId) {
        return;
      }
      this.bit.book = bookCover.externalId;
      this.bit.reference = '';
      this.bookTitle = bookCover.title;
      this.bookChapter = '';

      if (!this.bit.resource) {
        this.bit.resource = {
          type: BitResourceType.Image
        };
      }
      this.bit.resource.image = {
        src: bookCover.coverImage,
        alt: bookCover.title
      };
      this.setBookData(bookCover);

      if (!this.bit.instruction) {
        this.bit.instruction = bookCover.title;
      }

      this.saveChanges();
    });
  }

  removeChosenReference() {
    this.bit.reference = null;
    this.bookChapter = '';
  }

  openChooseReference() {
    const modalRef = this.ngbModal.open(ChooseBookAnchorComponent, {
      windowClass: 'transparent-modal',
      animation: false
    });

    modalRef.componentInstance.bookId = this.bit.book;

    const sub = modalRef.componentInstance.anchorChosen.subscribe(({id, title}) => {
      sub.unsubscribe();
      this.bit.reference = id;
      this.bookChapter = title;

      if (!this.bit.body || !this.bitmarkTextContentService.getTextFromJson(this.bit.body, this.bit.format)) {
        this.bit.body = title;
        this.updateContentEventSubject.next(this.bitmarkTextContentService.getBodyContent(this.bit.body, this.bit.format));
      }

      this.saveChanges();
    });
  }

  onUpdateContent(bit: any, bitJson: JSONContent) {
    bit.body = bitJson?.content;
    bit.format = BitmarkFormat.PP;
    this.saveChanges();
  }

  saveChanges() {
    this.initFlags();

    this.bit.answer = null;

    this.saveInlineBit.emit(this.bit);
  }

  private initFlags() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.isBodyEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.body, this.bit?.format);
  }

  private loadBookData() {
    this.isDataLoaded = true;

    if (!this.bit?.book) {
      return;
    }

    this.bitbookApiService.getBookById(this.bit.book)
      .subscribe(
        data => {
          this.setBookData(data);
        },
        () => {
          this.bitbookApiService.getBasicBookById(this.bit.book)
            .subscribe(data => {
              this.hasAccessToBook = false;
              this.setBookData(data);
            }, (error) => {
              console.error(error);
              this.imageExists = false;
            });
        });
  }

  private setBookData(data: BookEntity | BookCoverEntity) {
    const coverSrc = typeof (data.coverImage) === 'string' ? data.coverImage : data?.coverImage?.src1x || data?.coverImage?.src2x || data?.coverImage?.src3x;
    this.imageExists = !!coverSrc;
    if (this.imageExists) {
      this.bookCoverImageSrc = coverSrc;
      this.bookTitle = data.title;
      if (this.bit.reference && (data as any).toc) {
        const tocEntry = (data as BookEntity).toc.find(x => x.anchor === this.bit.reference);
        this.bookChapter = tocEntry.item ?
          `${tocEntry.item} - ${tocEntry.title}` :
          tocEntry.title;
      }
    }
  }
}
