<div class="reader-shadow bg-white pt-2 pb-4 choose-anchor-container">
  <div class="d-flex align-items-start justify-content-between px-3">
    <h4 class="m-0 pt-4 font-weight-medium">{{ 'Shared.ChooseChapter' | translate }}</h4>
    <bitmark-icon name="close" class="size-20px cursor-pointer text-black pt-1" (click)="closeModal()"></bitmark-icon>
  </div>
  <div class="pt-3 px-3 anchors-list">
    <div class="d-flex align-items-center">
      <input class="border-0 ml-2 flex-grow-1 flex-shrink-1"
             [placeholder]="'Search.SearchChapter' | translate"
             (input)="searchChapter($event)"/>
    </div>
  </div>
  <div class="d-flex flex-column pt-1 anchors-list">
    @for (anchor of displayChapterAnchors; track anchor) {
      <div (click)="chooseAnchor(anchor.anchor, anchor.chapter)"
           class="d-flex cursor-pointer mx-3 my-1">
        <h6 class="m-0" [ngStyle]="{paddingLeft: getLevelPadding(anchor.level)}">{{ anchor.chapter }}</h6>
      </div>
    }
  </div>
</div>
