<div class="overflow-hidden flashcards-container"
     [class.reader-shadow]="isInModal"
     [class.flashcards-container-modal]="isInModal"
     inViewport
     (inViewportAction)="onInViewportChange($event)">
  @if (loadingFlashcards) {
    <div class="flashcard-mode-placeholder">
      <div class="header"></div>
      <div class="card-content"></div>
      <div class="mt-2 navigation"></div>
    </div>
  } @else {
    <bitmark-app-flashcards-normal #normalModeComponent
                                   [hidden]="isLeitnerMode"
                                   [appFlashcardsBit]="bit"
                                   [flashcardBits]="flashcardBits"
                                   [isInModal]="isInModal"
                                   [isMobile]="isMobile"
                                   [isActive]="!isLeitnerMode"
                                   [listenToKeyboard]="isKeyboardFocused && !isLeitnerMode"
                                   (changed)="changed.emit()">
    </bitmark-app-flashcards-normal>
    <bitmark-app-flashcards-leitner #leitnerModeComponent
                                    [hidden]="!isLeitnerMode"
                                    [appFlashcardsBit]="bit"
                                    [flashcardBits]="flashcardBits"
                                    [isInModal]="isInModal"
                                    [isMobile]="isMobile"
                                    [isActive]="isLeitnerMode"
                                    [listenToKeyboard]="isKeyboardFocused && isLeitnerMode"
                                    (changed)="changed.emit()">
    </bitmark-app-flashcards-leitner>
  }
  @if (isMobile && !isInModal) {
    <div class="d-flex justify-content-center pt-2 pb-4 flashcards-learn">
      <button class="btn btn-action bit-button flashcards-learn-btn"
              (click)="learn()"
              [innerText]="'Bits.Learn' | translate">
      </button>
    </div>
  }
  <div class="d-flex justify-content-between">
    @if (!isMobile || isInModal) {
      <div class="flashcards-leitner custom-switch custom-switch-md ml-2"
           [class.is-in-modal]="isInModal"
           (click)="toggleLeitnerMode($event)">
        <input class="custom-control-input leitner-mode-checkbox"
               type="checkbox"
               role="switch"
               id="leitnerMode"
               [checked]="isLeitnerMode">
        <label class="custom-control-label" for="leitnerMode">Leitner</label>
      </div>
    }
    @if (!isMobile) {
      <div class="mr-2 mb-1">
        <bitmark-icon
          name="keyboard-fill"
          class="size-40px d-block cursor-pointer"
          [ngClass]="isKeyboardFocused ? 'keyboard-focus-active' : 'keyboard-focus'"
          (click)="focusKeyboard()">
        </bitmark-icon>
      </div>
    }
  </div>
  @if (isInModal) {
    <div class="flashcards-close-modal">
      <bitmark-icon name="close"
                    class="size-20px cursor-pointer"
                    (click)="closeModal()">
      </bitmark-icon>
    </div>
  }
</div>
