<div #readerContainer class="pos-relative">
  <div infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollUpDistance]="2"
       [infiniteScrollThrottle]="50"
       [alwaysCallback]="true"
       [infiniteScrollContainer]="'.infinite-scroll-container'"
       [scrollWindow]="false"
       (scrolled)="onScrollDown()"
       (scrolledUp)="onScrollUp()"
       [fromRoot]="true"
       class="bits-wrapper themed">
    <div class="d-flex flex-column"
         [ngClass]="{'book-padding': !isNewRelease}"
         [style.opacity]="isLoading ? '0' : '1'">
      @for (bitWrapper of bitBookContent; track getBitId(bitWrapper); let bitIndex = $index) {
        <div class="position-relative">
<!--          @if (bitIndex === 0 && hiddenElements?.indexOf(ReaderHiddenElements.BitActionsPadding) !== -1) {-->
<!--            <bitmark-bit-actions [bitWrapper]="null"-->
<!--                                 [actions]="bitEditorActions"-->
<!--                                 class="text-center"-->
<!--                                 dropdownClass="sm"-->
<!--                                 [cssClass]="'position-relative ml-auto mr-auto absolute-menu absolute-menu-first'">-->
<!--            </bitmark-bit-actions>-->
<!--          }-->
          @if (bitWrapper.annotations?.length) {
            @for (bitAnnotation of getTopAnnotations(bitWrapper.annotations); track bitAnnotation.id) {
              <bitmark-bit-wrapper class="my-3"
                                   [context]="context"
                                   [bitWrapper]="getApiWrapperFromAnnotation(bitWrapper, bitAnnotation)"
                                   [actions]="getBitAnnotationActions(bitAnnotation)"
                                   [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                                   (isBitAnnotationBeingEdited)="saveEditedAnnotation($event)"
                                   (navigateToBook)="navigateToBook.emit($event)"
                                   (navigateToProduct)="navigateToProduct.emit($event)">
              </bitmark-bit-wrapper>
            }
          }
          @if (bitWrapper?.editorLoadingPre) {
            <div [ngClass]='[brandingThemeClass]'
                 class="bit-skeleton-loader bit-wrapper-1 bit-shadow mt-4 p-4 text-black-50">
              <bitmark-skeleton-loader [bitWrapper]='bitWrapper'
                                       [actions]='skeletonLoaderActions'>
              </bitmark-skeleton-loader>
            </div>
          }
          <bitmark-bit-wrapper [context]="context"
                               [bitWrapper]="bitWrapper"
                               [bookId]="bitBook.externalId"
                               [actions]="bitActions"
                               [queryParams]='globalNotebookApiQueryParams'
                               [isBeingEditedByUser]="bitWrapper.isBeingEditedByUser"
                               [saveUserEdit]="bitWrapper.saveUserEdit"
                               [focusedBit]="focusedBitId"
                               [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                               (toggleTocEvent)="toggleToc.emit($event)"
                               (openResource)="onOpenResource($event)"
                               (isBitBeingEdited)="onIsBitBeingEdited($event)"
                               (editClosed)="closeBitIsEditedByUser(bitWrapper)"
                               (navigateToBook)="navigateToBook.emit($event)"
                               (navigateToProduct)="navigateToProduct.emit($event)"
                               (closeBook)="closeBook.emit()"
                               (bitEditInProgress)="showBitEditProgress()"
                               (bitEditCompleted)="showBitEditCompleted($event)">
          </bitmark-bit-wrapper>
          @if (bitWrapper.editorLoadingPost) {
            <div [ngClass]='[brandingThemeClass]'
                 class="bit-skeleton-loader bit-wrapper-1 bit-shadow mt-4 p-4 text-black-50">
              <bitmark-skeleton-loader [bitWrapper]='bitWrapper'
                                       [actions]='skeletonLoaderActions'>
              </bitmark-skeleton-loader>
            </div>
          }
          @if (bitWrapper.annotations?.length) {
            @for (bitAnnotation of getBottomAnnotations(bitWrapper.annotations); track bitAnnotation.id) {
              <bitmark-bit-wrapper class="my-3"
                                   [context]="context"
                                   [bitWrapper]="getApiWrapperFromAnnotation(bitWrapper, bitAnnotation)"
                                   [actions]="getBitAnnotationActions(bitAnnotation)"
                                   [shouldProtectAgainstUserCopy]="shouldProtectAgainstUserCopy"
                                   (isBitAnnotationBeingEdited)="saveEditedAnnotation($event)">
              </bitmark-bit-wrapper>
            }
          }
          @if (bitWrapper.editorOperation) {
            <div class="bit-shadow mt-4 p-4 align-items-start d-flex justify-content-center bg-black">
              @for (file of bitWrapper.editorOperation.data.runningJobs; track file) {
                <div class="d-inline-block">
                  <bitmark-pdf-upload [afterBitId]="bitWrapper?.id !== 'start' ? bitWrapper.id : null"
                                      [bookExternalId]="bitBook.externalId"
                                      (dropzoneSelect)="createJob($event, bitWrapper)"
                                      (jobFinished)="onJobFinished($event, bitWrapper)"
                                      [file]="file">
                  </bitmark-pdf-upload>
                </div>
              }
            </div>
          }
          @if (bitWrapper?.bit?.type !== BitType.BitBookEnding && bitWrapper?.bit?.type !== BitType.VirtualMarkBookAsRead) {
            <bitmark-bit-actions [bitWrapper]="bitWrapper?.bit?.type === BitType.BitBookSummary ? null : bitWrapper"
                                 [actions]="bitEditorActions"
                                 [isCustomIcon]="bitBookContent[bitIndex + 1]?.bit?.type === BitType.BitBookEnding"
                                 class="text-center"
                                 dropdownClass="sm"
                                 [cssClass]="'position-relative ml-auto mr-auto ' + ( hiddenElements?.indexOf(ReaderHiddenElements.BitActionsPadding) !== -1 ? ' absolute-menu ' : '') + (bitBookContent[bitIndex + 1]?.bit?.type === BitType.BitBookEnding && ' absolute-menu-plus ')">
            </bitmark-bit-actions>
          }
        </div>
      }

      @if (bitEditorStatus) {
        <span class="position-fixed reader-editor-status overflow-hidden">
          @switch (bitEditorStatus) {
            @case ('ready') {
              <img src="../../../../../assets/images/editor-status/saved.svg"/>
            }
            @case ('updating') {
              <img src="../../../../../assets/images/editor-status/wait.svg"/>
            }
            @case ('failed') {
              <img src="../../../../../assets/images/editor-status/error.svg"/>
            }
          }
        </span>
      }
    </div>
    <div [hidden]="!isLoadingAdditionalContent"
         class="position-fixed w-100 justify-content-center"
         [class.d-flex]="isLoadingAdditionalContent"
         [style.top]="isLoadingAdditionalContentTop ? 0 : undefined"
         [style.bottom]="isLoadingAdditionalContentBottom ? 0 : undefined"
         style="left: 0; z-index: 100000">
      <div class="spinner delayed-spinner" style="width: fit-content">
        <bitmark-progress-spinner [size]="20"></bitmark-progress-spinner>
      </div>
    </div>
  </div>

  <!--d-inline-block-->
  <div class="d-inline-block ml-auto mr-3 absolute-dropdown"
       (click)="navigateToTopBottom()">
    <div class="btn btn-calm btn-action btn-circle position-fixed page-scroll-action"
         [ngClass]="isScrolledToBottom ? 'page-scroll-top' : 'page-scroll-bottom'">
      <bitmark-icon name="chevron-up" class="p-0"></bitmark-icon>
    </div>
  </div>
  <div id="annotation-menu" #annotationsMenuTemplate class="annotations-menu" style="display: none">
    <div #annotationsMenuPopup class="d-flex flex-column align-items-start annotations-menu-popup">
      <div #annotationsMenuHighlightTemplate class="w-100" style="display: none">
        <div class="p-1 d-flex">
          <button (click)="toggleAnnotationHighlight()"
                  class="pl-1 mr-3 annotation-btn-img">
            <img src="assets/images/annotations/marker-pen.png" class="cursor-pointer"
                 style="max-width: 25px; height: auto;"/>
          </button>
        </div>
        <div class="separator"></div>
      </div>
      <div class="d-flex flex-row justify-content-around p-1">
        <button (click)="createAnnotation('bookmark')"
                class="annotation-btn-img">
          <img src="assets/images/annotations/annotation-bookmark.png" class="mr-3 cursor-pointer"
               style="max-width: 100px; height: auto;"/>
        </button>
        <button (click)="createAnnotation('favourite')"
                class="annotation-btn-img">
          <img src="assets/images/annotations/annotation-favorite.png" class="cursor-pointer"
               style="max-width: 100px; height: auto;"/>
        </button>
      </div>
      <div class="separator"></div>
      <div class="d-flex flex-row justify-content-between p-1 w-100">
        <button (click)="createAnnotation('note')"
                class="annotation-btn-img">
          <img src="assets/images/annotations/annotation-note.png" style="max-width: 100px; height: auto;"/>
        </button>
        <button (click)="createAnnotation('handwritten')"
                class="annotation-btn-img">
          <img src="assets/images/annotations/annotation-handwritten.png" style="max-width: 100px; height: auto;"/>
        </button>
      </div>
    </div>
  </div>
  <div id="selection-ref" style="position: absolute;"></div>
</div>
<div style="display: none">{{ bitbookContentVersion }}</div>
@if (showJsonEditor) {
  <bitmark-json-editor [bookExternalId]="bitBook.externalId"
                       (close)="showJsonEditor = false;">
  </bitmark-json-editor>
}
@if (showHtmlTranslatorEditor) {
  <bitmark-html-translator [bookExternalId]="bitBook.externalId"
                       (close)="showHtmlTranslatorEditor = false;">
  </bitmark-html-translator>
}
@if (showBitEditor) {
  <bitmark-bit-editor [bookExternalId]="bitBook.externalId"
                      [themeId]='bitBook.theme'
                      [publisherId]="bitBook.publisherId"
                      [editingBitBitmark]="bitEditorCurrentEditedBit?.bitmark"
                      [isEditingWholeBook]="isEditingWholeBook"
                      [bitId]="bitEditorCurrentEditedBit?.id"
                      [bitData]="bitEditorCurrentEditedBit?.bit"
                      [bitBitmarkVersion]="bitEditorCurrentEditedBit?.system?.bitmarkVersion"
                      [convertToBitmarkIfEmpty]="isNewRelease"
                      (save)="saveBitFromEditor($event)"
                      (close)="onBitEditorClose()">
  </bitmark-bit-editor>
}
