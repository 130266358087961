import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JSONContent} from '@tiptap/core';
import {LearningEventCommon, toggleAnimations} from '../learning-event.common';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {BitmarkTextContentService} from '../../../shared';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {LearningEventPreparationTaskBit} from './learning-event-preparation-task.models';
import {ReaderLeContentService} from '../../../reader/reader-book/reader-content/reader-le-content.service';
import {LearningEventCompletionBit} from '../learning-event-completion/learning-event-completion.models';

@Component({
  selector: 'bitmark-learning-event-preparation-task',
  templateUrl: './learning-event-preparation-task.component.html',
  styleUrls: ['./learning-event-preparation-task.component.scss', '../learning-event.common.scss'],
  animations: toggleAnimations
})
export class LearningEventPreparationTaskComponent extends LearningEventCommon implements OnInit {
  @Input() bit?: LearningEventPreparationTaskBit;
  @Output() changed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<LearningEventCompletionBit>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    if (value) {
      setTimeout(() => {
        this.toggleState = 'expanded';
        this.toggleStateIsDone = 'expanded';
        this.isBodyCollapsed = false;
      });
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  isLeadEmpty = false;
  isItemEmpty = false;
  isBodyEmpty = false;

  constructor(readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              readerLeContentService: ReaderLeContentService) {
    super(readerTipTapService, bitmarkTextContentService, readerLeContentService);
  }

  ngOnInit() {
    this.initFlags();
    this.toggleStateIsDone = this.bit?.answer?.isDone ? 'collapsed' : 'expanded';

    this.initExpandedBit(this.bit.id, this.bit.answer?.isDone);
  }

  toggleCheckbox() {
    if (!this.bit.answer) {
      this.bit.answer = {};
    }

    this.bit.answer.isDone = !this.bit.answer.isDone;

    if (this.isBodyCollapsed) {
      if (this.bit.answer?.isDone) {
        this.toggleStateIsDone = 'collapsed';
      } else {
        this.toggleState = 'collapsed';
        this.toggleStateIsDone = 'expanded';
      }
    } else {
      if (this.bit.answer?.isDone) {
        this.toggleState = 'collapsed';
        this.toggleStateIsDone = 'collapsed';
        this.isBodyCollapsed = true;
      }
    }

    this.changed.emit();
  }

  onUpdateContent(bit: any, bitJson: JSONContent) {
    bit.body = bitJson?.content;
    bit.format = BitmarkFormat.PP;
    this.saveChanges();
  }

  saveChanges() {
    this.initFlags();

    this.bit.answer = null;

    this.saveInlineBit.emit(this.bit);
  }

  private initFlags() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.isBodyEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.body, this.bit?.format);
  }
}
