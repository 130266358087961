<div class="learning-event-header">
  @if (isBeingEditedByUser) {
    <div class="d-flex align-items-center">
      @if (!bit.isInfoOnly) {
        <div class="bit-checkbox hidden-input d-flex mr-2">
          <input type="checkbox">
          <label></label>
        </div>
      }
      <div class="learning-event-item">
        <bitmark-editable-text [text]="bit.item"
                               [placeholder]="'Bits.Item' | translate"
                               [cssClass]="'is-editing'"
                               (onChange)="bit.item = $event; saveChanges();">
        </bitmark-editable-text>
      </div>
      <div class="d-flex learning-event-lead-dash-before"></div>
      <div class="learning-event-lead">
        <bitmark-editable-text [text]="bit.lead"
                               [placeholder]="'Bits.Lead' | translate"
                               [cssClass]="'is-editing'"
                               (onChange)="bit.lead = $event; saveChanges();">
        </bitmark-editable-text>
      </div>
      <div class="d-flex learning-event-lead-dash-after"></div>
    </div>
  } @else if (!isItemEmpty || !isLeadEmpty) {
    <div class="d-flex align-items-center">
      @if (!bit.isInfoOnly) {
        <div class="bit-checkbox hidden-input d-flex mr-2">
          <input type="checkbox">
          <label></label>
        </div>
      }
      @if (!isItemEmpty) {
        <div class="learning-event-item">
          <bitmark-text [text]="bit.item"></bitmark-text>
        </div>
      }
      @if (!isItemEmpty && !isLeadEmpty) {
        <div class="d-flex learning-event-lead-dash-before"></div>
      }
      @if (!isLeadEmpty) {
        <div class="learning-event-lead">
          <bitmark-text [text]="bit.lead"></bitmark-text>
        </div>
      }
      @if (!isItemEmpty && !isLeadEmpty) {
        <div class="d-flex learning-event-lead-dash-after"></div>
      }
    </div>
  }
  <div class="d-flex">
    <div class="w-100" [class.mt-1]="bit.item">
      <div class="d-flex position-relative">
        @if (!bit.isInfoOnly) {
          <div class="bit-checkbox mr-2" (click)="toggleCheckbox()">
            <input type="checkbox"
                   [checked]="bit.answer?.isDone">
            <label></label>
          </div>
        }
        @if (isBeingEditedByUser) {
          <div class="learning-event-instruction">
            <bitmark-editable-text
              (onChange)="bit.instruction = $event; saveChanges();"
              [text]="bit.instruction"
              [placeholder]="'Bits.Instruction' | translate"
              [cssClass]="'is-editing'">
            </bitmark-editable-text>
          </div>
        } @else if (bit.instruction) {
          <div class="learning-event-instruction" [class.is-closed]="bit?.answer?.isDone">
            <bitmark-text [text]="bit.instruction"></bitmark-text>
          </div>
        }
        @if (!isBodyEmpty || isBeingEditedByUser) {
          <div class="d-flex cursor-pointer toggle-btn" [class.rotate]="!isBodyCollapsed"
               (click)="toggleBody(bit.id, bit?.answer?.isDone)">
            <bitmark-icon name="chevron-right" class="size-30px"></bitmark-icon>
          </div>
        }
      </div>
    </div>
  </div>
</div>
<div class="overflow-hidden" [@slideToggleIsDone]="toggleStateIsDone">
  <div class="d-flex align-items-center flex-wrap learning-event-header-details">
    @if (!bit.isInfoOnly) {
      <div class="bit-checkbox hidden-input d-flex mr-2">
        <input type="checkbox">
        <label></label>
      </div>
    }
    @if (isBeingEditedByUser) {
      <div class="mt-2 mr-2 learning-event-activity-type">
        <bitmark-editable-text
          (onChange)="bit.activityType = $event; saveChanges();"
          [text]="bit.activityType"
          [placeholder]="'Bits.ActivityType' | translate"
          class="py-1 px-2"
          [cssClass]="'is-editing'">
        </bitmark-editable-text>
      </div>
      <div class="d-flex align-items-center mt-2 learning-event-duration">
        <bitmark-icon name="stopwatch" class="size-18px mr-1"></bitmark-icon>
        <bitmark-duration-editor ngDefaultControl
                                 class="is-editing"
                                 [(ngModel)]="bit.duration"
                                 (ngModelChange)="saveChanges()">
        </bitmark-duration-editor>
      </div>
    } @else {
      @if (bit.activityType) {
        <div class="mr-2 learning-event-activity-type"
             [class.mt-2]="bit.item || bit.instruction">
          <bitmark-text [text]="bit.activityType" class="py-1 px-2"></bitmark-text>
        </div>
      }
      @if (bit.duration) {
        <div class="d-flex align-items-center learning-event-duration"
             [class.mt-2]="bit.item || bit.instruction">
          <bitmark-icon name="stopwatch" class="size-18px mr-1"></bitmark-icon>
          <span>{{ bit.duration | amDuration }}</span>
        </div>
      }
    }
  </div>
  @if (bit.body) {
    <div class="overflow-hidden" [@slideToggle]="toggleState">
      <div class="d-flex">
        @if (!bit.isInfoOnly) {
          <div class="bit-checkbox hidden-input d-flex mr-2">
            <input type="checkbox">
            <label></label>
          </div>
        }
        @if (isBeingEditedByUser) {
          <div class="flex-fill bit-body bit-body-allow-highlight">
            <div class="mt-4 is-editing-multi-line">
              <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                          [bit]="bit"
                                          [bitContent]="bit.body | bitmark:bit.format:'json'"
                                          [isReadOnly]="false"
                                          [placeholder]="'Bits.PlaceholderBody' | translate">
              </bitmark-reader-text-editor>
            </div>
          </div>
        } @else if (!isBodyEmpty) {
          <div class="flex-fill bit-body bit-body-allow-highlight">
            <div class="mt-4">
              <bitmark-text [text]="bit.body" [format]="bit.format"></bitmark-text>
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>
