@if (bitResourceAudio?.src) {
  <div class="d-flex w-100 text-center resource-audio-wrapper">
    @if (bitResourceAudio?.src) {
      <audio #audioElement
             class="w-100"
             controls
             controlsList="nodownload"
             inViewport
             (inViewportAction)="onInViewportChange($event)">
        Your browser does not support the audio element.
      </audio>
    }
  </div>
  @if (bitResourceAudio?.caption) {
    <div class="w-100 pt-2 resource-audio-caption">
      <bitmark-text [text]="bitResourceAudio.caption"
                    [format]="bitFormat">
      </bitmark-text>
    </div>
  }
}
