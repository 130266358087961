import {PublicShopNav} from "public-shop/public-shop.models";

export interface HostSubdomainConfig {
  subDomain: string;
  gaTrackingCode?: string;
  space?: string;
  publicShopConfig?: PublicShopNav[];
}

export const appHost = {
  getSubdomainConfig: (): HostSubdomainConfig => {
    const subDomain = window.location.hostname.toLowerCase().replace('.getmorebrain.com', '');
    return subdomainConfig[subDomain];
  }
}

const subdomainConfig: { [key: string]: HostSubdomainConfig } = {
  '192.168.1.203': {
    subDomain: 'localhost',
  },
  '192.168.0.121': {
    subDomain: 'localhost',
  },
  localhost: {
    subDomain: 'localhost',
    // publicShopConfig: [{
    //   name: 'Shop', segment: '', bookId: 'e-public-academy-ch-de_zfyilj8rimmd'
    // }, {
    //   name: 'Gastronomy', segment: 'gastronomy',
    //   children: [{
    //     name: 'Pauli Digital Solution', segment: "pauli", bookId: "e-p-xm-pauli-test_0v9f5l7rq3g4"
    //   }]
    // }]
  },
  app: {
    subDomain: 'app',
    gaTrackingCode: 'G-PEDDGKMNGG',
  },
  ef: {
    subDomain: 'ef',
    gaTrackingCode: 'G-V2FTKM689B',
    space: 'ef'
  },
  cosmic: {
    subDomain: 'cosmic',
    gaTrackingCode: 'G-EFCPEQGH88'
  },
  'www-cosmic': {
    subDomain: 'www-cosmic',
    gaTrackingCode: 'G-EFCPEQGH88',
    publicShopConfig: [{
      name: 'Shop', segment: '', bookId: 'e-public-academy-ch-de_zfyilj8rimmd'
    }, {
      name: 'Gastronomy', segment: 'gastronomy',
      children: [{
        name: 'Pauli Digital Solution', segment: "pauli", bookId: "e-p-xm-pauli-test_0v9f5l7rq3g4"
      }]
    }]
  },
  www: {
    subDomain: 'www',
    gaTrackingCode: 'G-EFCPEQGH88',
    publicShopConfig: [{
      name: '', segment: ''
    }, {
      name: 'Gastronomy', segment: 'gastronomy',
      children: [{
        name: 'Pauli Digital Solution', segment: "pauli", bookId: "e-p-xp-pauli-pd-shop_c2ywb_fqjc1_"
      }]
    }]
  },
  'ef-cosmic': {
    subDomain: 'ef-cosmic',
    gaTrackingCode: 'G-EFCPEQGH88',
    space: 'ef',
    publicShopConfig: [{
      name: '', segment: '', bookId: 'e-public-academy-ch-de_zfyilj8rimmd'
    }]
  },
  goldstuck: {
    subDomain: 'goldstuck',
    gaTrackingCode: 'G-JMQTW92RRS',
    space: 'goldstuck'
  },
  additiv: {
    subDomain: 'additiv',
    gaTrackingCode: 'G-XKZ21J3EBW',
    space: 'additiv'
  },
  electrosuisse: {
    subDomain: 'electrosuisse',
    space: 'electrosuisse'
  },
  vps: {
    subDomain: 'vps',
    space: 'vps'
  },
};

