<ng-container [ngSwitch]="notebook.subtype">

  <div *ngSwitchCase="NotebookTypes.LearningPath"
       class="w-100 h-100 cover-shadow position-relative learning-path"
       [ngClass]="size"
       (click)="onClick.emit($event)">
    @if (notebook?.coverImage) {
      <div class="w-100 h-100 learning-path-cover-mask">
        @if (coverImageWebp && notebook.coverImage) {
          <picture>
            <source type="image/webp" [attr.srcset]="coverImageWebp | rewriteUrlResize:'notebook-cover':size">
            <source type="image/jpg" [attr.srcset]="coverImageJpg | rewriteUrlResize:'notebook-cover':size">
            <img [attr.alt]="notebook.title"
                 [src]="coverImageJpg | rewriteUrlResize:'notebook-cover':size"
                 (error)="fallbackToSupportedCover($event)"
                 loading="lazy"/>
          </picture>
        } @else if (coverImageJpg) {
          <img [attr.alt]="notebook.title"
               [src]="coverImageJpg | rewriteUrlResize:'notebook-cover':size"
               (error)="fallbackToSupportedCover($event)"
               loading="lazy"/>
        }
      </div>
      <div class="position-absolute h-100"
           style="top: 0">
        <svg width="100%" height="100%" viewBox="0 0 140 200" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
          <path
            d="M59.3893503,75.125 C84.2355654,77.8450672 98.208917,75.499094 103.064636,87.5 C110.711562,106.399372 14.9434275,118.790964 4.51934661,153.832982 C-5.90473427,188.875 69.3136056,198 69.3136056,198"
            id="Path" fill="transparent" stroke="#FFFFFF" stroke-width="3" stroke-linecap="round"
            stroke-linejoin="round"></path>
        </svg>
      </div>
    } @else {
      <div>
        <bitmark-notebook-learning-path-cover [primaryColor]="notebook?.branding?.primaryColor"
                                              [viewBoxWidth]="140"
                                              [viewBoxHeight]="200">
        </bitmark-notebook-learning-path-cover>
      </div>
    }
  </div>
  <div *ngSwitchCase="NotebookTypes.DraftBook"
       class="h-100 cover-shadow draft-book"
       [ngClass]="size"
       (click)="onClick.emit($event)">
    @if (notebook?.coverImage) {
      <div class="h-100">
        <div class="overflow-hidden h-100 d-flex align-items-end">
          <picture>
            @if (coverImageWebp) {
              <source type="image/webp" [attr.srcset]="coverImageWebp">
            }
            <source type="image/jpg" [attr.srcset]="coverImageJpg">
            <img #imageElement
                 (load)="onImageLoad({imageElem: imageElement, checkIfWide: true})"
                 [attr.alt]="notebook.title"
                 [src]="coverImageJpg | rewriteUrlResize:'notebook-cover':size"
                 [width]='draftCoverImageWidth'
                 [height]='draftCoverImageHeight'
                 (error)="fallbackToSupportedCover($event)"
                 loading="lazy"/>
          </picture>
        </div>
      </div>
    } @else {
      <bitmark-book-default-cover [text]="notebook.title"
                                  [coverColor]='notebook?.coverColor'
                                  [isDraft]="true">
      </bitmark-book-default-cover>
    }
  </div>

  <div *ngSwitchCase="NotebookTypes.Worksheet"
       class="h-100 d-flex align-items-center">
    <img alt="Worksheet"
         src="assets/images/worksheets-2x.png"/>
  </div>
  <div *ngSwitchCase="NotebookTypes.Homework"
       class="h-100 d-flex align-items-center">
    <img alt="Homework"
         src="assets/images/worksheets-2x.png"/>
  </div>
  <div *ngSwitchCase="NotebookTypes.Flashcard"
       class="h-100 d-flex align-items-end">
    <img alt="Flashcard"
         src="assets/images/notebook/notebook-flashcards-cover.png"
         class="flashcards-img"
         [ngClass]="size"
         (click)="onClick.emit($event)"/>
  </div>

  <div *ngSwitchDefault class="w-100 h-100 position-relative cover-shadow" #notebookDefaultCover>
    <bitmark-notebook-standard-cover
      [notebook]="notebook"
      [coverImageJpg]="coverImageJpg"
      [coverImageWebp]="coverImageWebp"
      [isEmoji]="isEmoji"
      [showcaseLetter]="showcaseLetter"
      [size]="size"
      [adaptSize]="adaptSize"
      (onClick)="onClick.emit($event)"
      (fallbackToSupportedCover)="fallbackToSupportedCover($event)"
      (onImageLoaded)="onImageLoad($event)"
      class="w-100 h-100">
    </bitmark-notebook-standard-cover>
  </div>

</ng-container>
