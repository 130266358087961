@if (searchResults) {
  <div class="my-4 mx-1 mx-md-0">
    @if (searchResults?.length || searchQuery?.length > 0) {
      <div class="bits-wrapper px-sm-4 search-results-title">
        @if (searchResults?.length) {
          <div
            [ngClass]="{'pb-0 mb-0' : displayedActiveFiltersArr?.length, 'pb-2': !displayedActiveFiltersArr?.length}">
            <div>Showing
              @if (totalResults) {
                <span>{{ totalResults }}</span> Results
              }
              @if (searchQuery) {
                <span>for&nbsp;«{{ searchQuery }}»</span>
              }
            </div>
          </div>
        } @else {
          <div class="h4 font-weight-lighter"
               [innerHTML]="'Reader.Search.NoResultsFound' | translate">
          </div>
        }
        @if (displayedActiveFiltersArr?.length) {
          @for (k of displayedActiveFiltersArr; track k) {
            <div (click)="removeFilter(k)"
                 class="cursor-pointer chip d-inline-block mr-1 mb-1 px-1">
              <span>{{ k.text }}</span>
              <bitmark-icon name="close"
                            style="position: relative; top: 3px; margin-left: 2px"
                            class="size-13px text-white cursor-pointer">
              </bitmark-icon>
            </div>
          }
        }
      </div>
    }
    <div infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollUpDistance]="2"
         [infiniteScrollThrottle]="50"
         [alwaysCallback]="true"
         [infiniteScrollContainer]="'.infinite-scroll-container'"
         [scrollWindow]="false"
         (scrolled)="onScrollDown()"
         [fromRoot]="true"
         class="bits-wrapper px-sm-4">
      <div class="d-flex flex-column mt-5">
        @if (searchResults?.length) {
          <div class="mb-4">
            @for (bitWrapper of searchResults; track getBitId(bitWrapper)) {
              <div class='mb-4'>
                <bitmark-bit-wrapper context="search"
                                     [bitWrapper]="bitWrapper"
                                     [actions]="bitActions"
                                     (openResource)="onOpenResource($event)"
                                     (navigateToBook)="navigateToBook.emit($event)"
                                     (navigateToProduct)="navigateToProduct.emit($event)"
                                     (closeBook)="closeBook.emit()">
                </bitmark-bit-wrapper>
                @if (bitWrapper.tags) {
                  <div class="text-right reader-chip-container">
                    @for (tag of bitWrapper.tags; track tag) {
                      <span [innerText]="tag" class="reader-chip ml-1"></span>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
        <div class="reader-bottom-spacer"></div>
        @if (isLoadingBottom) {
          <div class="text-center p-2">
            <bitmark-progress-spinner></bitmark-progress-spinner>
          </div>
        }
      </div>
    </div>
  </div>
}
