<div class="d-none d-md-block unselectable">
  <div ngbDropdown
       id="app-dropdown"
       class="d-inline-block w-100 d-inline-block"
       #appDropdown="ngbDropdown"
       [container]="container"
       (openChange)="onOpenDesktopDropdownChange($event)"
       [placement]="placement"
       [autoClose]="autoClose"
       [ngStyle]="dropdownStyle">
    <div ngbDropdownToggle
         preventDefault
         [id]="id">
      <ng-container *ngTemplateOutlet="dropdownButton"></ng-container>
    </div>
    <div ngbDropdownMenu
         scrollAntiBlock
         [attr.aria-labelledby]="id"
         [ngClass]="dropdownClass">
      @if (actions || additionalContent) {
        <app-dropdown-desktop [actions]="actions"
                              [hasRawLabels]="hasRawLabels"
                              [additionalContent]="additionalContent"
                              (selected)="onOptionSelected($event)"
                              (closed)="close()">
        </app-dropdown-desktop>
      }
    </div>
  </div>
</div>

<div class="d-block d-md-none" style="padding-bottom: 3px">
  <div (click)="openDropdownMobile()">
    <ng-container *ngTemplateOutlet="dropdownButton"></ng-container>
  </div>
</div>
