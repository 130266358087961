<div [style.width]="bit.width ? bit.width + 'px !important' : undefined"
     [style.height]="bit.height ? bit.height + 'px !important' : undefined"
     class="iframely-embed-container">
  @if (iframeSrc) {
    <div class="iframely-card iframely-small iframely-embed">
      <div class="iframely-responsive">
        <iframe [src]="iframeSrc" allowfullscreen></iframe>
      </div>
    </div>
    <!--  <script async src="//cdn.iframe.ly/embed.js" charset="utf-8"></script>-->
  }
  <div class="bit-body bit-body-allow-highlight">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
</div>
