<div class="message-wrapper"
     [class.is-edit]="isBeingEditedByUser">
  <div class="px-3"
       [class.pt-3]="isResourceOnly || !isTextOnly"
       [class.pb-4]="isResourceOnly || !isTextOnly"
       [class.is-text-only]="isTextOnly && !isEmojiMessage"
       [class.is-emoji]="isEmojiMessage">
    @if (bit.resource && !isBeingEditedByUser) {
      <bitmark-resource [bitResource]="bit.resource"
                        [hostBit]="bit"
                        (open)="openResource.emit($event)"
                        [class.pb-4]="bit?.body"
                        class="d-block align-content-center justify-content-center">
      </bitmark-resource>
    }
    @if (isBeingEditedByUser) {
      <div class="position-relative d-flex align-content-center justify-content-center">
        @if (bit.resource) {
          <div>
            <bitmark-resource [bitResource]="bit.resource"
                              [hostBit]="bit"
                              (open)="openResource.emit($event)"
                              [class.pb-4]="bit?.body"
                              class="d-block image-editable">
            </bitmark-resource>
            @if (isBeingEditedByUser) {
              <bitmark-icon name="trash-can-outline"
                            class="size-30px remove-image"
                            (click)="removeImage()">
              </bitmark-icon>
            }
          </div>
        } @else {
          <div class="d-flex align-items-center justify-content-center image-placeholder"
               [class.mb-4]="bit?.body || isBeingEditedByUser"
               (click)="fileInput.click()">
            <bitmark-icon name="cloud-upload" class="size-30px"></bitmark-icon>
          </div>
        }
        <input accept="image/*" hidden type="file" [attr.multiple]="null" #fileInput (change)="uploadImage()">
      </div>
    }
    @if (bit?.body || isBeingEditedByUser) {
      <div class="bit-body-text"
           [ngClass]="fullWidth && 'text-body-full-width'">
        @if (!isBeingEditedByUser) {
          <bitmark-text [attr.data-bitmark-node]="'body'"
                        [text]="bit?.body"
                        [format]="bit.format">
          </bitmark-text>
        } @else {
          <bitmark-editable-text (onChange)="bit.body = $event"
                                 [text]="bit?.body"
                                 [format]="bit.format"
                                 [placeholder]="'Bits.PlaceholderBody' | translate"
                                 [allowNewline]="true">
          </bitmark-editable-text>
        }
      </div>
    }
  </div>
</div>
@if (isBeingEditedByUser) {
  <div class="p-2 edit-container">
    <div class="d-flex justify-content-center m-2">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
}
