@if (bit.resource && !bit?.resource?.article) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
@if (hasRefs) {
  <div class="book-references">
    @if (bit?.refBookTitle) {
      <span class="book-reference-items">
        <span class="book-reference-title">{{ bit.refBookTitle }}</span>
        @if (bit?.refAuthor?.length || bit?.refPublisher?.length) {
          <span>, </span>
        }
      </span>
    }
    <span class="book-reference-items book-reference-authors">
      @for (author of bit?.refAuthor; track author; let isLast = $last) {
        <span class="book-reference-item">{{ author }}</span>
        @if (!isLast || bit?.refPublisher?.length) {
          <span>, </span>
        }
      }
    </span>
    <span class="book-reference-items book-reference-publishers">
      @for (publisher of bit?.refPublisher; track publisher; let isLast = $last) {
        <span class="book-reference-item">{{ publisher }}</span>
        @if (!isLast) {
          <span>, </span>
        }
      }
    </span>
  </div>
}
<div class="bit-body bit-body-allow-highlight"
     [ngClass]="bit.type">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit.footer"
                [format]="bit.format">
  </bitmark-text>
}
