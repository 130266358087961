@if (bit.resource && !bit?.resource?.article) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    (open)="openResource.emit($event)"
                    class="d-block">
  </bitmark-resource>
}
<div class="bit-body bit-body-allow-highlight"
     [ngClass]="bit.type">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
@if (bit?.resource?.article) {
  <bitmark-resource-article [bitResourceArticle]="bit?.resource?.article"
                            class="d-block mt-1">
  </bitmark-resource-article>
}
