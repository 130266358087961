<ng-container>
  @if (displayedUsers?.length || isOrganizer) {
    <div class='d-flex flex-column align-items-start justify-content-start'>
      <div class='d-flex align-items-center justify-content-start mb-2'>
        <h4 [innerHTML]='label' class='font-weight-light mb-0 pb-0 mr-1' [style.color]='shopBranding?.primaryColor'></h4>
        <ng-template #dropdownButton>
          <div class='p-1 cursor-pointer'>
            <div class='dot' [ngClass]="{'calm': !isOrganizer, 'dot-manage': isOrganizer}"></div>
          </div>
        </ng-template>
        @if (isOrganizer) {
          <app-dropdown class='d-inline-block mr-3 user-list-dot-dropdown'
                        style='max-height: 24px; position: relative; top: -7px'
                        [placement]="['bottom-left', 'top-left', 'left']"
                        [actions]='listActions'
                        [dropdownButton]='dropdownButton'
                        dropdownClass='auto-width'>
          </app-dropdown>
        }
      </div>
      <div class='text-white'>
        @for (user of displayedUsers; track user.id) {
          <app-course-user-list-item [isOrganizer]='isOrganizer'
                                     [user]='user'
                                     [course]="course"
                                     [showEmails]="showEmails"
                                     [isParticipantsList]='isParticipantsList'
                                     [filterText]='filterText'
                                     [shopBranding]='shopBranding'
                                     (onResendInvitation)='resendInvitation($event)'
                                     (onBuyForUser)='buyForUser($event)'
                                     (onDeleteEmail)='deleteEmail($event)'
                                     (onEditEmail)='editEmail($event)'
                                     (onEditUser)='editUser($event)'>
          </app-course-user-list-item>
        }
        @for (removedUser of removedUsers; track removedUser) {
          <div class='text-red'>{{ removedUser }}</div>
        }
      </div>
    </div>
  }
  @if (isEditing) {
    <div class='d-flex mt-5'>
      <div class='text-white' style='position: relative; top: -12px;'>
        <div style='min-height: 200px; min-width: 480px; max-width: 800px'
             class='d-flex mb-0 orange-editable-field justify-content-between align-items-start py-2'>
        <span style='min-height: 200px; min-width: 440px; max-width: 90%' [contentEditable]='isEditing'
              [attr.data-placeholder]="(operation === 'add' ? 'Shop.Editor.AddSeveral' :
               'add-paid' ? 'Shop.Editor.BuyForUsers' :
               'add-free' ? 'Shop.Editor.JoinForUsers' : 'Shop.Editor.ReplaceAll') | translate"
              #editingLabel></span>
          <div class='d-flex flex-row align-items-start'
               style='min-width: 54px'>
            <img (click)='cancelEditing()'
                 src='assets/images/ts-editor/close.png'
                 class='translated-label-icon ml-2' />
            <img (click)='saveChanges()'
                 src='assets/images/ts-editor/ribbon-save.png'
                 class='translated-label-icon ml-1' />
          </div>
        </div>
      </div>
    </div>
  }
</ng-container>
