<div class='page-subscribe-container'>
  <form [formGroup]='form' (ngSubmit)='subscribe($event)'
        class='form mr-auto ml-auto my-2'>
    <div class='row no-gutters input-group align-items-center mt-4'>
      <div class='mb-5 w-100 page-subscribe-icon'>💌</div>
      <h3 class='page-subscribe-lead button-caption' [innerText]='bit?.buttonCaption || "Subscribe"'></h3>
      <label class='col-sm-12 page-subscribe-instruction' for='email'>
        Get new features delivered straight to your inbox
      </label>
      <input #email
             type='email'
             id='email'
             name='email'
             autocomplete='username'
             class='col-sm-12 form-control p-2'
             required
             [placeholder]="'Your email'"
             [pattern]='emailPattern'
             formControlName='email'>
    </div>
    <button class='subscribe-button d-flex flex-row justify-content-center align-items-center'>
      <span>Subscribe</span>
      <bitmark-icon
        name='arrow-right'
        class='size-18px font-weight-bold d-block text-white ml-1'>
      </bitmark-icon>
    </button>
  </form>
</div>

