<div>
  <div class="position-relative">
    <bitmark-resource #resourceComponent
                      [hostBit]="bit"
                      [bitResource]="bit?.resource || defaultResource"
                      [imageBit]="true"
                      [canBeEdited]="canBeEdited"
                      [showReplaceButton]="!bit?.resource"
                      (open)="openResource.emit($event)"
                      (changed)="updateBitResource($event)">
    </bitmark-resource>
  </div>
</div>
@if (bit?.body) {
  <div class="mt-4 bit-body bit-body-allow-highlight"
       [ngClass]="bit.type">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit?.format">
    </bitmark-text>
  </div>
}
@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit.footer"
                [format]="bit.format">
  </bitmark-text>
}
