import {LearningEventCommon, toggleAnimations} from '../learning-event.common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LearningEventCompletionBit} from './learning-event-completion.models';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {BitmarkTextContentService} from '../../../shared';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {ReaderLeContentService} from '../../../reader/reader-book/reader-content/reader-le-content.service';
import {JSONContent} from '@tiptap/core';

@Component({
  selector: 'bitmark-learning-event-completion',
  templateUrl: './learning-event-completion.component.html',
  styleUrls: ['./learning-event-completion.component.scss'],
  animations: toggleAnimations
})
export class LearningEventCompletionComponent extends LearningEventCommon implements OnInit {
  @Input() bit?: LearningEventCompletionBit;
  @Output() saveInlineBit = new EventEmitter<LearningEventCompletionBit>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    if (value) {
      setTimeout(() => {
        this.toggleState = 'expanded';
        this.toggleStateIsDone = 'expanded';
        this.isBodyCollapsed = false;
      });
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  isLeadEmpty = false;
  isItemEmpty = false;
  isBodyEmpty = false;

  constructor(readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              readerLeContentService: ReaderLeContentService) {
    super(readerTipTapService, bitmarkTextContentService, readerLeContentService);
  }

  ngOnInit() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.isBodyEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.body, this.bit?.format);

    this.initExpandedBit(this.bit.id);
  }

  onUpdateContent(bit: any, bitJson: JSONContent) {
    bit.body = bitJson?.content;
    bit.format = BitmarkFormat.PP;
    this.saveChanges();
  }

  saveChanges() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.isBodyEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.body, this.bit?.format);

    this.saveInlineBit.emit(this.bit);
  }
}
