<div class="d-none d-md-block unselectable">
  <div ngbDropdown
       id="app-dropdown"
       class="d-inline-block w-100 d-inline-block"
       #appDropdown="ngbDropdown"
       [container]="container"
       (openChange)="onOpenDesktopDropdownChange($event)"
       [placement]="placement"
       [autoClose]="autoClose"
       [ngStyle]="dropdownStyle">
    <div ngbDropdownToggle
         [id]="id">
      <ng-container *ngTemplateOutlet="dropdownButton"></ng-container>
    </div>
    <div ngbDropdownMenu
         scrollAntiBlock
         [stopPropagation]="scrollAntiBlockStopPropagation"
         [ngClass]="dropdownClass"
         [attr.aria-labelledby]="id">
      @if (actions) {
        <bitmark-dropdown-desktop [actions]="actions"
                                  [additionalContent]="additionalContent"
                                  (selected)="onOptionSelected($event)"
                                  (closed)="close()">
        </bitmark-dropdown-desktop>
      }
    </div>
  </div>
</div>

<div class="d-block d-md-none">
  <div (click)="openDropdownMobile(actions)">
    <ng-container *ngTemplateOutlet="dropdownButton"></ng-container>
  </div>
</div>
