<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-end align-items-start w-100 px-md-2 pt-md-1">
    <button class="bg-transparent border-0 p-0" (click)="dismiss()">
      <bitmark-icon name="close" class="size-20px text-black"></bitmark-icon>
    </button>
  </div>
  <div class="modal-body p-0">
    <div class="w-100 h-100 text-center">
      @if (url) {
        <iframe width="100%" height="100%" frameborder="0" [src]="url"></iframe>
      }
    </div>
  </div>
</div>
