@if (bitResourceVideo?.src) {
  <figure class="text-center resource-container">
    <div class="w-100 position-relative overflow-hidden d-flex align-items-center justify-content-center"
         [ngClass]="videoCss">
      <video #videoElement
             [style.opacity]="isVideoVisible ? 1 : 0"
             [style.aspect-ratio]="aspectRatio"
             [src]="bitResourceVideo.src"
             controls
             controlsList="nodownload"
             playsinline
             class="w-100"
             (loadedmetadata)="onVideoMetadataLoaded($event)">
        Your browser does not support the video element.
      </video>
      @if (bitResourceImage && !isVideoVisible) {
        <div [ngClass]="videoCss"
             class="cursor-pointer"
             (click)="startVideoPlay()">
          <bitmark-resource-image [bitResourceImage]="bitResourceImage"
                                  [imageMaxWidth]="0"
                                  class="position-absolute d-block w-100 h-100 tl-0 cover-transform">
            <div class="w-100 h-100 bg-light-gray"></div>
          </bitmark-resource-image>
          <div class="position-absolute d-flex w-100 h-100 tl-0">
            <div class="play-button-icon-play"></div>
            <bitmark-icon name="youtube"
                          class="m-auto size-80px play-button">
            </bitmark-icon>
          </div>
        </div>
      }
      @if (playError) {
        <div class="position-absolute d-flex w-100 h-100 tl-0">
          <span class="bg-danger text-white m-auto error p-2 text-center" [innerHTML]="playError"></span>
        </div>
      }
    </div>
    @if (bitResourceVideo?.caption && !isBeingEditedByUser) {
      <figcaption class="resource-video-caption">
        <bitmark-text [text]="bitResourceVideo.caption"
                      [format]="bitFormat">
        </bitmark-text>
      </figcaption>
    } @else if (isBeingEditedByUser) {
      <bitmark-reader-simple-text-editor class="w-100 image-caption"
                                         [bit]="bit"
                                         [bitContent]="bitResourceVideo.caption"
                                         [placeholder]="'Shared.Caption' | translate"
                                         [isReadOnly]="false"
                                         (updateBit)="onCaptionChanged($event)">
      </bitmark-reader-simple-text-editor>
    }
    @if (bitResourceVideo?.copyright) {
      <figcaption class="resource-video-caption">
        <bitmark-text [text]="bitResourceVideo.caption"
                      [format]="bitFormat">
        </bitmark-text>
      </figcaption>
    }
  </figure>
}
