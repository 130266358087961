<div class="d-flex">
  <div class="align-self-end user-avatar">
    <bitmark-user-box-photo [iconUrl]="bit?.partner?.avatarImage?.src"
                            [iconAlt]="bit?.partner?.avatarImage?.alt"
                            [fullName]="bit?.partner?.name"
                            [size]="'md'"
                            [alwaysShowIcon]="false"
                            [showName]="true">
    </bitmark-user-box-photo>
  </div>

  <blockquote class="bit-body conversation-text-wrapper rectangle-speech-lighting"
              [ngClass]="hasAvatarIcon ? 'with-icon' : 'without-icon'"
              [class.with-partner]="hasPartner"
              #bubble>
    <bitmark-text
      [format]="bit?.format"
      [text]="bit?.body"
      [cssClass]="'centered-list'">
    </bitmark-text>
  </blockquote>
</div>
