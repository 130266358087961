<div class="contextual-menu-absolute pos-absolute pr-1 cursor-pointer d-hidden"
     id="contextual-menu-absolute-list">
  <div class="reader-shadow tiptap-bubble-menu-list text-left">
      <span class="mx-2 position-relative list-indent-icon" (click)="editor.can().liftListItem('listItem') ? editor.chain().focus().liftListItem('listItem').run() : editor.chain().focus().liftListItem('taskItem').run()">
        <img class="tiptap-bubble-menu-icon" src="assets/images/icon-level-dec.png"/>
      </span>
    <span class="mx-2 position-relative list-indent-icon" (click)="editor.can().sinkListItem('listItem') ? editor.chain().focus().sinkListItem('listItem').run() : editor.chain().focus().sinkListItem('taskItem').run()">
      <img class="tiptap-bubble-menu-icon" src="assets/images/icon-level-inc.png"/>
      </span>
    <ng-template #tiptapListBubbleMenuDropdown>
      <span dropdownButton class="cursor-pointer contextual-menu-list-type contextual-menu-trigger ml-2 mr-1">{{ 'Reader.Editor.List' | translate }}</span>
    </ng-template>
    <bitmark-dropdown class="d-inline-block ml-auto float-right"
                      dropdownClass="lg reader-text-editor"
                      container="body"
                      [actions]="menuActions"
                      [dropdownButton]="tiptapListBubbleMenuDropdown">
    </bitmark-dropdown>
  </div>
</div>

<ng-template #tiptapListBubbleMenu>
  <div class="tiptap-menu text-left">
    <div>
      <button class="tiptap-bubble-item p-1 pl-2 w-100 text-left"
              (click)="editor.chain().focus().toggleBulletList().run()"
              [ngClass]="editor.isActive('bulletList') ? 'is-active' : ''">
        <span style="width: 20px" class="text-center d-inline-block">•</span> {{ 'Reader.Editor.List' | translate }}
      </button>
    </div>
    <div>
      <button class="tiptap-bubble-item p-1 pl-2 w-100 text-left"
              (click)="editor.chain().focus().toggleOrderedList().run()"
              [ngClass]="editor.isActive('orderedList') ? 'is-active' : ''">
        <span style="width: 20px" class="text-center d-inline-block">1.</span> {{ 'Reader.Editor.NumberList' | translate }}
      </button>
    </div>
    <div>
      <button class="tiptap-bubble-item p-1 pl-2 w-100 text-left"
              (click)="editor.chain().focus().toggleLetteredList().run()"
              [ngClass]="editor.isActive('letteredList') ? 'is-active' : ''">
        <span style="width: 20px" class="text-center d-inline-block">A.</span> {{ 'Reader.Editor.LetterList' | translate }}
      </button>
    </div>
    <div>
      <button class="tiptap-bubble-item p-1 pl-2 w-100 text-left"
              (click)="editor.chain().focus().toggleLetteredList().run()"
              [ngClass]="editor.isActive('letteredListLower') ? 'is-active' : ''">
        <span style="width: 20px" class="text-center d-inline-block">a.</span> {{ 'Reader.Editor.LetterList' | translate }}
      </button>
    </div>
    <div>
      <button class="tiptap-bubble-item p-1 pl-2 w-100 text-left"
              (click)="editor.chain().focus().toggleOrderedListRomanLower().run()"
              [ngClass]="editor.isActive('orderedListRoman') ? 'is-active' : ''">
        <span style="width: 20px" class="text-center d-inline-block">I.</span> {{ 'Reader.Editor.RomanList' | translate }}
      </button>
    </div>
    <div>
      <button class="tiptap-bubble-item p-1 pl-2 w-100 text-left"
              (click)="editor.chain().focus().toggleOrderedListRoman().run()"
              [ngClass]="editor.isActive('orderedListRomanLower') ? 'is-active' : ''">
        <span style="width: 20px" class="text-center d-inline-block">i.</span> {{ 'Reader.Editor.RomanList' | translate }}
      </button>
    </div>
    <div>
      <button class="tiptap-bubble-item p-1 pl-2 w-100 text-left"
              (click)="editor.chain().focus().toggleTaskList().run()"
              [ngClass]="editor.isActive('taskList') ? 'is-active' : ''">
          <span style="width: 20px" class="text-center d-inline-block"><input style="width: 20px"
                                                                              type="checkbox"/></span> {{ 'Reader.Editor.TaskList' | translate }}
      </button>
    </div>
    <div>
      <button class="tiptap-bubble-item p-1 pl-2 w-100 text-left"
              (click)="editor.chain().focus().toggleNoBulletList().run()"
              [ngClass]="editor.isActive('noBulletList') ? 'is-active' : ''">
        <span class=" d-inline-block" style="width: 20px"></span> {{ 'Reader.Editor.NoBullets' | translate }}
      </button>
    </div>
    <div class="tiptap-menu-separator"></div>
    <div>
      <button class="tiptap-bubble-item p-1 pl-2 d-block w-100 text-left" (click)="deleteList()">
        <div class="d-inline-block" style="width: 20px"></div>
        {{ 'Shared.Delete' | translate }}
      </button>
    </div>
  </div>
</ng-template>
