<div class="learning-path-video-call-header"
     [ngClass]="{
      'learning-path-video-call-header-minimized': isHeaderMinimized,
      'learning-path-video-call-header-only': isHeaderOnly
     }">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column scheduled-date">
      <div (click)="isBeingEditedByUser && d.toggle()"
           [ngClass]="isBeingEditedByUser && 'cursor-pointer'">{{ bit.date | bitmarkDate:'LLLL' }}
      </div>
      <div class="date-text-lg font-weight-bold"
           (click)="isBeingEditedByUser && d.toggle()"
           [ngClass]="isBeingEditedByUser && 'cursor-pointer'">{{ (bit.date | bitmarkDate:'d') || '?' }}
      </div>
      <div (click)="isBeingEditedByUser && d.toggle()"
           [ngClass]="isBeingEditedByUser && 'cursor-pointer'">{{ bit.date | bitmarkDate:'E' }}
      </div>
      <input
        [(ngModel)]="bit.date"
        ngbDatepicker
        #d="ngbDatepicker"
        container="body"
        class="scheduled-date-edit-input"/>
    </div>
    <div class="p-2 d-flex header-content">
      <div class="header-text mr-1">
        <div class="font-weight-bold text-truncate">
          @if (!isBeingEditedByUser) {
            <div class="learning-path-item">
              @if (bit.item) {
                <bitmark-text [text]="bit.item"></bitmark-text>
              }
            </div>
          } @else {
            <div class="learning-path-item">
              <bitmark-editable-text
                (onChange)="bit.item = $event"
                [text]="bit.item"
                [placeholder]="'Bits.Item' | translate">
              </bitmark-editable-text>
            </div>
          }
          @if (!isBeingEditedByUser) {
            <div class="font-weight-bold learning-path-instruction">
              <bitmark-text [text]="bit.instruction"></bitmark-text>
            </div>
          } @else {
            <div class="font-weight-bold learning-path-instruction">
              <bitmark-editable-text
                (onChange)="bit.instruction = $event"
                [text]="bit.instruction"
                [placeholder]="'Bits.Instruction' | translate">
              </bitmark-editable-text>
            </div>
          }
        </div>
        <div class="d-flex flex-row learning-path-header-details">
          @if (bit.action || isBeingEditedByUser) {
            <div class="mr-1"
                 [ngClass]="!isBeingEditedByUser && 'text-truncate'">
              @if (!isBeingEditedByUser) {
                <bitmark-text [text]="bit.action"
                              [truncate]="true">
                </bitmark-text>
              } @else {
                <bitmark-editable-text (onChange)="bit.action = $event"
                                       [text]="bit.action"
                                       [placeholder]="'Bits.Action' | translate">
                </bitmark-editable-text>
              }
            </div>
          }
          @if ((bit.action && ((bit.date | bitmarkDate) || bit.location)) || isBeingEditedByUser) {
            <div class="mr-1">&bull;</div>
          }
          @if ((bit.date | bitmarkDate) || isBeingEditedByUser) {
            <div class="mr-1 d-flex flex-row time-display">
              @if (!isBeingEditedByUser) {
                <span>{{ bit.date | bitmarkDate:'shortTime' }}</span>
              } @else {
                <bitmark-time-editor [(ngModel)]="learningPathVideoCallBitTime"
                                     placeholder="{{'Bits.From' | translate}}"
                                     ngDefaultControl>
                </bitmark-time-editor>
              }
              @if ((bit.dateEnd | bitmarkDate) || isBeingEditedByUser) {
                <div>
                  <span class="mx-1">-</span>
                  @if (!isBeingEditedByUser) {
                    <span>{{ bit.dateEnd | bitmarkDate:'shortTime' }}</span>
                  } @else {
                    <bitmark-time-editor [(ngModel)]="learningPathVideoCallBitEndTime"
                                         placeholder="{{'Bits.To' | translate}}"
                                         ngDefaultControl>
                    </bitmark-time-editor>
                  }
                </div>
              }
            </div>
          }
          @if (bit.location || isBeingEditedByUser) {
            <div class="mr-1"
                 [ngClass]="!isBeingEditedByUser && 'text-truncate'">
              @if (!isBeingEditedByUser) {
                <bitmark-text [text]="bit.location"
                              [truncate]="true">
                </bitmark-text>
              } @else {
                <bitmark-editable-text (onChange)="bit.location = $event"
                                       [text]="bit.location"
                                       [placeholder]="'Bits.Location' | translate">
                </bitmark-editable-text>
              }
            </div>
          }
          @if ((
            (
              bit.action
              || bit.location
              || (bit.date | bitmarkDate)
            )
            && bit.duration
            && !(bit.dateEnd | bitmarkDate)
          )
          || (isBeingEditedByUser && !(bit.dateEnd | bitmarkDate))) {
            <div class="mr-1">&bull;</div>
          }
          @if ((bit.duration || isBeingEditedByUser) && !(bit.dateEnd | bitmarkDate)) {
            <div class="mr-1 header-duration"
                 [ngClass]="!isBeingEditedByUser && 'text-truncate'">
              @if (!isBeingEditedByUser) {
                <span>{{ bit.duration | amDuration }}</span>
              } @else {
                <bitmark-duration-editor ngDefaultControl
                                         [(ngModel)]="bit.duration">
                </bitmark-duration-editor>
              }
            </div>
          }
          @if ((bit.date | bitmarkDate) && !isBeingEditedByUser) {
            <div class="cursor-pointer d-flex align-items-center"
                 (click)="downloadAddToCalendar()">
              <bitmark-icon name="calendar-event" class="size-18px"></bitmark-icon>
            </div>
          }
        </div>
      </div>
      @if (bit.answer?.isDone && isTracked && !bit.isInfoOnly) {
        <div class="is-done-check d-flex align-items-center"
             (click)="reset($event)">
          <bitmark-icon name="check-circle-fill" class="size-30px"></bitmark-icon>
        </div>
      }
    </div>
  </div>
</div>
@if (isContentVisible || isBeingEditedByUser) {
  <div [ngClass]="isBeingEditedByUser && 'body-container-edit-mode'">
    <div>
      @if (bit?.body || isBeingEditedByUser) {
        <div class="bit-body p-2 bit-body-allow-highlight">
          @if (!isBeingEditedByUser) {
            <bitmark-text [text]="bit?.body"
                          [format]="getBodyFormat(bit.body, bit.format)">
            </bitmark-text>
          } @else {
            <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                        [bit]="bit"
                                        [bitContent]="bit.body | bitmark:bit.format:'json'"
                                        [isReadOnly]="false"
                                        [placeholder]="'Bits.PlaceholderBody' | translate"
                                        class="w-100">
            </bitmark-reader-text-editor>
          }
        </div>
      }
      @if (bit.videoCallLink && !bit.isInfoOnly) {
        <div class="d-flex justify-content-center pb-4"
             [ngClass]="!bit?.body ? 'pt-4' : 'pt-2'">
          <button class="btn btn-action bit-button text--ellipsis d-inline-block"
                  [innerHTML]="'Reader.Actions.JoinCall' | translate"
                  (click)="joinCall()">
          </button>
          @if (bit.answer?.hasJoined && !bit.answer?.isDone && isTracked) {
            <button class="btn btn-action bit-button text--ellipsis d-inline-block ml-2"
                    [innerHTML]="'Shared.MarkAsDone' | translate"
                    (click)="markAsDone()">
            </button>
          }
        </div>
      }
    </div>
  </div>
}
@if (isBeingEditedByUser) {
  <div class="p-2 edit-container">
    <input type="text" class="form-control mb-1" placeholder="https://..."
           [(ngModel)]="bit.videoCallLink"/>
    <div class="d-flex justify-content-center m-2 mt-4">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button text--ellipsis d-inline-block state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
}
