@if (user) {
  <div class='btn-link cursor-pointer d-flex flex-row justify-content-start align-items-center'
       [ngClass]="{'text-gray': !user.id}" [style.color]='user?.id ? shopBranding?.linkColor : ""'>
    @if (!isEditing) {
      <div>
        <span [ngClass]="{'text-green': user?.status === 'new', 'text-gradient': user?.status === 'redundant'}">
          @if (user.fullName) {
            <span (click)='addOrganizer(user)'>{{ user.fullName }}</span>
          }
          @if (user.fullName && isOrganizer) {
            <span> <</span>
          }
          @if (user.email && isOrganizer) {
            <span (click)='addOrganizer(user)' [innerHTML]='highlightedEmail'></span>
          }
          @if (user.fullName && isOrganizer) {
            <span>></span>
          }
        </span>
        @for (emoji of user?.emoji; track emoji) {
          <span>{{ emoji }}</span>
        }
      </div>
    } @else {
      <span class='d-flex mb-0 justify-content-between align-items-center py-2'
            [ngClass]='{"translated-label": !isEditing, "orange-editable-field": isEditing}'>
                <span [contentEditable]='isEditing' #editingLabel>{{ user.email }}</span>
      <div class='d-flex flex-row align-items-center'
           style='min-width: 54px'>
        <img (click)='cancelEditing()'
             src='assets/images/ts-editor/close.png'
             class='translated-label-icon ml-2'/>
        <img (click)='saveEmail()'
             src='assets/images/ts-editor/ribbon-save.png'
             class='translated-label-icon ml-1'/>
      </div>
    </span>
    }
<!--    @if (isOrganizer) {-->
<!--      <ng-template #dropdownButton>-->
<!--        <div class='px-1 cursor-pointer'>-->
<!--          <div class='dot orange'></div>-->
<!--        </div>-->
<!--      </ng-template>-->
<!--      <app-dropdown class='d-inline-block mr-3 user-dot-dropdown'-->
<!--                    [placement]="['bottom-left', 'top-left', 'left']"-->
<!--                    [actions]='userActions'-->
<!--                    [dropdownButton]='dropdownButton'-->
<!--                    dropdownClass='auto-width'>-->
<!--      </app-dropdown>-->
<!--    }-->
  </div>
}
<ng-template #emojiMenu>
  <div class='emoji-menu'>
    <div class='d-flex flex-row justify-content-between align-items-center'>
      <button class='emoji-item' (click)="toggleEmoji('✅')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('✅') !== -1}">
        <strong>✅</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('❌')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('❌') !== -1}">
        <strong>❌</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('🥇')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('🥇') !== -1}">
        <strong>🥇</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('😃')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('😃') !== -1}">
        <strong>😃</strong>
      </button>
    </div>
    <div class='d-flex flex-row justify-content-between align-items-center' style='margin-top: 6px'>
      <button class='emoji-item' (click)="toggleEmoji('🚀')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('🚀') !== -1}">
        <strong>🚀</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('🧠')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('🧠') !== -1}">
        <strong>🧠</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('✍️')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('✍️') !== -1}">
        <strong>✍</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('👍️')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('👍️') !== -1}">
        <strong>👍</strong>
      </button>
    </div>
  </div>
</ng-template>
