<div class="lti-header p-2"
     [ngClass]="{
      'lti-header-minimized': isHeaderMinimized,
      'lti-header-only': isHeaderOnly
     }">
  <div class="d-flex flex-row">
    @if (isContentVisible) {
      <div [ngClass]="(imageExists || isBeingEditedByUser) && 'learning-path-bit-image mr-2'">
        @if (imageExists) {
          <div class="position-relative h-100"
               [ngClass]="isBeingEditedByUser && 'image-editable'">
            <div class="image-container">
              <img [src]="bit.resource.image.src"
                   [alt]="bit.resource.image.alt"
                   (error)="handleImageLoadError()"/>
            </div>
            @if (isBeingEditedByUser) {
              <bitmark-icon name="trash-can-outline"
                            class="size-30px remove-image"
                            (click)="removeImage()">
              </bitmark-icon>
            }
          </div>
        } @else if (isBeingEditedByUser) {
          <div class="d-flex align-items-center justify-content-center image-placeholder"
               [ngClass]="isBeingEditedByUser && 'image-editable'"
               (click)="isBeingEditedByUser && fileInput.click()">
            <bitmark-icon name="cloud-upload" class="size-30px"></bitmark-icon>
          </div>
        }
        <input accept="image/*" hidden type="file" [attr.multiple]="null" #fileInput (change)="uploadImage()">
      </div>
    }
    <div class="lti-header-data">
      <div>
        <div class="font-weight-bold text-truncate">
          @if (isBeingEditedByUser) {
            <div class="learning-path-item">
              <bitmark-editable-text
                (onChange)="bit.item = $event"
                [text]="bit.item"
                [placeholder]="'Bits.Item' | translate">
              </bitmark-editable-text>
            </div>
            <div class="font-weight-bold learning-path-instruction">
              <bitmark-editable-text
                (onChange)="bit.instruction = $event"
                [text]="bit.instruction"
                [placeholder]="'Bits.Instruction' | translate">
              </bitmark-editable-text>
            </div>
          } @else {
            <div class="learning-path-item">
              @if (bit.item) {
                <bitmark-text [text]="bit.item"></bitmark-text>
              }
            </div>
            <div class="font-weight-bold learning-path-instruction">
              <bitmark-text [text]="bit.instruction"></bitmark-text>
            </div>
          }
        </div>
      </div>
      <div class="d-flex flex-row learning-path-header-details">
        @if (bit.action || isBeingEditedByUser) {
          <div class="mr-1 header-details-column"
               [ngClass]="!isBeingEditedByUser && 'text-truncate'">
            @if (isBeingEditedByUser) {
              <bitmark-editable-text
                (onChange)="bit.action = $event"
                [text]="bit.action"
                [placeholder]="'Bits.Action' | translate">
              </bitmark-editable-text>
            } @else {
              <bitmark-text [text]="bit.action"
                            [truncate]="true">
              </bitmark-text>
            }
          </div>
        }
        @if ((bit.location && bit.action) || isBeingEditedByUser) {
          <div class="mr-1">&bull;
          </div>
        }
        @if (bit.location || isBeingEditedByUser) {
          <div class="mr-1"
               [ngClass]="!isBeingEditedByUser && 'text-truncate'">
            @if (isBeingEditedByUser) {
              <bitmark-editable-text
                (onChange)="bit.location = $event"
                [text]="bit.location"
                [placeholder]="'Bits.Location' | translate">
              </bitmark-editable-text>
            } @else {
              <bitmark-text [text]="bit.location"
                            [truncate]="true">
              </bitmark-text>
            }
          </div>
        }
        @if (((bit.action || bit.location) && bit.duration) || isBeingEditedByUser) {
          <div class="mr-1">&bull;
          </div>
        }
        @if (bit.duration || isBeingEditedByUser) {
          <div class="header-details-column"
               [ngClass]="!isBeingEditedByUser && 'text-truncate'">
            @if (isBeingEditedByUser) {
              <bitmark-duration-editor ngDefaultControl
                                       [(ngModel)]="bit.duration">
              </bitmark-duration-editor>
            } @else {
              <span>{{ bit.duration | amDuration }}</span>
            }
          </div>
        }
      </div>
    </div>
    @if (bit.answer?.isDone && isTracked && !bit.isInfoOnly) {
      <div class="lti-check d-flex align-items-center ml-1"
           (click)="reset($event)">
        <bitmark-icon name="check-circle-fill" class="size-30px"></bitmark-icon>
      </div>
    }
  </div>
  @if (!isContentVisible && bit.deepLink) {
    <div>
      <div class="d-flex justify-content-center pt-2">
        <button class="btn btn-action bit-button mr-1"
                (click)="open($event)"
                [innerHTML]="'Shared.Open' | translate">
        </button>
      </div>
    </div>
  }
</div>
@if (isContentVisible) {
  <div [ngClass]="isBeingEditedByUser && 'lti-body-container-edit-mode'">
    <div>
      @if (bit?.body || isBeingEditedByUser) {
        <div class="bit-body p-2 bit-body-allow-highlight">
          @if (isBeingEditedByUser) {
            <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                        [bit]="bit"
                                        [bitContent]="bit.body | bitmark:bit.format:'json'"
                                        [isReadOnly]="false"
                                        [placeholder]="'Bits.PlaceholderBody' | translate"
                                        class="w-100">
            </bitmark-reader-text-editor>
          } @else {
            <bitmark-text [text]="bit?.body"
                          [format]="getBodyFormat(bit.body, bit.format)">
            </bitmark-text>
          }
        </div>
      }
      @if (bit.deepLink && !bit.isInfoOnly) {
        <div class="d-flex justify-content-center pb-4"
             [ngClass]="!bit?.body ? 'pt-4' : 'pt-2'">
          <button class="btn btn-action bit-button text--ellipsis d-inline-block mr-1"
                  (click)="open($event)"
                  [innerHTML]="'Shared.Open' | translate">
          </button>
          @if (bit.answer?.hasOpened && !bit.answer?.isDone && isTracked) {
            <button class="btn btn-action bit-button text--ellipsis d-inline-block ml-2"
                    [innerHTML]="'Shared.MarkAsDone' | translate"
                    (click)="markAsDone()">
            </button>
          }
        </div>
      }
    </div>
  </div>
}
@if (isBeingEditedByUser) {
  <div class="p-2 edit-container">
    <div class="font-weight-bold-sm">{{ selectedResource?.title || ('Bits.NoResourceSelected' | translate) }}</div>
    <div class="d-flex justify-content-center m-2">
      <button class="btn btn-action bit-button text--ellipsis d-inline-block state-choose-button"
              [innerHTML]="'Shared.ChooseContinue' | translate"
              (click)="openDeepLink()">
      </button>
    </div>
    <div class="d-flex justify-content-center m-2 mt-4">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button text--ellipsis d-inline-block state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
}
