import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IdentityService, SubSink} from 'core';
import {environment} from 'environment';


@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit, OnDestroy {
  code: string;
  space: string;
  buttonTitle: string;
  backgroundColor: string;
  messages: Array<{ content: string, cssClass?: string }>;
  showDontPanicImg: boolean;
  continueLink: string;

  private subSink = new SubSink();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private identityService: IdentityService) {
  }

  ngOnInit() {
    this.subSink.sink = this.activatedRoute.queryParams.subscribe(params => {
      this.code = params.code || '404';
      this.space = params.space || 'personal';
      this.continueLink = params.continueLink || '';
      this.buttonTitle = this.code === '401'
        ? 'Shared.Retry'
        : this.continueLink
          ? 'Shared.TryAgain'
          : 'Shared.Continue';
      this.backgroundColor = params.color
        || (this.code.startsWith('5') ? '#000' : '#e6137a');
      this.showDontPanicImg = ['Cosmic', 'Development'].includes(environment.name) && this.code.startsWith('5');
      this.messages = [{
        content: this.code === '0'
          ? ''
          : this.code === '403'
            ? `${this.code} Logged out`
            : this.code === '401'
              ? `${this.space.toUpperCase()}.${this.code} Access Denied`
              : this.code === '404'
                ? `${this.code} Not found`
                : `${this.code} Ups`,
        cssClass: 'h1'
      }]
        .concat(Object.keys(params)
          .filter(k => ['reason'].includes(k))
          .filter(k => k != 'continueLink')
          .map(k => {
            return {
              content: params[k],
              cssClass: 'h4'
            }
          }))
        .concat(Object.keys(params)
          .filter(k => !['color', 'code', 'space', 'reason'].includes(k))
          .filter(k => k != 'continueLink')
          .map(k => {
            return {
              content: params[k],
              cssClass: 'font-weight-bold'
            }
          }))
        .concat([{
          content: `Get More Brain v.${environment.version}`,
          cssClass: 'small mt-6'
        }])
        .filter(x => !!x);
    });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  handleContinue() {
    if (this.continueLink) {
      window.location.href = this.continueLink;
    } else if (this.code === '403') {
      this.router.navigateByUrl('/logout');
    } else if (this.code === '401') {
      this.router.navigateByUrl(`/space/${this.space}/login`);
    } else if (this.code.startsWith('5')) {
      this.router.navigateByUrl(`/space/${this.identityService.getSpace()}/workspace`);
    } else {
      this.router.navigateByUrl(`/space/${this.identityService.getSpace()}/my-library`);
    }
  }
}

