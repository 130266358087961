import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {combineLatest, from, fromEvent, Observable, tap} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {JSONContent} from '@tiptap/core';
import * as bpgLib from '@gmb/bitmark-parser-generator/dist/browser/bitmark-parser-generator.min.js';
import {BitmarkParserGenerator, BitmarkVersionType} from '@gmb/bitmark-parser-generator';
import {SubSink} from '../../../shared';
import {BaseBit, BitApiWrapper, BitType} from '../../../bits/bits.models';
import {BitbookApiService} from '../../bitbook-api.service';
import {BrandingDefaultThemes} from '../../../shared/models/bitmark.models';
import {BrandingRenderService} from '../../branding-render.service';
import {Publisher} from '../../publisher.models';
import {PublisherApiService} from '../../publisher-api.service';
import {BitmarkConfig} from '../../../bitmark.module';
import {ParserApiService} from '../../parser-api.service';

interface ParserBit extends BitApiWrapper {
  parser?: {
    errors?: Array<{
      location?: {
        start: {
          line: number,
          column: number
        },
        end: {
          line: number,
          column: number
        }
      },
      message?: string
    }>
  };
}

@Component({
  selector: 'bitmark-bit-editor',
  templateUrl: './bit-editor.component.html',
  styleUrls: ['./bit-editor.component.scss']
})
export class ReaderBitEditorComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('codeEditor') codeEditorElement: ElementRef;
  @Input() bookExternalId: string;
  @Input() editingBitBitmark: string;
  @Input() afterBitId: string;
  @Input() bitId: string;
  @Input() bitData?: BaseBit;
  @Input() bitBitmarkVersion?: string;
  @Input() isOpen = false;
  @Input() themeId = '';
  @Input() publisherId?: number;
  @Input() isEditingWholeBook = false;
  @Input() convertToBitmarkIfEmpty = false;
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();

  parsedBits: Array<ParserBit>;
  allBitmarkBits: Array<BitApiWrapper>;
  saveDisabled = false;
  bitEditNotSupportedError: string;
  private publishers: Array<Publisher>;
  private publishers$: Observable<Array<Publisher>>;
  private parserGenerator: BitmarkParserGenerator;
  private subSink = new SubSink();
  pasteListener: () => void;

  BitType = BitType;

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private parserApiService: ParserApiService,
              private brandingRenderService: BrandingRenderService,
              private bitbookApiService: BitbookApiService,
              private translateService: TranslateService,
              private publishersApiService: PublisherApiService) {
    this.parserGenerator = new bpgLib.BitmarkParserGenerator();
  }

  ngOnInit() {
    if (this.bitData) {
      this.bitEditNotSupportedError = this.checkEditBitSupportedFeatures(this.bitData);
      this.saveDisabled = !!this.bitEditNotSupportedError;
    }

    this.publishers$ = this.publishersApiService.getPublishers()
      .pipe(tap((data) => {
        this.publishers = data;
      }));

    let bitmark$;
    if (this.isEditingWholeBook) {
      bitmark$ = this.bitbookApiService.getBookBitmark(this.bookExternalId)
        .pipe(tap((res) => {
          this.editingBitBitmark = res;
        }, (err) => {
          console.log(err);
        }));
    } else {
      if (!this.editingBitBitmark?.trim() && this.convertToBitmarkIfEmpty && this.bitId) {
        bitmark$ = this.bitbookApiService.convertToBitmark(this.bitId)
          .pipe(tap(data => {
            this.editingBitBitmark = data.bitmark;
          }));
      }
    }

    if (bitmark$) {
      this.subSink.sink = combineLatest(bitmark$, this.publishers$)
        .subscribe(() => {
          this.showPreview(this.editingBitBitmark);
        });
    } else {
      this.subSink.sink = this.publishers$.subscribe();
    }
  }

  ngAfterViewInit() {
    if (!this.isEditingWholeBook) {
      this.subSink.sink = fromEvent(this.codeEditorElement.nativeElement, 'input')
        .pipe(debounceTime(200), distinctUntilChanged())
        .subscribe(() => this.showPreview(), err => console.log(err));
    }
    if (this.editingBitBitmark) {
      this.subSink.sink = this.publishers$.subscribe(() => {
        this.showPreview();
      });
    }
  }

  ngOnDestroy() {
    if (this.pasteListener) {
      this.pasteListener();
    }

    this.subSink.unsubscribe();
  }

  showPreview(defaultBitmark = '') {
    const text = this.codeEditorElement.nativeElement.value || defaultBitmark;

    const options = {
      bitmarkVersion: (this.bitBitmarkVersion && !isNaN(Number(this.bitBitmarkVersion)) ? Number(this.bitBitmarkVersion) : 2) as BitmarkVersionType
    };

    let bitmarkVersion: BitmarkVersionType = null;
    const trimmedText = text.trim().toLowerCase();
    if (trimmedText.includes('[.table') && !trimmedText.includes('[.cloze')) {
      bitmarkVersion = 3;
    }

    const parserPromise = this.bitmarkConfig.localParser?.enabled
      ? from(this.parserGenerator.convert(text, options))
      : this.parserApiService.parseBitmark(text, bitmarkVersion);

    // const options = {
    //   bitmarkVersion: 3 as BitmarkVersionType
    // };
    //
    // const parserPromise = from(this.parserGenerator.convert(text, options));

    const obs$ = parserPromise.subscribe(
      (parsedBits: Array<BitApiWrapper>) => {
        if (parsedBits?.length) {
          const brandingBit: any = {meta: {}};
          brandingBit.meta.themeId = this.themeId;
          brandingBit.meta.thisBook = {theme: this.themeId};
          brandingBit.meta.originBook = null;
          parsedBits?.forEach((x: BitApiWrapper) => {
            const bitPublisher = x.bit?.publisher?.length ? this.publishers?.find(p => p.code === x.bit.publisher[0]) : null;
            x.meta = {
              branding: this.getMetaBranding(bitPublisher, x.bit?.theme),
              thisBook: {
                theme: this.brandingRenderService.getBookThemeBranding(brandingBit.meta),
                publisherId: this.publisherId
              }
            };
            return x;
          });
          this.allBitmarkBits = parsedBits;
          this.parsedBits = parsedBits
            .filter((b: BitApiWrapper) => {
              return typeof b.bit === 'object' && b.bit.type;
            })
            .map((b: BitApiWrapper) => {
              return Object.assign({
                id: b.bit?.id,
                meta: {
                  thisBook: {
                    theme: BrandingDefaultThemes.SystemDefault
                  }
                }
              }, b);
            });
          // console.log('Parse result', parsedBits);
        }

        obs$.unsubscribe();
      },
      (err) => {
        console.log(err);
      });
  }

  onSave() {
    if (this.saveDisabled) {
      return;
    }

    if (!this.areParsedBitsValid()) {
      alert(this.translateService.instant('Reader.Editor.InvalidBitmark'));
      return;
    }

    const txt = this.codeEditorElement.nativeElement.value;
    if (this.isEditingWholeBook) {
      this.bitbookApiService.saveBookBitmark(this.bookExternalId, txt)
        .subscribe((res) => {
          console.log(res);
          this.close.emit();
        }, (err) => {
          console.log(err);
          alert(err?.error?.message || err?.message);
        });

      return;
    }
    if (!this.allBitmarkBits?.length) {
      return;
    }
    for (let i = 0; i < this.allBitmarkBits.length; i++) {
      if ((this.allBitmarkBits[i] as any).errors?.length) {
        const error = (this.allBitmarkBits[i] as any).errors[0];
        alert(typeof error === 'string' ? error : `${error.message} on line ${error.line} for bit number ${(i + 1)}, ${this.allBitmarkBits[i]?.bitmark}`);
        return;
      }
    }
    this.save.emit(txt);
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Tab') {
      const htmlElement = e.srcElement as HTMLTextAreaElement;

      if (htmlElement) {
        const val = htmlElement.value,
          start = htmlElement.selectionStart,
          end = htmlElement.selectionEnd;

        // set textarea value to: text before caret + tab + text after caret
        htmlElement.value = val.substring(0, start) + '\t' + val.substring(end);

        // put caret at right position again
        htmlElement.selectionStart = htmlElement.selectionEnd = start + 1;

        // prevent the focus lose
        return false;
      }
    }
  }

  private areParsedBitsValid(): boolean {
    return this.parsedBits.every(b => b?.bit?.type && b.bit.type.toString() !== '_error');
  }

  private getMetaBranding(bitPublisher: Publisher, themes: Array<string>) {
    if (!bitPublisher && !themes?.length) {
      return null;
    }

    const brandingObj: any = {};

    if (bitPublisher) {
      brandingObj.publisher = {
        id: bitPublisher.id,
        code: bitPublisher.code,
        name: bitPublisher.name
      };
    }
    if (themes?.length) {
      brandingObj.theme = themes[0];
    }

    return brandingObj;
  }

  private checkEditBitSupportedFeatures(bit: BaseBit): string {
    const bitBody = (bit as any)?.body;
    if (Array.isArray(bitBody) && this.containsMathComponent(bitBody)) {
      return this.translateService.instant('Reader.Editor.MathFormulasNotSupported');
    }
    return '';
  }

  private containsMathComponent(content: Array<JSONContent>): boolean {
    for (const data of content) {
      if (data.type === 'mathComponent') {
        return true;
      }
      if (data.content?.length && this.containsMathComponent(data.content)) {
        return true;
      }
    }

    return false;
  }
}

