<div class="d-flex flex-row release-note-container">
  @if (bit?.releaseDate && (bit?.releaseDate | bitmarkDate)) {
    <div class="d-flex flex-row">
      <div class="d-flex flex-column justify-content-center release-note-date">
        <div>{{ bit.releaseDate | bitmarkDate: 'd' }}</div>
        <div>{{ bit.releaseDate | bitmarkDate: 'MMM' }}</div>
      </div>
      <div class="separator-vertical mr-4 ml-4"></div>
    </div>
  }
  <div class="release-note-main-content">
    <div class="release-note-instruction text-body">
      <bitmark-text [text]="bit.instruction" [format]="bit.format"></bitmark-text>
    </div>
    @if (bit?.releaseVersion) {
      <div class="release-note-version text-body d-flex">
        <span class="mr-1">{{ 'Shared.Version' | translate }}</span>
        <bitmark-text [text]="bit.releaseVersion" [format]="bit.format"></bitmark-text>
      </div>
    }
    <div class="bit-body bit-body-allow-highlight">
      <bitmark-text [attr.data-bitmark-node]="'body'"
                    [text]="bit?.body"
                    [format]="bit.format">
      </bitmark-text>
    </div>
  </div>
  <div class="ml-2">
    <div class="release-note-kind">{{ bit.releaseKind }}</div>
  </div>
</div>
