@if (bit.resource && !bit?.resource?.article) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    (open)="openResource.emit($event)"
                    [ngClass]="bit?.body ? 'pb-4' : ''"
                    class="d-block">
  </bitmark-resource>
}
@if (bit?.body) {
  <div class="bit-body bit-body-allow-highlight"
       [ngClass]="bit.type">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
@if (bit?.resource?.article) {
  <bitmark-resource-article [bitResourceArticle]="bit?.resource?.article"
                            class="d-block mt-1">
  </bitmark-resource-article>
}
<div class="py-2 link-button-wrapper">
  <button class="btn btn-action bit-button d-inline-block text-center" (click)="onClick()">➔</button>
</div>
