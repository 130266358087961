import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {JSONContent} from '@tiptap/core';
import {AnnotationNoteBit} from './annotation-note.models';
import {BitResource} from '../../bits.models';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../../reader';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-annotation-note',
  templateUrl: './annotation-note.component.html',
  styleUrls: ['./annotation-note.component.scss']
})
export class AnnotationNoteComponent implements OnInit, OnDestroy {
  @Input() bit?: AnnotationNoteBit;
  @Input() user?: any;
  @Input() canBeEdited = false;
  @Input() isBeingEdited = true;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() onIsBeingEdited = new EventEmitter<{ title: Array<JSONContent>, content: Array<JSONContent> }>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();

  @ViewChild('titleEditorComponent') titleEditorComponent: ReaderTextEditorComponent;
  @ViewChild('editorComponent') editorComponent: ReaderTextEditorComponent;

  BitmarkFormats = BitmarkFormat;
  editBodyValue: any;
  editTitleValue: any;

  @HostListener('document:gmbUserDetailsDelivered', ['$event'])
  onUserDetailsDelivered(event: any) {
    if (this.user && (event?.detail?.email === this.user?.email || event?.detail?.ssoEmail === this.user?.email)) {
      this.user.fullName = event?.detail?.fullName;
    }
  }

  ngOnInit(){
    this.canBeEdited = this.canBeEdited && !this.user;
    if(this.user){
      const ev = new CustomEvent('gmbUserDetailsRequested', {
        detail: {
          userEmail: this.user?.email
        }, bubbles: true,
      });
      document.dispatchEvent(ev);
    }
  }

  ngOnDestroy() {
    this.editorComponent = null;
    this.titleEditorComponent = null;
  }

  onUpdate(bitJson: JSONContent) {
    this.onIsBeingEdited.emit({title: this.editTitleValue || this.bit.title, content: bitJson?.content});
    this.editBodyValue = bitJson?.content;
  }

  onTitleUpdate(bitJson: JSONContent) {
    this.onIsBeingEdited.emit({title: bitJson?.content, content: this.editBodyValue || this.bit.content});
    this.editTitleValue = bitJson?.content;
  }

  enterEditMode() {
    this.isBeingEdited = true;
    if (this.editorComponent?.enterEditMode) {
      this.editorComponent.enterEditMode();
    }
  }

  enterTitleEditMode() {
    this.isBeingEdited = true;
    if (this.titleEditorComponent?.enterEditMode) {
      this.titleEditorComponent.enterEditMode();
    }
  }
}
