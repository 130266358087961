<div class="w-100 pos-relative">
  <ng-template #dropdownButtonSkeleton>
    <div class="dot-wrapper">
      <div class="dot"></div>
    </div>
  </ng-template>
  <bitmark-dropdown [actions]="actions"
                    class="d-inline-block ml-auto"
                    container="body"
                    style='position: absolute; top: -15px; right: -15px;'
                    [dropdownButton]="dropdownButtonSkeleton"
                    [placement]="['bottom-right', 'bottom-left', 'top-right', 'top-left', 'left', 'right']"
                    dropdownClass='lg'>
  </bitmark-dropdown>
  <div class='px-2'>
    <h3 class='d-flex justify-content-start align-items-start mb-4'>
      <div class="skeleton skeleton-header"></div>
    </h3>
    <h3 class='d-flex justify-content-start align-items-center mb-4'>
      <div class='skeleton-circle skeleton'></div><div class="skeleton skeleton-text ml-2"></div>
    </h3>
    <h3 class='d-flex justify-content-start align-items-center mb-4'>
      <div class='skeleton-circle skeleton'></div><div class="skeleton skeleton-text ml-2"></div>
    </h3>
    <h3 class='d-flex justify-content-start align-items-center mb-4'>
      <div class='skeleton-circle skeleton'></div><div class="skeleton skeleton-text ml-2"></div>
    </h3>
    <h3 class='d-flex justify-content-start align-items-center mb-4'>
      <div class='skeleton-circle skeleton'></div><div class="skeleton skeleton-text ml-2"></div>
    </h3>
  </div>
</div>
