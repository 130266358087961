<nav aria-label="page navigation text-white">
  <div class="d-flex justify-content-center">
    <app-pagination-label class="display-block"
                          [offset]="offset"
                          [limit]="limit"
                          [total]="total"
                          [shopBranding]='shopBranding'>
    </app-pagination-label>
  </div>
  <div class="mt-2">
    <button class="btn btn-link text-decoration-none"
            (click)="clickPreviousPage()"
            [style.color]='begin === 1 ? shopBranding?.secondaryColor : shopBranding?.linkColor'
            [disabled]="begin === 1"
            [innerHTML]="'Search.Previous' | translate"
    ></button>
    <button class="btn btn-link text-decoration-none"
            (click)="clickNextPage()"
            [style.color]='begin === 1 ? shopBranding?.secondaryColor : shopBranding?.linkColor'
            [disabled]="total <= limit + offset"
            [innerHTML]="'Search.Next' | translate"
    ></button>
  </div>
</nav>
