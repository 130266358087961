@if (bit?.body) {
  <div class="bit-body bit-body-allow-highlight"
       [ngClass]="bit.type">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4 pt-2">
  </bitmark-resource>
}
<div class="mt-4">
  @for (choice of bit.choices; track choice; let index = $index) {
    <div class="bit-radio mb-2"
         [ngClass]="answersCss[index]">
      <input [id]="'i' + bitId + index"
             type="radio"
             [name]="'answer' + bitId"
             [value]="choice.choice"
             [disabled]="bit.isExample || readOnly || !allowAnswerSubmit"
             [checked]="choice.choice===bit.answer?.choice"
             (change)="setQuizAnswer(choice.choice)"/>
      <label [for]="'i' + bitId + index">
        <bitmark-text [text]="choice.choice"
                      [format]="bit.format">
        </bitmark-text>
      </label>
    </div>
  }
</div>
