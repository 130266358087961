<div class="position-relative d-flex flex-column resource-video-wrapper"
     [class.resource-video-wrapper-size]="!isStreaming">
  <div class="w-100 resource-video-recorder-stream-wrapper" [class.switch-camera]="isCameraSwitching">
    <video #videoStream
           [hidden]="isCameraSwitching"
           playsinline
           muted
           [volume]="0"
           class="w-100"
           [style.aspect-ratio]="aspectRatio">
      Your browser does not support the video element.
    </video>
    <canvas #videoCanvas
            [hidden]="!isCameraSwitching"
            class="video-canvas"
            [style.aspect-ratio]="aspectRatio">
    </canvas>
  </div>
  <div class="flex-grow-1"></div>
  <div class="resource-video-device-name">{{ currentDevice?.label }}</div>
  <div class="btn btn-action empty-resource-btn bg-calm" (click)="cancelRecording()">
    <bitmark-icon name="close"></bitmark-icon>
  </div>
  @if (canSwitchCamera) {
    <div class="btn btn-action bg-calm repeat-btn" (click)="revertCamera()">
      <bitmark-icon name="arrow-repeat"></bitmark-icon>
    </div>
  }
</div>
<div class="d-flex justify-content-center mt-4 resource-video-action-buttons-wrapper"
     [class.mt-4]="!hideTakePictureButton">
  <input type="file"
         accept="image/*"
         hidden
         [attr.capture]="videoCameraConfigOptions.video.deviceId"
         [attr.multiple]="null"
         #fileImageInput
         (change)="uploadMobilePhoto()"/>
  @if (isUploadingPhoto) {
    <div>
      <bitmark-progress-spinner [size]="36"></bitmark-progress-spinner>
    </div>
  }
  @if (!isUploadingPhoto && !hideTakePictureButton) {
    <div class="btn btn-action resource-video-action-btn"
         (click)="takePicture()">
      <bitmark-icon
        name="camera"
        class="resource-video-action-btn-icon">
      </bitmark-icon>
    </div>
  }
</div>
