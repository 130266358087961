export const translation = {
  'ToC': {
    'cover': 'Beginning',
    'final': 'End'
  },
  'Auth': {
    'LogOut': 'Log out',
    'Login': 'Login',
    'Register': 'Register',
    'Settings': 'Settings',
    'AlreadyHaveAnAccount': 'Already have an account?',
    'Email': 'Email',
    'Password': 'Password',
    'ShowPassword': 'Show Password',
    'ResetPassword': 'Reset Password',
    'HidePassword': 'Hide Password',
    'LostPassword': 'Lost Password',
    'SignInWith': 'Sign in with {{socialMedia}}',
    'GoToLogin': 'Login again...',
    'UseAnotherAccount': 'Use another Account...'
  },
  'Meta': {
    'ContentNotAvailable': 'Content is not available now',
    'NewVersionAvailable': 'New Version available',
    'PleaseUpgrade': 'Please upgrade to Version {{newVersion}}'
  },
  'Menu': {
    'Notebooks': 'Workspace',
    'Books': 'My Library',
    'BookShop': 'Academy'
  },
  'Contact': {
    'ContactUs': 'Contact Us',
    'Support': 'Support',
    'UpgradeAndLogout': 'Update and Re-login',
    'ClearCache': 'Clear Cache',
    'APISupported': 'IndexedDB-API is supported',
    'MBAvailable': 'MB available',
    'ServiceWorkerActive': 'Service worker is active',
    'NewVersionAvailable': 'New Version available',
    'SystemUpToDate': 'Your system appears to be up to date. However, you can use the button below to ensure it updates ' +
      'to the latest version',
    'PrivacyPolicy': 'Terms and Services'
  },
  'Search': {
    'ShowOnlyFreeTitles': 'Show only free books',
    'SearchAllBooks': 'Search All Books',
    'SearchBooks': 'Search Books',
    'ContentForAllBooks': 'Content for All Books',
    'SearchAllBits': 'Search All Bits',
    'SearchNotebook': 'Search Notebook',
    'SearchBook': 'Search Book',
    'SearchChapter': 'Search Chapter',
    'BooksFor': 'Books for «{{searchedText}}»',
    'ResultsByPublisher': 'Showing Results for Publisher «{{searchedText}}»',
    'ResultsByAuthor': 'Showing Results for Author «{{searchedText}}»',
    'BitsFor': 'Bits for «{{searchedText}}»',
    'PaginationOutOf': '{{begin}} - {{end}} out of {{total}}',
    'Previous': 'Previous',
    'Next': 'Next',
    'LastSearched': 'Last Searched',
    'LastViewed': 'Last Viewed',
    'NoBooksFound': 'No Books Found',
    'NoBitsFound': 'No Bits Found',
    'BooksNotFound': 'Books not found',
    'BitsNotFound': 'Bits not found'
  },
  'Profile': {
    'Title': 'Profile',
    'FirstName': 'First Name',
    'LastName': 'Last Name',
    'Country': 'Country',
    'Language': 'Language',
    'PrivateEmail': 'Private Email',
    'MobilePhone': 'Mobile Phone',
    'DoubleChecking': 'Just double-checking it\'s you!',
    'ChangePassword': 'Change Password',
    'ResetPassword': 'Reset Password',
    'YourOldPassword': 'Your old password',
    'NewPassword': 'New Password',
    'ChangePasswordSuccess': 'Your password has been successfully changed!',
    'ChangePasswordFail': 'Your password change attempt was not successful!',
    'AddPhoto': 'Add Photo',
    'RemovePhoto': 'Remove Photo',
    'SavePhoto': 'Save Photo',
    'ChoosePhoto': 'Choose a photo or drag and drop one.',
    'AddPicture': 'Add Picture',
    'RemovePicture': 'Remove Picture',
    'SavePicture': 'Save Picture',
    'ChoosePicture': 'Choose a picture or drag and drop one.',
    'UpdatePhotoFail': 'Your photo update attempt was not successful.',
    'EmailEmptyError': 'The Email cannot be empty!',
    'PasswordEmptyError': 'The new password cannot be empty!',
    'ImageTooLarge': 'Image is too large. It must be smaller than 3MB',
    'ValidationMessage': 'A validation email has been sent to your new address',
    'YourEmailAddress': 'Your email address',
    'HasBeenVerified': 'has been successfully verified.',
    'ResendVerificationMailTo': 'Resend Verification Mail to',
    'EmailNotVerified': 'Your email has not been verified'
  },
  'Timeline': {
    'Available': 'Available',
    'NotAvailable': 'Not Available',
    'Reply': 'Reply',
    'Today': 'Today',
    'ConnectTo': 'Connect to ...',
    'EmailPeer': 'Emails (of existing Get More Brain accounts)',
    'UserNotFound': 'User was not found!',
    'NewMessages': 'New Messages',
    'NewMessage': 'New Message',
    'TypeAMessage': 'Type a message...',
    'ConnectMessage': 'Hi, nice to connect.',
    'MarkAllAsRead': 'Mark all messages as read',
    'DeleteChat': 'Delete Chat',
    'DeleteChatConfirm': 'Do you really want to delete this chat?',
    'BlockUser': 'Block User',
    'ManageGroup': 'Manage Group',
    'LeaveGroup': 'Leave Group',
    'BlockUserConfirm': 'Do you really want to block this user?',
    'UnblockUser': 'Unblock user',
    'UnblockUserConfirm': 'Do you really want to unblock this user?',
    'ConnectionRequest': 'Connection request',
    'CreateEntry': {
      'AddMultiQuizContinue': 'Add multi quiz...',
      'AddBlankQuiz': 'Add blank quiz',
      'OpenLinkInBrowser': 'Open link in Browser',
      'CopyLink': 'Copy link',
      'AddHintContinue': 'Add hint...',
      'EditLinkContinue': 'Edit link...',
      'EditHintContinue': 'Edit hint...',
      'RemoveHint': 'Remove hint',
      'Unlink': 'Unlink',
      'Video': 'Video',
      'TakePicture': 'Take Picture or Photo Library',
      'PhotoLibrary': 'Photo Library',
      'Audio': 'Audio',
      'Document': 'Document',
      'Emoji': 'Emoji'
    },
    'Actions': {
      'YouDeletedThisMessage': 'You deleted this message',
      'ThisMessageWasDeleted': 'This message was deleted',
      'ThisMessageIsHidden': 'This message is hidden',
      'HideSolution': 'Hide Solution',
      'RevealSolution': 'Reveal Solution',
      'Verify': 'Verify',
      'Repeat': 'Repeat',
      'TextContinue': 'Text...',
      'HrefContinue': 'Href...',
      'HintContinue': 'Hint...',
      'PasteVideoLink': 'Paste a video link below',
      'BrowserDoesNotSupportAudio': 'Your browser does not support the audio element.',
      'BrowserDoesNotSupportVideo': 'Your browser does not support the video element.',
      'ChooseDocument': 'Choose a document or drag and drop one.',
      'ChooseAudio': 'Choose an audio file or drag and drop one.',
      'ErrorConnecting': 'Error connecting with {{email}}',
      'UserNotFound': 'User {{email}} was not found',
      'PasteAndSendBit': 'Paste and Send Bit',
      'SendBasket': 'Send Basket',
      'ErrorUploadingPdf': 'We could not convert your pdf. The problem was logged. We are working on a solution to convert more complex pdf\'s in the future.'
    },
    'Quiz': {
      'CannotSend': 'You cannot send the quiz now.',
      'CannotSendWithoutGap': 'You cannot send a quiz without any gap.'
    },
    'SearchAll': 'All Users'
  },
  'Notebooks': {
    'UntitledNotebook': 'Untitled Notebook',
    'UntitledDraftBook': 'Untitled Book',
    'UntitledFlashcards': 'Untitled Flashcards',
    'Title': 'Workspace',
    'PersonalNotebooks': 'Personal Notebooks',
    'Homework': 'Homework',
    'SmartNotebooks': 'Smart Notebooks',
    'DeleteConfirm': 'Do you really want to delete your Notebook «{{notebook}}»? This cannot be undone!',
    'Notebook': 'Notebook',
    'Worksheet': 'Worksheet',
    'Flashcards': 'Flashcards',
    'RecentQuizzes': 'Recent Quizzes',
    'RecentVideos': 'Recent Videos',
    'LearningPath': 'Learning Path',
    'DraftBook': 'Draft Book',
    'DropFileHere': 'Drop file here',
    'DropFileAllowedTypes': 'You are only allowed to drop PDF\'s or bitmark files'
  },
  'HandIn': {
    'Archive': 'Archive',
    'Unarchive': 'Unarchive',
    'Today': 'today',
    'Yesterday': 'yesterday',
    'Assign': 'Assign...',
    'Unassign': 'Remove assignment',
    'AssignToMe': 'Assign to me',
    'YourHandInApproved': 'Your hand in has been approved',
    'YourHandInRejected':'Your hand in has been rejected',
    'PleaseReadComments': 'Please read the comments carefully and try again.',
    'BookWasHandedIn': 'Book was handed in',
    'Locked': 'Locked',
    'By': 'by',
    'YouHaveApprovedThis': 'You have approved this',
    'For':'for',
    'YouHaveRejectedThis': 'You have rejected this',
    'PleaseHandInTheBookReady':'Please hand in the book when ready.',
    'PleaseHandInTheBookUntil': 'Please hand in the book until',
    'YourBookWillBeLocked': 'Your book will be locked 🔒 (view only) after submission.',
    'BookWasHandedInSuccessfully': 'Book was handed in successfully.',
    'YourWorkOnThisBookWasApproved': 'Your work on this book was approved!',
    'ThereWasAProblemHandingIn': 'There was a problem handing in the book',
    'ThereWasAnErrorAccepting': 'There was an error accepting the hand-in',
    'ThereWasAnErrorAssigning': 'There was an error assigning the hand in',
    'ErrorNoExperts':'There are no experts to be assigned',
    'NoNewHandIns':'No new hand-ins',
    'NoAssignedHandIns': 'No assigned hand-ins',
    'NoHandIns':'No hand-ins',
    'NewHandIns':'Not Assigned',
    'AssignedToMe':'My Reviews',
    'All':'Approved'
  },
  'Books': {
    'MyBooks': 'Books',
    'Books': 'books',
    'Courses': 'Courses',
    'MyClasses': 'My Classes',
    'RecentlyUsed': 'Recently Used',
    'SelfLearning': 'Self-Determined Learning',
    'Purchased': 'Purchased',
    'All': 'All',
    'Unbuy': 'Unbuy',
    'UnbuyTraining': 'Cancel Training',
    'ChatWithBook': 'Chat with Book',
    'Publisher': 'Publisher',
    'Authors': 'Authors',
    'Published': 'Published',
    'Updated': 'Updated',
    'Language': 'Language',
    'ISBN': 'ISBN',
    'EmptyLibraryMessage': 'Wondering why "My Library" is empty? Take a look in the',
    'CostsCoveredBy': 'Costs covered by {{company}}',
    'Pages': 'Pages',
    'AlsoAvailableIn': 'Also available in',
    'Series': 'Series «{{seriesName}}»',
    'CurrentSeries': 'Current Series',
    'OnlyPaper': 'Book only available on paper.',
    'VoteMakeBookAvailable': 'Vote to make it available online',
    'DigitalMaterial': 'But we have some nice, digital material for this book.',
    'OneVoteToMakeAvailable': '{{numberOfVotes}} vote to make it available online',
    'VotesToMakeAvailable': '{{numberOfVotes}} votes to make it available online',
    'Types': {
      'Flashcards': 'Flashcards',
      'PPT': 'PPT',
      'Word': 'Word',
      'Excel': 'Excel',
      'PDF': 'PDF',
      'Script': 'Script',
      'Quizzes': 'Quizzes',
      'Video': 'Video',
      'VideoLecture': 'Video Lecture',
      'LTIContainer': 'LTI Container',
      'WBT': 'WBT',
      'Audio': 'Audio',
      'LiveBook': 'Live Book',
      'Homework': 'Homework'
    }
  },
  'Shared': {
    'BroadcastSubtitle': 'Send to every student in class',
    'Join': 'Join',
    'Edit': 'Edit',
    'Edited': 'Edited',
    'EditContinue': 'Edit...',
    'SetContinue': 'Set...',
    'Remove': 'Remove',
    'Cancel': 'Cancel',
    'OK': 'OK',
    'Search': 'Search',
    'SearchStudents': 'Search Students',
    'Clone': 'Clone',
    'Page': 'Page',
    'Open': 'Open',
    'OpenContinue': 'Open...',
    'Download': 'Download',
    'Share': 'Share',
    'Send': 'Send',
    'Show': 'Show',
    'Insert': 'Insert',
    'ShowHiddenFields': 'Show All Fields...',
    'HideHiddenFields': 'Hide All Fields',
    'SendContinue': 'Send...',
    'Sending': 'Sending',
    'SendingContinue': 'Sending...',
    'Rename': 'Rename',
    'Replace': 'Replace',
    'ReplaceContinue': 'Replace...',
    'Delete': 'Delete',
    'DeleteContinue': 'Delete...',
    'DeletePermanentlyContinue': 'Delete Permanently...',
    'DeleteImmediately': 'Delete Immediately',
    'Save': 'Save',
    'Sell': 'Sell',
    'For': 'for',
    'Hide': 'Hide',
    'Free': 'Free',
    'Buy': 'Buy',
    'Unbuy': 'Unbuy',
    'Here': 'Here',
    'Continue': 'Continue',
    'Name': 'Name',
    'Favorites': 'Favorites',
    'Copy': 'Copy',
    'Paste': 'Paste',
    'PasteSpecial': 'Paste Special...',
    'Cut': 'Cut',
    'Undo': 'Undo',
    'Accept': 'Accept',
    'Reject': 'Reject',
    'Revoke': 'Revoke',
    'SentOn': 'Sent on',
    'RejectedOn': 'Rejected on',
    'RevokedOn': 'Revoked on',
    'UploadPDF': 'Upload PDF',
    'Duplicate': 'Duplicate',
    'MarkAsDone': 'Mark as done',
    'RateAndMarkAsDone': 'Rate and Mark as Done ✅',
    'HandIn': 'Hand In',
    'Choose': 'Choose',
    'ChooseContinue': 'Choose...',
    'CloseConfirm': 'Are you sure to want to close?',
    'CloseModalConfirm': 'Are you sure to want to close the dialog?',
    'TryAgain': 'Try Again',
    'Retry': 'Retry',
    'True': 'True',
    'False': 'False',
    'Start': 'Start',
    'ChooseBook': 'Choose Book',
    'ChooseChapter': 'Choose Chapter',
    'Book': 'Book',
    'Chapter': 'Chapter',
    'Video': 'Video',
    'CameraContinue': 'Camera...',
    'UploadContinue': 'Upload...',
    'EmbedVideoContinue': 'Embed (Youtube, Vimeo)...',
    'Audio': 'Audio',
    'Voice': 'Voice',
    'Image': 'Image',
    'UrlContinue': 'From URL...',
    'Documents': 'Documents',
    'LinkContinue': 'Link...',
    'Website': 'Website',
    'ManageContinue': 'Organize...',
    'Caption': 'Caption',
    'Attempts': 'Attempts',
    'Close': 'Close',
    'AddToCalendar': 'Add to calendar',
    'ReplaceImageContinue': 'Replace Image...'
  },
  'Shop': {
    'Price': 'Price',
    'TotalPrice': 'Total Price',
    'YouAreBuyingFor': 'You are buying for',
    'Shop': 'Academy',
    'Product': 'Product',
    'Products': 'Products',
    'PurchaseSuccessful': 'Purchase Successful',
    'AddedSuccessfully': 'Added Successfully',
    'PurchaseFailed': 'Purchase Failed',
    'BookNotFound': 'Book not found.',
    'AllBooksNext': 'All Books »',
    'SubCategories': 'subcategories',
    'MoreInfo': 'Details...',
    'MoreInfoContinue': 'More info...',
    'ThisIsAdditional': 'This is a Supporting Material for',
    'SupportingMaterial': 'Supporting Material',
    'ThanksForJoining': 'Thanks for joining!',
    'Publish': 'Publish',
    'EditContinue': 'Edit...',
    'EditLP': 'Learning Path...',
    'InvitedBy': 'You have been invited by {{user}}',
    'InvitationFor': 'Invitation for',
    'GetAnotherCopy': 'Get Another Copy',
    'Editor': {
      'CopyEmailAddress': 'Copy E-mail Address',
      'Chat': 'Chat',
      'SendEmail': 'Send Email...',
      'AddFlag': 'Set Flag',
      'CopyAll': 'Copy All',
      'ReplaceAll': 'Replace All...',
      'AddSeveral': 'Add Several...',
      'ResendInvitation': 'Resend Invitation ✉️',
      'ByInvitationOnly': 'This course is by invitation only (purchase or self-registration is not possible)',
      'ClosedCourse': 'Closed Course - Participants are managed by Organizers only 🔒',
      'Content': 'Content',
      'ShopPage': 'Shop Page',
      'CustomShopPage': 'Custom Shop Page',
      'NewRelease': 'New Release',
      'InviteUsersToBuy': 'Invite Users to Buy...',
      'InviteUsersToJoin': 'Invite Users to Join...',
      'BuyForUsers': 'Buy For Users...',
      'JoinForUsers': 'Add Users as Participants...',
      'BuyForUser': 'Buy For User...',
      'JoinForUser': 'Join User...',
      'DeleteAll': 'Delete All',
      'SortByEmail': 'Sort by E-mail',
      'SortByFirstName': 'Sort by First Name',
      'SortByLastName': 'Sort by Last Name',
      'SendReleaseNotes': 'Send Release Notes',
      'SectionTitle': 'Section Title'
    },
    'Course': {
      "Description": "Description",
      'UpcomingDates': 'Classes',
      'Content': 'Content',
      'CourseContent': 'Course Content',
      'CourseLessons': 'Lessons',
      'CourseStartDate': 'Start',
      'CourseBooks': 'Course Books',
      'TheCurriculum': 'The Curriculum',
      'BuyForAll': 'Buy for Participants',
      'UpdateInvitations': 'Update Invitations',
      'Now': 'Now',
      'Next': 'Next',
      'Done': 'Done',
      'Participants': 'Participants',
      'Organizers': 'Organizers',
      'Coaches': 'Coaches',
      'Editors': 'Editors',
      'InvitationsUpdated': 'The invitations have been updated for all participants!',
      'BoughtForParticipants': 'All participants have joined the course.',
      'CopyLink': 'Copy Link',
      'Duration': 'Duration',
      'Effort': 'Effort',
      'Format': 'Format',
      'ClassSize': 'Class Size',
      'Career': 'Career',
      'Tuition': 'Tuition',
      'MoveUp': 'Move up',
      'MoveDown': 'Move down',
      'HandInFolder': 'Hand-In Folder for Expert Feedback',
      'Experts': 'Experts',
      'Modules': 'The Modules',
      'Starts': 'Starts',
      'Ends': 'Ends',
      'ViewDetails': 'View Details',
      'NoPriceSet': 'The price for this product has not been set. In order for participants to access this product, you must first set the price.',
      'ModulesCouldNotBeUpdated': 'The modules could not be updated. Perhaps you have tried uploading too many photos at once. Please try again later.',
      'ProductLoadError': 'There was an error loading the product',
      'AllParticipantsInvited': 'All participants have been invited!',
      'AllParticipantsJoinedCourse': 'All the users have already joined the course',
      'UsersJoinError': 'There was a problem joining the users',
      'InvitationSent': 'Invitation was sent!',
      'SelfJoinedParticipants': 'Self-Joined Participants',
      'EmailLanguage': 'Email language for new registered users - '
    }
  },
  'Bits': {
    'With': 'To',
    'SuccessfullySent': 'Successfully sent',
    'ToUser': 'to {{userName}}',
    'Recent': 'Recent',
    'ModuleDone': 'Module done',
    'HadRead': 'read',
    'SendAt': 'Send at',
    'Item': 'Item',
    'Lead': 'Lead',
    'Location': 'Location',
    'Instruction': 'Instruction',
    'Action': 'Task',
    'Duration': 'Duration',
    'Date': 'Date',
    'From': 'From',
    'To': 'To',
    'Quiz': 'Quiz',
    'PlaceholderBody': '...',
    'NoResourceSelected': 'No resource selected',
    'GetFeedback': 'Get Feedback',
    'DoItAgain': 'Do it again',
    'FinalFeedback': 'Final Feedback',
    'FeedbackRetryOrSubmitMessage': 'You got {{correct}} out of {{total}} answers correct.',
    'Generate': 'Generate'
  },
  'Reader': {
    'SendMyAnswer': 'Send my answer',
    'ShowAll': 'Show all',
    'CreateNewNotebook': 'Create new «{{untitledNotebook}}»',
    'Search': {
      'Search': 'Search...',
      'LastSearched': 'Last Searched',
      'NoResultsFound': 'No results found.'
    },
    'Editor': {
      'NewChapter': 'New Chapter',
      'Title': 'Title',
      'Subtitle': 'Subtitle',
      'Text': 'Text',
      'Code': 'Code',
      'Math': 'Math',
      'Link': 'Link',
      'Quote': 'Quote',
      'ScientificQuote': 'Scientific Quote',
      'HistoricQuote': 'Historic Quote',
      'List': 'List',
      'NumberList': 'Numbered List',
      'LetterList': 'Letter List',
      'RomanList': 'Roman List',
      'TaskList': 'Task List',
      'NoBullets': 'No Bullets',
      'AddNote': 'Add Note',
      'AddHandwritten': 'Add Scribble',
      'AddQuickNote': 'Add Quick Note',
      'TypeSomething': 'Type Something ...',
      'InvalidBitmark': 'Invalid bitmark',
      'ImageLeftAligned': 'Left Aligned',
      'ImageRightAligned': 'Right Aligned',
      'ImageCenterAligned': 'Centered',
      'MathFormulasNotSupported': 'Editing bits with math formulas is not yet supported'
    },
    'Handwritten': {
      'WaitingForImage': 'Waiting for image',
      'ErrorLoadingImage': 'Error loading image',
      'LoadingImage': 'Loading Image',
      'PreparingImage': 'Preparing Image',
      'ColorPalette': 'Color Palette',
      'ExtraSmall': 'Extra small',
      'Small': 'Small',
      'MediumSmall': 'Medium small',
      'Medium': 'Medium',
      'MediumLarge': 'Medium large',
      'Large': 'Large',
      'ExtraLarge': 'Extra large',
    },
    'Filters': {
      'Quiz': 'Content',
      'Resources': 'Resources',
      'Grade': 'Grade',
      'Complexity': 'Complexity',
      'Tags': 'Tags',
      'Skills': 'Skills'
    },
    'Actions': {
      'Send': 'Send',
      'SendContinue': 'Send...',
      'SendToClass': 'Send to Class Chat...',
      'Broadcast': 'Broadcast',
      'SendBook': 'Send Book',
      'SendAll': 'Send All',
      'DeleteBit': 'Delete Bit',
      'DeleteAll': 'Delete All',
      'SaveToNotebook': 'Save to Notebook...',
      'SaveToAnotherNotebook': 'Save to Another Notebook...',
      'ResetBookRating': 'Reset Book Rating',
      'SaveAllToNotebook': 'Save All to Notebook',
      'FindInBook': 'Find in Book',
      'SuccessfullyStored': 'Stored successfully!',
      'SavingTo': 'Saving to «{{notebookName}}»',
      'SuccessfullyStoredToNotebook': 'Successfully stored to notebook «{{notebookName}}»',
      'ErrorOcurredStoring': 'An error was encountered while storing to «{{notebookName}}»',
      'CopyBitmarkType': 'Copy bitmark {{bitmarkType}}',
      'EditBitmarkType': 'Edit bitmark {{bitmarkType}}',
      'BrandingPublisherTheme': 'Publisher {{publisherName}} {{newline}} {{publisherId}}/{{theme}}',
      'ExtractText': 'Copy Extracted Text',
      'CopyBitmarkJSON': 'Copy bitmark JSON',
      'CopyLinkToBit': 'Copy Link to this Bit',
      'CopyAllBitsToBasket': 'Copy All Bits to Basket',
      'ResetAnswer': 'Reset Answer',
      'ResetHighlights': 'Reset Highlights',
      'JoinCall': 'Join call',
      'Add': 'Add',
      'AddChapter': 'Add Chapter',
      'AddCard': 'Add Card',
      'UploadCards': 'Upload Cards...',
      'PasteQuizletCards': 'Paste Flashcards from Quizlet (TSV)',
      'PasteStepsScribePro': 'Paste Steps from Scribe Pro (Markdown)',
      'AddBit': 'Add Bit',
      'AddLearningPathLTI': 'Add Learning Path LTI',
      'AddLearningPath': 'Add Learning Path',
      'EditLearningPathContinue': 'Edit Learning Path...',
      'LearningPathGoal': 'Learning Objectives',
      'LearningPathReadBook': 'Read Book',
      'LearningPathStep': 'Learning Step',
      'LearningPathVideoCall': 'Video Call',
      'LearningPathExternalLink': 'External Link',
      'LearningPathLTI': 'LTI Resource',
      'LearningPathClassroomEvent': 'Classroom Event',
      'LearningPathClosing': 'Completion',
      'AddBotMessage': 'Add Bot Message',
      'AddBotAction': 'Add Bot Action',
      'BotActionSend': 'Send Message',
      'AddNote': 'Add Note',
      'AddHandwritten': 'Add Scribble',
      'AddBookmark': 'Add Bookmark',
      'AddFavorite': 'Add Favorite',
      'AddArticle': 'Add Text',
      'AddQuote': 'Add Quote',
      'ExampleNotes': 'Example Notes',
      'InsertBasket': 'Insert Basket',
      'SetCover': 'Upload Custom Cover...',
      'RemoveCover': 'Remove Custom Cover',
      'CoverStyle': 'Cover Style',
      'DefaultCover': 'Image on Cover',
      'FullCover': 'Image fills Cover',
      'CustomCover': 'Custom Cover',
      'ErrorSetCover': 'This image is too small. Please provide an image that is at least 700px wide or 1000px high.',
      'AddAuthorNote': 'Add Author Note',
      'AddReviewerNote': 'Add Reviewer Note',
      'AddInfo': 'Add Info',
      'AddSideNote': 'Add Side Note',
      'AddRemark': 'Add Remark',
      'AddExample': 'Add Example'
    },
    'Basket': {
      'Basket': 'Basket',
      'EmptyBasket': 'Empty Basket',
      'AddToBasket': 'Add to Basket',
      'RemoveFromBasket': 'Remove from Basket',
      'SendAll': 'Send all',
      'SaveAll': 'Save all'
    },
    'Bin': {
      'RestoreAll': 'Put All Back',
      'EmptyBin': 'Empty Bin',
      'Restore': 'Put Back',
      'MoveToBin': 'Move to Bin',
      'DeleteBitConfirmation': 'Are you sure you want to delete this bit?'
    },
    'Bits': {
      'Level': 'Level'
    },
    'Translate': {
      'Translate': 'Translate',
      'TranslateTitle': 'Translate...',
      'ChooseLanguage': 'Choose Language',
      'SearchLanguages': 'Search Languages',
      'RecentLanguages': 'Recent Languages',
      'AllLanguages': 'All Languages'
    },
    'LearningEvents': {
      'ThisWeek': 'This week',
      'Next14Days': 'Next 14 days',
      'AllUpcoming': 'All upcoming',
      'Past': 'Past'
    }
  },
  'System': {
    'SystemTitle': 'System',
    'Bin': 'Bin'
  },
  'Cockpit': {
    'AverageCompletedActivities': 'Avg. Completed Activities',
    'AverageCorrectAnswers': 'Avg. Correct Answers',
    'NoDataYet': 'No data yet'
  }
};
