<div class="my-5 d-flex flex-column justify-content-start align-items-center hand-in-container">
  @if (handInBit) {
    @if (handInBit?.handInApproved) {
      <p class="mb-3">{{ 'HandIn.YourHandInApproved' | translate }} 👍 - {{ handInDate | smartDate }}</p>
    } @else {
      <p class="mb-2">{{ 'HandIn.YourHandInRejected' | translate }} ❌ - {{ handInDate | smartDate }}</p>
      <p class="mb-3 mt-0">{{ 'HandIn.PleaseReadComments' | translate }}</p>
    }
    @if(handInBit?.handIn?.book){
      <bitmark-page-open-book (navigateToBook)="openBook()"
                              [book]="handInBit?.handIn?.book"
                              [xModule]="handInBit?.xModule">
      </bitmark-page-open-book>
    }
  }
</div>

