@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
@if (bit?.heading?.forKeys || bit?.heading?.forValues) {
  <div class="row no-gutters mb-1 font-weight-bold">
    <div class="col-sm-8 col-12 text-pre-wrap">
      @if (bit?.heading?.forKeys) {
        <span [innerText]="bit?.heading?.forKeys"></span>
      }
    </div>
    <div class="col-sm-4 col-12 text-pre-wrap">
      @if (bit?.heading?.forValues) {
        <span [innerText]="bit?.heading?.forValues"></span>
      }
    </div>
  </div>
}
@for (pair of bit?.pairs; track pair) {
  <div class="row no-gutters pb-2">
    <div class="col-sm-8 col-12 px-1 bit-body match-text sub-item-positioning"
         [ngClass]="bit.type">
      @if (pair.item) {
        <bitmark-text [text]="pair.item"
                      [format]="bit.format"
                      class="sub-item"
                      cssClass="sub-item-match-solution-grouped text-nowrap">
        </bitmark-text>
      }
      <bitmark-text [text]="pair.key"
                    [format]="bit.format">
      </bitmark-text>
    </div>
    <div class="p-0 col-sm-4 col-12">
      <bitmark-match-select-placeholder [pair]="pair"
                                        [format]="bit.format"
                                        [options]="solutions"
                                        [readOnly]="readOnly || !allowAnswerSubmit"
                                        [hasFeedback]="hasFeedback"
                                        (changed)="changed.emit()">
      </bitmark-match-select-placeholder>
    </div>
  </div>
}

@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit.footer"
                [format]="bit.format">
  </bitmark-text>
}
