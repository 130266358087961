<div class="w-100 d-flex justify-content-center align-items-center flex-column text-white error-content"
     [class.dont-panic]="showDontPanicImg"
     [style.background-color]="backgroundColor">
  @for (message of messages; track message) {
    <div [innerText]="message.content"
         [ngClass]="message.cssClass">
    </div>
  }

  <div class="pt-lg-5 pt-6">
    <button (click)="handleContinue()"
            class="btn-action btn-action--filled">
      <span [translate]="buttonTitle"></span>
    </button>
  </div>
</div>
