@if (bit?.body) {
  <div class="bit-body bit-body-allow-highlight"
       [ngClass]="bit.type">
    @if (canBeEdited) {
      <bitmark-reader-monaco-code-editor
        [(content)]="bit.body"
        [language]="bit.computerLanguage"
        [readonly]="false"
        [showMinimap]="bit.codeMinimap"
        [showLineNumbers]="bit.codeLineNumbers"
        (updateContent)="onUpdateContent()">
      </bitmark-reader-monaco-code-editor>
    } @else {
      <bitmark-reader-monaco-code-editor
        [content]="bit.body"
        [language]="bit.computerLanguage"
        [readonly]="true"
        [showMinimap]="bit.codeMinimap"
        [showLineNumbers]="bit.codeLineNumbers">
      </bitmark-reader-monaco-code-editor>
    }
  </div>
}
