@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
<div class="bit-body bit-body-allow-highlight"
     [ngClass]="bit.type">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
  <textarea autosize [minRows]="4"
            class="bg-transparent w-100 mt-1 input long p-0 d-inline-block"
            [class.answered]="readOnly"
            [readOnly]="readOnly"
            [value]="bit.answer?.text"
            (input)="change($event)">
  </textarea>
</div>
