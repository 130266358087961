<div class="chapter-background"
     [ngClass]="'level-' + bit.level"
     [style.background-image]="bit.resource?.image?.src ? 'url(' + bit.resource?.image?.src + ')' : null">
</div>
<div class="chapter-wrapper"
     [ngClass]="'level-' + bit.level">
  @if (bit?.item) {
    <div class="chapter-item-wrapper">
      <bitmark-text class="bitmark-item"
                    cssClass="text-nowrap"
                    style="z-index: 1"
                    [text]="bit?.item"
                    [format]="bit.format">
      </bitmark-text>
      <div class="d-inline-block chapter-item-icon"></div>
    </div>
  }

  <div #chapter class="chapter-title-wrapper">
    <div class="bit-body chapter-title">
      @if (isEditable) {
        <bitmark-reader-simple-text-editor #editorComponent
                                           (updateBit)="changeText($event)"
                                           (click)="enterEditMode()"
                                           [bit]="bit"
                                           [bitContent]="(bit?.title || bit?.subtitle) | bitmark:titleFormat:'json'"
                                           [isReadOnly]="false"
                                           [hasStrike]="false"
                                           [hasLists]="false"
                                           [hasHighlight]="false"
                                           [hasLight]="false"
                                           [hasScript]="false"
                                           (created)="editorCreated.emit(editorComponent)"
                                           class="w-100">
        </bitmark-reader-simple-text-editor>
      } @else {
        <bitmark-text [text]="$any(bit?.title || bit?.subtitle)"
                      [format]="bit.format">
        </bitmark-text>
      }
    </div>
  </div>
  <ng-template #chapterLevelsPicker>
    <div class="position-absolute editor-chapter-levels">
      <span class="text-muted mr-1 ">{{ 'Reader.Bits.Level' | translate }}</span>
      @for (level of levels; track level) {
        <span class="p-1 cursor-pointer"
              (click)="changeLevel(level)"
              [ngClass]="{'editor-chapter-selected-level': selectedLevel === level}"
              [innerText]="level">
      </span>
      }
    </div>
  </ng-template>
</div>

@if (bit?.instruction) {
  <div class="chapter-instruction-wrapper"
       [ngClass]="'level-' + bit.level">
    <bitmark-text class="bitmark-instruction position-relative"
                  [text]="bit?.instruction"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
@if (bit?.body) {
  <div class="chapter-body-wrapper"
       [ngClass]="'level-' + bit.level">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  class="bit-body bit-body-allow-highlight position-relative body"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
