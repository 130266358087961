import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {JSONContent} from '@tiptap/core';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../reader';
import {BitmarkFormat} from '../../shared/models/bitmark.models';
import {DefinitionListBit} from './definition-list.models';

@Component({
  selector: 'bitmark-definition-list',
  templateUrl: './definition-list.component.html',
  styleUrls: ['./definition-list.component.scss']
})
export class DefinitionListComponent implements OnInit {
  @Input() bit: DefinitionListBit;
  @Input() canBeEdited: boolean;
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();
  @Output() saveInlineBit = new EventEmitter<DefinitionListBit>();

  isMobile = false;

  constructor(private deviceDetectorService: DeviceDetectorService) {
  }

  ngOnInit() {
    this.isMobile = this.deviceDetectorService.isMobile();
  }

  onUpdateQuestion(bit: DefinitionListBit, bitJson: JSONContent) {
    if (!bit.definitions?.length) {
      bit.definitions = [{
        term: bitJson?.content
      }];
    } else {
      bit.definitions[0].term = bitJson?.content;
    }

    if (this.canBeEdited) {
      bit.format = BitmarkFormat.PP;
      this.saveInlineBit.emit(this.bit);
    }
  }

  onUpdateAnswer(bit: DefinitionListBit, bitJson: JSONContent) {
    if (!bit.definitions?.length) {
      bit.definitions = [{
        definition: bitJson?.content
      }];
    } else {
      bit.definitions[0].definition = bitJson?.content;
    }

    if (this.canBeEdited) {
      bit.format = BitmarkFormat.PP;
      this.saveInlineBit.emit(this.bit);
    }
  }
}
