<!--<?xml version="1.0" encoding="utf-8"?>-->
<!-- SO: http://stackoverflow.com/questions/15430189/pure-svg-way-to-fit-text-to-a-box  -->
<!--<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">-->

<div class="w-100 h-100" style="border-radius: 4px;
        overflow: hidden;">
  <svg xmlns="http://www.w3.org/2000/svg"
       xmlns:xhtml="http://www.w3.org/1999/xhtml"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       version="1.1"
       preserveAspectRatio="xMinYMin"
       width="100%"
       height="100%"
       viewBox="0 0 140 200"
       [ngClass]="{'svg-not-exist': isNotExist, 'default-product-cover': isProductCover}"
  >
    <!-- Text-Elemente -->
    <foreignObject
      x="0" y="0" width="140" rx="4" ry="4" height="200" fill="yellow" [attr.color]="coverColor ? coverColor : isDraft ? '#ff8c00' : 'rgb(255, 206, 0)'"
      transform="translate(0,0)"
    >
      <style>
        div.svg-default-div {
          display: table;
          width: 130px;
          height: 200px;
          margin-left: 5px;
          margin-right: 5px;
        }

        p.svg-default-text {
          display: table-cell;
          text-align: left;
          vertical-align: bottom;
          font-size: 14px;
          font-weight: 500;
          font-family: 'Inter', 'sans-serif';
          padding-bottom: 10px;
        }

        p.svg-not-exist-text{
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          font-size: 72px;
          font-weight: 500;
          font-family: 'Inter', 'sans-serif';
          padding-top: 20px;
        }

        body.svg-default-body {
          margin: 0;
          border-radius: 3px;
          background: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.5)), rgb(255, 206, 0);
        }

        body.svg-not-exist-body {
          margin: 0;
          border-radius: 3px;
          background: grey;
        }

        body.svg-draft-body {
          background: linear-gradient(rgba(255,255,255,0),rgba(0,0,0,0.5)), #ff8c00;
        }

        .svg-not-exist{
          padding: 0 20px;
        }

        .default-product-cover{
          padding: 0 20px;
        }
      </style>
      <body class="svg-default-body" [class.svg-draft-body]="isDraft" [class.svg-not-exist-body]="isNotExist" xmlns="http://www.w3.org/1999/xhtml">
      <div class="svg-default-div">
        <p class="svg-default-text" [class.svg-not-exist-text]="isNotExist" id="text">{{text}}</p>
      </div>
      </body>
    </foreignObject>
  </svg>
</div>
