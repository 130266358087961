<div class="d-flex align-items-center justify-content-between py-2 button-bar">
  <div class="d-flex align-items-center justify-content-start pl-4">
    <bitmark-reader-search-box class="flex-grow-1"
                               (close)="close.emit()">
    </bitmark-reader-search-box>
    @if (!isExternalSearch) {
      <bitmark-icon name="filter"
                    class="px-2 size-20px cursor-pointer"
                    (click)="toggleSidebar.emit()">
      </bitmark-icon>
    }
  </div>

  <bitmark-icon name="close"
                class="px-2 size-20px cursor-pointer"
                (click)="close.emit()">
  </bitmark-icon>
</div>
