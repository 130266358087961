import {Component, Input, OnInit} from '@angular/core';
import {BitResource} from '../../bits.models';
import {FileUtilsService} from '../../../shared/utils/file-utils.service';

@Component({
  selector: 'bitmark-resource-document-link',
  templateUrl: './resource-document-link.component.html',
  styleUrls: ['./resource-document-link.component.scss', '../../bits.scss']
})
export class ResourceDocumentLinkComponent implements OnInit {
  @Input()
  set bitResource(value: BitResource | undefined) {
    this._bitResource = value;
    this.computePreview();
  }

  get bitResource(): BitResource | undefined {
    return this._bitResource;
  }

  private _bitResource: BitResource | undefined;

  documentTitle?: string;
  documentExtension?: string;
  previewImageClass: string;
  allowDirectDownload = false;
  isDownloadLoading = false;

  constructor(private fileUtilsService: FileUtilsService) {
  }

  ngOnInit() {
    if (this.bitResource?.document?.url) {
      this.documentTitle = this.bitResource.document.url.split('/').pop();
      this.documentExtension = this.documentTitle.split('.').pop();

      this.allowDirectDownload = !['pdf'].includes(this.documentExtension.toLowerCase());
    }

    this.computePreview();
  }

  computePreview() {
    if (this.bitResource?.preview?.image?.src) {
      this.previewImageClass = this.bitResource.preview.image.width > this.bitResource.preview.image.height
        ? 'preview-landscape'
        : 'preview-portrait';
    }
  }

  downloadDocument(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.isDownloadLoading = true;
    this.fileUtilsService.fetchAndDownload(this.documentTitle, this.bitResource.document.url)
      .subscribe(() => {
        this.isDownloadLoading = false;
      }, () => {
        this.isDownloadLoading = false;
        window.open(this.bitResource.document.url, '_blank');
      });
  }
}
