import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {JSONContent} from '@tiptap/core';
import {BaseBit, BitResourceImage, BitResourceVideo, BitType} from '../../bits.models';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-resource-video',
  templateUrl: './resource-video.component.html',
  styleUrls: ['./resource-video.component.scss', '../../bits.scss']
})
export class ResourceVideoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() bit?: BaseBit;
  @Input() bitResourceVideo?: BitResourceVideo;
  @Input() hostBitType: BitType;
  @Input() bitFormat?: BitmarkFormat;
  @Input() isBeingEditedByUser = false;
  @Output() captionChanged = new EventEmitter<BitResourceVideo>();

  @ViewChild('videoElement') videoElement: ElementRef;
  bitResourceImage: BitResourceImage;
  isVideoVisible = false;
  playError: string;
  aspectRatio: number;
  videoCss: string;

  ngOnInit() {
    // this.bitResourceVideo.posterImage = {
    //   src1x: 'https://docs.bitmark.cloud/bit-books/rav/web-resources/images/auge.png',
    //   format: 'png',
    //   width: 526,
    //   height: 296
    // };

    this.videoCss = `video-bit-type-${this.hostBitType}`;

    if (!this.bitFormat) {
      this.bitFormat = BitmarkFormat.MM;
    }
  }

  ngOnChanges() {
    this.renderVideo();
  }

  ngOnDestroy() {
    this.videoElement = null;
  }

  renderVideo() {
    this.isVideoVisible = !this.bitResourceVideo?.posterImage;
    if (this.bitResourceVideo?.posterImage) {
      this.bitResourceImage = Object.assign({}, this.bitResourceVideo.posterImage);
    }

    this.aspectRatio = +this.bitResourceVideo?.width && +this.bitResourceVideo?.height
      ? +this.bitResourceVideo.width / +this.bitResourceVideo.height
      : 16 / 9;
  }

  onInViewportChange() {
    // const videoEl = this.videoElement.nativeElement as HTMLVideoElement;
    // if (videoEl.src || !$event) {
    //   return;
    // }
    // videoEl.src = this.bitResourceVideo.src;
    // videoEl.load();
  }

  onVideoMetadataLoaded($event: any) {
    if (!(this.bitResourceVideo?.width && this.bitResourceVideo?.height)) {
      const width = $event?.target?.videoWidth;
      const height = $event?.target?.videoHeight;

      if (width && height) {
        this.aspectRatio = width / height;
      }
    }
  }

  startVideoPlay() {
    const videoEl = this.videoElement.nativeElement as HTMLVideoElement;
    this.playError = null;
    videoEl.play()
      .then(() => {
        this.isVideoVisible = true;
      }, (err: DOMException) => {
        console.error(err);
        this.playError = `Cannot play video. ${err.message}`;
      });
  }

  onCaptionChanged(content: JSONContent) {
    this.captionChanged.emit({
      ...this.bitResourceVideo,
      caption: content?.content
    });
  }
}
