<div class='d-flex flex-column w-100'>
  <div class='page-section-folder-body' [innerHTML]='bit?.body'></div>
  <div class='d-flex flex-row flex-wrap page-product-container'>
    @for (product of products; track product) {
      <div class='page-product d-flex flex-column justify-content-center align-items-center'
           [ngClass]="{'entry-wide': isWideEntry, 'cursor-pointer': product?.bookExists, 'cursor-default': !product?.bookExists}">
        <div class='book-cover d-flex align-items-end ml-auto mr-auto px-2 px-lg-3 position-relative'>
          <bitmark-book-cover (click)='openProductPage(product)'
                              (onIsWideImage)='setWideImage()'
                              class='w-100 h-100'
                              [coverImage]="product?.media?.length && product?.media[0].file.url"
                              [isFullyBranded]='true'
                              [isWideCoverAllowed]='true'
                              [isNotExist]="!product?.bookExists"
                              [title]='product?.bookExists ? title : "🤔"'>
          </bitmark-book-cover>
        </div>
        <div class='mt-2 book-texts px-lg-1 px-0' (click)='openProductPage(product)'>
          @if (product?.publisher) {
            <div class='book-publisher mb-0 text-center'
                 [innerText]='product.publisher'>
            </div>
          }
          @if (product?.title) {
            <div class='book-title text-center line-clamp'
                 [innerText]='product.title + (!product?.bookExists ? "&nbsp;🔒" : "")'>
            </div>
          }
          @if (product?.meta) {
            <div>
              @if (BookType.Paperbook === product.meta.type) {
                <div class='text-center book-subtitle'>Paper</div>
              } @else if (BookType.PDFBook === product.meta.type) {
                <div class='text-center book-subtitle'>PDF</div>
              }
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
