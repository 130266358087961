<div class="learning-event-header"
     inViewport
     (inViewportAction)="onInViewportChange($event)">
  @if (isBeingEditedByUser) {
    <div class="d-flex align-items-center">
      @if (!bit.isInfoOnly) {
        <div class="bit-checkbox hidden-input d-flex mr-2">
          <input type="checkbox">
          <label></label>
        </div>
      }
      <div class="learning-event-item">
        <bitmark-editable-text [text]="bit.item"
                               [placeholder]="'Bits.Item' | translate"
                               [cssClass]="'is-editing'"
                               (onChange)="bit.item = $event; saveChanges();">
        </bitmark-editable-text>
      </div>
      <div class="d-flex learning-event-lead-dash-before"></div>
      <div class="learning-event-lead">
        <bitmark-editable-text [text]="bit.lead"
                               [placeholder]="'Bits.Lead' | translate"
                               [cssClass]="'is-editing'"
                               (onChange)="bit.lead = $event; saveChanges();">
        </bitmark-editable-text>
      </div>
      <div class="d-flex learning-event-lead-dash-after"></div>
    </div>
  } @else if (!isItemEmpty || !isLeadEmpty) {
    <div class="d-flex align-items-center">
      @if (!bit.isInfoOnly) {
        <div class="bit-checkbox hidden-input d-flex mr-2">
          <input type="checkbox">
          <label></label>
        </div>
      }
      @if (!isItemEmpty) {
        <div class="learning-event-item">
          <bitmark-text [text]="bit.item"></bitmark-text>
        </div>
      }
      @if (!isItemEmpty && !isLeadEmpty) {
        <div class="d-flex learning-event-lead-dash-before"></div>
      }
      @if (!isLeadEmpty) {
        <div class="learning-event-lead">
          <bitmark-text [text]="bit.lead"></bitmark-text>
        </div>
      }
      @if (!isItemEmpty && !isLeadEmpty) {
        <div class="d-flex learning-event-lead-dash-after"></div>
      }
    </div>
  }
  <div class="d-flex">
    <div class="w-100" [class.mt-1]="bit.item">
      <div class="d-flex position-relative">
        @if (!bit.isInfoOnly) {
          <div class="bit-checkbox mr-2" (click)="toggleCheckbox()">
            <input type="checkbox"
                   [checked]="bit.answer?.isDone">
            <label></label>
          </div>
        }
        @if (isBeingEditedByUser) {
          <div class="learning-event-instruction">
            <bitmark-editable-text
              (onChange)="bit.instruction = $event; saveChanges();"
              [text]="bit.instruction"
              [placeholder]="'Bits.Instruction' | translate"
              [cssClass]="'is-editing'">
            </bitmark-editable-text>
          </div>
        } @else if (bit.instruction) {
          <div class="learning-event-instruction" [class.is-closed]="bit?.answer?.isDone">
            <bitmark-text [text]="bit.instruction"></bitmark-text>
          </div>
        }
        @if (!isBodyEmpty || isBeingEditedByUser) {
          <div class="d-flex cursor-pointer toggle-btn" [class.rotate]="!isBodyCollapsed"
               (click)="toggleBody(bit.id, bit?.answer?.isDone)">
            <bitmark-icon name="chevron-right" class="size-30px"></bitmark-icon>
          </div>
        }
      </div>
    </div>
  </div>
</div>
<div [class.overflow-hidden]="!isBeingEditedByUser" [@slideToggleIsDone]="toggleStateIsDone">
  <div class="d-flex align-items-center flex-wrap learning-event-header-details">
    @if (!bit.isInfoOnly) {
      <div class="bit-checkbox hidden-input d-flex mr-2">
        <input type="checkbox">
        <label></label>
      </div>
    }
    @if (isBeingEditedByUser) {
      <div class="mt-2 mr-2 learning-event-activity-type">
        <bitmark-editable-text
          (onChange)="bit.activityType = $event; saveChanges();"
          [text]="bit.activityType"
          [placeholder]="'Bits.ActivityType' | translate"
          class="py-1 px-2"
          [cssClass]="'is-editing'">
        </bitmark-editable-text>
      </div>
      <div class="d-flex align-items-center mt-2 learning-event-duration">
        <bitmark-icon name="stopwatch" class="size-18px mr-1"></bitmark-icon>
        <bitmark-duration-editor ngDefaultControl
                                 class="is-editing"
                                 [(ngModel)]="bit.duration"
                                 (ngModelChange)="saveChanges()">
        </bitmark-duration-editor>
      </div>
      <div class="d-flex align-items-center mt-2 ml-2 mr-2 learning-event-location">
        <bitmark-icon name="geo-alt" class="size-18px mr-1"></bitmark-icon>
        <bitmark-editable-text
          (onChange)="bit.location = $event; saveChanges();"
          [text]="bit.location"
          [placeholder]="'Bits.Location' | translate"
          [cssClass]="'is-editing'">
        </bitmark-editable-text>
      </div>
    } @else {
      @if (bit.activityType) {
        <div class="mr-2 learning-event-activity-type"
             [class.mt-2]="bit.item || bit.instruction">
          <bitmark-text [text]="bit.activityType" class="py-1 px-2"></bitmark-text>
        </div>
      }
      @if (bit.duration) {
        <div class="d-flex align-items-center learning-event-duration"
             [class.mt-2]="bit.item || bit.instruction">
          <bitmark-icon name="stopwatch" class="size-18px mr-1"></bitmark-icon>
          <span>{{ bit.duration | amDuration }}</span>
        </div>
      }
      @if (bit.location) {
        <div class="d-flex align-items-center mr-2 learning-event-location"
             [class.mt-2]="bit.item || bit.instruction"
             [class.ml-2]="bit.activityType || bit.duration">
          <bitmark-icon name="geo-alt" class="size-18px mr-1"></bitmark-icon>
          <bitmark-text [text]="bit.location"></bitmark-text>
        </div>
      }
    }
  </div>
  <div class="overflow-hidden" [@slideToggle]="toggleState">
    <div class="d-flex">
      @if (!bit.isInfoOnly) {
        <div class="bit-checkbox hidden-input d-flex mr-2">
          <input type="checkbox">
          <label></label>
        </div>
      }
      <div class="flex-fill">
        @if (isBeingEditedByUser) {
          <div class="flex-fill bit-body bit-body-allow-highlight">
            <div class="mt-4 is-editing-multi-line">
              <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                          [bit]="bit"
                                          [bitContent]="bit.body | bitmark:bit.format:'json'"
                                          [isReadOnly]="false"
                                          [placeholder]="'Bits.PlaceholderBody' | translate">
              </bitmark-reader-text-editor>
            </div>
          </div>
        } @else if (!isBodyEmpty) {
          <div class="flex-fill bit-body bit-body-allow-highlight">
            <div class="mt-4">
              <bitmark-text [text]="bit.body" [format]="bit.format"></bitmark-text>
            </div>
          </div>
        }
        @if (bit.book) {
          <div class="d-flex flex-column align-items-center mt-4 learning-event-open-book">
            <div class="image-container" [class.cursor-pointer]="hasAccessToBook" (click)="open()">
              @if (imageExists) {
                <img [src]="bookCoverImageSrc"
                     alt="Book cover"
                     (error)="handleImageLoadError()"/>
              }
            </div>
            <div class="mt-2 learning-event-book-title bit-body-text">
              @if (bookTitle) {
                <bitmark-text [format]="BitmarkFormat.MM" [text]="bookTitle"></bitmark-text>
              } @else {
                <div>&nbsp;</div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  @if (bit.book) {
    <div class="d-flex justify-content-center">
      @if (!bit.isInfoOnly) {
        <div class="bit-checkbox hidden-input d-flex mr-2">
          <input type="checkbox">
          <label></label>
        </div>
      }
      @if (hasAccessToBook) {
        <button class="mt-4 btn btn-action bit-button text--ellipsis d-inline-block"
                [class.mt-2]="imageExists"
                (click)="open()"
                [innerHTML]="bit?.buttonCaption || ('Shared.Open'| translate)">
        </button>
      } @else {
        <div class="mt-4 btn btn-action bit-button btn-no-access justify-content-center">🔒</div>
      }
    </div>
  }

  @if (isBeingEditedByUser) {
    <div class="mt-2"></div>
    <div class="p-2 edit-container">
      <div class="d-flex flex-column align-items-end">
        <div class="d-flex w-100 py-1 align-items-center edit-input-container">
          <div class="edit-input-value text--ellipsis mr-1"
               [ngClass]="{'edit-input-value-placeholder': !bookTitle}">{{ bookTitle || 'Shared.Book' | translate }}
          </div>
          <button class="btn btn-action bit-button text--ellipsis d-inline-block w-auto state-button state-save-button"
                  [innerHTML]="'Shared.ChooseContinue' | translate"
                  (click)="openChooseBook()">
          </button>
        </div>
        <div class="d-flex w-100 py-1 mt-1 align-items-center edit-input-container">
          <div class="edit-input-value text--ellipsis mr-1"
               [ngClass]="{'edit-input-value-placeholder': !bookChapter}">{{ bookChapter || 'Shared.Chapter' | translate }}
          </div>
          @if (bookChapter) {
            <bitmark-icon name="trash-can-outline"
                          class="size-24px mr-1 cursor-pointer remove-book-btn"
                          (click)="removeChosenReference()">
            </bitmark-icon>
          }
          <button class="btn btn-action bit-button text--ellipsis d-inline-block w-auto state-button state-save-button"
                  [innerHTML]="'Shared.ChooseContinue' | translate"
                  [disabled]="!this.bit.book"
                  (click)="openChooseReference()">
          </button>
        </div>
      </div>
    </div>
  }
</div>

