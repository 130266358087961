<div class="reader-shadow bg-white modal-container">
  @if (videoResource) {
    <div class="p-4 preview">
      <bitmark-resource-video-link [bitResource]="videoResource"></bitmark-resource-video-link>
    </div>
  } @else {
    <div class="d-flex flex-column align-items-center justify-content-center video-placeholder">
      <img src="../../../../../assets/images/logo-youtube.png"
           alt="youtube"
           class="py-1 video-logo"/>
      <img src="../../../../../assets/images/logo-vimeo.png"
           alt="vimeo"
           class="py-1 video-logo"/>
    </div>
  }
  <div class="p-2 edit-container">
    <input type="text"
           class="form-control mb-1"
           placeholder="https://..."
           [(ngModel)]="videoLink"
           (ngModelChange)="onLinkChanged()"/>
    <div class="d-flex justify-content-center m-2 mt-4">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
</div>
