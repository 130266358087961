@if (user) {
  <div class='cursor-pointer d-flex flex-row justify-content-start align-items-center pb-1'
       [ngClass]="{'text-gray': !user?.id, 'text-white': user?.id}">

    <img *ngIf='user?.photoUrl' [src]='user?.photoUrl' class='avatar-photo mr-2' />
    <div *ngIf='!user?.photoUrl' class='px-2' style='margin-right: 12px'></div>
    @if (!isEditing) {
      <div>
        <span [ngClass]="{'text-green': user?.status === 'new', 'text-gradient': user?.status === 'redundant'}">
          @if (user.fullName) {
            <span [innerHTML]='highlightedFullName'></span>
          }
          @if (user.fullName && (isOrganizer || showEmails)) {
            <span> <</span>
          }
          @if (user.email && (isOrganizer || showEmails)) {
            <span [innerHTML]='highlightedEmail'></span>
          }
          @if (user.fullName && (isOrganizer || showEmails)) {
            <span>></span>
          }
          @if (!user?.isCourseSessionJoined && isParticipantsList && isOrganizer){
            <em class="ml-1">Payment Open</em>
          }
        </span>
        @for (emoji of user?.emoji; track emoji) {
          <span class='ml-1 color-calm'>{{ emoji }}</span>
        }
      </div>
    } @else {
      <span class='d-flex mb-0 justify-content-between align-items-center py-2'
            [ngClass]='{"translated-label": !isEditing, "orange-editable-field": isEditing}'>
                <span [contentEditable]='isEditing' #editingLabel>{{ user.email }}</span>
      <div class='d-flex flex-row align-items-center'
           style='min-width: 54px'>
        <img (click)='cancelEditing()'
             src='assets/images/ts-editor/close.png'
             class='translated-label-icon ml-2'/>
        <img (click)='saveEmail()'
             src='assets/images/ts-editor/ribbon-save.png'
             class='translated-label-icon ml-1'/>
      </div>
    </span>
    }
    <ng-template #dropdownButton>
      <div class='px-1 cursor-pointer'>
        <div class='dot' [ngClass]="{'calm': !isOrganizer, 'dot-manage': isOrganizer}"></div>
      </div>
    </ng-template>
    <app-dropdown class='d-inline-block mr-3 user-dot-dropdown'
                  [placement]="['bottom-left', 'top-left', 'left']"
                  [actions]='userActions'
                  [dropdownButton]='dropdownButton'
                  dropdownClass='auto-width'>
    </app-dropdown>
  </div>
}
<ng-template #emojiMenu>
  <div class='emoji-menu'>
    <div class='d-flex flex-row justify-content-between align-items-center'>
      <button class='emoji-item' (click)="toggleEmoji('✅')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('✅') !== -1}">
        <strong>✅</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('❌')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('❌') !== -1}">
        <strong>❌</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('🥇')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('🥇') !== -1}">
        <strong>🥇</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('😃')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('😃') !== -1}">
        <strong>😃</strong>
      </button>
    </div>
    <div class='d-flex flex-row justify-content-between align-items-center' style='margin-top: 6px'>
      <button class='emoji-item' (click)="toggleEmoji('🚀')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('🚀') !== -1}">
        <strong>🚀</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('🧠')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('🧠') !== -1}">
        <strong>🧠</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('✍️')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('✍️') !== -1}">
        <strong>✍</strong>
      </button>
      <button class='emoji-item' (click)="toggleEmoji('👍️')"
              [ngClass]="{ 'is-active-emoji': user?.emoji && user?.emoji?.indexOf('👍️') !== -1}">
        <strong>👍</strong>
      </button>
    </div>
  </div>
</ng-template>
