<div [ngClass]="cssClass">
  @if (iconActions?.length) {
    <div class="icon-actions-container">
      @for (action of iconActions; track action.icon) {
        @if (!action.isHidden) {
          <bitmark-icon [name]="action.icon" class="size-18px cursor-pointer icon-action" (click)="action.handler()"></bitmark-icon>
        }
      }
    </div>
  }
  <ng-template #dropdownButton>
    @if (!isCustomIcon) {
      <div class="dot-wrapper"
           (click)="augmentActions($event)">
        <div class="dot"></div>
      </div>
    } @else {
      <div (click)="augmentActions($event)"
           class="btn-action btn-action--filled btn-circle mt-2 mb-1">
        <bitmark-icon name="plus" class="p-0"></bitmark-icon>
      </div>
    }
  </ng-template>
  <bitmark-dropdown class="d-inline-block ml-auto"
                    id="topDropdown"
                    container="body"
                    [placement]="['bottom-right', 'bottom-left', 'top-right', 'top-left', 'left', 'right']"
                    [actions]="augmentedActions"
                    [dropdownButton]="dropdownButton"
                    [dropdownClass]="dropdownClass || 'lg'">
  </bitmark-dropdown>
</div>
