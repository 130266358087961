export const translation = {
  'ToC': {
    'cover': 'Prefață',
    'final': 'Final'
  },
  'Auth': {
    'LogOut': 'Ieșire',
    'Login': 'Intră în Cont',
    'Register': 'Cont nou',
    'Settings': 'Setări',
    'AlreadyHaveAnAccount': 'Ai deja cont?',
    'Email': 'Email',
    'Password': 'Parola',
    'ShowPassword': 'Afișează',
    'HidePassword': 'Ascunde',
    'LostPassword': 'Am pierdut parola',
    'SignInWith': 'Intră cu {{socialMedia}}',
    'GoToLogin': 'Conectează-te din nou',
    'UseAnotherAccount': 'Utilizează un alt cont...'
  },
  'Meta': {
    'ContentNotAvailable': 'Conținutul nu este momentan disponibil',
    'NewVersionAvailable': 'O Nouă Versiune este Disponibilă',
    'PleaseUpgrade': 'Te rugăm să updatezi la versiunea {{newVersion}}'
  },
  'Menu': {
    'Notebooks': 'Workspace',
    'Books': 'My Library',
    'BookShop': 'Academy'
  },
  'Contact': {
    'ContactUs': 'Contacteaza-ne',
    'Support': 'Suport',
    'UpgradeAndLogout': 'Actualizează și loghează-te din nou',
    'ClearCache': 'Curăță Cache-ul',
    'APISupported': 'Compatibil cu IndexedDB-API',
    'MBAvailable': 'MB disponibili',
    'ServiceWorkerActive': 'Service worker-ul este activ',
    'NewVersionAvailable': 'O nouă versiune e disponibilă',
    'SystemUpToDate': 'Sistemul dumneavoastră pare să fie la zi. Totuși, puteți folosi butonul de mai jos ca să ' +
      'vă asigurați că aveți cea mai nouă versiune',
    'PrivacyPolicy': 'Termeni și Servicii'
  },
  'Search': {
    'ShowOnlyFreeTitles': 'Arată doar titlurile gratuite',
    'SearchAllBooks': 'Arată Toate Cărțile',
    'SearchBooks': 'Căutare de cărți',
    'ContentForAllBooks': 'Conținutul tuturor cărților',
    'SearchAllBits': 'Caută în Toți Biții',
    'SearchNotebook': 'Caută Caiet',
    'SearchBook': 'Caută Carte',
    'SearchChapter': 'Caută Capitol din Carte',
    'BooksFor': 'Cărți pentru {{searchedText}}',
    'ResultsByPublisher': 'Rezultate pentru Editor «{{searchedText}}»',
    'ResultsByAuthor': 'Rezultate pentru Autor «{{searchedText}}»',
    'BitsFor': 'Biți pentru «{{searchedText}}»',
    'PaginationOutOf': '{{begin}} - {{end}} din {{total}}',
    'Previous': 'Înapoi',
    'Next': 'Înainte',
    'LastSearched': 'Ultimele Căutări',
    'LastViewed': 'Ultimele Vizualizate',
    'NoBooksFound': 'Nicio carte găsită',
    'NoBitsFound': 'Niciun bit găsit',
    'BooksNotFound': 'Nicio carte găsită',
    'BitsNotFound': 'Niciun bit găsit'
  },
  'Profile': {
    'Title': 'Profil',
    'FirstName': 'Prenume',
    'LastName': 'Nume',
    'Country': 'Țara',
    'Language': 'Limba',
    'PrivateEmail': 'Email Privat',
    'MobilePhone': 'Telefon Mobil',
    'DoubleChecking': 'Verificăm doar că ești tu!',
    'ChangePassword': 'Schimba Parola',
    'ResetPassword': 'Reseteaza Parola',
    'YourOldPassword': 'Parola veche',
    'NewPassword': 'Parola Noua',
    'ChangePasswordSuccess': 'Parola ta a fost schimbată cu succes!',
    'ChangePasswordFail': 'Nu am reușit să iți schimbăm parola.',
    'AddPhoto': 'Adaugă Poza',
    'RemovePhoto': 'Șterge Poza',
    'SavePhoto': 'Salvează Poza',
    'ChoosePhoto': 'Alege o poza sau trage una deasupra.',
    'AddPicture': 'Adaugă Poza',
    'RemovePicture': 'Șterge Poza',
    'SavePicture': 'Salvează Poza',
    'ChoosePicture': 'Alege o poza sau trage una deasupra.',
    'UpdatePhotoFail': 'Nu am reușit să iți schimbăm poza.',
    'EmailEmptyError': 'Emailul este obligatoriu!',
    'PasswordEmptyError': 'Parola este obligatorie!',
    'ImageTooLarge': 'Imaginea e prea mare. Trebuie să fie mai mică de 3MB',
    'ValidationMessage': 'Un email de validare a fost trimis către noua ta adresă',
    'YourEmailAddress': 'Adresa ta de email',
    'HasBeenVerified': 'a fost verificat cu succes.',
    'ResendVerificationMailTo': 'Retrimite Emailul de Validare la',
    'EmailNotVerified': 'Emailul tău nu a fost verificat'
  },
  'Timeline': {
    'Available': 'Disponibil',
    'NotAvailable': 'Indisponibil',
    'Reply': 'Raspunde',
    'Today': 'Astăzi',
    'ConnectTo': 'Conectează-te cu ...',
    'EmailPeer': 'Emails (conturi existente Get More Brain)',
    'UserNotFound': 'Utilizatorul nu a fost găsit!',
    'NewMessages': 'Mesaje Noi',
    'NewMessage': 'Mesaj Nou',
    'TypeAMessage': 'Scrie un mesaj...',
    'ConnectMessage': 'Bună, bine te-am găsit!',
    'MarkAllAsRead': 'Marchează-le pe toate ca citite',
    'DeleteChat': 'Ștergeți conversația',
    'DeleteChatConfirm': 'Sunteți sigur că doriți să ștergeți această conversație?',
    'BlockUser': 'Blochează utilizator',
    'BlockUserConfirm': 'Ești sigur că vrei să blochezi acest utilizator?',
    'UnblockUser': 'Deblochează utilizator',
    'UnblockUserConfirm': 'Ești sigur că vrei să deblochezi acest utilizator?',
    'ConnectionRequest': 'Cerere de conectare',
    'ManageGroup': 'Administrare grup',
    'LeaveGroup': 'Părăsire grup',
    'CreateEntry': {
      'AddMultiQuizContinue': 'Adauga multi-quiz',
      'AddBlankQuiz': 'Adauga quiz gol',
      'OpenLinkInBrowser': 'Deschide in browser',
      'CopyLink': 'Copiaza link',
      'AddHintContinue': 'Adauga sugestie...',
      'EditLinkContinue': 'Editeaza link...',
      'EditHintContinue': 'Editeaza sugestie...',
      'RemoveHint': 'Sterge sugestie',
      'Unlink': 'Decupleaza',
      'Video': 'Video',
      'TakePicture': 'Fă Poză sau Bibliotecă',
      'PhotoLibrary': 'Bibliotecă de Poze',
      'Audio': 'Audio',
      'Document': 'Document',
      'Emoji': 'Emoji'
    },
    'Actions': {
      'YouDeletedThisMessage': 'Ai șters acest mesaj',
      'ThisMessageWasDeleted': 'Acest mesaj a fost șters',
      'ThisMessageIsHidden': 'Acest mesaj este ascuns',
      'HideSolution': 'Ascunde Soluția',
      'RevealSolution': 'Afișează Soluția',
      'Verify': 'Verifică',
      'Repeat': 'Repetă',
      'TextContinue': 'Text...',
      'HrefContinue': 'Href...',
      'HintContinue': 'Hint...',
      'PasteVideoLink': 'Adaugă un link video dedesubt',
      'BrowserDoesNotSupportAudio': 'Browserul tău nu are suport pentru elementul audio.',
      'BrowserDoesNotSupportVideo': 'Browserul tău nu are suport pentru elementul video.',
      'ChooseDocument': 'Alege un document sau trage unul deasupra.',
      'ChooseAudio': 'Alege un fișier audio sau trage unul deasupra.',
      'ErrorConnecting': 'Nu te-am putut conecta cu {{email}}',
      'UserNotFound': 'Emailul {{email}} nu a fost găsit',
      'PasteAndSendBit': 'Lipeste si trimite bit',
      'SendBasket': 'Trimite Cos',
      'ErrorUploadingPdf': 'We could not convert your pdf. The problem was logged. We are working on a solution to convert more complex pdf\'s in the future.'
    },
    'Quiz': {
      'CannotSend': 'ro-You cannot send the quiz now.',
      'CannotSendWithoutGap': 'ro-You cannot send a quiz without any gap.'
    },
    'SearchAll': 'Toți utilizatorii'
  },
  'Notebooks': {
    'UntitledNotebook': 'Caiet Nou',
    'UntitledDraftBook': 'Draft carte nou',
    'UntitledFlashcards': 'Card Nou',
    'Title': 'Workspace',
    'PersonalNotebooks': 'Caiete Personale',
    'Homework': 'Temă de Casă',
    'SmartNotebooks': 'Caiete Inteligente',
    'DeleteConfirm': 'Ești sigur că vrei să ștergi caietul «{{notebook}}»? Operațiunea e irevocabilă!',
    'Notebook': 'Caiet',
    'Worksheet': 'Foaie de Lucru',
    'Flashcards': 'Carduri',
    'RecentQuizzes': 'Quiz-uri Recente',
    'RecentVideos': 'Video-uri Recente',
    'LearningPath': 'Obiectiv de învățare',
    'DraftBook': 'Draft carte',
    'DropFileHere': 'Puneți fișierul aici',
    'DropFileAllowedTypes': 'Aveți voie să puneți numai fișiere PDF sau bitmark'
  },
  'HandIn': {
    'Archive': 'Arhivează',
    'Unarchive': 'Dezarhivează',
    'Today': 'azi',
    'Yesterday': 'ieri',
    'Assign': 'Asigneaza...',
    'Unassign': 'Scoate asignarea',
    'AssignToMe': 'Asigneaza-mi mie',
    'YourHandInApproved': 'Munca ta a fost aprobata!',
    'YourHandInRejected': 'Munca ta a fost respinsa.',
    'PleaseReadComments': 'Te rugam sa citesti comentariile cu atentie si sa incerci din nou.',
    'BookWasHandedIn': 'Cartea a fost trimisa',
    'Locked': 'Blocat',
    'By': 'de',
    'YouHaveApprovedThis': 'Ai aprobat aceasta tema.',
    'For': 'pentru',
    'YouHaveRejectedThis': 'Ai respins aceasta tema.',
    'PleaseHandInTheBookReady': 'Te rugam sa trimiti cartea cand ai terminat de completat.',
    'PleaseHandInTheBookUntil': 'Te rugam sa trimiti cartea pana la',
    'YourBookWillBeLocked': 'Cartea ta va fi blocata 🔒 (ne-modificabila) dupa trimitere.',
    'BookWasHandedInSuccessfully': 'Cartea ta a fost trimisa cu succes!',
    'YourWorkOnThisBookWasApproved': 'Munca ta pe aceasta carte a fost aprobata!!',
    'ThereWasAProblemHandingIn': 'A aparut o problema la trimiterea cartii.',
    'ThereWasAnErrorAccepting': 'A aparut o problema la aprobarea cartii.',
    'ThereWasAnErrorAssigning': 'A aparut o problema la asignarea cartii.',
    'ErrorNoExperts': 'Nu exista experti pentru asignat.',
    'NoNewHandIns': 'Fara depuneri noi',
    'NoAssignedHandIns': 'Fara depuneri asignate',
    'NoHandIns': 'Fara depuneri',
    'NewHandIns': 'Depuneri ne-asignate',
    'AssignedToMe': 'Review-urile mele',
    'All': 'Aprobate'
  },
  'Books': {
    'MyBooks': 'Cărți',
    'Books': 'cărți',
    'Courses': 'Cursuri',
    'MyClasses': 'Clasele mele',
    'RecentlyUsed': 'Folosite Recent',
    'SelfLearning': 'Învățare autodeterminată',
    'Purchased': 'Cumpărate',
    'All': 'Toate',
    'Unbuy': 'Cumpără',
    'UnbuyTraining': 'Anuleaza Cursul',
    'ChatWithBook': 'Discuta cu Cartea',
    'Publisher': 'Editor',
    'Authors': 'Autori',
    'Published': 'Publicat la',
    'Updated': 'Modificat la',
    'Language': 'Limba',
    'ISBN': 'ISBN',
    'EmptyLibraryMessage': 'Te întrebi de ce "My Library" este goală? Aruncă o privire în',
    'CostsCoveredBy': 'Costurile sunt acoperite de {{company}}',
    'Pages': 'Pagini',
    'AlsoAvailableIn': 'Disponibilă și în',
    'Series': 'Seria «{{seriesName}}»',
    'CurrentSeries': 'Seria Curentă',
    'OnlyPaper': 'Cartea disponibilă doar în format fizic.',
    'VoteMakeBookAvailable': 'Votează să fie disponibilă și online',
    'DigitalMaterial': 'Dar avem câteva materiale digitale interesante pentru această carte.',
    'OneVoteToMakeAvailable': '{{numberOfVotes}} vot pentru a fi disponibilă online',
    'VotesToMakeAvailable': '{{numberOfVotes}}  voturi pentru a fi disponibilă online',
    'Types': {
      'Flashcards': 'Flashcard-uri',
      'PPT': 'PPT',
      'Word': 'Word',
      'Excel': 'Excel',
      'PDF': 'PDF',
      'Script': 'Script',
      'Quizzes': 'Quiz-uri',
      'Video': 'Video',
      'VideoLecture': 'Prezentare Video',
      'LTIContainer': 'Container LTI',
      'WBT': 'WBT',
      'Audio': 'Audio',
      'LiveBook': 'Carte Live',
      'Homework': 'Temă de Casă'
    }
  },
  'Shared': {
    'BroadcastSubtitle': 'Trimite către toti studentii din clasă',
    'Join': 'Înscrie-te',
    'Edit': 'Modifică',
    'Edited': 'Edited',
    'EditContinue': 'Modifică...',
    'SetContinue': 'Setează...',
    'Remove': 'Sterge',
    'Cancel': 'Înapoi',
    'OK': 'OK',
    'Search': 'Caută',
    'SearchStudents': 'Caută Studenți',
    'Open': 'Deschide',
    'OpenContinue': 'Deschide...',
    'Download': 'Descarcă',
    'Share': 'Distribuie',
    'Send': 'Trimite',
    'Show': 'Arată',
    'Insert': 'Inserează',
    'ShowHiddenFields': 'Afişează Toate Câmpurile...',
    'HideHiddenFields': 'Ascunde Toate Câmpurile',
    'SendContinue': 'Trimite...',
    'Sending': 'În proces de trimitere',
    'SendingContinue': 'În proces de trimitere...',
    'Rename': 'Redenumește',
    'Replace': 'Înlocuiește',
    'ReplaceContinue': 'Înlocuiește...',
    'Delete': 'Șterge',
    'DeleteContinue': 'Șterge...',
    'DeletePermanentlyContinue': 'Șterge permanent...',
    'DeleteImmediately': 'Șterge acum',
    'Save': 'Salvează',
    'Sell': 'Vinde',
    'For': 'pentru',
    'Hide': 'Ascunde',
    'Free': 'Gratuit',
    'Buy': 'Cumpără',
    'Unbuy': 'De-Cumpără',
    'Here': 'Aici',
    'Continue': 'Continuă',
    'Name': 'Nume',
    'Favorites': 'Favorite',
    'Copy': 'Copiază',
    'Paste': 'Lipește',
    'PasteSpecial': 'Lipește Special...',
    'Cut': 'Taie',
    'Undo': 'Anulează',
    'Accept': 'Acceptă',
    'Reject': 'Respinge',
    'Revoke': 'Revocă',
    'SentOn': 'Trimis la',
    'RejectedOn': 'Respinsă la',
    'RevokedOn': 'Revocată la',
    'UploadPDF': 'Adaugă PDF',
    'Duplicate': 'Duplică',
    'Page': 'Pagina',
    'MarkAsDone': 'Marchează ca terminat',
    'RateAndMarkAsDone': 'Rate and Mark as Done ✅',
    'HandIn': 'Predă Cartea',
    'Choose': 'Selectați',
    'ChooseContinue': 'Selectați...',
    'CloseConfirm': 'Sunteți sigur că doriți sa închideți?',
    'CloseModalConfirm': 'Sunteți sigur că doriți sa închideți fereastra?',
    'TryAgain': 'Reîncearcă',
    'Retry': 'Reîncearcă',
    'True': 'Adevărat',
    'False': 'Fals',
    'Start': 'Start',
    'ChooseBook': 'Alege Carte',
    'ChooseChapter': 'Alege Capitol',
    'Book': 'Carte',
    'Chapter': 'Capitol',
    'Video': 'Video',
    'CameraContinue': 'Cameră...',
    'UploadContinue': 'Adaugă...',
    'EmbedVideoContinue': 'Încorporează (Youtube, Vimeo)...',
    'Audio': 'Audio',
    'Voice': 'Voce',
    'Image': 'Imagine',
    'UrlContinue': 'URL...',
    'Documents': 'Documente',
    'LinkContinue': 'Link...',
    'Website': 'Site web',
    'ManageContinue': 'Organizați...',
    'Caption': 'Legendă',
    'Attempts': 'Încercări',
    'Close': 'Închide',
    'AddToCalendar': 'Adaugă în calendar',
    'ReplaceImageContinue': 'Înlocuiește Imaginea...'
  },
  'Shop': {
    'Price': 'Preț',
    'TotalPrice': 'Preț total',
    'YouAreBuyingFor': 'Cumpărați pentru',
    'Shop': 'Academy',
    'Product': 'Produs',
    'Products': 'Produse',
    'PurchaseSuccessful': 'Ai cumpărat cu succes!',
    'AddedSuccessfully': 'Adăugată cu Succes!',
    'PurchaseFailed': 'Cumpărarea a eșuat.',
    'BookNotFound': 'Cartea nu a fost găsită.',
    'AllBooksNext': 'Toate Cărțile »',
    'SubCategories': 'subcategorii',
    'MoreInfo': 'Detalii...',
    'MoreInfoContinue': 'Mai multe...',
    'ThisIsAdditional': 'Acest produs e un Material de Suport pentru',
    'SupportingMaterial': 'Supporting Material',
    'ThanksForJoining': 'Mulțumim pentru participare!',
    'Publish': 'Publish',
    'EditContinue': 'Modifica...',
    'EditLP': 'Learning Path...',
    'InvitedBy': 'Ați fost invitat de {{user}}',
    'InvitationFor': 'Invitație pentru',
    'GetAnotherCopy': 'Obțineți o altă copie',
    'Editor': {
      'CopyEmailAddress': 'Copiați adresa de e-mail',
      'Chat': 'Chat',
      'SendEmail': 'Trimite e-mail...',
      'AddFlag': 'Selecteaza Emoji',
      'CopyAll': 'Copiaza-i pe Toti',
      'ReplaceAll': 'Inlocuieste Tot',
      'AddSeveral': 'Adauga Mai Multi...',
      'ResendInvitation': 'Resend Invitation ✉️',
      'ByInvitationOnly': 'Acest curs se organizează numai pe bază de invitație (nu este posibilă achiziționarea sau autoînregistrarea)',
      'ClosedCourse': 'Curs închis - Participanții sunt gestionați doar de către organizatori 🔒',
      'Content': 'Content',
      'ShopPage': 'Pagina Shop',
      'CustomShopPage': 'Pagina Shop Customizata',
      'NewRelease': 'Versiune nouă',
      'InviteUsersToBuy': 'Invite Users to Buy...',
      'InviteUsersToJoin': 'Invitați utilizatorii să cumpere...',
      'BuyForUsers': 'Cumpărați pentru utilizatori...',
      'JoinForUsers': 'Adăugați participanti...',
      'BuyForUser': 'Buy For User...',
      'JoinForUser': 'Join User...',
      'DeleteAll': 'Sterge Tot...',
      'SortByEmail': 'Sorteaza dupa Email',
      'SortByFirstName': 'Sorteaza dupa Prenume',
      'SortByLastName': 'Sorteaza dupa Nume',
      'SendReleaseNotes': 'Trimite Notele de Release',
      'SectionTitle': 'Titlu de Secțiune'
    },
    'Course': {
      "Description": "Descriere",
      'Content': 'Continut',
      'UpcomingDates': 'Clase',
      'CourseContent': 'Continutul Cursului',
      'CourseLessons': 'Lectii',
      'CourseStartDate': 'Incepand din',
      'CourseBooks': 'Course Books',
      'TheCurriculum': 'Curriculum',
      'BuyForAll': 'Cumpărați pentru participanți',
      'UpdateInvitations': 'Actualizați invitațiile',
      'Now': 'Acum',
      'Next': 'Urmează',
      'Done': 'Terminat',
      'Participants': 'Participanti',
      'Organizers': 'Organizatori',
      'Coaches': 'Traineri',
      'Editors': 'Editori',
      'InvitationsUpdated': 'Invitațiile au fost actualizate pentru toți participanții!',
      'BoughtForParticipants': 'Toți participanții s-au înscris la curs.',
      'CopyLink': 'Copiază Link',
      'Duration': 'Durată',
      'Effort': 'Efort',
      'Format': 'Format',
      'ClassSize': 'Dimensiunea clasei',
      'Career': 'Carieră',
      'Tuition': 'Taxe de școlarizare',
      'MoveUp': 'În sus',
      'MoveDown': 'În jos',
      'HandInFolder': 'Dosar Hand-in pentru feedback-ul experților',
      'Experts': 'Experți',
      'Modules': 'Modulele',
      'Starts': 'Începuturi',
      'Ends': 'Sfârșituri',
      'ViewDetails': 'Vezi detalii',
      'NoPriceSet': 'Prețul pentru acest produs nu a fost stabilit. Pentru ca participanții să poată accesa acest produs, trebuie să setați mai întâi prețul.',
      'ModulesCouldNotBeUpdated': 'Modulele nu au putut fi actualizate. Poate ați încercat să încărcați prea multe fotografii simultan. Vă rugăm să încercați din nou mai târziu.',
      'ProductLoadError': 'A apărut o eroare la încărcarea produsului',
      'AllParticipantsInvited': 'Toți participanții au fost invitați!',
      'AllParticipantsJoinedCourse': 'Toți utilizatorii s-au înscris deja la curs',
      'UsersJoinError': 'A existat o problemă de conectare a utilizatorilor',
      'InvitationSent': 'Invitația a fost trimisă!',
      'SelfJoinedParticipants': 'Participanți independenți',
      'EmailLanguage': 'Limba de e-mail pentru noii utilizatori înregistrați - '
    }
  },
  'Bits': {
    'With': 'Către',
    'SuccessfullySent': 'Trimis cu succes',
    'ToUser': 'către {{userName}}',
    'Recent': 'Recent',
    'ModuleDone': 'Modul terminat',
    'HadRead': 'Citit',
    'SendAt': 'Trimis la',
    'Item': 'Număr',
    'Lead': 'Lead',
    'Location': 'Locație',
    'Instruction': 'Instrucțiune',
    'Action': 'Sarcină',
    'Duration': 'Durată',
    'From': 'De la',
    'To': 'Până la',
    'Quiz': 'ro-Quiz',
    'PlaceholderBody': '...',
    'NoResourceSelected': 'Nici o resursă selectată',
    'GetFeedback': 'Obțineți feedback',
    'DoItAgain': 'Din nou',
    'FinalFeedback': 'Feedback final',
    'FeedbackRetryOrSubmitMessage': 'Ai răspuns corect la {{correct}} din {{total}} întrebări.',
    'Generate': 'Generează'
  },
  'Reader': {
    'SendMyAnswer': 'Trimite răspunsul',
    'ShowAll': 'Arată tot',
    'CreateNewNotebook': 'Creează «{{untitledNotebook}}»',
    'Search': {
      'Search': 'Caută...',
      'LastSearched': 'Ultimele Căutări',
      'NoResultsFound': 'Niciun rezultat găsit.'
    },
    'Editor': {
      'NewChapter': 'Capitol Nou',
      'Title': 'Titlu',
      'Subtitle': 'Subtitlu',
      'Text': 'Text',
      'Code': 'Cod',
      'Math': 'Matematica',
      'Link': 'Link',
      'Quote': 'Citat',
      'ScientificQuote': 'Citat Stiintific',
      'HistoricQuote': 'Citat Istoric',
      'List': 'Lista',
      'NumberList': 'Lista Numerica',
      'LetterList': 'Lista de Litere',
      'RomanList': 'Lista romana',
      'TaskList': 'Lista Taskuri',
      'NoBullets': 'Fara Bullets',
      'AddNote': 'Adaugă Nota',
      'AddHandwritten': 'Adaugă Scribble',
      'AddQuickNote': 'Adaugă Nota Rapida',
      'TypeSomething': 'Începe să scrii ...',
      'InvalidBitmark': 'bitmark invalid',
      'ImageLeftAligned': 'Stânga',
      'ImageRightAligned': 'Dreapta',
      'ImageCenterAligned': 'Centrat',
      'MathFormulasNotSupported': 'Editarea biților cu formule matematice nu este încă acceptată'
    },
    'Handwritten': {
      'WaitingForImage': 'Asteptand imaginea',
      'ErrorLoadingImage': 'Eroare la incarcarea imaginii',
      'LoadingImage': 'Incarcam imaginea',
      'PreparingImage': 'Pregatim imaginea',
      'ColorPalette': 'Paleta de culori',
      'ExtraSmall': 'Extra mic',
      'Small': 'Mic',
      'MediumSmall': 'Mic spre mediu',
      'Medium': 'Mediu',
      'MediumLarge': 'Mediu spre mare',
      'Large': 'Mare',
      'ExtraLarge': 'Extra mare',
    },
    'Filters': {
      'Quiz': 'Conținut',
      'Resources': 'Resurse',
      'Grade': 'Clasă',
      'Complexity': 'Complexitate',
      'Tags': 'Tag-uri',
      'Skills': 'Skill-uri'
    },
    'Actions': {
      'Send': 'Trimite',
      'SendContinue': 'Trimite...',
      'SendToClass': 'Trimite Către Studentii Clasei...',
      'Broadcast': 'Trimite',
      'SendBook': 'Trimite Cartea',
      'SendAll': 'Trimite tot',
      'DeleteBit': 'Șterge Bit',
      'DeleteAll': 'Șterge Tot',
      'SaveToNotebook': 'Salvează în Caiet...',
      'SaveToAnotherNotebook': 'Salvează în alt Caiet...',
      'SaveAllToNotebook': 'Salvează tot în Caiet',
      'ResetBookRating': 'Reset Book Rating',
      'FindInBook': 'Afișează în Carte',
      'SuccessfullyStored': 'Salvat cu succes!',
      'SavingTo': 'Salvăm în «{{notebookName}}»',
      'SuccessfullyStoredToNotebook': 'Salvat cu succes în caietul «{{notebookName}}»',
      'ErrorOcurredStoring': 'A apărut o eroare în procesul de salvare în «{{notebookName}}»',
      'CopyBitmarkType': 'Copiază bitmark {{bitmarkType}}',
      'EditBitmarkType': 'Modifică bitmark {{bitmarkType}}',
      'BrandingPublisherTheme': 'Editor {{publisherName}} {{newline}} {{publisherId}}/{{theme}}',
      'ExtractText': 'Extrage Textul',
      'CopyBitmarkJSON': 'Copiază bitmark JSON',
      'CopyLinkToBit': 'Copiază link către bit',
      'CopyAllBitsToBasket': 'Copiază toți biții în coș',
      'ResetAnswer': 'Resetează răspunsul',
      'ResetHighlights': 'Resetează evidențierile',
      'JoinCall': 'Alăturați-vă apelului',
      'Add': 'Adaugă',
      'AddChapter': 'Adaugă Capitol',
      'AddCard': 'Adaugă Card',
      'UploadCards': 'Uploadează Carduri...',
      'PasteQuizletCards': 'Lipiți carduri din Quizlet (TSV)',
      'PasteStepsScribePro': 'Lipiți pași din Scribe Pro (Markdown)',
      'AddBit': 'Adaugă Bit',
      'AddLearningPathLTI': 'Adaugă o cale de învățare LTI',
      'AddLearningPath': 'Adaugă o cale de învățare',
      'EditLearningPathContinue': 'Modifică calea de învățare...',
      'LearningPathGoal': 'Obiectiv de învățare',
      'LearningPathReadBook': 'Citește o carte',
      'LearningPathStep': 'Pas de învățare',
      'LearningPathVideoCall': 'Apel video',
      'LearningPathExternalLink': 'Link extern',
      'LearningPathLTI': 'Resursă LTI',
      'LearningPathClassroomEvent': 'Eveniment în clasă',
      'LearningPathClosing': 'Finalizare',
      'AddBotMessage': 'Adaugă un mesaj bot',
      'AddBotAction': 'Adaugă o acțiune bot',
      'BotActionSend': 'Send Message',
      'AddNote': 'Adaugă Notă',
      'AddHandwritten': 'Adaugă Scribble',
      'AddBookmark': 'Adaugă un marcaj',
      'AddFavorite': 'Adaugă favorit',
      'AddArticle': 'Adăugați un bloc de text',
      'AddQuote': 'Adaugă Citat',
      'ExampleNotes': 'Exemple de Note',
      'InsertBasket': 'Inserați Coș',
      'SetCover': 'Uploadează Copertă Personalizată...',
      'RemoveCover': 'Șterge Coperta Personalizată',
      'CoverStyle': 'Stilul Copertei',
      'DefaultCover': 'Imaginea pe copertă',
      'FullCover': 'Imaginea acoperă coperta',
      'CustomCover': 'Copertă personalizată',
      'ErrorSetCover': 'Această imagine este prea mică. Vă rugăm adăugați o imagine cu dimensiunile minime de 700px lungime sau 1000px înălțime.',
      'AddAuthorNote': 'Adăugați o notă a autorului',
      'AddReviewerNote': 'Adăugați o notă a evaluatorului',
      'AddInfo': 'Adăugați informații',
      'AddSideNote': 'Adăugați o notă secundară',
      'AddRemark': 'Adăugați observație',
      'AddExample': 'Adăugați un exemplu'
    },
    'Basket': {
      'Basket': 'Coșul Tău',
      'EmptyBasket': 'Goliți coșul',
      'AddToBasket': 'Adaugă în Coș',
      'RemoveFromBasket': 'Șterge din coș',
      'SendAll': 'Trimiteți tot',
      'SaveAll': 'Salvați tot'
    },
    'Bin': {
      'RestoreAll': 'Pune totul înapoi',
      'EmptyBin': 'Golește coșul',
      'Restore': 'Pune înapoi',
      'MoveToBin': 'Mută în coș',
      'DeleteBitConfirmation': 'Ești sigur că vrei să ștergi acest bit?'
    },
    'Bits': {
      'Level': 'Nivel'
    },
    'Translate': {
      'Translate': 'Traducere',
      'TranslateTitle': 'Traducere...',
      'ChooseLanguage': 'Alege limba',
      'SearchLanguages': 'Caută limba',
      'RecentLanguages': 'Limbile folosite recente',
      'AllLanguages': 'Toate limbile'
    },
    'LearningEvents': {
      'ThisWeek': 'Săptămână aceasta',
      'Next14Days': 'Următoarele 14 de zile',
      'AllUpcoming': 'Următoarele',
      'Past': 'Trecute'
    }
  },
  'System': {
    'SystemTitle': 'System',
    'Bin': 'Coș'
  },
  'Cockpit': {
    'AverageCompletedActivities': 'Activități finalizate în medie',
    'AverageCorrectAnswers': 'Răspunsuri corecte medii',
    'NoDataYet': 'Nu există date încă'
  }
};


