<div class="learning-event-header">
  @if (isBeingEditedByUser) {
    <div class="d-flex align-items-center">
      <div class="learning-event-item">
        <bitmark-editable-text [text]="bit.item"
                               [placeholder]="'Bits.Item' | translate"
                               [cssClass]="'is-editing'"
                               (onChange)="bit.item = $event; saveChanges();">
        </bitmark-editable-text>
      </div>
      <div class="d-flex learning-event-lead-dash-before"></div>
      <div class="learning-event-lead">
        <bitmark-editable-text [text]="bit.lead"
                               [placeholder]="'Bits.Lead' | translate"
                               [cssClass]="'is-editing'"
                               (onChange)="bit.lead = $event; saveChanges();">
        </bitmark-editable-text>
      </div>
      <div class="d-flex learning-event-lead-dash-after"></div>
    </div>
  } @else if (!isItemEmpty || !isLeadEmpty) {
    <div class="d-flex align-items-center">
      @if (!isItemEmpty) {
        <div class="learning-event-item">
          <bitmark-text [text]="bit.item"></bitmark-text>
        </div>
      }
      @if (!isItemEmpty && !isLeadEmpty) {
        <div class="d-flex learning-event-lead-dash-before"></div>
      }
      @if (!isLeadEmpty) {
        <div class="learning-event-lead">
          <bitmark-text [text]="bit.lead"></bitmark-text>
        </div>
      }
      @if (!isItemEmpty && !isLeadEmpty) {
        <div class="d-flex learning-event-lead-dash-after"></div>
      }
    </div>
  }
  <div class="d-flex">
    <div class="w-100">
      <div class="d-flex align-items-center position-relative">
        @if (isBeingEditedByUser) {
          <div class="learning-event-instruction">
            <bitmark-editable-text
              (onChange)="bit.instruction = $event; saveChanges();"
              [text]="bit.instruction"
              [placeholder]="'Bits.Instruction' | translate"
              [cssClass]="'is-editing'">
            </bitmark-editable-text>
          </div>
        } @else if (bit.instruction) {
          <div class="learning-event-instruction">
            <bitmark-text [text]="bit.instruction"></bitmark-text>
          </div>
        }
        @if (!isBodyEmpty || isBeingEditedByUser) {
          <div class="d-flex cursor-pointer toggle-btn" [class.rotate]="!isBodyCollapsed"
               (click)="toggleBody(bit.id)">
            <bitmark-icon name="chevron-right" class="size-30px"></bitmark-icon>
          </div>
        }
      </div>
      <div class="d-flex align-items-center flex-wrap learning-event-header-details">
        @if (isBeingEditedByUser) {
          <div class="mt-2 learning-event-activity-type">
            <bitmark-editable-text
              (onChange)="bit.activityType = $event; saveChanges();"
              [text]="bit.activityType"
              [placeholder]="'Bits.ActivityType' | translate"
              class="py-1 px-2"
              [cssClass]="'is-editing'">
            </bitmark-editable-text>
          </div>
        } @else if (bit.activityType) {
          <div class="learning-event-activity-type"
               [class.mt-2]="bit.item || bit.instruction">
            <bitmark-text [text]="bit.activityType" class="py-1 px-2"></bitmark-text>
          </div>
        }
      </div>
    </div>
  </div>
</div>
<div class="overflow-hidden" [@slideToggleIsDone]="toggleStateIsDone">
  @if (isBeingEditedByUser) {
    <div class="bit-body bit-body-allow-highlight overflow-hidden" [@slideToggle]="toggleState">
      <div class="mt-4 is-editing-multi-line">
        <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                    [bit]="bit"
                                    [bitContent]="bit.body | bitmark:bit.format:'json'"
                                    [isReadOnly]="false"
                                    [placeholder]="'Bits.PlaceholderBody' | translate"
                                    class="w-100">
        </bitmark-reader-text-editor>
      </div>
    </div>
  } @else if (!isBodyEmpty) {
    <div class="bit-body bit-body-allow-highlight overflow-hidden" [@slideToggle]="toggleState">
      <div class="mt-4">
        <bitmark-text [text]="bit.body" [format]="bit.format"></bitmark-text>
      </div>
    </div>
  }
</div>
