<div #rootElement
     [ngClass]='brandingClass'
     class='position-relative bit-container'
     [attr.lang]='bitWrapper?.bit?.lang || bitWrapper?.meta?.language || null'
     [style.zoom]="fontScale ? fontScale + '%' : ''"
     [style.visibility]="shouldProtectAgainstUserCopy ? isInViewPort ? 'visible' : 'hidden' : ''"
     [class.full-screen]="fullScreen"> <!-- maybe use [style.transform: scale()] ??? -->
  @if (showItemLeadInstruction || (isBeingEditedByUser && canHaveItemLeadInstruction)) {
    <bitmark-bit-item-instruction [bit]='bitWrapper.bit'
                                  [isBeingEditedByUser]='isBeingEditedByUser'
                                  [canBeEdited]='isEditable && isInViewPort'
                                  [updateInstructionEvent]='updateInstructionEvent'
                                  (onIsBeingEdited)='saveBitJson($event)'>
    </bitmark-bit-item-instruction>
  }
  <!--  @if (bitWrapper?.bit?.format === BitmarkFormat.Prosemirror) {-->
  <!--    <bitmark-prosemirror [bit]='bitWrapper.bit'-->
  <!--                         [canBeEdited]='isEditable && isInViewPort'-->
  <!--                         [disableKeyboardInput]="context === 'timeline'"-->
  <!--                         [isBeingEdited]='bitWrapper?.isBeingEdited'-->
  <!--                         (onIsBeingEdited)='onIsBitBeingEdited({content: $event, bitWrapper: bitWrapper})'-->
  <!--                         (editorCreated)='editorCreated.emit($event)'>-->
  <!--    </bitmark-prosemirror>-->
  <!--  } @else {-->
  @switch (bitWrapper?.bit?.type) {
    @case (BitType.BitBookSummary) {
      <bitmark-bit-book-summary [bitBook]='bitWrapper?.meta?.thisBook'></bitmark-bit-book-summary>
    }
    @case (BitType.BitBookEnding) {
      <bitmark-bit-book-ending></bitmark-bit-book-ending>
    }
    @case (BitType.VirtualMarkBookAsRead) {
      <bitmark-virtual-mark-book-as-read [markBookAsReadBit]='bitWrapper.bit'></bitmark-virtual-mark-book-as-read>
    }
    @case (BitType.VirtualHandIn) {
      <bitmark-virtual-hand-in [handInBit]='bitWrapper.bit'></bitmark-virtual-hand-in>
    }
    @case (BitType.VirtualHandInReview) {
      <bitmark-virtual-hand-in-review (assignHandIn)="assignHandIn.emit($event)"
                                      [handInBit]='bitWrapper.bit'></bitmark-virtual-hand-in-review>
    }
    @case (BitType.VirtualHandInResult) {
      <bitmark-virtual-hand-in-result [handInBit]='bitWrapper.bit'
                                      (navigateToBook)='navigateToBook.emit($event)'
                                      (connectWithUser)="connectWithUser.emit($event)">
      </bitmark-virtual-hand-in-result>
    }
    @case (articleBitTypes[articleBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-article [bit]='bitWrapper.bit'
                       [bitSubtype]='bitWrapper?.bit?.type'
                       [isBeingEditedByUser]='isBeingEditedByUser'
                       [saveUserEdit]='saveUserEdit'
                       [canBeEdited]='isEditable && isInViewPort'
                       [isEditable]='isEditable'
                       [isResourceType]='isArticleResourceType'
                       (instructionChanged)='updateInstructionEvent.next($event)'
                       (openResource)='openResource.emit($event)'
                       (cancelBitChanges)='revertBitChanges($event)'
                       (saveInlineBit)='saveBitJson($event)'
                       (editorCreated)='editorCreated.emit($event)'
                       (changed)='changed()'>
      </bitmark-article>
    }
    @case (articleAltBitTypes[articleAltBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-article-alt [bit]="bitWrapper.bit"
                           (openResource)="openResource.emit($event)">
      </bitmark-article-alt>
    }
    @case (BitType.AppAiPrompt) {
      <bitmark-app-ai-prompt [bit]='bitWrapper.bit'
                             [canBeEdited]='isEditable && isInViewPort'
                             [isBeingEditedByUser]='isBeingEditedByUser'
                             (cancelBitChanges)='revertBitChanges($event)'
                             (saveInlineBit)='saveBitJson($event)'
                             (editorCreated)='editorCreated.emit($event)'>
      </bitmark-app-ai-prompt>
    }
    @case (BitType.AppCodeEditor) {
      <bitmark-app-code-editor [bit]='bitWrapper.bit'
                               (saveInlineBit)='saveBitJson($event)'>
      </bitmark-app-code-editor>
    }
    @case (BitType.AppFlashcards) {
      <bitmark-app-flashcards [bitWrapper]='bitWrapper'
                              [bit]='bitWrapper.bit'
                              [isFocused]='isInViewPort && focusedBit === bitWrapper?.id'
                              (changed)='changed()'>
      </bitmark-app-flashcards>
    }
    @case (cardBitTypes[cardBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-card-1 [bit]='bitWrapper.bit'
                      [bitSubtype]='bitWrapper?.bit?.type'
                      (openResource)='openResource.emit($event)'
                      (onIsBeingEdited)='onIsAnnotationBeingEdited({content: $event, bitWrapper: bitWrapper})'>
      </bitmark-card-1>
    }
    @case (BitType.AnnotationNote) {
      <bitmark-annotation-note [bit]='bitWrapper.bit'
                               [canBeEdited]='true'
                               [isBeingEdited]='bitWrapper?.isBeingEdited'
                               [user]="bitWrapper.user"
                               (openResource)='openResource.emit($event)'
                               (onIsBeingEdited)='onIsAnnotationBeingEdited({content: $event, bitWrapper: bitWrapper})'
                               (editorCreated)='editorCreated.emit($event)'>
      </bitmark-annotation-note>
    }
    @case (BitType.AnnotationBookmark) {
      <bitmark-annotation-bookmark [bit]='bitWrapper.bit'
                                   [canBeEdited]='true'
                                   [user]="bitWrapper.user"
                                   [isBeingEdited]='bitWrapper?.isBeingEdited'
                                   (openResource)='openResource.emit($event)'
                                   (onIsBeingEdited)='onIsAnnotationBeingEdited({content: $event, bitWrapper: bitWrapper})'
                                   (editorCreated)='editorCreated.emit($event)'>
      </bitmark-annotation-bookmark>
    }
    @case (BitType.AnnotationFavorite) {
      <bitmark-annotation-favorite [bit]='bitWrapper.bit'
                                   [canBeEdited]='true'
                                   [user]="bitWrapper.user"
                                   [isBeingEdited]='bitWrapper?.isBeingEdited'
                                   (openResource)='openResource.emit($event)'
                                   (onIsBeingEdited)='onIsAnnotationBeingEdited({content: $event, bitWrapper: bitWrapper})'
                                   (editorCreated)='editorCreated.emit($event)'>
      </bitmark-annotation-favorite>
    }
    @case (BitType.AnnotationHandwritten) {
      <bitmark-annotation-handwritten [bit]='bitWrapper.bit'
                                      [isBeingEdited]='bitWrapper?.isBeingEdited'
                                      (openResource)='openResource.emit($event)'
                                      (onIsBeingEdited)='onIsAnnotationBeingEdited({content: $event, bitWrapper: bitWrapper})'>
      </bitmark-annotation-handwritten>
    }
    @case (BitType.BookReference) {
      <bitmark-book-reference [bit]='bitWrapper.bit'
                              (openResource)='openResource.emit($event)'>
      </bitmark-book-reference>
    }
    @case (BitType.BotActionSend) {
      <bitmark-bot-action-send [bit]='bitWrapper.bit'
                               [bitId]='bitWrapper.id'
                               [isBeingEditedByUser]='isBeingEditedByUser'
                               [saveUserEdit]='saveUserEdit'
                               (editClosed)='editClosed.emit($event)'
                               (saveInlineBit)='saveBitJson($event)'>
      </bitmark-bot-action-send>
    }
    @case (BitType.BotActionResponse) {
      <bitmark-bot-action-response [bit]='bitWrapper.bit'
                                   (changed)='changed()'>
      </bitmark-bot-action-response>
    }
    @case (BitType.Cloze) {
      <bitmark-cloze [bit]='bitWrapper.bit'
                     [readOnly]='feedbackInProgress'
                     [allowAnswerSubmit]='allowAnswerSubmit'
                     [hasFeedback]='hasFeedback'
                     (openResource)='openResource.emit($event)'
                     (changed)='changed()'>
      </bitmark-cloze>
    }
    @case (BitType.Code) {
      <bitmark-code [bit]='bitWrapper.bit'
                    [canBeEdited]='isEditable && isInViewPort'
                    (saveInlineBit)='saveBitJson($event)'>
      </bitmark-code>
    }
    @case (BitType.ClozeSolutionGrouped) {
      <bitmark-cloze [bit]='bitWrapper.bit'
                     [readOnly]='feedbackInProgress'
                     [allowAnswerSubmit]='allowAnswerSubmit'
                     [hasFeedback]='hasFeedback'
                     [isSolutionGrouped]='true'
                     (openResource)='openResource.emit($event)'
                     (changed)='changed()'>
      </bitmark-cloze>
    }
    @case (BitType.MultipleChoiceText) {
      <bitmark-cloze [clozeAndMultipleChoiceBit]='bitWrapper.bit'
                     [readOnly]='feedbackInProgress'
                     [allowAnswerSubmit]='allowAnswerSubmit'
                     [hasFeedback]='hasFeedback'
                     (openResource)='openResource.emit($event)'
                     (changed)='changed()'>
      </bitmark-cloze>
    }
    @case (BitType.ClozeAndMultipleChoiceText) {
      <bitmark-cloze [clozeAndMultipleChoiceBit]='bitWrapper.bit'
                     [readOnly]='feedbackInProgress'
                     [allowAnswerSubmit]='allowAnswerSubmit'
                     [hasFeedback]='hasFeedback'
                     (openResource)='openResource.emit($event)'
                     (changed)='changed()'>
      </bitmark-cloze>
    }
    @case (BitType.ClozeInstructionGrouped) {
      <bitmark-cloze [bit]='bitWrapper.bit'
                     [readOnly]='feedbackInProgress'
                     [allowAnswerSubmit]='allowAnswerSubmit'
                     [hasFeedback]='hasFeedback'
                     [isInstructionGrouped]='true'
                     (openResource)='openResource.emit($event)'
                     (changed)='changed()'>
      </bitmark-cloze>
    }
    @case (BitType.ConversationLeft1) {
      <bitmark-conversation-left-1 [bit]='bitWrapper.bit'></bitmark-conversation-left-1>
    }
    @case (BitType.ConversationLeft1Scream) {
      <bitmark-conversation-left-1-scream [bit]='bitWrapper.bit'></bitmark-conversation-left-1-scream>
    }
    @case (BitType.ConversationLeft1Thought) {
      <bitmark-conversation-left-1-thought [bit]='bitWrapper.bit'></bitmark-conversation-left-1-thought>
    }
    @case (BitType.ConversationRight1) {
      <bitmark-conversation-right-1 [bit]='bitWrapper.bit'></bitmark-conversation-right-1>
    }
    @case (BitType.ConversationRight1Scream) {
      <bitmark-conversation-right-1-scream [bit]='bitWrapper.bit'></bitmark-conversation-right-1-scream>
    }
    @case (BitType.ConversationRight1Thought) {
      <bitmark-conversation-right-1-thought [bit]='bitWrapper.bit'></bitmark-conversation-right-1-thought>
    }
    @case (BitType.MultipleChoice) {
      <bitmark-multiple-choice [bit]='bitWrapper.bit'
                               [bitId]='bitWrapper.id'
                               [readOnly]='feedbackInProgress'
                               [allowAnswerSubmit]='allowAnswerSubmit'
                               [hasFeedback]='hasFeedback'
                               (openResource)='openResource.emit($event)'
                               (changed)='changed()'>
      </bitmark-multiple-choice>
    }
    @case (BitType.MultipleChoice1) {
      <bitmark-multiple-choice-1 [bit]='bitWrapper.bit'
                                 [bitId]='bitWrapper.id'
                                 [readOnly]='feedbackInProgress'
                                 [allowAnswerSubmit]='allowAnswerSubmit'
                                 [hasFeedback]='hasFeedback'
                                 (openResource)='openResource.emit($event)'
                                 (changed)='changed()'>
      </bitmark-multiple-choice-1>
    }
    @case (BitType.MultipleResponse) {
      <bitmark-multiple-response #bitElement
                                 [bit]='bitWrapper.bit'
                                 [bitId]='bitWrapper.id'
                                 [readOnly]='feedbackInProgress'
                                 [allowAnswerSubmit]='allowAnswerSubmit'
                                 [hasFeedback]='hasFeedback'
                                 (openResource)='openResource.emit($event)'
                                 (changed)='changed()'>
      </bitmark-multiple-response>
    }
    @case (BitType.MultipleResponse1) {
      <bitmark-multiple-response-1 [bit]='bitWrapper.bit'
                                   [bitId]='bitWrapper.id'
                                   [readOnly]='feedbackInProgress'
                                   [allowAnswerSubmit]='allowAnswerSubmit'
                                   [hasFeedback]='hasFeedback'
                                   (openResource)='openResource.emit($event)'
                                   (changed)='changed()'>
      </bitmark-multiple-response-1>
    }
    @case (BitType.ButtonCopyText) {
      <bitmark-button-copy-text [bit]='bitWrapper.bit'></bitmark-button-copy-text>
    }
    @case (pageBuyButtonBitTypes[pageBuyButtonBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-page-buy-button [bit]='bitWrapper.bit'
                               (navigateToBook)='navigateToBook.emit($event)'>
      </bitmark-page-buy-button>
    }
    @case (BitType.VendorStripePricingTable) {
      <bitmark-vendor-stripe-pricing-table
        [bit]='bitWrapper.bit'>
      </bitmark-vendor-stripe-pricing-table>
    }
    @case (BitType.PageBuyButtonPromotion) {
      <bitmark-page-buy-button-promotion
        [bit]='bitWrapper.bit'
        (navigateToBook)='navigateToBook.emit($event)'>
      </bitmark-page-buy-button-promotion>
    }
    @case (BitType.PageFooter) {
      <bitmark-page-footer [bit]='bitWrapper.bit'>
      </bitmark-page-footer>
    }
    @case (BitType.Chapter) {
      <bitmark-chapter [bitId]='bitWrapper.bit.id || bitWrapper.id'
                       [isEditable]='isEditable && isInViewPort'
                       [isBeingEdited]='bitWrapper?.isBeingEdited'
                       [bit]='bitWrapper.bit'
                       (onIsBeingEdited)='onIsBitBeingEdited({bit: $event, bitWrapper: bitWrapper})'
                       (onLevelChanged)="onChapterBitLevelChanged({bit: $event, bitWrapper: bitWrapper})"
                       (editorCreated)='editorCreated.emit($event)'>
      </bitmark-chapter>
    }
    @case (cookIngredientsBitTypes[cookIngredientsBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-cook-ingredients [bit]="bitWrapper.bit"
                                (changed)='changed()'>
      </bitmark-cook-ingredients>
    }
    @case (BitType.InternalLink) {
      <bitmark-internal-link [bit]='bitWrapper.bit'></bitmark-internal-link>
    }
    @case (BitType.Essay) {
      <bitmark-essay [bit]='bitWrapper.bit'
                     [readOnly]='feedbackInProgress'
                     [allowAnswerSubmit]='allowAnswerSubmit'
                     [hasFeedback]='hasFeedback'
                     (openResource)='openResource.emit($event)'
                     (changed)='changed()'>
      </bitmark-essay>
    }
    @case (BitType.ExtractorBlock) {
      <bitmark-extractor-block [bit]='bitWrapper.bit'
                               [canBeEdited]='isEditable && isInViewPort'
                               (saveInlineBit)='saveBitJson($event.bit, $event.tags)'>
      </bitmark-extractor-block>
    }
    @case (BitType.Flashcard1) {
      <bitmark-flashcard-1 [bit]='bitWrapper.bit'
                           [canBeEdited]='isEditable && isInViewPort'
                           (saveInlineBit)='saveBitJson($event)'
                           (editorCreated)='editorCreated.emit($event)'>
      </bitmark-flashcard-1>
    }
    @case (BitType.PrototypeImages) {
      <bitmark-prototype-images [bit]="bitWrapper.bit"
                                (changed)="changed()">
      </bitmark-prototype-images>
    }
    @case (quoteBitTypes[quoteBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-quote [bit]='bitWrapper.bit'
                     (onIsBeingEdited)='onIsBitBeingEdited({bitWrapper: bitWrapper, updates: $event})'
                     [isEditable]='isEditable'>
      </bitmark-quote>
    }
    @case (BitType.SurveyRating) {
      <bitmark-survey-rating [bit]="bitWrapper.bit"
                             [allowAnswerSubmit]="allowAnswerSubmit"
                             (changed)="changed()">
      </bitmark-survey-rating>
    }
    @case (BitType.SurveyRatingOnce) {
      <bitmark-survey-rating [bit]="bitWrapper.bit"
                             [isOnce]="true"
                             [allowAnswerSubmit]="allowAnswerSubmit"
                             (changed)="changed()">
      </bitmark-survey-rating>
    }
    @case (BitType.SurveyRatingDisplay) {
      <bitmark-survey-rating [bit]="bitWrapper.bit"
                             [displayOnly]="true">
      </bitmark-survey-rating>
    }
    @case (imageBitTypes[imageBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-image [bit]="bitWrapper.bit"
                     [bitWrapper]="bitWrapper"
                     [canBeEdited]="isEditable"
                     (openResource)="openResource.emit($event)"
                     (saveInlineBit)="saveBitJson($event)">
      </bitmark-image>
    }
    @case (BitType.ImagesLogoGrave) {
      <bitmark-images-logo-grave [bit]='bitWrapper.bit'
                                 [isBeingEditedByUser]='isBeingEditedByUser'
                                 [canBeEdited]='isEditable && isInViewPort'
                                 (cancelBitChanges)='revertBitChanges($event)'
                                 (saveInlineBit)='saveBitJson($event)'
                                 (editorCreated)='editorCreated.emit($event)'>
      </bitmark-images-logo-grave>
    }
    @case (BitType.ImageResponsive) {
      <bitmark-image-responsive [bit]='bitWrapper.bit'
                                [bitWrapper]='bitWrapper'
                                (openResource)='openResource.emit($event)'>
      </bitmark-image-responsive>
    }
    @case (BitType.Interview) {
      <bitmark-interview [bit]='bitWrapper.bit'
                         [readOnly]='feedbackInProgress'
                         [allowAnswerSubmit]='allowAnswerSubmit'
                         [hasFeedback]='hasFeedback'
                         (openResource)='openResource.emit($event)'
                         (changed)='changed()'>
      </bitmark-interview>
    }
    @case (BitType.InterviewInstructionGrouped) {
      <bitmark-interview [bit]='bitWrapper.bit'
                         [isInstructionGrouped]='true'
                         [readOnly]='feedbackInProgress'
                         [allowAnswerSubmit]='allowAnswerSubmit'
                         [hasFeedback]='hasFeedback'
                         (openResource)='openResource.emit($event)'
                         (changed)='changed()'>
      </bitmark-interview>
    }
    @case (BitType.Match) {
      <bitmark-match [bit]='bitWrapper.bit'
                     [readOnly]='feedbackInProgress'
                     [allowAnswerSubmit]='allowAnswerSubmit'
                     [hasFeedback]='hasFeedback'
                     (changed)='changed()'>
      </bitmark-match>
    }
    @case (BitType.MatchAll) {
      <bitmark-match [bit]='bitWrapper.bit'
                     [readOnly]='feedbackInProgress'
                     [allowAnswerSubmit]='allowAnswerSubmit'
                     [hasFeedback]='hasFeedback'
                     (openResource)='openResource.emit($event)'
                     (changed)='changed()'>
      </bitmark-match>
    }
    @case (BitType.MatchReverse) {
      <bitmark-match [bit]='bitWrapper.bit'
                     [isReverse]='true'
                     [readOnly]='feedbackInProgress'
                     [allowAnswerSubmit]='allowAnswerSubmit'
                     [hasFeedback]='hasFeedback'
                     (openResource)='openResource.emit($event)'
                     (changed)='changed()'>
      </bitmark-match>
    }
    @case (BitType.MatchAllReverse) {
      <bitmark-match [bit]='bitWrapper.bit'
                     [isReverse]='true'
                     [readOnly]='feedbackInProgress'
                     [allowAnswerSubmit]='allowAnswerSubmit'
                     [hasFeedback]='hasFeedback'
                     (openResource)='openResource.emit($event)'
                     (changed)='changed()'>
      </bitmark-match>
    }
    @case (BitType.MatchSolutionGrouped) {
      <bitmark-match-solution-grouped [bit]='bitWrapper.bit'
                                      [readOnly]='feedbackInProgress'
                                      [allowAnswerSubmit]='allowAnswerSubmit'
                                      [hasFeedback]='hasFeedback'
                                      (openResource)='openResource.emit($event)'
                                      (changed)='changed()'>
      </bitmark-match-solution-grouped>
    }
    @case (BitType.RecordAudio) {
      <bitmark-record-audio [bit]="bitWrapper.bit" (changed)='changed()'></bitmark-record-audio>
    }
    @case (BitType.RecordVideo) {
      <bitmark-record-video [bit]="bitWrapper.bit"
                            (changed)='changed($event)'>
      </bitmark-record-video>
    }
    @case (BitType.ReleaseNote) {
      <bitmark-release-note [bit]='bitWrapper.bit'></bitmark-release-note>
    }
    @case (BitType.TrueFalse) {
      <bitmark-true-false [bit]='bitWrapper.bit'
                          [bitId]='bitWrapper.id'
                          [readOnly]='feedbackInProgress'
                          [allowAnswerSubmit]='allowAnswerSubmit'
                          [hasFeedback]='hasFeedback'
                          (changed)='changed()'>
      </bitmark-true-false>
    }
    @case (BitType.BookSources) {
      <bitmark-book-sources [bit]='bitWrapper.bit'
                            [bitId]='bitWrapper.id'
                            (navigateToBook)='navigateToBook.emit($event)'>
      </bitmark-book-sources>
    }
    @case (BitType.BookLink) {
      <bitmark-book-link (navigateToBook)='navigateToBook.emit($event)'
                         (isBookUnaccessibleToUser)='isBookUnaccessibleToUser.emit($event)'
                         [bit]='bitWrapper.bit'>
      </bitmark-book-link>
    }
    @case (BitType.BookClose) {
      <bitmark-book-close [bit]="bitWrapper.bit"
                          (closeBook)="closeBook.emit()">
      </bitmark-book-close>
    }
    @case (BitType.TrueFalse1) {
      <bitmark-true-false-1 [bit]='bitWrapper.bit'
                            [bitId]='bitWrapper.id'
                            [readOnly]='feedbackInProgress'
                            [allowAnswerSubmit]='allowAnswerSubmit'
                            [hasFeedback]='hasFeedback'
                            (changed)='changed()'>
      </bitmark-true-false-1>
    }
    @case (BitType.SelfAssessment) {
      <bitmark-self-assessment [bit]='bitWrapper.bit'></bitmark-self-assessment>
    }
    @case (BitType.Statement) {
      <bitmark-statement [bit]='bitWrapper.bit'></bitmark-statement>
    }
    @case (BitType.DefinitionList) {
      <bitmark-definition-list [bit]='bitWrapper.bit'
                               [canBeEdited]='isEditable && isInViewPort'
                               (saveInlineBit)='saveBitJson($event)'
                               (editorCreated)='editorCreated.emit($event)'>
      </bitmark-definition-list>
    }
    @case (detailsBitTypes[detailsBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-details-1 [bit]='bitWrapper.bit'
                         [isBeingEditedByUser]='isBeingEditedByUser'
                         [canBeEdited]='isEditable && isInViewPort'
                         [isEditable]='isEditable'
                         [updateInstructionEvent]='updateInstructionEvent'
                         [saveUserEdit]='saveUserEdit'
                         (openResource)='openResource.emit($event)'
                         (instructionChanged)='updateInstructionEvent.next($event)'
                         (cancelBitChanges)='revertBitChanges($event)'
                         (saveInlineBit)='saveBitJson($event)'
                         (editorCreated)='editorCreated.emit($event)'>
      </bitmark-details-1>
    }
    @case (BitType.About) {
      <bitmark-about [bit]='bitWrapper.bit'></bitmark-about>
    }
    @case (BitType.DetailsImage) {
      <bitmark-details-image [bit]='bitWrapper.bit'></bitmark-details-image>
    }
    @case (BitType.MatchMatrix) {
      <bitmark-match-matrix [bit]='bitWrapper.bit'
                            [readOnly]='feedbackInProgress'
                            [allowAnswerSubmit]='allowAnswerSubmit'
                            [hasFeedback]='hasFeedback'
                            (changed)='changed()'>
      </bitmark-match-matrix>
    }
    @case (BitType.LearningEventClassroomEvent) {
      <bitmark-learning-event-classroom-event [bit]="bitWrapper.bit"
                                              [bookId]="bookId"
                                              [isBeingEditedByUser]="isBeingEditedByUser"
                                              (saveInlineBit)="saveBitJsonDebounced($event, [], false)">
      </bitmark-learning-event-classroom-event>
    }
    @case (BitType.LearningEventCompletion) {
      <bitmark-learning-event-completion [bit]="bitWrapper.bit"
                                         [bookId]="bookId"
                                         [isBeingEditedByUser]="isBeingEditedByUser"
                                         (saveInlineBit)="saveBitJsonDebounced($event, [], false)">
      </bitmark-learning-event-completion>
    }
    @case (BitType.LearningEventExternalLink) {
      <bitmark-learning-event-external-link [bit]="bitWrapper.bit"
                                            [bookId]="bookId"
                                            [isBeingEditedByUser]="isBeingEditedByUser"
                                            (saveInlineBit)="saveBitJsonDebounced($event, [], false)"
                                            (changed)="changed()">
      </bitmark-learning-event-external-link>
    }
    @case (BitType.LearningEventLearningObjectives) {
      <bitmark-learning-event-learning-objectives [bit]="bitWrapper.bit"
                                                  [bookId]="bookId"
                                                  [isBeingEditedByUser]="isBeingEditedByUser"
                                                  (saveInlineBit)="saveBitJsonDebounced($event, [], false)">
      </bitmark-learning-event-learning-objectives>
    }
    @case (BitType.LearningEventLearningStep) {
      <bitmark-learning-event-learning-step [bit]="bitWrapper.bit"
                                            [bookId]="bookId"
                                            [isBeingEditedByUser]="isBeingEditedByUser"
                                            (saveInlineBit)="saveBitJsonDebounced($event, [], false)">
      </bitmark-learning-event-learning-step>
    }
    @case (BitType.LearningEventPreparationTask) {
      <bitmark-learning-event-preparation-task [bit]="bitWrapper.bit"
                                               [bookId]="bookId"
                                               [isBeingEditedByUser]="isBeingEditedByUser"
                                               (changed)="changed()"
                                               (saveInlineBit)="saveBitJsonDebounced($event, [], false)">
      </bitmark-learning-event-preparation-task>
    }
    @case (BitType.LearningEventReadBook) {
      <bitmark-learning-event-read-book [bit]="bitWrapper.bit"
                                        [bookId]="bookId"
                                        [isBeingEditedByUser]="isBeingEditedByUser"
                                        (saveInlineBit)="saveBitJsonDebounced($event, [], false)"
                                        (changed)="changed()"
                                        (navigateToBook)="navigateToBook.emit($event)"
                                        (isBookUnaccessibleToUser)="isBookUnaccessibleToUser.emit($event)">
      </bitmark-learning-event-read-book>
    }
    @case (BitType.LearningEventVideoCall) {
      <bitmark-learning-event-video-call [bit]="bitWrapper.bit"
                                         [bookId]="bookId"
                                         [isBeingEditedByUser]="isBeingEditedByUser"
                                         (saveInlineBit)="saveBitJsonDebounced($event, [], false)">
      </bitmark-learning-event-video-call>
    }
    @case (BitType.LearningPathClosing) {
      <bitmark-learning-path-closing [bit]='bitWrapper.bit'
                                     [isBeingEditedByUser]='isBeingEditedByUser'
                                     [saveUserEdit]='saveUserEdit'
                                     (changed)='changed()'
                                     (editClosed)='editClosed.emit($event)'
                                     (saveInlineBit)='saveBitJson($event)'>
      </bitmark-learning-path-closing>
    }
    @case (BitType.LearningPathBook) {
      <bitmark-learning-path-book [bit]='bitWrapper.bit'
                                  [isBeingEditedByUser]='isBeingEditedByUser'
                                  [saveUserEdit]='saveUserEdit'
                                  (changed)='changed()'
                                  (navigateToBook)='navigateToBook.emit($event)'
                                  (isBookUnaccessibleToUser)='isBookUnaccessibleToUser.emit($event)'
                                  (editClosed)='editClosed.emit($event)'
                                  (saveInlineBit)='saveBitJson($event)'>
      </bitmark-learning-path-book>
    }
    @case (BitType.LearningPathVideoCall) {
      <bitmark-learning-path-video-call [bit]='bitWrapper.bit'
                                        [isBeingEditedByUser]='isBeingEditedByUser'
                                        [saveUserEdit]='saveUserEdit'
                                        (changed)='changed()'
                                        (editClosed)='editClosed.emit($event)'
                                        (saveInlineBit)='saveBitJson($event)'>
      </bitmark-learning-path-video-call>
    }
    @case (BitType.LearningPathClassroomEvent) {
      <bitmark-learning-path-classroom-event [bit]='bitWrapper.bit'
                                             [isBeingEditedByUser]='isBeingEditedByUser'
                                             [saveUserEdit]='saveUserEdit'
                                             (changed)='changed()'
                                             (editClosed)='editClosed.emit($event)'
                                             (saveInlineBit)='saveBitJson($event)'>
      </bitmark-learning-path-classroom-event>
    }
    @case (BitType.LearningPathExternalLink) {
      <bitmark-learning-path-external-link [bit]='bitWrapper.bit'
                                           [isBeingEditedByUser]='isBeingEditedByUser'
                                           [saveUserEdit]='saveUserEdit'
                                           (changed)='changed()'
                                           (editClosed)='editClosed.emit($event)'
                                           (saveInlineBit)='saveBitJson($event)'>
      </bitmark-learning-path-external-link>
    }
    @case (BitType.LearningPathStep) {
      <bitmark-learning-path-step [bit]='bitWrapper.bit'
                                  [isBeingEditedByUser]='isBeingEditedByUser'
                                  [saveUserEdit]='saveUserEdit'
                                  (changed)='changed()'
                                  (editClosed)='editClosed.emit($event)'
                                  (saveInlineBit)='saveBitJson($event)'>
      </bitmark-learning-path-step>
    }
    @case (BitType.LearningPathLearningGoal) {
      <bitmark-learning-path-learning-goal [bit]='bitWrapper.bit'
                                           [isBeingEditedByUser]='isBeingEditedByUser'
                                           [saveUserEdit]='saveUserEdit'
                                           (changed)='changed()'
                                           (editClosed)='editClosed.emit($event)'
                                           (saveInlineBit)='saveBitJson($event)'>
      </bitmark-learning-path-learning-goal>
    }
    @case (BitType.LearningPathLti) {
      <bitmark-learning-path-lti [bit]='bitWrapper.bit'
                                 [bitId]='bitWrapper.id'
                                 [isBeingEditedByUser]='isBeingEditedByUser'
                                 [saveUserEdit]='saveUserEdit'
                                 (changed)='changed()'
                                 (editClosed)='editClosed.emit($event)'
                                 (saveInlineBit)='saveBitJson($event)'>
      </bitmark-learning-path-lti>
    }
    @case (BitType.PadletEmbed) {
      <bitmark-padlet-embed [bit]='bitWrapper.bit'
                            [isBeingEditedByUser]='isBeingEditedByUser'
                            [saveUserEdit]='saveUserEdit'
                            (editClosed)='editClosed.emit($event)'
                            (saveInlineBit)='saveBitJson($event)'>
      </bitmark-padlet-embed>
    }
    @case (BitType.Mark) {
      <bitmark-mark [bit]='bitWrapper.bit'></bitmark-mark>
    }
    @case (BitType.Toc) {
      <bitmark-toc (toggleTocEvent)='toggleTocEvent.emit()'
                   [bit]='bitWrapper.bit'>
      </bitmark-toc>
    }
    @case (BitType.Summary) {
      <bitmark-summary [bit]='bitWrapper.bit'></bitmark-summary>
    }
    @case (BitType.Scorm) {
      <bitmark-scorm [bit]='bitWrapper.bit'
                     [isBeingEditedByUser]='isBeingEditedByUser'
                     [saveUserEdit]='saveUserEdit'
                     (editClosed)='editClosed.emit($event)'
                     (saveInlineBit)='saveBitJson($event)'>
      </bitmark-scorm>
    }
    @case (stepImageScreenshotWithPointerBitTypes[stepImageScreenshotWithPointerBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-step-image-screenshot-with-pointer [bit]='bitWrapper.bit'
                                                  [canBeEdited]='isEditable && isInViewPort'
                                                  [isBeingEditedByUser]='isBeingEditedByUser'
                                                  (cancelBitChanges)='revertBitChanges($event)'
                                                  (saveInlineBit)='saveBitJson($event)'
                                                  (editorCreated)='editorCreated.emit($event)'>
      </bitmark-step-image-screenshot-with-pointer>
    }
    @case (BitType.PreparationNote) {
      <bitmark-preparation-note [bit]='bitWrapper.bit'
                                (changed)='changed()'>
      </bitmark-preparation-note>
    }
    @case (BitType.Correction) {
      <bitmark-correction [bit]='bitWrapper.bit'></bitmark-correction>
    }
    @case (tableBitTypes[tableBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-table [bit]="bitWrapper.bit"></bitmark-table>
    }
    @case (tableImageBitTypes[tableImageBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-table-image [bit]='bitWrapper.bit'
                           (openResource)='openResource.emit($event)'
                           (measureDisplaySize)='measureDisplaySize.emit($event)'>
      </bitmark-table-image>
    }
    @case (BitType.TakePicture) {
      <bitmark-take-picture [bit]='bitWrapper.bit'
                            (changed)='changed()'>
      </bitmark-take-picture>
    }
    @case (BitType.Survey) {
      <bitmark-survey [bit]='bitWrapper.bit'></bitmark-survey>
    }
    @case (BitType.SurveyMatrix) {
      <bitmark-survey-matrix [bitWrapper]="bitWrapper"
                             [bit]="bitWrapper.bit"
                             [allowAnswerSubmit]="allowAnswerSubmit"
                             (changed)='changed()'>
      </bitmark-survey-matrix>
    }
    @case (BitType.SurveyMatrixMe) {
      <bitmark-survey-matrix [bitWrapper]="bitWrapper"
                             [bit]="bitWrapper.bit"
                             [isMe]="true"
                             [allowAnswerSubmit]="allowAnswerSubmit"
                             [peerUser]="peerUser"
                             (changed)='changed()'>
      </bitmark-survey-matrix>
    }
    @case (pageBitTypes[pageBitTypes.indexOf(bitWrapper?.bit?.type)]) {
      <bitmark-page [bit]='bitWrapper.bit'
                    (openResource)='openResource.emit($event)'
                    (measureDisplaySize)='measureDisplaySize.emit($event)'>
      </bitmark-page>
    }
    @case (BitType.PagePerson) {
      <bitmark-page-person [bit]='bitWrapper.bit'></bitmark-page-person>
    }
    @case (BitType.PageOpenBook) {
      <bitmark-page-open-book (navigateToBook)='navigateToBook.emit($event)'
                              (isBookUnaccessibleToUser)='isBookUnaccessibleToUser.emit($event)'
                              [bit]='bitWrapper.bit'>
      </bitmark-page-open-book>
    }
    @case (BitType.PageProduct) {
      <bitmark-page-product (navigateToBook)='navigateToBook.emit($event)'
                            (navigateToProduct)='navigateToProduct.emit($event)'
                            [isNavigateDirectlyToBook]="isNavigateDirectlyToBook"
                            [bit]='bitWrapper.bit'>
      </bitmark-page-product>
    }
    @case (BitType.PageProductList) {
      <bitmark-page-product-list (navigateToBook)='navigateToBook.emit($event)'
                                 (navigateToProduct)='navigateToProduct.emit($event)'
                                 [isNavigateDirectlyToBook]="isNavigateDirectlyToBook"
                                 [bit]='bitWrapper.bit'>
      </bitmark-page-product-list>
    }
    @case (BitType.PageProductVideo) {
      <bitmark-page-product-video (navigateToBook)='navigateToBook.emit($event)'
                                  (navigateToProduct)='navigateToProduct.emit($event)'
                                  [isNavigateDirectlyToBook]="isNavigateDirectlyToBook"
                                  [bit]='bitWrapper.bit'>
      </bitmark-page-product-video>
    }
    @case (BitType.PageProductVideoList) {
      <bitmark-page-product-video-list (navigateToBook)='navigateToBook.emit($event)'
                                       (navigateToProduct)='navigateToProduct.emit($event)'
                                       [isNavigateDirectlyToBook]="isNavigateDirectlyToBook"
                                       [bit]='bitWrapper.bit'>
      </bitmark-page-product-video-list>
    }
    @case (BitType.PageSectionFolder) {
      <bitmark-page-section-folder (navigateToProduct)='navigateToProduct.emit($event)'
                                   (navigateToBook)='navigateToBook.emit($event)'
                                   [isNavigateDirectlyToBook]="isNavigateDirectlyToBook"
                                   [bit]='bitWrapper.bit'>
      </bitmark-page-section-folder>
    }
    @case (BitType.Module) {
      <bitmark-module [moduleBit]="bitWrapper.bit"
                      [moduleBitSubtype]='bitWrapper?.bit?.type'
                      [context]="context"
                      [isBeingEditedByUser]='isBeingEditedByUser'
                      [saveUserEdit]='saveUserEdit'
                      [canBeEdited]='isEditable'
                      [isEditable]='isEditable && isInViewPort'
                      [isResourceType]='isArticleResourceType'
                      (onIsBeingEdited)="onIsBitBeingEdited({bit: $event, bitWrapper: bitWrapper})"
                      (instructionChanged)='updateInstructionEvent.next($event)'
                      (openResource)='openResource.emit($event)'
                      (navigateToBook)='navigateToBook.emit($event)'
                      (saveInlineBit)='saveBitJson($event)'
                      (editorCreated)='editorCreated.emit($event)'>
      </bitmark-module>
    }
    @case (BitType.Message) {
      <bitmark-message [bit]='bitWrapper.bit'
                       (openResource)='openResource.emit($event)'
                       [isBeingEditedByUser]='isBeingEditedByUser'
                       [saveUserEdit]='saveUserEdit'
                       (editClosed)='editClosed.emit($event)'
                       (saveInlineBit)='saveBitJson($event)'>
      </bitmark-message>
    }
    @case (BitType.SystemMessage) {
      <bitmark-system-message [bit]='bitWrapper.bit'></bitmark-system-message>
    }
    @case (BitType.IFramelyEmbed) {
      <bitmark-iframely-embed [bit]="bitWrapper.bit"
                              (onIsBeingEdited)='onIsBitBeingEdited({bit: $event, bitWrapper: bitWrapper})'>
      </bitmark-iframely-embed>
    }
    @case (BitType.HighchartsChart) {
      <bitmark-highcharts-chart [bit]="bitWrapper.bit"></bitmark-highcharts-chart>
    }
    @default {
      <div class='font-weight-bold' [innerText]='bitWrapper.bit?.type'></div>
      <pre>{{ bitWrapper.bit | json }}</pre>
    }
  }

  @if (footnotes?.length) {
    <div class="footnotes">
      @for (f of footnotes; track f) {
        <div>
          @if (!f.autoNumbered) {
            <sup>
              <bitmark-text [format]="BitmarkFormat.MM" [text]="f.indexContent"></bitmark-text>
            </sup>
          } @else {
            <sup class="auto-numbered"></sup>
          }
        </div>
        <bitmark-text [format]="BitmarkFormat.MM" [text]="f.content"></bitmark-text>
      }
    </div>
  }

  <!--  }-->
  @if (showFeedback && (bitWrapper?.bit?.feedback?.length || bitEvaluationFeedbackMessages?.length)) {
    <bitmark-bit-feedback class='mt-2 d-block'
                          [bit]='bitWrapper?.bit?.feedback?.length ? bitWrapper?.bit?.feedback : bitEvaluationFeedbackMessages'>
    </bitmark-bit-feedback>
  }
  @if (isFeedbackButtonVisible()) {
    <div class='feedback-button-wrapper animated fadeInDown pt-2'>
      <button class='btn btn-action bit-button text--ellipsis d-inline-block '
              (click)='getFeedback()'
              [innerText]="getFeedbackButtonText() | translate">
      </button>
    </div>
  }
  @if (showInterimFeedback) {
    <div class="feedback-button-wrapper animated fadeInDown pt-2">
      <button class='btn btn-action bit-button text--ellipsis d-inline-block '
              (click)='doItAgainFeedback()'
              [innerText]="'Bits.DoItAgain' | translate">
      </button>
      <button class='btn btn-action bit-button text--ellipsis d-inline-block  ml-2'
              (click)='submitResultsFeedback()'
              [innerText]="'Bits.FinalFeedback' | translate">
      </button>
    </div>
  }
  <!--    {{allowAnswerSubmit}} | {{hasFeedback}} | {{hasAnswer}} | {{hasSolution}}-->
  <!--
  <div *ngIf="bitWrapper?.publishedBit?.hasNewBitVersion"
       class="d-flex flex-row align-items-center mt-2">
    <bitmark-icon name="information-circle-outline" class="size-30px mr-1"></bitmark-icon>
    <div class="font-weight-bold" style="font-size: 13px">Has new version</div>
  </div>
  -->
</div>
<!--<bitmark-bit-actions *ngIf="!hideDefaultActions"-->
<!--                     [bitWrapper]="bitWrapper"-->
<!--                     [actions]="actions"-->
<!--                     [cssClass]="actionsCssClass"-->
<!--                     (dblclick)="log(bitWrapper)">-->
<!--</bitmark-bit-actions>-->
<!--{{isInViewPort}}-->
