<div class="pdf-upload-entry mx-3 mx-lg-5" style="width: 140px;">
  @if (!job?.coverSrc) {
    <div class="notebooks-dropzone">
      <div class="pdf-square-dropzone-small">
        <img src="assets/images/pdf-upload.svg"/>
      </div>
    </div>
  } @else {
    <embed [attr.src]="job?.coverSrc"
           width="140"
           height="200">
  }
  <p class="mt-2 text-white f-14 text-center overflow-hidden">
    {{ job?.fileName }}
  </p>
  @if (job?.status !== 3) {
    <div>
      <div class="progress mt-3">
        <div class="progress-bar" [style.width.%]="progressPercentage"></div>
      </div>
      @if (job?.status === 1) {
        <p class="mt-2 text-center dropzone-converting-label">Uploading...</p>
      } @else if (job?.status === 2) {
        <p class="mt-2 text-center dropzone-converting-label">Converting...</p>
      }
    </div>
  }
</div>
