import {AfterViewInit, Component, HostListener, Inject, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe, Location} from '@angular/common';
import {concatMap} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ClipboardService} from 'ngx-clipboard';
import {ReaderContentCommonComponent} from '../reader-content-common.component';
import {BitmarkConfig} from '../../../../bitmark.module';
import {BitbookApiService} from '../../../bitbook-api.service';
import {BitbookMqService} from '../../../bitbook-mq.service';
import {ReaderTocService} from '../../../reader-toc.service';
import {ParserApiService} from '../../../parser-api.service';
import {ReaderBasketService} from '../../../reader-basket.service';
import {ReaderClipboardService} from '../../../reader-clipboard.service';
import {ReaderContentService} from '../reader-content.service';
import {BrandingRenderService} from '../../../branding-render.service';
import {ReaderTipTapTapService} from '../../../tiptap/reader-tiptap.service';
import {BitmarkDatePipe, DebounceService, DropdownItemModel, EventEmitter as EE, ScrollDirective} from '../../../../shared';
import {DomUtilsService} from '../../../../shared/dom/dom-utils.service';
import {DomObserverService} from '../../../../shared/dom/dom-observer.service';
import {FileUtilsService} from '../../../../shared/utils/file-utils.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {AnalyticsService} from '../../../../shared/analytics/analytics.service';
import {RolesApiService} from '../../../roles-api.service';
import StringCaseService from '../../../../shared/utils/string-case.service';
import {ArrayService} from '../../../../shared/utils/array.service';
import {ReaderModes} from '../../../reader.models';
import {BitApiWrapper, BitType} from '../../../../bits/bits.models';
import {BitmarkSettings} from '../../../../shared/settings/bitmark.settings';
import {FilterTypes, ReaderLeContentService} from '../reader-le-content.service';
import {BookPosition} from '../../../reader-tracking.service';

interface DateEntry {
  title: string;
  date: Date;
  isToday: boolean;
  isExpanded: boolean;
  bits: Array<BitApiWrapper>;
}

@Component({
  selector: 'bitmark-reader-content-learning-events',
  templateUrl: './reader-content-learning-events.component.html',
  styleUrls: ['./reader-content-learning-events.component.scss', '../../../reader-common.scss']
})
export class ReaderContentLearningEventsComponent extends ReaderContentCommonComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() readerMode: ReaderModes;

  enableFilters = false;
  bitActions: Array<DropdownItemModel> = [];

  groupedBitBookContent: Array<{ date: Date, bits: Array<BitApiWrapper> }> = [];
  displayGroupedBitBookContent: Array<DateEntry> = [];
  filteredBitsCount: { [key in FilterTypes]: number };

  protected readonly FilterTypes = FilterTypes;

  private subInternalLinkClicked: string;
  private subCrossLinkClicked: string;

  @HostListener('window:popstate', ['$event'])
  // this is used when using the browser back button after selecting bits in toc/using internal link
  onPopState() {
    this.popStateListenerAction();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.debounceService.debounce(500, () => {
      this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
    });
  }

  constructor(@Inject('BitmarkConfig') bitmarkConfig: BitmarkConfig,
              bitBookApiService: BitbookApiService,
              bitbookMqService: BitbookMqService,
              readerTocService: ReaderTocService,
              translate: TranslateService,
              router: Router,
              private route: ActivatedRoute,
              location: Location,
              parserApiService: ParserApiService,
              basketSvc: ReaderBasketService,
              readerBasketService: ReaderBasketService,
              readerClipboard: ReaderClipboardService,
              readerContentService: ReaderContentService,
              clipboardService: ClipboardService,
              brandingRenderService: BrandingRenderService,
              readerTipTapService: ReaderTipTapTapService,
              ngbModal: NgbModal,
              scrollDirective: ScrollDirective,
              domUtilsService: DomUtilsService,
              domObserverService: DomObserverService,
              fileUtilsService: FileUtilsService,
              deviceDetectorService: DeviceDetectorService,
              analyticsService: AnalyticsService,
              rolesApiService: RolesApiService,
              stringCaseService: StringCaseService,
              arrayService: ArrayService,
              private debounceService: DebounceService,
              private ngZone: NgZone,
              private datePipe: DatePipe,
              public readerLeContentService: ReaderLeContentService) {
    super(bitmarkConfig,
      bitBookApiService,
      readerContentService,
      bitbookMqService,
      readerTocService,
      readerBasketService,
      readerClipboard,
      clipboardService,
      basketSvc,
      translate,
      parserApiService,
      router,
      location,
      brandingRenderService,
      readerTipTapService,
      ngbModal,
      scrollDirective,
      domUtilsService,
      domObserverService,
      fileUtilsService,
      deviceDetectorService,
      analyticsService,
      rolesApiService,
      stringCaseService,
      arrayService
    );
  }

  ngOnInit() {
    this.pagination = {
      pageSize: 1000,
      pageNumber: 1,
      startBitId: null,
    };
    this.enableFilters = this.readerMode === ReaderModes.LearningEvents;

    this.sub.sink = this.bitbookMqService.onBitsVisibility()
      .subscribe(this.handleBitsVisibilityChanged.bind(this));

    this.subInternalLinkClicked = EE.default.on('internalLinkClicked', ({reference, bitId}: { reference: string, bitId: string }) => {
      this.ngZone.run(() => {
        this.navigateToBitReference(reference, bitId);
      });
    });
    this.subCrossLinkClicked = EE.default.on('crossRefLinkClicked', ({bookId, reference}: { bookId: string, reference: string }) => {
      this.ngZone.run(() => {
        this.navigateToBookBitReference(bookId, reference);
      });
    });

    this.readerLeContentService.loadExpandedBits(this.bitBook.externalId);

    if (this.readerMode === ReaderModes.LearningEventsReadonly) {
      this.globalNotebookApiQueryParams = {
        type: 'learning-event-static'
      };

      super.renderPage(this.route.snapshot.fragment, false, {
        readerMode: this.readerMode,
        ignoreStartBit: false
      });
    } else {
      this.readerLeContentService.loadCollapsedBits(this.bitBook.externalId);
      this.readerLeContentService.loadCollapsedDateToggles(this.bitBook.externalId);

      this.sub.sink = this.readerLeContentService.getLastFilter(this.bitBook.externalId).subscribe(lastFilter => {
        this.readerLeContentService.currentFilterType = Object.values(FilterTypes).includes(lastFilter as FilterTypes) ? lastFilter as FilterTypes : FilterTypes.CurrentWeek;

        this.renderPage(this.route.snapshot.fragment, false, {
          readerMode: this.readerMode,
          ignoreStartBit: false
        });
      });
    }
  }

  ngAfterViewInit() {
    this.initializeUserCopyProtection();
  }

  ngOnDestroy() {
    if (this.scrollUnlisten) {
      this.scrollUnlisten();
    }
    if (this.scrollEndUnlisten) {
      this.scrollEndUnlisten();
    }
    this.sub.unsubscribe();
    EE.default.clear(this.subInternalLinkClicked);
    EE.default.clear(this.subCrossLinkClicked);
  }

  override renderPage(fragment?: string, refreshToc?: boolean, additionalOptions?: {
    readerMode?: ReaderModes,
    ignoreStartBit?: boolean
  }) {
    this.isLoading = true;
    this.setIsWorkingState(true);

    this.readerContentService.loadContent(this.bitBook, this.pagination, fragment, false, this.readerContentQueryType, this.globalNotebookApiQueryParams, additionalOptions)
      .pipe(concatMap((res: {
        bitBookContent: Array<BitApiWrapper>,
        lastPos: BookPosition,
        isAtTop: boolean,
        isAtBottom: boolean
      }) => {
        this.bitBookContent = res.bitBookContent;
        this.groupBits();
        const groupedBits = this.getFilteredBits(this.readerLeContentService.currentFilterType);
        const bits = [];
        groupedBits.forEach(x => {
          bits.push(...x.bits);
        });

        this.filteredBitsCount = {
          [FilterTypes.CurrentWeek]: this.getFilteredBits(FilterTypes.CurrentWeek).reduce((x, y) => x + y.bits?.length || 0, 0),
          [FilterTypes.Next14Days]: this.getFilteredBits(FilterTypes.Next14Days).reduce((x, y) => x + y.bits?.length || 0, 0),
          [FilterTypes.AllUpcoming]: this.getFilteredBits(FilterTypes.AllUpcoming).reduce((x, y) => x + y.bits?.length || 0, 0),
          [FilterTypes.Past]: this.getFilteredBits(FilterTypes.Past).reduce((x, y) => x + y.bits?.length || 0, 0)
        };

        const scrollObs = this.readerContentService.scrollToLastPosition(bits, res.lastPos);

        this.displayGroupedBitBookContent = groupedBits;

        this.isAtTheTop = res.isAtTop;
        this.isAtTheBottom = res.isAtBottom;
        this.bitbookContentVersion = new Date().getTime();

        if (this.bitBookContent?.length > 2) {
          this.brandingThemeClass = this.bitBook?.theme;
        }

        return scrollObs;
      }))
      .subscribe((hasScrolled) => {
        if (!hasScrolled) {
          setTimeout(() => {
            this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
          }, 250);
        }

        this.scrollUnlisten = this.readerContentService.listenToScroll(this.bitBook,
          () => {
            this.toggleUserCopyProtection(false);
          },
          () => this.bitBookContent);
        this.scrollEndUnlisten = this.readerContentService.listenToScrollEnd(this.bitBook,
          () => {
            const timeoutId = setTimeout(() => {
              this.toggleUserCopyProtection(true);
            }, 2000);
            this.shouldProtectAgainstUserCopyTimeouts.push(timeoutId);
          },
          () => this.bitBookContent);
        setTimeout(() => {
          this.readerContentService.computeAndNotifyBitsScrollVisibility(() => this.bitBookContent);
        }, 250);
        this.isLoading = false;
        this.setIsWorkingState(false);
      }, (err: HttpErrorResponse) => {
        this.bitBookContent = [];
        this.isLoading = false;
        this.setIsWorkingState(false);
        this.fail.emit(err);
      });
  }

  groupBits() {
    let currentGroup: Array<BitApiWrapper> = [];
    let currentLeBit: BitApiWrapper = null;
    this.bitBookContent
      .filter(bitWrapper => ![BitType.BitBookSummary, BitType.BitBookEnding].includes(bitWrapper.bit?.type))
      .forEach(bitWrapper => {
        if (bitWrapper.bit?.type?.startsWith('le-') && bitWrapper.bit?.hasOwnProperty('date')) {
          if (currentLeBit) {
            this.groupedBitBookContent.push({
              date: new Date((currentLeBit.bit as any).date),
              bits: currentGroup
            });

            currentGroup = [bitWrapper];
            currentLeBit = bitWrapper;
          } else {
            currentLeBit = bitWrapper;
            currentGroup.push(bitWrapper);
          }
        } else {
          currentGroup.push(bitWrapper);
        }
      });

    if (currentLeBit) {
      this.groupedBitBookContent.push({
        date: new Date((currentLeBit.bit as any).date),
        bits: currentGroup
      });
    }
  }

  filter(filterType: FilterTypes) {
    this.readerLeContentService.currentFilterType = filterType;
    this.displayGroupedBitBookContent = this.getFilteredBits(filterType);

    this.readerLeContentService.storeLastFilter(this.bitBook.externalId, filterType);
  }

  toggleDateEntry(entry: DateEntry) {
    entry.isExpanded = !entry.isExpanded;

    if (entry.isExpanded) {
      this.readerLeContentService.removeCollapsedDateToggle(this.bitBook.externalId, entry.date);
    } else {
      this.readerLeContentService.addCollapsedDateToggle(this.bitBook.externalId, entry.date);
    }
  }

  private getFilteredBits(filterType: FilterTypes): Array<DateEntry> {
    let bits = [];

    const oneDay = 60 * 60 * 24 * 1000;

    if (filterType === FilterTypes.CurrentWeek) {
      const monday = this.getMonday(new Date());
      for (let i = 0; i < 7; i++) {
        const day = new Date(monday.getTime() + i * oneDay);
        const entry = this.groupedBitBookContent.find(x => x.date.getDate() === day.getDate() && x.date.getMonth() === day.getMonth() && x.date.getFullYear() === day.getFullYear());

        const today = new Date();
        bits.push({
          title: day.toLocaleDateString(BitmarkSettings.locale, {weekday: 'long'}),
          date: day,
          isToday: day.getDate() === today.getDate() && day.getMonth() === today.getMonth() && day.getFullYear() === today.getFullYear(),
          isExpanded: !this.readerLeContentService.isDateToggleCollapsed(this.bitBook.externalId, day),
          bits: entry?.bits || []
        });
      }
    } else if (filterType === FilterTypes.Next14Days) {
      const today = new Date();
      for (let i = 0; i < 14; i++) {
        const day = new Date(today.getTime() + (i + 1) * oneDay);
        const entry = this.groupedBitBookContent.find(x => x.date.getDate() === day.getDate() && x.date.getMonth() === day.getMonth() && x.date.getFullYear() === day.getFullYear());

        if (entry?.bits?.length) {
          bits.push({
            title: new BitmarkDatePipe(this.datePipe).transform(entry.date),
            date: entry.date,
            isToday: false,
            isExpanded: !this.readerLeContentService.isDateToggleCollapsed(this.bitBook.externalId, entry.date),
            bits: entry.bits
          });
        }
      }
    } else if (filterType === FilterTypes.AllUpcoming) {
      const today = new Date(new Date().setHours(23, 59, 59));
      bits = this.arrayService.sortBy(this.groupedBitBookContent
        .filter(entry => entry.date > today)
        .map(entry => {
          return {
            title: new BitmarkDatePipe(this.datePipe).transform(entry.date),
            date: entry.date,
            isToday: false,
            isExpanded: !this.readerLeContentService.isDateToggleCollapsed(this.bitBook.externalId, entry.date),
            bits: entry.bits
          };
        }), 'date');
    } else if (filterType === FilterTypes.Past) {
      const today = new Date(new Date().setHours(23, 59, 59));
      bits = this.arrayService.sortBy(this.groupedBitBookContent
        .filter(entry => entry.date < today)
        .map(entry => {
          return {
            title: new BitmarkDatePipe(this.datePipe).transform(entry.date),
            date: entry.date,
            isToday: false,
            isExpanded: !this.readerLeContentService.isDateToggleCollapsed(this.bitBook.externalId, entry.date),
            bits: entry.bits
          };
        }), 'date');
    }

    return bits;
  }

  private getMonday(d: Date): Date {
    const day = d.getDay();
    const diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
}
