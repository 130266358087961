<div class="triangle" [ngStyle]="triangleOffsetStyle"></div>
<div class="context-menu row no-gutters">
  @for (action of actions; track action) {
    <button (click)="action.handler()" class="btn border-0 py-0 px-2 d-flex align-items-center">
      @if (action.icon) {
        <app-icon [name]="action.icon" class="size-22px"></app-icon>
      }
      @if (action.label && !action.icon) {
        <span [innerHTML]="action.label"></span>
      }
    </button>
  }
</div>
