<div class="contextual-menu-absolute pos-absolute px-1 cursor-pointer d-hidden" id="contextual-menu-absolute-header">
  <ng-template #tiptapHeaderBubbleMenuDropdown>
    <div class="px-1">
      <span dropdownButton class="cursor-pointer contextual-menu-header-type contextual-menu-trigger">Title</span>
    </div>
  </ng-template>
  <bitmark-dropdown class="d-inline-block ml-auto float-right"
                    dropdownClass="lg reader-text-editor"
                    container="body"
                    [actions]="menuActions"
                    [dropdownButton]="tiptapHeaderBubbleMenuDropdown">
  </bitmark-dropdown>
</div>

<!--<ng-template #tiptapHeaderBubbleMenu>-->
<!--  <div class="tiptap-menu tiptap-bubble-menu-headers text-left">-->
<!--    <div>-->
<!--      <button class="tiptap-bubble-item p-1 pl-4 w-100 text-left font-weight-bold"-->
<!--              (click)="editor.chain().focus().toggleHeading({ level: 1 }).run()"-->
<!--              [ngClass]="editor.isActive('heading', { level: 1 }) ? 'is-active' : ''">-->
<!--        Title-->
<!--      </button>-->
<!--    </div>-->
<!--    <div>-->
<!--      <button class="tiptap-bubble-item p-1 pl-4 w-100 text-left font-weight-medium"-->
<!--              (click)="editor.chain().focus().toggleHeading({ level: 2 }).run()"-->
<!--              [ngClass]="editor.isActive('heading', { level: 2 }) ? 'is-active' : ''">-->
<!--        Subtitle-->
<!--      </button>-->
<!--    </div>-->
<!--    <div>-->
<!--      <button class="tiptap-bubble-item p-1 pl-4 w-100 text-left"-->
<!--              (click)="clearHeader();"-->
<!--              [ngClass]="!editor.isActive('heading') ? 'is-active' : ''">-->
<!--        Text-->
<!--      </button>-->
<!--    </div>-->
<!--    <div class="tiptap-menu-separator"></div>-->
<!--    <div>-->
<!--      <button class="tiptap-bubble-item p-1 pl-4 w-100 text-left" (click)="deleteNode()">-->
<!--        Delete-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
