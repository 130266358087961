@if (showName) {
  <div class="d-flex align-items-center">
    @if (namePosition === 'left') {
      <div>
        <span>{{ fullName }}</span>
      </div>
    }
    @if (alwaysShowIcon || (photoUrl || iconAlt)) {
      <div [ngClass]="size"
           class="avatar"
           [class.is-group]="isGroup"
           [class.mr-2]="namePosition === 'right'"
           [class.ml-2]="namePosition === 'left'">
        @if (photoUrl) {
          <img [src]="photoUrl"/>
        }
      </div>
    }
    @if (namePosition === 'right') {
      <div>
        <span>{{ fullName }}</span>
      </div>
    }
  </div>
} @else {
  <div [ngClass]="size"
       class="avatar"
       [class.is-group]="isGroup">
    @if (photoUrl) {
      <img [src]="photoUrl"/>
    }
  </div>
}
