<ng-container>
  @if (!isShopReader && !isSelfLearningReader) {
    <div class='reader-content-wrapper'>
      @if (bitBook) {
        <div scrollAntiBlock
             bitmarkScrollDirective
             class='h-100 infinite-scroll-container reader-content'>
          @if (readerMode === ReaderModes.Book) {
            <bitmark-reader-content-book [bitBook]='bitBook'
                                         [fontScale]='fontScale'
                                         (toggleToc)='toggleToc.emit()'
                                         (sendBits)='sendBits.emit($event)'
                                         (sendBitsToClass)='sendBitsToClass.emit($event)'
                                         (saveBits)='saveBits.emit($event)'
                                         (copyLinkToBit)='copyLinkToBit.emit($event)'
                                         (isWorking)='isWorking.emit($event)'
                                         (fail)='handleFail()'
                                         (assignHandIn)="assignHandIn.emit($event)"
                                         (unassignHandIn)="unassignHandIn.emit($event)"
                                         (navigateToBook)='navigateToBook.emit($event)'
                                         (navigateToProduct)='navigateToProduct.emit($event)'
                                         (closeBook)="close.emit()">
            </bitmark-reader-content-book>
          } @else if (readerMode === ReaderModes.Notebook || readerMode === ReaderModes.NewRelease) {
            <bitmark-reader-content-notebook [bitBook]='bitBook'
                                             [hiddenElements]="hiddenElements"
                                             [configuration]="configuration"
                                             (toggleToc)='toggleToc.emit()'
                                             (sendBits)='sendBits.emit($event)'
                                             (sendBitsToClass)='sendBitsToClass.emit($event)'
                                             (saveBits)='saveBits.emit($event)'
                                             (copyLinkToBit)='copyLinkToBit.emit($event)'
                                             (isWorking)='isWorking.emit($event)'
                                             (findInBook)='findInBook.emit($event)'
                                             (fail)='handleFail()'
                                             (navigateToBook)='navigateToBook.emit($event)'
                                             (navigateToProduct)='navigateToProduct.emit($event)'
                                             (closeBook)="close.emit()">
            </bitmark-reader-content-notebook>
          } @else if (readerMode === ReaderModes.LearningPaths) {
            <bitmark-reader-content-learning-paths [bitBook]='bitBook'
                                                   [courseId]='courseId'
                                                   (sendBits)='sendBits.emit($event)'
                                                   (sendBitsToClass)='sendBitsToClass.emit($event)'
                                                   (saveBits)='saveBits.emit($event)'
                                                   (copyLinkToBit)='copyLinkToBit.emit($event)'
                                                   (isWorking)='isWorking.emit($event)'
                                                   (fail)='handleFail()'
                                                   (navigateToBook)='navigateToBook.emit($event)'
                                                   (navigateToProduct)='navigateToProduct.emit($event)'
                                                   (closeBook)="close.emit()">
            </bitmark-reader-content-learning-paths>
          } @else if (readerMode === ReaderModes.NotebookLearningPath) {
            <bitmark-reader-content-notebook-learning-paths [bitBook]="bitBook"
                                                            [hiddenElements]="hiddenElements"
                                                            [courseId]="courseId"
                                                            (sendBits)="sendBits.emit($event)"
                                                            (sendBitsToClass)='sendBitsToClass.emit($event)'
                                                            (saveBits)="saveBits.emit($event)"
                                                            (copyLinkToBit)="copyLinkToBit.emit($event)"
                                                            (isWorking)="isWorking.emit($event)"
                                                            (fail)="handleFail()"
                                                            (navigateToBook)="navigateToBook.emit($event)"
                                                            (navigateToProduct)='navigateToProduct.emit($event)'
                                                            (closeBook)="close.emit()">
            </bitmark-reader-content-notebook-learning-paths>
          } @else if ([ReaderModes.LearningEvents, ReaderModes.LearningEventsReadonly].includes(readerMode)) {
            <bitmark-reader-content-learning-events [bitBook]='bitBook'
                                                    [readerMode]="readerMode"
                                                    (sendBits)='sendBits.emit($event)'
                                                    (sendBitsToClass)='sendBitsToClass.emit($event)'
                                                    (saveBits)='saveBits.emit($event)'
                                                    (copyLinkToBit)='copyLinkToBit.emit($event)'
                                                    (isWorking)='isWorking.emit($event)'
                                                    (fail)='handleFail()'
                                                    (navigateToBook)='navigateToBook.emit($event)'
                                                    (closeBook)="close.emit()">
            </bitmark-reader-content-learning-events>
          } @else if (readerMode === ReaderModes.DraftBook) {
            <bitmark-reader-content-draft-book [bitBook]='bitBook'
                                               [hiddenElements]="hiddenElements"
                                               (toggleToc)='toggleToc.emit()'
                                               (sendBits)='sendBits.emit($event)'
                                               (sendBitsToClass)='sendBitsToClass.emit($event)'
                                               (saveBits)='saveBits.emit($event)'
                                               (copyLinkToBit)='copyLinkToBit.emit($event)'
                                               (isWorking)='isWorking.emit($event)'
                                               (findInBook)='findInBook.emit($event)'
                                               (fail)='handleFail()'
                                               (navigateToBook)='navigateToBook.emit($event)'
                                               (navigateToProduct)='navigateToProduct.emit($event)'
                                               (closeBook)="close.emit()">
            </bitmark-reader-content-draft-book>
          } @else if (readerMode === ReaderModes.Flashcards) {
            <bitmark-reader-content-flashcards [bitBook]="bitBook"
                                               [hiddenElements]="hiddenElements"
                                               (toggleToc)='toggleToc.emit()'
                                               (sendBits)="sendBits.emit($event)"
                                               (sendBitsToClass)='sendBitsToClass.emit($event)'
                                               (saveBits)="saveBits.emit($event)"
                                               (copyLinkToBit)="copyLinkToBit.emit($event)"
                                               (isWorking)="isWorking.emit($event)"
                                               (findInBook)='findInBook.emit($event)'
                                               (fail)="handleFail()"
                                               (navigateToBook)="navigateToBook.emit($event)"
                                               (navigateToProduct)='navigateToProduct.emit($event)'
                                               (closeBook)="close.emit()">
            </bitmark-reader-content-flashcards>
          } @else if (readerMode === ReaderModes.Search) {
            <bitmark-reader-content-search [isExternalSearch]="isExternalSearch"
                                           [externalLocation]="externalLocation"
                                           [bookExternalId]="bitBook.externalId"
                                           [isReadOnly]="isReadOnly"
                                           (sendBits)="sendBits.emit($event)"
                                           (sendBitsToClass)='sendBitsToClass.emit($event)'
                                           (saveBits)="saveBits.emit($event)"
                                           (copyLinkToBit)="copyLinkToBit.emit($event)"
                                           (isWorking)="isWorking.emit($event)"
                                           (showBitInBook)="showBitInBook($event)"
                                           (fail)="handleFail()"
                                           (navigateToBook)="navigateToBook.emit($event)"
                                           (navigateToProduct)='navigateToProduct.emit($event)'
                                           (closeBook)="close.emit()">
            </bitmark-reader-content-search>
          }
        </div>
      } @else {
        <div [style.visibility]="isLoading ? 'hidden' : 'visible'"
             class='h-100 reader-content bits-wrapper d-flex flex-column align-items-center justify-content-center text-white'>
          <bitmark-icon name='emoticon-sad-outline' class='size-80px mb-2'></bitmark-icon>
          <div>Content not available or book not found</div>
        </div>
      }

      <div class='reader-title-wrapper'>
        @if (readerMode !== ReaderModes.Search && hiddenElements?.indexOf(ReaderHiddenElements.ChapterPath) === -1) {
          <div class='d-none d-md-block reader-title-area'>
            @if (bookTitle && chapterPath) {
              <div class='reader-title-content'>
                <div class='font-weight-normal text--ellipsis unselectable book-selected-chapter'
                     [innerText]='chapterPath'>
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  }
<!--  @if (readerMode === ReaderModes.LearningEvents) {-->
<!--    <div>-->
<!--      <div class="reader-content-wrapper">-->
<!--        @if (bitBook) {-->
<!--          <div class="h-100 reader-content">-->
<!--            <bitmark-reader-content-learning-events [bitBook]='bitBook'-->
<!--                                                    (sendBits)='sendBits.emit($event)'-->
<!--                                                    (sendBitsToClass)='sendBitsToClass.emit($event)'-->
<!--                                                    (saveBits)='saveBits.emit($event)'-->
<!--                                                    (copyLinkToBit)='copyLinkToBit.emit($event)'-->
<!--                                                    (isWorking)='isWorking.emit($event)'-->
<!--                                                    (fail)='handleFail()'-->
<!--                                                    (navigateToBook)='navigateToBook.emit($event)'-->
<!--                                                    (closeBook)="close.emit()">-->
<!--            </bitmark-reader-content-learning-events>-->
<!--          </div>-->
<!--        }-->
<!--      </div>-->
<!--    </div>-->
<!--  }-->
  @if (isShopReader) {
    <div>
      <div class='reader-content-wrapper'>
        @if (bitBook) {
          <div bitmarkScrollDirective
               class='h-100 infinite-scroll-container reader-content'>
            <bitmark-reader-content-shop [bitBook]='bitBook'
                                         [readerMode]='readerMode'
                                         [isEditable]='readerMode===ReaderModes.Shop || readerMode===ReaderModes.ShopSection'
                                         (toggleToc)='toggleToc.emit()'
                                         (sendBits)='sendBits.emit($event)'
                                         (saveBits)='saveBits.emit($event)'
                                         (copyLinkToBit)='copyLinkToBit.emit($event)'
                                         (isWorking)='isWorking.emit($event)'
                                         (findInBook)='findInBook.emit($event)'
                                         (fail)='handleFail()'
                                         (navigateToBook)='navigateToBook.emit($event)'
                                         (navigateToProduct)='navigateToProduct.emit($event)'
                                         (closeBook)="close.emit()">
            </bitmark-reader-content-shop>
          </div>
        }
      </div>
    </div>
  }
  @if (isSelfLearningReader) {
    <div>
      <div class='reader-content-wrapper'>
        @if (bitBook) {
          <div bitmarkScrollDirective
               class='h-100 infinite-scroll-container reader-content'>
            <bitmark-reader-content-self-learning [bitBook]='bitBook'
                                                  [readerMode]='readerMode'
                                                  (toggleToc)='toggleToc.emit()'
                                                  (sendBits)='sendBits.emit($event)'
                                                  (saveBits)='saveBits.emit($event)'
                                                  (copyLinkToBit)='copyLinkToBit.emit($event)'
                                                  (isWorking)='isWorking.emit($event)'
                                                  (findInBook)='findInBook.emit($event)'
                                                  (fail)='handleFail()'
                                                  (navigateToBook)='navigateToBook.emit($event)'
                                                  (navigateToProduct)='navigateToProduct.emit($event)'
                                                  (closeBook)="close.emit()">
            </bitmark-reader-content-self-learning>
          </div>
        }
      </div>
    </div>
  }
</ng-container>
