<div class="flashcards-card-container">
  <div class="flashcards-card" [@rotatedState]="cardState">
    <div class="d-flex flex-column flashcards-card-front" (click)="cardClick()">
      <div class="bit-inner-icon flashcards-card-logo"></div>
      <bitmark-text [text]="frontCard?.text" [format]="frontCard?.format" class="d-block overflow-auto"></bitmark-text>
      <div class="d-flex justify-content-between disable-annotations">
        @if (showCardIndex) {
          <div class="d-flex align-items-end flashcards-card-index">{{ currentCardIndex }} / {{ cardsCount }}</div>
        }
        @if (showActionButtons) {
          <div class="d-flex justify-content-end align-items-end flashcards-card-buttons flashcards-card-front-buttons">
            <div class="wrong-btn mr-4"
                 [class.selected-action-btn]="markedAsWrong"
                 [class.opposite-selected-action-btn]="markedAsRight"
                 (click)="$event.preventDefault(); $event.stopPropagation(); markAsWrong.emit()">
            </div>
            <div class="right-btn"
                 [class.selected-action-btn]="markedAsRight"
                 [class.opposite-selected-action-btn]="markedAsWrong"
                 (click)="$event.preventDefault(); $event.stopPropagation(); markAsRight.emit()">
            </div>
          </div>
        }
      </div>
    </div>
    <div class="flashcards-card-back" (click)="cardClick()">
      <bitmark-text [text]="backCard?.text" [format]="backCard?.format" class="d-block overflow-auto"></bitmark-text>
      <div class="d-flex justify-content-between disable-annotations">
        <div class="d-flex align-items-end flashcards-card-index">{{ currentCardIndex }} / {{ cardsCount }}</div>
        @if (showActionButtons) {
          <div class="d-flex justify-content-end align-items-end flashcards-card-buttons">
            <div class="wrong-btn mr-4"
                 [class.selected-action-btn]="markedAsWrong"
                 [class.opposite-selected-action-btn]="markedAsRight"
                 (click)="$event.preventDefault(); $event.stopPropagation(); markAsWrong.emit()">
            </div>
            <div class="right-btn" [class.selected-action-btn]="markedAsRight"
                 [class.opposite-selected-action-btn]="markedAsWrong"
                 (click)="$event.preventDefault(); $event.stopPropagation(); markAsRight.emit()">
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
