import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {AngularNodeViewComponent} from 'ngx-tiptap';

@Component({
  selector: 'bitmark-nodeview-math',
  templateUrl: './math.component.html'
})
export class NodeviewMathComponent extends AngularNodeViewComponent implements AfterViewInit {
  @ViewChild('mathField') mathField: ElementRef;

  ngAfterViewInit() {
    this.mathField.nativeElement.addEventListener('focus', () => {
      if (this.editor.isEditable) {
        this.mathField.nativeElement.setOptions({
          readOnly: false,
          virtualKeyboardMode: 'manual'
        });
      } else {
        this.mathField.nativeElement.setOptions({
          readOnly: true,
          virtualKeyboardMode: 'off'
        });
      }
    });
    this.mathField.nativeElement.value = this.node.attrs.formula;
    this.mathField.nativeElement.addEventListener('input', (ev) => {
      // console.log(ev.target.value);
      this.updateAttributes({
        formula: ev.target.value,
      });
    });
  }
}
