@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    (open)="openResource.emit($event)"
                    class="d-block mb-4"
                    [ngClass]="(!(bit.item || bit.lead || bit.instruction) && bit.resource?.type === BitResourceType.Document) ? 'pt-4' : ''">
  </bitmark-resource>
}
@if (bit?.availableClassifications?.length) {
  <ng-template #selectedClassificationButton>
    <div class="d-flex align-items-center align-content-between cursor-pointer">
      <div>{{bit?.classification}}</div>
      <bitmark-icon name="chevron-down" class="flex-shrink-0 pl-1"></bitmark-icon>
    </div>
  </ng-template>
  <div class="d-inline-block bit-dropdown bit-dropdown-with-icon">
    @if (canBeEdited) {
      <bitmark-dropdown container="body"
                        [placement]="['bottom', 'bottom-left', 'bottom-right','top', 'top-left', 'top-right', 'auto']"
                        [actions]="dropdownActions"
                        [dropdownButton]="selectedClassificationButton">
      </bitmark-dropdown>
    } @else {
      <div class="d-flex align-items-center align-content-between">
        <span [innerText]="bit?.classification || '&nbsp;'"></span>
        <bitmark-icon name="chevron-down" class="flex-shrink-0 pl-1"></bitmark-icon>
      </div>
    }
  </div>
}
<div class="bit-body bit-body-allow-highlight">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
