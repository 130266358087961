@if (bit?.resource || clozeAndMultipleChoiceBit?.resource) {
  <bitmark-resource [bitResource]="bit?.resource || clozeAndMultipleChoiceBit?.resource"
                    [hostBit]="bit || clozeAndMultipleChoiceBit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
@if (groupedResourceItems?.length) {
  <ul class="solution-instruction-grouped p-2 mb-2">
    @for (item of groupedResourceItems; track item) {
      <li>
        <div class="d-inline"
             [class.grouped-item-strike]="bit?.quizStrikethroughSolutions && item.occurrences === item.found"
             [ngClass]="isInstructionGrouped ? 'cloze-instruction' : 'cloze-solution'">
          <bitmark-text [text]="item.text"
                        [format]="format"
                        [cssClass]="'d-inline-elements'">
          </bitmark-text>
          @if (bit?.quizCountItems && item.occurrences > 1) {
            <span>(</span>
            <span class="cloze-count-content">
              <span
                [class.grouped-item-strike]="bit?.quizStrikethroughSolutions && item.found"
                [ngClass]="isInstructionGrouped ? 'cloze-instruction' : 'cloze-solution'">{{ item.occurrences }}</span>
              @if (item.found && item.found !== item.occurrences) {
                <span class="ml-1">{{ item.occurrences - item.found }}</span>
              }
            </span>
            <span>)</span>
          }
        </div>
      </li>
    }
  </ul>
}
<div class="bit-body" [ngClass]="bit?.type || clozeAndMultipleChoiceBit?.type">
  @for (part of parts; track part) {
    @if (part.isNewLine) {
      <div class="d-block"></div>
    }
    @if (part.isNewLine2) {
      <div class="d-block">&nbsp;</div>
    }
    @if (part.isSpace) {
      <div class="d-inline">&nbsp;</div>
    }
    @if (part.string) {
      <bitmark-text [text]="part.string"
                    [format]="format"
                    [cssClass]="'d-inline-elements'">
      </bitmark-text>
    }
    @if (part.gapPlaceholderBit) {
      <bitmark-gap-placeholder class="d-inline"
                               [readOnly]="readOnly || !allowAnswerSubmit"
                               [hasFeedback]="hasFeedback"
                               [allowAnswerSubmit]="allowAnswerSubmit"
                               [showInstruction]="bit?.quizStrikethroughSolutions || !groupedResourceItems?.length"
                               [gapPlaceholderBit]="part.gapPlaceholderBit"
                               (changed)="gapValueChanged()">
      </bitmark-gap-placeholder>
    }
    @if (part.selectPlaceholderBit) {
      <bitmark-select-placeholder class="d-inline"
                                  [readOnly]="readOnly || !allowAnswerSubmit"
                                  [hasFeedback]="hasFeedback"
                                  [allowAnswerSubmit]="allowAnswerSubmit"
                                  [selectPlaceholderBit]="part.selectPlaceholderBit"
                                  (changed)="changed.emit()">
      </bitmark-select-placeholder>
    }
  }
</div>
