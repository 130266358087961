<div class="position-relative unselectable">
  <bitmark-user-box-photo [iconUrl]="user?.photoUrl"
                          [userEmail]="user?.email || user?.ssoEmail"
                          [fullName]="user?.fullName"
                          [showIconDefaultText]="!!user?.id"
                          [size]="photoSize"
                          [isGroup]="!!user?.groupId"
                          [ngClass]="canOpenAvatar && 'cursor-pointer'"
                          (click)="canOpenAvatar && openAvatar()">
  </bitmark-user-box-photo>
<!--  <div class="position-absolute status dot"-->
<!--       [ngClass]="[(user.isAvailable && user.isActive) ? 'balanced' : 'gray', dotSize]">-->
<!--  </div>-->
</div>

<ng-template #avatar let-modal>
  <div class="modal-header border-0 p-1 justify-content-end">
    <app-icon name="close" class="size-20px cursor-pointer" (click)="modal.dismiss()"></app-icon>
  </div>
  <div class="modal-body d-flex justify-content-center">
    <bitmark-user-box-photo [iconUrl]="user?.photoUrl"
                            [userEmail]="user?.email || user?.ssoEmail"
                            [fullName]="user?.fullName"
                            [showIconDefaultText]="!!user?.id"
                            [isGroup]="!!user?.groupId"
                            size="xxl">
    </bitmark-user-box-photo>
  </div>
</ng-template>
