<bitmark-article [isBeingEditedByUser]="isBeingEditedByUser"
                 [canBeEdited]="canBeEdited"
                 [isResourceType]="false"
                 [bit]="bit"
                 [bitSubtype]="BitType.AppAiPrompt"
                 (cancelBitChanges)="cancelBitChanges.emit($event)"
                 (saveInlineBit)="saveInlineBit.emit($event)"
                 (editorCreated)="editorCreated.emit($event)">
</bitmark-article>
<div class="pt-2 d-flex justify-content-center">
  <button class="btn btn-action bit-button"
          (click)="generate()"
          [innerText]="'Bits.Generate' | translate">
  </button>
</div>

