@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
<div class="bit-body bit-body-allow-highlight"
     [ngClass]="bit.type">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
<div class="d-none d-sm-flex row no-gutters my-1 font-weight-bold">
  <div class="col-8"></div>
  <div class="col-2 text-pre-wrap text-center bit-label">
    <bitmark-text [text]="bit?.labelTrue || ('Shared.True' | translate)"
                  [format]="bit.format">
    </bitmark-text>
  </div>
  <div class="col-2 text-pre-wrap text-center bit-label">
    <bitmark-text [text]="bit?.labelFalse || ('Shared.False' | translate)"
                  [format]="bit.format">
    </bitmark-text>
  </div>
</div>
@for (statement of bit?.statements; track statement; let statementIndex = $index) {
  <div class="row no-gutters mb-2">
    <div class="col-sm-8 col-12 px-sm-1 pb-1 pb-sm-0 mb-4 mb-sm-0 bit-body sub-item-positioning"
         [ngClass]="bit.type">
      @if (statement.item) {
        <bitmark-text [text]="statement.item"
                      [format]="bit.format"
                      class="sub-item"
                      cssClass="sub-item-true-false text-nowrap">
        </bitmark-text>
      }
      <bitmark-text [text]="statement.statement"
                    [format]="bit.format">
      </bitmark-text>
    </div>
    <div class="col-sm-2 col-12 text-sm-center bit-radio"
         [ngClass]="answerTrueCss[statementIndex]">
      <input [id]="'i' + bitId + '-' + statementIndex + '-true'"
             type="radio"
             [disabled]="statement.isExample || readOnly || !allowAnswerSubmit"
             (change)="change(statement, true)"
             [checked]="statement.isExample ? statement.example : !!statement.answer?.choice"
             [name]="'answer' + bitId + '-' + statementIndex"/>
      <label [for]="'i' + bitId + '-' + statementIndex + '-true'">
      <span class="d-block d-sm-none font-weight-bold"
            [innerText]="bit?.labelTrue || ('Shared.True' | translate)"></span>
      </label>
    </div>
    <div class="col-sm-2 col-12 text-sm-center bit-radio"
         [ngClass]="answerFalseCss[statementIndex]">
      <input [id]="'i' + bitId + '-' + statementIndex + '-false'"
             type="radio"
             [disabled]="statement.isExample || readOnly || !allowAnswerSubmit"
             (change)="change(statement, false)"
             [checked]="statement.isExample ? !statement.example : statement.answer && !statement.answer?.choice"
             [name]="'answer' + bitId + '-' + statementIndex"/>
      <label [for]="'i' + bitId + '-' + statementIndex + '-false'">
      <span class="d-block d-sm-none font-weight-bold"
            [innerText]="bit?.labelFalse || ('Shared.False' | translate)"></span>
      </label>
    </div>
  </div>
}

@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit.footer"
                [format]="bit.format">
  </bitmark-text>
}
