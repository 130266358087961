@if (bit?.body) {
  <div class="bit-body bit-body-allow-highlight"
       [ngClass]="bit.type">
    <bitmark-text [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4 pt-2">
  </bitmark-resource>
}
<div class="mt-4">
  @for (response of bit.responses; track response; let index = $index) {
    <div class="bit-checkbox mb-2"
         [ngClass]="answersCss[index]">
      <input [id]="'i' + bitId + index"
             type="checkbox"
             [value]="response.response"
             [disabled]="bit.isExample || readOnly || !allowAnswerSubmit"
             [checked]="isResponseChecked(response.response)"
             (change)="setQuizAnswer($event, response.response)"/>
      <label [for]="'i' + bitId + index" class="w-100">
        <bitmark-text [text]="response.response"
                      [format]="bit.format"
                      class="d-inline-block">
        </bitmark-text>
      </label>
    </div>
  }
</div>
