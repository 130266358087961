<div class="d-flex flex-column">
  <div class="d-flex justify-content-end align-items-start w-100 px-md-2 pt-md-1">
    <button class="bg-transparent border-0 p-0" (click)="close()">
      <app-icon name="close" class="size-20px text-black"></app-icon>
    </button>
  </div>
  <div class="modal-body p-0">
    <ng-content></ng-content>
  </div>
</div>
