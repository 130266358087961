<div class="d-flex flex-column w-100 unselectable">
  <div class="d-flex flex-row justify-content-center">
    <bitmark-user-box-photo [iconUrl]="user?.photoUrl"
                            [userEmail]="user?.email || user?.ssoEmail"
                            [fullName]="user?.fullName"
                            [showIconDefaultText]="!!user?.id"
                            size="lg"
                            class="cursor-pointer"
                            (click)="goToProfile()">
    </bitmark-user-box-photo>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <span class="text-white text-center m-auto d-block" [innerHTML]="user.fullName"></span>
  </div>
<!--  <div class="d-flex flex-row justify-content-center text-center">-->
<!--    <app-user-status-dropdown class="m-auto d-inline-block"></app-user-status-dropdown>-->
<!--  </div>-->
</div>
