@if (basketCount !== -1) {
  <div class="host"
       [ngClass]="brandingClass"
       [style.opacity]="brandingClass ? 1 : 0">
    <div class="reader-basket-header">
      <div class="d-flex align-items-center justify-content-between">
        <div class="py-2 button-bar left">
          <bitmark-icon name="close"
                        class="px-2 size-20px cursor-pointer"
                        (click)="close()">
          </bitmark-icon>
          <div class="text-white">Basket</div>
          <div class="ml-2 mr-4 px-2 badge" [innerText]="basketCount"></div>
        </div>
        <div class="py-2 button-bar right">
          <ng-template #dropdownButton>
            <bitmark-icon name="dots-horizontal"
                          class="px-2 size-20px cursor-pointer">
            </bitmark-icon>
          </ng-template>
          <bitmark-dropdown class="d-inline-block m-0 p-0"
                            id="topDropdown"
                            container="body"
                            [placement]="['bottom-right', 'bottom-left', 'top-right', 'top-left', 'left', 'right']"
                            [actions]="actions"
                            [dropdownButton]="dropdownButton"
                            dropdownClass="lg">
          </bitmark-dropdown>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-center p-2 logo-container">
      <div class="logo-img"></div>
    </div>

    <div class="h-100 row no-gutters">
      <div class="col-md-12">
        <div class="reader-content-wrapper">
          <div scrollAntiBlock
               class="h-100 flex-grow-1 infinite-scroll-container reader-content">
            <bitmark-reader-content-basket (sendBits)="sendBits.emit($event)"
                                           (sendBitsToClass)="sendBitsToClass.emit($event)"
                                           (saveBits)="saveBits.emit($event)"
                                           (findInBook)="findInBook.emit($event)">
            </bitmark-reader-content-basket>
          </div>
        </div>
      </div>
    </div>
  </div>
}
