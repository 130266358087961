<div class="reader-text-editor reader-simple-text-editor" #readerTextEditor>
  <div class="pos-relative text-editor-actions">
    <bitmark-contextual-menu-header class="d-none"
                                    (onDeleteNode)="deleteNode($event)"
                                    (onClearHeader)="clearHeader()"
                                    [editor]="editor">
    </bitmark-contextual-menu-header>
    <bitmark-contextual-menu-list (onDeleteNode)="deleteNode($event)"
                                  [editor]="editor">
    </bitmark-contextual-menu-list>
    <div class="reader-shadow contextual-menu-general" id="contextual-menu-general">
      <div class="tiptap-text-bubble-menu-top d-flex flex-row justify-content-between align-items-center">
        <button class="tiptap-bubble-item" (click)="editor.chain().focus().toggleBold().run()"
                [ngClass]="{ 'is-active-bubble': editor.isActive('bold')}">
          <strong>B</strong>
        </button>
        <button class="tiptap-bubble-item" (click)="editor.chain().focus().toggleItalic().run()"
                [ngClass]="{ 'is-active-bubble': editor.isActive('italic')}">
          <em>I</em>
        </button>
        @if (hasStrike) {
          <button class="tiptap-bubble-item" (click)="editor.chain().focus().toggleStrike().run()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('strike')}">
            <span style="text-decoration: line-through">S</span>
          </button>
        }
        @if (hasLight) {
          <button class="tiptap-bubble-item" (click)="editor.chain().focus().toggleMark('light').run()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('light')}">
            <span style="color: gray">L</span>
          </button>
        }
        @if (hasHighlight) {
          <button style="background-color: #ffff58" class="tiptap-bubble-item"
                  (click)="editor.chain().focus().toggleMark('highlight').run()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('mark')}">
            <span>H</span>
          </button>
        }
        @if (hasScript) {
          <button class="tiptap-bubble-item" (click)="editor.commands.toggleSubscript()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('subscript')}">
            <span>S<sub>s</sub></span>
          </button>
          <button class="tiptap-bubble-item" (click)="editor.commands.toggleSuperscript()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('superscript')}">
            <span>S<sup>s</sup></span>
          </button>
        }
        @if (hasLists) {
          <button class="tiptap-bubble-item" (click)="editor.commands.toggleBulletList()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('bulletList') || editor.isActive('orderedList') || editor.isActive('unorderedList') || editor.isActive('noBulletList')}">
            <span>•</span>
          </button>
        }
        <button class="tiptap-bubble-item" (click)="editor.chain().focus().clearNodes().unsetAllMarks().run()">
          <img class="tiptap-bubble-menu-icon" src="assets/images/icon-erase.svg"/>
        </button>
      </div>
    </div>
    <input accept="image/*" hidden type="file" [attr.multiple]="null" #fileInput (change)="uploadImage()">
  </div>
  <tiptap-editor [editor]="editor" [attr.spellcheck]="isSpellcheckOn"></tiptap-editor>
  <div class="tiptap-bubble-menu-list-empty"></div>
</div>
