<div class="login auth d-flex flex-column py-3">
  <div class="logo d-flex flex-column justify-content-end align-items-center pt-1">
    <app-logo></app-logo>
  </div>
  @if (user) {
    <div class="form mr-auto ml-auto offset-4 col-4 offset-4 my-6 text-center">
      <p class="text-white mb-0" [innerHTML]="'Profile.YourEmailAddress' | translate"></p>
      <h5 class="text-white mb-0" [innerText]="user.email"></h5>
      <p class="text-white" [innerHTML]="'Profile.HasBeenVerified' | translate"></p>
      <div class="row no-gutters mt-4">
        <div class="col-sm-12 text-center">
          <button [routerLink]="['/logout']"
                  class="btn-action btn-action--filled mx-auto"
                  [innerHTML]="'Shared.Continue' | translate">
          </button>
        </div>
      </div>
    </div>
  }
</div>
