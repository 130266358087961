@if (bit.resource && !bit?.resource?.article) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
@if (wordBankItems?.length) {
  <ul class="solution-instruction-grouped p-2 mb-2">
    @for (item of wordBankItems; track item.text) {
      <li>
        <div class="d-inline cursor-pointer"
             (click)="toggleWordBankSolution(item)"
             [class.used-solution]="item.used">
          <bitmark-text [text]="item.text"
                        [format]="bit.format"
                        [cssClass]="'d-inline-elements'">
          </bitmark-text>
        </div>
      </li>
    }
  </ul>
}
<div class="bit-body bit-body-allow-highlight"
     [ngClass]="bit.type">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
@if (bit?.resource?.article) {
  <bitmark-resource-article [bitResourceArticle]="bit?.resource?.article"
                            class="d-block my-1">
  </bitmark-resource-article>
}
<div class="bit-body">
  @if (bit.isExample) {
    <div class="w-100 mt-4 input long correct"
         [class.rows-height]="rowsCount && rowsCount > 0">
      <bitmark-text [text]="bit.example" [format]="bit.format"></bitmark-text>
    </div>
  } @else {
    <textarea #inputHiddenTextArea
              class="w-100 mt-4 input long hidden-input"
              disabled
              readonly
              [class.rows-height]="rowsCount && rowsCount > 0"
              [attr.rows]="(rowsCount && rowsCount > 0) ? rowsCount : null"
              [value]="bit.answer?.text || bit.partialAnswer">
    </textarea>
    <textarea class="w-100 mt-4 input long"
              [class.rows-height]="rowsCount && rowsCount > 0"
              [class.disabled]="!allowAnswerSubmit"
              [attr.rows]="(rowsCount && rowsCount > 0) ? rowsCount : null"
              [class.answered]="readOnly"
              [readOnly]="readOnly || !allowAnswerSubmit"
              [value]="bit.answer?.text || bit.partialAnswer"
              [style.height]="inputHiddenTextArea.scrollHeight + 5 + 'px !important'"
              (input)="change($event)">
  </textarea>
  }
</div>
