@if (drawnImage?.src) {
  <bitmark-resource-image [bitResourceImage]="drawnImage"
                          [hostBitType]="bit.type"
                          [fillUp]="true"
                          [class.cursor-zoom-in]="isImageZoomable"
                          [style.max-width.%]="100"
                          class="d-block"
                          (measureDisplaySize)="onImageResourceMeasured($event)"
                          (click)="onImageResourceClicked()">
  </bitmark-resource-image>
}
@if (bit?.body) {
  <div class="bit-body bit-body-allow-highlight">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
