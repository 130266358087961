<div class="bit-body">
  <bitmark-reader-monaco-code-editor
    [(content)]="bit.body"
    [language]="bit.computerLanguage"
    [readonly]="false"
    [showMinimap]="bit.codeMinimap"
    [showLineNumbers]="bit.codeLineNumbers"
    [updateLanguageEvent]="updateLanguageEventSubject"
    (updateContent)="onUpdateContent()">
  </bitmark-reader-monaco-code-editor>
</div>
