<div class="pos-relative reader-content-self-learning ">
  <div class="bits-wrapper themed">
    <div class="d-flex flex-column"
         [style.opacity]="isLoading ? '0' : '1'">
      @for (bitWrapper of bitBookContent; track getBitId(bitWrapper); let i = $index) {
        <div class='position-relative'>
          @if (bitWrapper.annotations?.length) {
            @for (bitAnnotation of getTopAnnotations(bitWrapper.annotations); track bitAnnotation.id) {
              <bitmark-bit-wrapper class="my-3"
                                   [context]="context"
                                   [bitWrapper]="getShopApiWrapperFromAnnotation(bitWrapper, bitAnnotation)"
                                   [actions]="getBitAnnotationActions(bitAnnotation)"
                                   (isBitAnnotationBeingEdited)="saveEditedAnnotation($event)"
                                   (navigateToBook)="navigateToBook.emit($event)"
                                   (navigateToProduct)="navigateToProduct.emit($event)">
              </bitmark-bit-wrapper>
            }
          }
          @if (bitWrapper?.editorLoadingPre) {
            <div [ngClass]='[brandingThemeClass]'
                 class="bit-skeleton-loader bit-wrapper-1 bit-shadow mt-4 p-4 text-black-50">
              <bitmark-skeleton-loader
                [bitWrapper]='bitWrapper'
                [actions]='skeletonLoaderActions'>
              </bitmark-skeleton-loader>
            </div>
          }
          <bitmark-bit-wrapper [context]="context"
                               [bitWrapper]="bitWrapper"
                               [actions]="bitActions"
                               [queryParams]='globalNotebookApiQueryParams'
                               [isBeingEditedByUser]="bitWrapper.isBeingEditedByUser"
                               [saveUserEdit]="bitWrapper.saveUserEdit"
                               [isNavigateDirectlyToBook]="true"
                               (toggleTocEvent)="toggleToc.emit($event)"
                               (openResource)="onOpenResource($event)"
                               (isBitBeingEdited)="onIsBitBeingEdited($event)"
                               (editClosed)="closeBitIsEditedByUser(bitWrapper)"
                               (navigateToBook)="navigateToBook.emit($event)"
                               (navigateToProduct)="navigateToProduct.emit($event)"
                               (closeBook)="closeBook.emit()"
                               (bitEditInProgress)="showBitEditProgress()"
                               (bitEditCompleted)="showBitEditCompleted($event)">
          </bitmark-bit-wrapper>
          @if (bitWrapper.editorLoadingPost) {
            <div [ngClass]='[brandingThemeClass]'
                 class="bit-skeleton-loader bit-wrapper-1 bit-shadow mt-4 p-4 text-black-50">
              <bitmark-skeleton-loader
                [bitWrapper]='bitWrapper'
                [actions]='skeletonLoaderActions'>
              </bitmark-skeleton-loader>
            </div>
          }
          @if (bitWrapper.annotations?.length) {
            <div>
              @for (bitAnnotation of getBottomAnnotations(bitWrapper.annotations); track bitAnnotation.id) {
                <bitmark-bit-wrapper class="my-3"
                                     [context]="context"
                                     [bitWrapper]="getApiWrapperFromAnnotation(bitWrapper, bitAnnotation)"
                                     [actions]="getBitAnnotationActions(bitAnnotation)"
                                     (isBitAnnotationBeingEdited)="saveEditedAnnotation($event)">
                </bitmark-bit-wrapper>
              }
            </div>
          }
          @if (bitWrapper.editorOperation) {
            <div class="bit-shadow mt-4 p-4 align-items-start d-flex justify-content-center bg-black">
              @for (file of bitWrapper.editorOperation.data.runningJobs; track file) {
                <div class="d-inline-block">
                  <bitmark-pdf-upload [afterBitId]="bitWrapper?.id !== 'start' ? bitWrapper.id : null"
                                      [bookExternalId]="bitBook.externalId"
                                      (dropzoneSelect)="createJob($event, bitWrapper)"
                                      (jobFinished)="onJobFinished($event, bitWrapper)"
                                      [file]="file">
                  </bitmark-pdf-upload>
                </div>
              }
            </div>
          }
          @if (bitWrapper?.bit?.type !== BitType.BitBookEnding && context !== 'self-learning-readonly' && bitWrapper?.bit?.type !== BitType.VirtualMarkBookAsRead) {
            <bitmark-bit-actions [bitWrapper]="bitWrapper?.bit?.type === BitType.BitBookSummary ? null : bitWrapper"
                                 [actions]="bitEditorActions"
                                 [isCustomIcon]="bitBookContent[i + 1]?.bit?.type === BitType.BitBookEnding"
                                 class="text-center absolute-menu-padding"
                                 dropdownClass="sm"
                                 [cssClass]="'position-relative ml-auto mr-auto absolute-menu-no-ending ' + (bitBookContent[i + 1]?.bit?.type === BitType.BitBookEnding && ' absolute-menu-plus ')">
            </bitmark-bit-actions>
          }
        </div>
      }

      @if (bitEditorStatus) {
        <span class="position-fixed reader-editor-status overflow-hidden">
        @switch (bitEditorStatus) {
          @case ('ready') {
            <img src="../../../../../assets/images/editor-status/saved.svg"/>
          }
          @case ('updating') {
            <img src="../../../../../assets/images/editor-status/wait.svg"/>
          }
          @case ('failed') {
            <img src="../../../../../assets/images/editor-status/error.svg"/>
          }
        }
      </span>
      }
    </div>
  </div>
  <div id="selection-ref" style="position: absolute;"></div>
</div>
<div style="display: none">{{ bitbookContentVersion }}</div>
@if (showJsonEditor) {
  <bitmark-json-editor [bookExternalId]="bitBook.externalId"
                       (close)="showJsonEditor = false;">
  </bitmark-json-editor>
}
@if (showBitEditor) {
  <bitmark-bit-editor [bookExternalId]="bitBook.externalId"
                      [themeId]='bitBook.theme'
                      [publisherId]="bitBook.publisherId"
                      [editingBitBitmark]="bitEditorCurrentEditedBit?.bitmark"
                      [bitData]="bitEditorCurrentEditedBit?.bit"
                      [isEditingWholeBook]="isEditingWholeBook"
                      (save)="saveBitFromEditor($event)"
                      (close)="onBitEditorClose()">
  </bitmark-bit-editor>
}
