<ng-template #dropdownButton>
  <button class="d-flex align-items-center justify-content-between border-0 bg-transparent">
    <app-icon name="circle" [ngClass]="selectedStatus.iconCssClass"></app-icon>
    <span class="text-white small pl-1" [innerHTML]="selectedStatus.label | translate"></span>
  </button>
</ng-template>

<app-dropdown
  class="d-inline-block ml-auto"
  id="topDropdown"
  [placement]="['bottom', 'top']"
  [actions]="statuses"
  [dropdownClass]="'md'"
  [dropdownButton]="dropdownButton">
</app-dropdown>
