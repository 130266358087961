<label class="d-inline-flex align-items-center justify-content-center cursor-pointer m-0 px-1"
       for="reader-search">
  <bitmark-icon name="magnify"></bitmark-icon>
</label>
<input #input
       id="reader-search"
       type="search"
       autocomplete="off"
       spellcheck="false"
       [placeholder]="'Reader.Search.Search' | translate"
       (keydown.escape)="onKeydownEscape($event)"
       (focusin)="onFocusIn($event)">
