<div class="d-inline-flex align-items-center align-content-between bit-dropdown bit-dropdown-with-icon"
     [ngClass]="answerCssClass">
  <bitmark-dropdown id="topDropdown"
                    class="flex-grow-1 w-100"
                    container="body"
                    [placement]="['bottom', 'bottom-left', 'bottom-right','top', 'top-left', 'top-right', 'auto']"
                    [actions]="selectPlaceholderBitActions"
                    [dropdownButton]="dropdownButton">
  </bitmark-dropdown>
</div>

<ng-template #dropdownButton>
  <div class="d-flex align-items-center align-content-between cursor-pointer"
       (click)="openDropdown($event)">
    @if (selectPlaceholderBit.prefix) {
      <div class="prefix"
           [innerText]="selectPlaceholderBit.prefix">
      </div>
    }
    <div class="answer flex-grow-1"
         [title]="selectedAnswer?.text || selectPlaceholderBit.answer?.text"
         [innerHTML]="selectedAnswer?.text || selectPlaceholderBit.answer?.text || '&nbsp;'">
    </div>
    <bitmark-icon name="chevron-down" class="flex-shrink-0"></bitmark-icon>
    @if (selectPlaceholderBit.postfix) {
      <div class="postfix"
           [innerText]="selectPlaceholderBit.postfix">
      </div>
    }
  </div>
</ng-template>
