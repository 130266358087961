<div class="d-inline-flex align-items-center flex-wrap">
  <div class="d-inline-block">
    @if (gapPlaceholderBit.isExample) {
      <bitmark-text
        class="input inline d-flex"
        [ngClass]="answerCssClass"
        [text]="gapPlaceholderBit.answer?.text"
        [format]="BitmarkFormat.MM">
      </bitmark-text>
    } @else {
      <span class="input inline hidden-input" #inputHidden>{{ gapPlaceholderBit.answer?.text || '' }}</span>
      <input class="input inline"
             [ngClass]="answerCssClass"
             autocomplete="off"
             autocorrect="false"
             spellcheck="false"
             type="text"
             [readOnly]="hasFeedback || readOnly"
             [value]="gapPlaceholderBit.answer?.text || ''"
             (input)="change($event)"
             [style.width]="inputHidden.clientWidth + 5 + 'px'"/>
    }
  </div>
  @if (gapPlaceholderBit.instruction && showInstruction) {
    <div [innerText]="'(' + gapPlaceholderBit.instruction + ')'"
         class="placeholder-instruction text-pre-wrap">
    </div>
  }
  @if (gapPlaceholderBit.hint) {
    <bitmark-icon name="help-circle-outline"
                  class="placeholder-hint cursor-pointer"
                  [ngbTooltip]="gapPlaceholderBit.hint"
                  triggers="click:blur"
                  container="body">
    </bitmark-icon>
  }
</div>

