import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthService} from '@faubulous/ng2-ui-auth';
import {AuthApiService, IdentityUser, SubSink} from 'core';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  emailVerificationToken: string;
  isAuthenticated: boolean;
  user: IdentityUser;

  private subSink = new SubSink();

  constructor(private auth: AuthService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private authApiService: AuthApiService) {
  }

  ngOnInit(): void {
    this.subSink.sink = this.activatedRoute.queryParams.subscribe(params => {
      this.emailVerificationToken = params.t;
      if (!this.emailVerificationToken) {
        return this.router.navigateByUrl('/');
      }
      this.isAuthenticated = this.auth.isAuthenticated();
      (this.isAuthenticated
        ? this.authApiService.verifyEmailAuthenticated(this.emailVerificationToken)
        : this.authApiService.verifyEmail(this.emailVerificationToken))
        .subscribe((user: IdentityUser) => {
          this.user = user;
        }, (error: HttpErrorResponse) => {
          alert(error.error.message);
          return this.router.navigateByUrl('/');
        });
    });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
