<div class="p-2 learning-path-step-header"
     [ngClass]="isHeaderMinimized && 'learning-path-step-header-minimized'">
  <div class="d-flex">
    <div class="header-text">
      <div class="text-truncate">
        @if (isBeingEditedByUser) {
          <div class="learning-path-item">
            <bitmark-editable-text
              (onChange)="bit.item = $event"
              [text]="bit.item"
              [placeholder]="'Bits.Item' | translate">
            </bitmark-editable-text>
          </div>
          <div class="font-weight-bold learning-path-instruction">
            <bitmark-editable-text
              (onChange)="bit.instruction = $event"
              [text]="bit.instruction"
              [placeholder]="'Bits.Instruction' | translate">
            </bitmark-editable-text>
          </div>
        } @else {
          <div class="learning-path-item">
            @if (bit.item) {
              <bitmark-text [text]="bit.item"></bitmark-text>
            }
          </div>
          <div class="font-weight-bold learning-path-instruction">
            <bitmark-text [text]="bit.instruction"></bitmark-text>
          </div>
        }
      </div>
      <div class="d-flex flex-row learning-path-header-details">
        @if (bit.action || isBeingEditedByUser) {
          <div class="mr-1"
               [ngClass]="!isBeingEditedByUser && 'text-truncate'">
            @if (isBeingEditedByUser) {
              <bitmark-editable-text
                (onChange)="bit.action = $event"
                [text]="bit.action"
                [placeholder]="'Bits.Action' | translate">
              </bitmark-editable-text>
            } @else {
              <bitmark-text [text]="bit.action"
                            [truncate]="true">
              </bitmark-text>
            }
          </div>
        }
        @if ((bit.location && bit.action) || isBeingEditedByUser) {
          <div class="mr-1">
            &bull;
          </div>
        }
        @if (bit.location || isBeingEditedByUser) {
          <div class="mr-1"
               [ngClass]="!isBeingEditedByUser && 'text-truncate'">
            @if (isBeingEditedByUser) {
              <bitmark-editable-text
                (onChange)="bit.location = $event"
                [text]="bit.location"
                [placeholder]="'Bits.Location' | translate">
              </bitmark-editable-text>
            } @else {
              <bitmark-text [text]="bit.location"
                            [truncate]="true">
              </bitmark-text>
            }
          </div>
        }
        @if (((bit.action || bit.location) && bit.duration) || isBeingEditedByUser) {
          <div class="mr-1">&bull;
          </div>
        }
        @if (bit.duration || isBeingEditedByUser) {
          <div class="mr-1 header-duration"
               [ngClass]="!isBeingEditedByUser && 'text-truncate'">
            @if (isBeingEditedByUser) {
              <bitmark-duration-editor ngDefaultControl
                                       [(ngModel)]="bit.duration">
              </bitmark-duration-editor>
            } @else {
              <span>{{ bit.duration | amDuration }}</span>
            }
          </div>
        }
      </div>
    </div>
    @if (bit.answer?.isDone && isTracked && !bit.isInfoOnly) {
      <div class="learning-path-step-check d-flex align-items-center"
           (click)="reset($event)">
        <bitmark-icon name="check-circle-fill" class="size-30px"></bitmark-icon>
      </div>
    }
  </div>
</div>
@if (bit?.body || !bit.answer?.isDone || isBeingEditedByUser) {
  <div [ngClass]="isBeingEditedByUser && 'body-container-edit-mode'">
    <div>
      @if (bit?.body || isBeingEditedByUser) {
        <div class="bit-body p-2 bit-body-allow-highlight">
          @if (isBeingEditedByUser) {
            <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                        [bit]="bit"
                                        [bitContent]="bit.body | bitmark:bit.format:'json'"
                                        [isReadOnly]="false"
                                        [placeholder]="'Bits.PlaceholderBody' | translate"
                                        class="w-100">
            </bitmark-reader-text-editor>
          } @else {
            <bitmark-text [text]="bit?.body"
                          [format]="getBodyFormat(bit.body, bit.format)">
            </bitmark-text>
          }
        </div>
      }
      @if (!bit.answer?.isDone && isTracked && !bit.isInfoOnly) {
        <div class="d-flex justify-content-center pb-4"
             [ngClass]="!bit?.body ? 'pt-4' : 'pt-2'">
          <button class="btn btn-action bit-button text--ellipsis d-inline-block ml-2"
                  [innerHTML]="'Shared.MarkAsDone' | translate"
                  (click)="markAsDone()">
          </button>
        </div>
      }
    </div>
  </div>
}
@if (isBeingEditedByUser) {
  <div class="p-2 edit-container">
    <div class="d-flex justify-content-center m-2">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button text--ellipsis d-inline-block state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
}
