import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {JSONContent} from '@tiptap/core';
import {BitResource} from '../../bits.models';
import {ReaderSimpleTextEditorComponent, ReaderTextEditorComponent} from '../../../reader';
import {AnnotationFavoriteBit} from './annotation-favorite.models';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-annotation-favorite',
  templateUrl: './annotation-favorite.component.html',
  styleUrls: ['./annotation-favorite.component.scss']
})
export class AnnotationFavoriteComponent implements OnInit, OnDestroy {
  @Input() bit?: AnnotationFavoriteBit;
  @Input() user?: any;
  @Input() canBeEdited = false;
  @Input() isBeingEdited = true;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() onIsBeingEdited = new EventEmitter<{ content: Array<JSONContent> }>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();

  @ViewChild('editorComponent') editorComponent: ReaderTextEditorComponent;

  BitmarkFormats = BitmarkFormat;

  @HostListener('document:gmbUserDetailsDelivered', ['$event'])
  onUserDetailsDelivered(event: any) {
    if (this.user && (event?.detail?.email === this.user?.email || event?.detail?.ssoEmail === this.user?.email)) {
      this.user.fullName = event?.detail?.fullName;
    }
  }

  ngOnInit() {
    this.canBeEdited = this.canBeEdited && !this.user;
    if (this.user) {
      const ev = new CustomEvent('gmbUserDetailsRequested', {
        detail: {
          userEmail: this.user?.email
        }, bubbles: true,
      });
      document.dispatchEvent(ev);
    }
  }

  ngOnDestroy() {
    this.editorComponent = null;
  }

  onUpdate(bitJson: JSONContent) {
    this.onIsBeingEdited.emit({content: bitJson?.content});
  }

  enterEditMode() {
    this.isBeingEdited = true;
    if (this.editorComponent?.enterEditMode) {
      this.editorComponent.enterEditMode();
    }
  }
}
