import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output} from '@angular/core';
import {PageProductBit} from './page-product.models';
import {BookType, HandInStatus, ProductFamily} from '../../shared/models/bitmark.models';
import {BitProductService} from "../bit-product.service";
import {BitmarkConfig} from "../../bitmark.module";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'bitmark-page-product',
  templateUrl: './page-product.component.html',
  styleUrls: ['./page-product.component.scss']
})
export class PageProductComponent implements OnInit {
  @Input() bit?: PageProductBit;
  @Input() isNavigateDirectlyToBook?: boolean;
  @Output() navigateToProduct: EventEmitter<{ productId: string, family?: ProductFamily }> = new EventEmitter<{
    productId: string,
    family?: ProductFamily
  }>();
  @Output() navigateToBook: EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily, queryParams?: any }> = new EventEmitter<{
    bookId: string,
    fragment: string,
    family?: ProductFamily
  }>();
  isWideEntry = false;
  publisher: string;
  title: string;
  bookType: string;
  product: any;
  statuses: Array<any> = [];

  @HostListener('document:gmbProductDetailsDelivered', ['$event'])
  onProductPriceDelivered(event: any) {
    const product = this.bitProductService.getProductsFromEvent(event, this.product ? [this.product] : [], [this.bit?.product]);
    this.product = product?.length ? product[0] : {};
    this.publisher = this.bitProductService.getPropertyValue(this.product?.meta?.publisher)
    this.title = this.product && this.product?.texts?.title ? this.product.texts.title : '';
  }

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private bitProductService: BitProductService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.statuses = [{
      label: this.translate.instant('Shared.Open'),
      handler: () => {
        if (this?.product?._id) {
          this.openProductPage();
        }
      },
    }, {
      label: this.translate.instant('Books.ChatWithBook'),
      isHidden: !this.bitmarkConfig.isProUser || !this.bitmarkConfig.hasChatGpt || this.product?.meta?.family !== ProductFamily.Book,
      handler: () => {
        const coverImageUrl = (this.product?.media?.length && this.product?.media[0].file.url) || (this.product?.relatedBook?.media?.length && this.product?.relatedBook?.media[0].file?.url);
        // this.timelineBotService.startChatWithBook(this.book._id, this.book.texts.title, coverImageUrl);
        console.log('Chat with book');
      },
    }]
    const event = new CustomEvent('gmbProductDetailsRequested', {
      detail: {
        productIds: [this.bit?.product],
      }, bubbles: true
    });
    document.dispatchEvent(event);
  }

  openProductPage() {
    if (this.isNavigateDirectlyToBook) {
      if (this.product?.meta?.family === ProductFamily.XModule) {
        return this.navigateToBook.emit({bookId: this.bit?.product, fragment: null, family: ProductFamily.XModule});
      }
      if (!this.product?.bookExists) {
        return;
      }
      this.navigateToBook.emit({bookId: this.bit?.product, fragment: 'start'})
    } else {
      if (this.product?.meta?.family === ProductFamily.XModule) {
        return this.navigateToProduct.emit({productId: this.bit?.product, family: ProductFamily.XModule});
      }
      this.navigateToProduct.emit({productId: this.bit?.product, family: this.product?.meta?.family});
    }
  }

  setWideImage() {

  }

  protected readonly BookType = BookType;
  protected readonly HandInStatus = HandInStatus;
}
