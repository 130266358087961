import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatchBit} from './match.models';
import {BitResource} from '../bits.models';
import {BitEvalResult} from '../bit-eval.service';
import {BitUtilsService} from '../../shared/utils/bit-utils.service';

@Component({
  selector: 'bitmark-match',
  templateUrl: './match.component.html',
  styleUrls: ['../bits.scss']
})
export class MatchComponent {
  @Input() bit?: MatchBit;
  @Input() isReverse = false;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Input() allowAnswerSubmit = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();

  static evalAnswer(bit: MatchBit): BitEvalResult {
    return {
      totalAnswers: bit.pairs?.length || 0,
      answeredAnswers: bit.pairs?.filter(x => !!x.answer?.text)?.length,
      notAnsweredAnswers: bit.pairs?.filter(x => !x.answer?.text)?.length,
      correctAnswers: bit.pairs?.filter(p => p.values
        .map(x => BitUtilsService.convertBitSolutionAnswer(x, p.isCaseSensitive))
        .includes(BitUtilsService.convertBitSolutionAnswer(p.answer?.text, p.isCaseSensitive))
      )?.length,
      incorrectAnswers: bit.pairs?.filter(p => !p.values
        .map(x => BitUtilsService.convertBitSolutionAnswer(x, p.isCaseSensitive))
        .includes(BitUtilsService.convertBitSolutionAnswer(p.answer?.text, p.isCaseSensitive))
      )?.length
    };
  }
}
