@if (bit?.body) {
  <div class="bit-body bit-body-allow-highlight mb-2">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
<div class="rating-bit-container">
  <div class="rating-container">
    <div class="level-container">
      @for (level of levels; track level) {
        <div [attr.id]="level === selectedLevel ? 'bit-' + bit.id + '-selected-level' : undefined"
             class="level-box"
             [class.cursor-pointer]="!displayOnly && allowAnswerSubmit"
             [class.selected]="level === selectedLevel"
             (click)="markAsSelected(level)">
          {{ level }}
        </div>
      }
    </div>
    <div class="levels-labels">
      <div class="levels-label">{{ bit.ratingLevelStart?.label }}</div>
      <div class="levels-label">{{ bit.ratingLevelEnd?.label }}</div>
    </div>
  </div>
</div>
@if (showFeedback && bit.answer?.hasSelected) {
  <div class='feedback-button-wrapper animated fadeInDown pt-2'>
    <button class='btn btn-action bit-button'
            (click)='submitAnswer()'
            [innerText]="bit.buttonCaption || ('Shared.Save' | translate)">
    </button>
  </div>
} @else if (isOnce && bit.answer?.hasSelected && bit.answer?.timestamp) {
  <div class="pt-2 feedback-timestamp">{{ bit.answer.timestamp | bitmarkDate }}</div>
}
