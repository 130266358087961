@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
<div class="mb-1 bit-body bit-body-allow-highlight"
     [ngClass]="bit.type">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
<div class="match-matrix-grid" [style.grid-template-columns]="columnsGridTemplateStyle">
  <div class="font-weight-bold">
    <bitmark-text [format]="bit?.format" [text]="bit?.heading?.forKeys" [cssClass]="'d-inline-elements'"></bitmark-text>
  </div>
  @for (x of columnsFillerArray; track x; let index = $index) {
    <div class="font-weight-bold d-sm-flex justify-content-sm-center">
      @if (bit?.heading?.forValues?.length > index) {
        <bitmark-text [format]="bit?.format"
                      [text]="bit?.heading?.forValues[index]"
                      [cssClass]="'d-inline-elements'">
        </bitmark-text>
      }
    </div>
  }
  @for (row of bit?.matrix; track row; let rowIndex = $index) {
    <div class="d-flex align-items-center">
      <div class="d-flex font-weight-bold font-weight-normal-sm">
        @if (row?.item) {
          <bitmark-text [text]="row.item"
                        [format]="bit.format"
                        class="sub-item"
                        cssClass="sub-item-match-matrix text-nowrap">
          </bitmark-text>
        }
        @if (row?.key) {
          <bitmark-text [text]="row.key"
                        [format]="bit.format"
                        [cssClass]="'d-inline-elements'">
          </bitmark-text>
        }
      </div>
    </div>
    @for (x of columnsFillerArray; track x; let index = $index) {
      <div>
        @if (row?.cells[index]?.values?.length) {
          @if (row.cells[index].isExample) {
            <div class="d-block input inline w-100 correct bit-body-text">
              <bitmark-text [text]="row.cells[index].example || row.cells[index].values[0]"
                            [format]="bit.format">
              </bitmark-text>
            </div>
          } @else {
            <input type="text"
                   class="input inline w-100"
                   [class.correct]="row.cells[index].isExample"
                   [ngClass]="answersCss[rowIndex*10+index]"
                   [placeholder]="row.cells[index].instruction | bitmark:bit.format:'text'"
                   [readOnly]="readOnly || !allowAnswerSubmit"
                   [value]="row.cells[index].answer?.text || ''"
                   (input)="change(row.cells[index], $event)"
                   [attr.size]="rowInputSize">
          }
        }
      </div>
    }
  }
</div>

<!--<div class="row no-gutters mb-1 text-pre-wrap">-->
<!--  <div class="col-sm-3 col-12 font-weight-bold" [innerText]="bit?.heading?.forKeys || '&nbsp;'"></div>-->
<!--  <div-->
<!--    class="col-sm-9 col-12 d-sm-flex justify-content-around font-weight-normal font-italic font-weight-bold-sm font-style-normal-sm">-->
<!--    @for (v of bit?.heading?.forValues; track v) {-->
<!--      <div class="text-sm-center px-0 px-sm-2 key-width"-->
<!--           [innerText]="v"-->
<!--           [style.width.%]="100/bit?.heading?.forValues?.length">-->
<!--      </div>-->
<!--    }-->
<!--  </div>-->
<!--</div>-->

<!--@for (row of bit?.matrix; track row; let rowIndex = $index) {-->
<!--  <div class="row no-gutters text-pre-wrap">-->
<!--    <div class="d-flex col-12 col-sm-3 font-weight-bold font-weight-normal-sm p-0 pr-0 pr-sm-2 mb-2">-->
<!--      @if (row?.item) {-->
<!--        <bitmark-text [text]="row.item"-->
<!--                      [format]="bit.format"-->
<!--                      class="sub-item"-->
<!--                      cssClass="sub-item-match-matrix text-nowrap">-->
<!--        </bitmark-text>-->
<!--      }-->
<!--      @if (row?.key) {-->
<!--        <bitmark-text class="input-label inline"-->
<!--                      [text]="row.key"-->
<!--                      [format]="bit.format"-->
<!--                      [cssClass]="'d-inline-elements'">-->
<!--        </bitmark-text>-->
<!--      }-->
<!--    </div>-->
<!--    <div class="col-12 col-sm-9 d-block d-sm-flex justify-content-sm-between">-->
<!--      @for (cell of row?.cells; track cell; let cellIndex = $index) {-->
<!--        @if (cell?.values?.length) {-->
<!--          <div class="ml-0 ml-sm-2 mb-2" [style.width.%]="100/(row?.cells?.length || 1)">-->
<!--            @if (cell.isExample) {-->
<!--              <div class="d-block input inline w-100 correct bit-body-text">-->
<!--                <bitmark-text [text]="cell.example || cell.values[0]" [format]="bit.format"></bitmark-text>-->
<!--              </div>-->
<!--            } @else {-->
<!--              <input type="text"-->
<!--                     class="input inline w-100"-->
<!--                     [class.correct]="cell.isExample"-->
<!--                     [ngClass]="answersCss[rowIndex*10+cellIndex]"-->
<!--                     [placeholder]="cell.instruction | bitmark:bit.format:'text'"-->
<!--                     [readOnly]="readOnly"-->
<!--                     [value]="cell.answer?.text || ''"-->
<!--                     (input)="change(cell, $event)">-->
<!--            }-->
<!--          </div>-->
<!--        }-->
<!--      }-->
<!--    </div>-->
<!--  </div>-->
<!--}-->

@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit.footer"
                [format]="bit.format">
  </bitmark-text>
}
