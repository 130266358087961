<div #headerContainer>
  <div class="d-flex justify-content-center pt-4 pb-4 flashcards-header"
       [class.flashcard-header-modal]="isInModal">
    <div class="mr-4 cursor-pointer flashcards-pot"
         [ngClass]="'pot-' + -1"
         [class.selected-flashcard-pot]="currentPotIndex === -1"
         (click)="changePot($event?.target,-1)">
      <div class="flashcards-pot-value">{{ flashcardBits.length - leinterPotsBitsCount }}</div>
    </div>
    @for (pot of appFlashcardsBit.answer?.leitner?.pots; track pot; let index = $index; let last = $last) {
      <div class="flashcards-pot"
           [class.mr-4]="!last"
           [class.cursor-pointer]="pot.length"
           [class.selected-flashcard-pot]="currentPotIndex === index"
           [ngClass]="'pot-' + index"
           (click)="pot.length && changePot($event?.target, index)">
        <div class="flashcards-pot-value">{{ pot.length || 0 }}</div>
      </div>
    }
  </div>
  <div class="position-relative flashcards-content" [class.flashcards-content-modal]="isInModal">
    @if (!card) {
      <div class="d-flex justify-content-center align-items-center h-100 flashcards-cards-empty">No more cards left.
      </div>
    } @else {
      <div class="flashcards-card-container" #cardContainer>
        <bitmark-app-flashcards-card
          [cardState]="cardState"
          [frontCard]="card.front"
          [backCard]="card.back"
          [showActionButtons]="!isMobile || isInModal"
          [currentCardIndex]="cardIndex + 1"
          [cardsCount]="flashcardPoolBits.length"
          (markAsRight)="markAsRight()"
          (markAsWrong)="markAsWrong()"
          (goToNext)="goToNext()">
        </bitmark-app-flashcards-card>
      </div>
    }
    @if (prevCard) {
      <div class="prev-flashcards-card-container" #prevCardContainer>
        <bitmark-app-flashcards-card
          [cardState]="'front'"
          [frontCard]="prevCard.front"
          [backCard]="prevCard.back"
          [showActionButtons]="!isMobile || isInModal"
          [currentCardIndex]="prevCardIndex"
          [cardsCount]="flashcardPoolBits.length"
          [markedAsRight]="appFlashcardsBit?.answer?.normal?.right?.includes(prevCard.bitId)"
          [markedAsWrong]="appFlashcardsBit?.answer?.normal?.wrong?.includes(prevCard.bitId)"
          (markAsRight)="markAsRight()"
          (markAsWrong)="markAsWrong()"
          (goToNext)="goToNext()">
        </bitmark-app-flashcards-card>
      </div>
    }
  </div>
</div>
@if (!isMobile || isInModal) {
  <div class="d-flex justify-content-center mt-2 flashcards-navigation">
    <div class="d-flex justify-content-center align-items-center flashcards-navigation-content">
      <div class="arrows p-1 position-relative"
           [class.arrows-background]="!!flashcardPoolBits.length">
        @if (!!flashcardPoolBits.length) {
          <div class="arrows arrows-background left-arrow p-1">
            <bitmark-icon
              name="arrow-left"
              class="size-25px d-block"
              (click)="goToPrevious()">
            </bitmark-icon>
          </div>
        }
        <bitmark-icon name="arrow-left"
                      class="size-40px d-block right-arrow"
                      [style.visibility]="!!flashcardPoolBits.length ? 'visible' : 'hidden'"
                      (click)="goToNext()">
        </bitmark-icon>
      </div>
    </div>
  </div>
}
