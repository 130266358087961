@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4">
  </bitmark-resource>
}
@if (wordBankItems?.length) {
  <ul class="solution-instruction-grouped p-2 mb-2">
    @for (item of wordBankItems; track item.text) {
      <li>
        <div class="d-inline cursor-pointer"
             (click)="toggleWordBankSolution(item)"
             [class.used-solution]="item.used">
          <bitmark-text [text]="item.text"
                        [format]="bit.format"
                        [cssClass]="'d-inline-elements'">
          </bitmark-text>
        </div>
      </li>
    }
  </ul>
}
<div class="bit-body bit-body-allow-highlight"
     [ngClass]="bit.type">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
@if (!isTakingPicture && bit.answer?.image) {
  <div class="mt-2">
    <bitmark-resource-image-link
      [bitResourceImageLink]="bit.answer.image"
      [fullWidth]="true">
    </bitmark-resource-image-link>
  </div>
}
@if (isTakingPicture) {
  <div class="mt-2">
    <bitmark-resource-camera-photo-edit
      [isUploadingPhoto]="isUploadingPicture"
      [hideTakePictureButton]="true"
      (onCapture)="pictureCaptured($event)"
      (onCancel)="cancelTakePicture()">
    </bitmark-resource-camera-photo-edit>
  </div>
}
<div class="d-flex align-items-center w-100 mt-4">
  <button class="btn-take-picture m-auto d-flex align-items-center justify-content-center"
          (click)="takePicture()">
    <bitmark-icon name="camera" class="size-35px text-white"></bitmark-icon>
  </button>
</div>
