<div class="annotation-container">
  <div class="p-1 text-center title-header"
       [ngStyle]="{ backgroundColor: bit.color?.secondary || '#ffed82' }">
    @if (!canBeEdited) {
      <bitmark-text [text]="$any(bit.title)"
                    [format]="BitmarkFormats.MM">
      </bitmark-text>
    }
    @if (canBeEdited) {
      <bitmark-reader-simple-text-editor #titleEditorComponent
                                         (updateBit)="onTitleUpdate($event)"
                                         (click)="enterTitleEditMode()"
                                         [bit]="bit"
                                         [bitContent]="bit.title"
                                         [isReadOnly]="false"
                                         class="w-100">
      </bitmark-reader-simple-text-editor>
    }
  </div>
  <div class="p-2 reader-text-editor annotation-body position-relative"
       [ngStyle]="{ backgroundColor: bit.color?.primary || '#fffb81' }">
    @if (!canBeEdited) {
      <bitmark-text [text]="$any(bit.content)"
                    [format]="BitmarkFormats.PP">
      </bitmark-text>
    } @else {
      <bitmark-reader-text-editor #editorComponent
                                  (updateBit)="onUpdate($event)"
                                  (click)="enterEditMode()"
                                  [bit]="bit"
                                  [bitContent]="bit.content"
                                  [isReadOnly]="false"
                                  class="w-100"
                                  (created)="editorCreated.emit(editorComponent)">
      </bitmark-reader-text-editor>
    }
    @if(user){
      <p style="position: absolute; bottom: -42px; right: 0">{{user?.fullName || user.email}}</p>
    }
  </div>
</div>
