<div>
  <div class="position-relative image-container">
    <bitmark-resource-image
      [bitResourceImage]="bit?.resource?.image"
      [hostBitType]="BitType.DetailsImage"
      [centered]="true"
      class="resource-image">
    </bitmark-resource-image>
    <div class="triangle"></div>
    <div class="toggle-btn" [class.rotate]="!isBodyCollapsed" (click)="toggleBody()">
      <bitmark-icon name="plus" class="size-50px"></bitmark-icon>
    </div>
  </div>
  <div class="body-container bit-body bit-body-allow-highlight overflow-hidden"
       [@slideToggle]="toggleState">
    <div class="body-size">
      <bitmark-text [text]="bit.body" [format]="bit.format"></bitmark-text>
    </div>
  </div>
  <div class="instruction-container d-flex justify-content-center bitmark-instruction">
    @if (bit?.item) {
      <bitmark-text class="item"
                    [text]="bit?.item"
                    [format]="bit.format">
      </bitmark-text>
    }
    @if (bit?.item && bit?.lead) {
      <div class="lead-dash"></div>
    }
    @if (bit?.lead) {
      <bitmark-text class="lead"
                    [text]="bit?.lead"
                    [format]="bit.format">
      </bitmark-text>
    }
    @if (bit?.instruction) {
      <bitmark-text class="instruction text-pre-wrap"
                    [text]="bit?.instruction"
                    [format]="bit.format">
      </bitmark-text>
    }
  </div>
</div>
