<app-modal-wrapper (closed)="dismiss()">
  <div class="d-flex flex-column justify-content-between h-100 w-100 pt-4 pr-2 pl-2 unselectable">
    <div class="multimedia__content d-flex justify-content-center align-items-center">
      @if (imageChangedEvent) {
        <div class="image-cropper">
          <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [resizeToWidth]="resizeToWidth"
            [cropperMinWidth]="cropperMinWidth"
            [onlyScaleDown]="true"
            [roundCropper]="true"
            [autoCrop]="false"
            alignImage="left"
            format="png">
          </image-cropper>
        </div>
      } @else {
        <div class="multimedia__content__dropzone-wrapper d-flex justify-content-center align-items-center">
          <input class="multimedia__content__input" type="file" (change)="fileChangeEvent($event)"
                 accept="{{acceptFiles}}">
          <div class="multimedia__content__dropzone-content">
            <app-icon name="cloud-upload" class="text-calm"></app-icon>
            <div class="text-calm">
              @if (acceptFiles.indexOf('image') !== -1) {
                <span [innerHtml]="'Profile.ChoosePhoto' | translate"></span>
              }
            </div>
          </div>
        </div>
      }
    </div>

    <div class="d-flex justify-content-center pt-4 flex-end mb-2">
      <button ngbAutofocus
              (click)="sendMultimedia()"
              class="btn-action btn-action--filled"
              [innerHtml]="'Profile.SavePhoto' | translate">
      </button>
    </div>
  </div>
</app-modal-wrapper>
