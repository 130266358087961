<div [ceModel]="text"
     [ceDefault]="defaultText"
     [ceAllowNewline]="allowNewline"
     (ceChange)="onTextChange($event)"
     (ceEnterPressed)="onEnterPressed()"
     [class]="'d-inline-block ' + cssClass"
     contenteditable="plaintext-only"
     [attr.data-placeholder]="placeholder"
     [attr.spellcheck]="isSpellcheckOn"
     (focusin)="isSpellcheckOn = true"
     (focusout)="isSpellcheckOn=false">
  {{text}}
</div>
