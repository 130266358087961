<ng-template #dropdownButton>
  <div class="image-container cursor-pointer">
    <div class="title">
      <h6 class="text-white mb-0">{{ title }}</h6>
      @if (subtitle) {
        <h6 class="text-white mb-0">{{ subtitle }}</h6>
      }
    </div>
    <img [src]="'../../../assets/images/resources/' + type + '-btn.png'" alt="{{title}}"/>
  </div>
</ng-template>
<div>
  <bitmark-dropdown class="d-inline-block ml-auto"
                    container="body"
                    [placement]="['bottom', 'top']"
                    [actions]="actions"
                    [dropdownButton]="dropdownButton"
                    dropdownClass="lg">
  </bitmark-dropdown>
</div>
