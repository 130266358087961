@if ((bit?.resource && !bit?.resource?.article) || isBeingEditedByUser) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [isBeingEditedByUser]="isBeingEditedByUser"
                    (open)="openResource.emit($event)"
                    (changed)="updateBitResource($event)"
                    class="d-block"
                    [ngClass]="[(!(bit.item || bit.lead || bit.instruction) && !isBeingEditedByUser && !isResourceType && bit.resource?.type === BitResourceType.Document) ? 'pt-4' : '',
                  'scrollbar-' + bitSubtype]">
  </bitmark-resource>
}
@if (wordBankItems?.length) {
  <ul class="solution-instruction-grouped p-2 mb-2">
    @for (item of wordBankItems; track item.text) {
      <li>
        <div class="d-inline cursor-pointer"
             (click)="toggleWordBankSolution(item)"
             [class.used-solution]="item.used">
          <bitmark-text [text]="item.text"
                        [format]="bit.format"
                        [cssClass]="'d-inline-elements'">
          </bitmark-text>
        </div>
      </li>
    }
  </ul>
}
<div [style.display]="!isBeingEditedByUser && !canBeEdited ? '' : 'none'"
     [ngClass]="bitSubtype"
     [class.mt-4]="(hasBitResource || isBeingEditedByUser) && !isBodyEmpty"
     [class.mb-4]="isBeingEditedByUser"
     [class.text-placeholder]="isBodyEmpty && isEditable"
     class="bit-body bit-body-allow-highlight">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="isBodyEmpty ? null : bit?.body"
                [format]="bit?.format">
  </bitmark-text>
</div>
@if (isBeingEditedByUser || canBeEdited) {
  <bitmark-reader-text-editor #editorComponent
                              class="d-block bit-body"
                              [ngClass]="bitSubtype"
                              [class.mt-4]="hasBitResource || isBeingEditedByUser"
                              [class.mb-4]="isBeingEditedByUser"
                              [bit]="bit"
                              [bitContent]="bit.body | bitmark:bodyFormat:'json'"
                              [updateContentEvent]="updateContentEventSubject"
                              [isReadOnly]="!canBeEdited"
                              [placeholder]="'Bits.PlaceholderBody' | translate"
                              (updateBit)="onUpdateContent(bit, $event)"
                              (click)="enterEditMode()"
                              (created)="editorCreated.emit(editorComponent)">
  </bitmark-reader-text-editor>
}
@if (bit?.resource?.article && !isBeingEditedByUser) {
  <bitmark-resource-article [bitResourceArticle]="bit?.resource?.article"
                            class="d-block mt-1">
  </bitmark-resource-article>
}
@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit.footer"
                [format]="bit.format">
  </bitmark-text>
}
@if (isBeingEditedByUser) {
  <div class="p-2 edit-container">
    <div class="d-flex justify-content-center m-2">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
}
