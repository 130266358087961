@if (!isBeingEditedByUser && !canBeEdited) {
  <div class="d-flex flex-row justify-content-start align-items-start"
       [ngClass]="{'module-fields-container-small': _durationHours, 'module-fields-container-large': !_durationHours, }">
    @if (bit?.icon?.length && !isImageError) {
      <div class='accordion-image mr-2'>
        <div class='position-relative h-100'>
          <div class='image-container'>
            <img [src]='bit?.icon'
                 [alt]='bit?.instruction'
                 (error)='isImageError = true'/>
          </div>
        </div>
      </div>
    }
    <div class="instruction-positioning instruction-positioning-{{bitTypeBranding}}">
      <div class="item-positioning item-positioning-{{bitTypeBranding}}">
        @if (!isItemEmpty) {
          <div class="item-wrapper">
            <bitmark-text class="bitmark-item item-bit item-bit-{{bitTypeBranding}}"
                          cssClass="d-inline-block"
                          [text]="bit?.item"
                          [format]="BitmarkFormats.PP">
            </bitmark-text>
          </div>
        }
        @if (!isItemEmpty && !isLeadEmpty) {
          <div class="d-inline-block lead-dash-{{bitTypeBranding}}"></div>
        }
        @if (!isLeadEmpty) {
          <bitmark-text class="bitmark-lead lead-bit lead-bit-{{bitTypeBranding}}"
                        cssClass="d-inline-block"
                        [text]="bit?.lead"
                        [format]="BitmarkFormats.PP">
          </bitmark-text>
        }
      </div>
      <div>
        @if (!isInstructionEmpty) {
          <bitmark-text class="bitmark-instruction instruction-bit instruction-bit-module"
                        [text]="bit?.instruction"
                        [format]="BitmarkFormats.PP">
          </bitmark-text>
        }
      </div>
    </div>
    <div class="duration text-white">
      @if (+_durationHours > 0) {
        <span class="mr-2"><span class="mr-1">{{ _durationHours }}</span>hr.</span>
      }
      @if (+_durationMinutes > 0) {
        <span class="">{{ _durationMinutes }}</span>'
      }
    </div>
  </div>
}

@if (!isBeingEditedByUser && canBeEdited) {
  <div class="d-flex flex-row justify-content-start align-items-start"
       [ngClass]="{'module-fields-container-small': _durationHours, 'module-fields-container-large': !_durationHours, }">
    @if (bit?.icon?.length && !isImageError) {
      <div class='accordion-image mr-2 h-100'>
        <div class='position-relative h-100'>
          <div class='image-container'>
            <img [src]='bit?.icon'
                 [alt]='bit?.instruction'
                 (error)='isImageError = true'/>
          </div>
        </div>
      </div>
    }
    <div class="instruction-positioning instruction-positioning-{{bitTypeBranding}}">
      <div class="item-positioning item-positioning-{{bitTypeBranding}}">
        @if (!isItemEmpty) {
          <div class="item-wrapper">
            <bitmark-editable-text class="bitmark-item item-bit item-bit-{{bitTypeBranding}}"
                                   (onChange)="onItemChanged(bit, $event)"
                                   [text]="bit.item"
                                   [format]="BitmarkFormats.PP"
                                   [placeholder]="'Bits.Item' | translate"
                                   [cssClass]="'text-pre'">
            </bitmark-editable-text>
          </div>
        }

        @if (!isItemEmpty && !isLeadEmpty) {
          <div class="d-inline-block lead-dash-{{bitTypeBranding}}"></div>
        }
        @if (!isLeadEmpty) {
          <bitmark-editable-text class="bitmark-lead lead-bit lead-bit-{{bitTypeBranding}}"
                                 (onChange)="onLeadChanged(bit, $event)"
                                 [text]="bit.lead"
                                 [format]="BitmarkFormats.PP"
                                 [placeholder]="'Bits.Lead' | translate">
          </bitmark-editable-text>
        }
      </div>

      <div>
        @if (!isInstructionEmpty) {
          <bitmark-editable-text class="bitmark-instruction instruction-bit lead-bit-{{bitTypeBranding}}"
                                 (onChange)="onInstructionChanged(bit, $event)"
                                 [text]="bit.instruction"
                                 [format]="BitmarkFormats.PP"
                                 [placeholder]="'Bits.Instruction' | translate">
          </bitmark-editable-text>
        }
      </div>
    </div>
    <div class="duration text-white cursor-pointer">
      <bitmark-duration-editor ngDefaultControl
                               class="module-duration-picker-window"
                               (ngModelChange)="onDurationChanged(bit, $event)"
                               [hoursLabel]="'hr.'"
                               [minutesLabel]="'\''"
                               [(ngModel)]="moduleDuration">
      </bitmark-duration-editor>
    </div>
  </div>
}

@if (isBeingEditedByUser) {
  <div class="d-flex flex-row justify-content-start align-items-start"
       [ngClass]="{'module-fields-container-small': _durationHours, 'module-fields-container-large': !_durationHours, }">
    <div class='accordion-image mr-2 h-100'>
      <div class='position-relative h-100'>
        @if (bit?.icon?.length && !isImageError) {
          <div class='position-relative h-100'
               [ngClass]="isBeingEditedByUser && 'image-editable'">
            <div class='image-container'>
              <img [src]='bit?.icon'
                   [alt]='bit?.instruction'
                   (error)='isImageError = true'/>
            </div>
            <bitmark-icon name='trash-can-outline'
                          class='size-30px remove-image'
                          (click)='removeImage()'>
            </bitmark-icon>
          </div>
        } @else {
          <div class='d-flex align-items-center justify-content-center image-placeholder'
               [ngClass]="'image-editable'"
               (click)='fileInput.click()'>
            <bitmark-icon
              name='cloud-upload' class='size-30px'></bitmark-icon>
          </div>
        }
        <input accept='image/*' hidden type='file' [attr.multiple]='null' #fileInput
               (change)='uploadImage()'>
      </div>
    </div>
    <div class="instruction-positioning instruction-positioning-{{bitTypeBranding}}">
      <div class="item-positioning item-positioning-{{bitTypeBranding}}">
        <div class="item-wrapper">
          <bitmark-editable-text class="bitmark-item item-bit item-bit-{{bitTypeBranding}}"
                                 (onChange)="onItemChanged(bit, $event)"
                                 [text]="bit.item"
                                 [format]="BitmarkFormats.PP"
                                 [placeholder]="'Bits.Item' | translate">
          </bitmark-editable-text>
        </div>

        <div class="d-inline-block lead-dash-{{bitTypeBranding}}"></div>

        <bitmark-editable-text class="bitmark-lead lead-bit lead-bit-{{bitTypeBranding}}"
                               (onChange)="onLeadChanged(bit, $event)"
                               [text]="bit.lead"
                               [format]="BitmarkFormats.PP"
                               [placeholder]="'Bits.Lead' | translate">
        </bitmark-editable-text>
        <div class="duration text-white cursor-pointer">
          <bitmark-duration-editor ngDefaultControl
                                   class="module-duration-picker-window"
                                   (ngModelChange)="onDurationChanged(bit, $event)"
                                   [hoursLabel]="'hr.'"
                                   [minutesLabel]="'\''"
                                   [(ngModel)]="moduleDuration">
          </bitmark-duration-editor>
        </div>
      </div>
      <div>
        <bitmark-editable-text class="bitmark-instruction instruction-bit lead-bit-{{bitTypeBranding}}"
                               (onChange)="onInstructionChanged(bit, $event)"
                               [text]="bit.instruction"
                               [format]="BitmarkFormats.PP"
                               [placeholder]="'Bits.Instruction' | translate">
        </bitmark-editable-text>
      </div>
    </div>
  </div>
}
