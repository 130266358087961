<div class="position-absolute w-100">
  <svg class="w-100 h-100">
    <g id="sticker_bookmark">
      <path
        d="M0,60.7894737 C48.8230026,59.5964912 115.753121,62.2107031 200.790356,68.6321093 C285.82759,75.0535155 322.531203,79.8428124 310.901196,83 L310.901196,19.0677419 L173.748429,19.0677419 L0,16 L0,60.7894737 Z"
        id="Path-2" fill="#000000" opacity="0.145228795"
        transform="translate(156.500000, 49.500000) scale(-1, 1) translate(-156.500000, -49.500000)">
      </path>
      <rect id="Rectangle"
            x="13" y="0" width="300" height="60"
            class="bookmark-rect"
            [ngStyle]="{ fill: bit.color?.primary }">
      </rect>
      <polygon id="Path"
               points="296.87234 5.45696821e-12 323.319149 5.45696821e-12 369 30 323.319149 60 296.87234 60"
               class="bookmark-polygon"
               [ngStyle]="{ fill: bit.color?.secondary }">
      </polygon>
    </g>
  </svg>
</div>
<div class="position-relative p-1 pl-3 ml-6 reader-text-editor annotation-body">
  @if (!canBeEdited) {
    <bitmark-text [text]="$any(bit.content)"
                  [format]="BitmarkFormats.PP">
    </bitmark-text>
  } @else {
    <bitmark-reader-simple-text-editor #editorComponent
                                       (updateBit)="onUpdate($event)"
                                       (click)="enterEditMode()"
                                       [bit]="bit"
                                       [bitContent]="bit.content"
                                       [isReadOnly]="false"
                                       [height]="50"
                                       [hasLists]="true"
                                       class="w-100"
                                       (created)="editorCreated.emit(editorComponent)">
    </bitmark-reader-simple-text-editor>
  }
  @if(user){
    <p style="position: absolute; bottom: -42px; right: 0">{{user?.fullName || user.email}}</p>
  }
</div>
