<div class="modal-backdrop show"></div>
<div #wrapper class="dropdown__mobile d-flex flex-column justify-content-end">
  <div class="content mr-2 ml-2 mt-2">
    @if (!additionalContent) {
      @for (action of actions; track action) {
        @if (!action.isHidden) {
          @if (!action.isSeparator) {
            <button (click)="choose(action)"
                    class="dropdown-item-button py-2 px-3">
              @if (action.image) {
                <div class='d-inline-block dropdown-item-image'>
                  <img [src]="action.image"/>
                </div>
              }
              @if (action.icon) {
                <bitmark-icon [name]="action.icon"
                              [ngClass]="action.iconCssClass || 'text-black'">
                </bitmark-icon>
              }
              @if (action.prefix) {
                <span class="text-left pl-2 prefix" [innerHTML]="action.prefix"></span>
              }
              <div class="text-left pl-2" [innerHTML]="action.label"></div>
              @if (action.actions) {
                <bitmark-icon name="chevron-right"
                              class="pl-2 align-self-end ml-auto">
                </bitmark-icon>
              }
            </button>
          } @else {
            <div class="dropdown-separator"></div>
          }
        }
      }
    } @else {
      <ng-container *ngTemplateOutlet="additionalContent"></ng-container>
    }
  </div>
  <button class="footer border-0 py-2 m-2" (click)="close()" [innerHTML]="'Shared.Cancel' | translate"></button>
</div>
