<div class="split split-left p-3 d-flex flex-column justify-content-between" style="will-change: transform">
  <textarea #codeEditor class="split-bitmark-editor h-100 flex-grow-1 p-3" scrollAntiBlock
            style="overscroll-behavior: contain; will-change: transform"></textarea>
  <div>
    <div class="pt-2 d-flex align-items-center justify-content-center">
      <div (click)="close.emit()" class="mx-4 text-center text-calm cursor-pointer font-weight-bold">
        Close
      </div>
      <div (click)="translateHtml()" class="btn btn-background-calm py-1 px-4 w-auto" style="max-width: 160px !important;">
        Translate
      </div>
    </div>
  </div>
</div>

<div class="split split-right p-3" style="overscroll-behavior: contain; will-change: transform" scrollAntiBlock>
  <div [innerText]="translatedHtml || ''"></div>
  @if(glossaryTranslations?.length){
    <div class="mt-3">
      Glossary translations: {{glossaryTranslations | json}}
    </div>
  }
</div>
