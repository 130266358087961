@if (!isLoading) {
  @if (items?.length) {
    <div class="bits-wrapper px-sm-4">
      <div class="d-flex flex-column mt-5">
        @for (bitWrapper of items; track bitWrapper.id) {
          <bitmark-bit-wrapper context="basket"
                               [bitWrapper]="bitWrapper"
                               [actions]="bitActions">
          </bitmark-bit-wrapper>
        }
        <div class="reader-bottom-spacer"></div>
      </div>
    </div>
  } @else {
    <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100 no-basket-items">
      <bitmark-icon name="basket-icon"></bitmark-icon>
      <div class="mt-2" [innerHTML]="'Reader.Basket.EmptyBasket' | translate"></div>
    </div>
  }
}
