<div class="bit-body"
     [ngClass]="bit.type">
  <details>
    <summary>
      @if (bit?.item) {
        <bitmark-text class="item"
                      [text]="bit?.item"
                      [format]="bit.format">
        </bitmark-text>
      }
      @if (bit?.item && bit?.lead) {
        <div class="lead-dash"></div>
      }
      @if (bit?.lead) {
        <bitmark-text class="lead"
                      [text]="bit?.lead"
                      [format]="bit.format">
        </bitmark-text>
      }
      @if (bit?.instruction) {
        <bitmark-text class="instruction text-pre-wrap"
                      [text]="bit?.instruction"
                      [format]="bit.format">
        </bitmark-text>
      }
    </summary>
    <p class="bit-body-allow-highlight content">
      <bitmark-text [attr.data-bitmark-node]="'body'"
                    [text]="bit?.body"
                    [format]="bit.format">
      </bitmark-text>
    </p>
  </details>
</div>
