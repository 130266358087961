@if (product) {
  <div class='w-100 page-product-container d-flex flex-row'>
    <div class='page-product d-flex flex-column justify-content-center align-items-center'
         [ngClass]="{'entry-wide': isWideEntry, 'cursor-pointer': !product?.bitbookDoesNotExist, 'cursor-default': product?.bitbookDoesNotExist}">
      <div class='book-cover mx-0 d-flex align-items-end ml-auto mr-auto position-relative'>
        <bitmark-book-cover (click)='openProductPage()'
                            class='w-100 h-100'
                            [cssClass]='"page-product-video-cover"'
                            [coverImage]='product?.media?.length && product?.media[0].file.url'
                            [isFullyBranded]='true'
                            [isWideCoverAllowed]='true'
                            [isProductCover]="true"
                            [isNotExist]="product?.productDoesNotExist || product?.isInWrongSpace"
                            [title]='product?.title'>
        </bitmark-book-cover>
        <div class='video-length'>{{ videoLength }}</div>
      </div>
      <div class='book-details d-flex flex-row mx-0 mt-1'>
        @if (publisherAvatar) {
          <img class='book-publisher-avatar mr-1' [attr.alt]='publisher' [src]='publisherAvatar'/>
        }
        <div class='book-texts px-lg-1 px-0' (click)='openProductPage()'>
          @if (title) {
            <div class='book-title line-clamp mb-0'
                 [innerText]='title + (product?.bitbookDoesNotExist && !product?.productDoesNotExist ? "&nbsp;🔒" : "")'>
            </div>
          }
          @if (publisher) {
            <div class='book-publisher mb-0'
                 [innerText]='publisher'>
            </div>
          }
          @if (product?.meta?.handInStatus) {
            <div class="text-center hand-in-emoji">
              @if(product?.meta?.handInStatus === HandInStatus.NotHandedIn){
                ✏️
              }
              @if(product?.meta?.handInStatus === HandInStatus.Rejected){
                ❌
              }
              @if(product?.meta?.handInStatus === HandInStatus.Approved){
                🏅
              }
              @if(product?.meta?.handInStatus === HandInStatus.HandedIn || product?.meta?.handInStatus === HandInStatus.Assigned){
                ✋
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}
