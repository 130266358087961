<div [style.aspect-ratio]="16/9">
  @if (!started) {
    <div class="w-100 h-100 position-relative placeholder">
      @if (posterResourceImage) {
        <bitmark-resource-image [bit]="bit"
                                [bitResourceImage]="posterResourceImage"
                                [imageMaxWidth]="0"
                                [hostBitType]="BitType.Scorm"
                                class="position-absolute d-block w-100 h-100 tl-0"
                                [ngClass]="!isBeingEditedByUser && 'cursor-pointer'"
                                (click)="start()">
        </bitmark-resource-image>
      }
      @if (isBeingEditedByUser && coverImageExists) {
        <bitmark-icon name="trash-can-outline"
                      class="size-40px cursor-pointer remove-image"
                      (click)="removeCoverImage()">
        </bitmark-icon>
      }
      @if (!coverImageExists && isBeingEditedByUser) {
        <div class="d-flex align-items-center justify-content-center image-placeholder"
             [ngClass]="isBeingEditedByUser && 'image-editable'"
             (click)="isBeingEditedByUser && fileInput.click()">
          <bitmark-icon name="cloud-upload" class="size-30px cursor-pointer"></bitmark-icon>
        </div>
      }
      <input accept="image/*" hidden type="file" [attr.multiple]="null" #fileInput (change)="uploadCoverImage()">
    </div>
  }
</div>
@if (!isBeingEditedByUser) {
  <button class="btn btn-action bit-button start-button"
          [innerHTML]="'Shared.Start' | translate"
          (click)="start()">
  </button>
} @else {
  <div class="p-2 edit-container">
    <div>
      <label for="source-dropdown" class="d-block font-weight-bold-sm">Source</label>
      <bitmark-dropdown id="source-dropdown"
                        container="body"
                        class="d-inline-block ml-4"
                        [placement]="['bottom', 'top']"
                        [actions]="courses"
                        [dropdownButton]="dropdownButton"
                        (selected)="bit.scormSource = $event.id">
      </bitmark-dropdown>
    </div>

    <div class="d-flex justify-content-center m-2 mt-4">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
}

<ng-template #dropdownButton>
  <div class="d-flex align-items-center align-content-between cursor-pointer">
    <div class="answer flex-grow-1"
         [title]="getScormSourceCourseTitle()"
         [innerHTML]="getScormSourceCourseTitle()">
    </div>
    <bitmark-icon name="chevron-down" class="flex-shrink-0"></bitmark-icon>
  </div>
</ng-template>
