<div class="position-relative document-icon-container">
  <svg width="120px" height="160px" viewBox="0 0 120 160" version="1.1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M120,42.105 L120,147.3675 C120,154.345 114.440816,160 107.586122,160 L12.4138776,160 C5.55673469,160 0,154.345 0,147.3675 L0,12.6325 C0,5.655 5.55673469,0 12.4138776,0 L78.6195918,0 L120,42.105 Z"
        id="path-1"></path>
    </defs>
    <g id="file" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Path">
        <mask id="mask-2" fill="white">
          <use href="#path-1"></use>
        </mask>
        <use id="Mask" [attr.fill]="documentPrimary || '#767676'" fill="" href="#path-1"></use>
        <path
          d="M123,36.8834239 L123,42.0095109 L89.1722449,42.0095109 C82.1681633,42.0095109 78.9379592,36.3355978 78.9379592,29.338587 L78.9379592,-2 L84.0612245,-2 L123,36.8834239 Z"
          [attr.fill]="documentSecondary || '#AAAAAA'" mask="url(#mask-2)"></path>
      </g>
    </g>
  </svg>
  <div class="text-white extension">{{documentExtension}}</div>
</div>
