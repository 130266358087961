import {Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageProcessorService, SubSink} from 'core';
import {finalize} from 'rxjs/operators';

export interface ImageResult {
  file: File;
  type: string;
  url: string;
}

@Component({
  selector: 'app-profile-photo-upload',
  templateUrl: './profile-photo-upload.component.html',
  styleUrls: ['./profile-photo-upload.component.scss']
})
export class ProfilePhotoUploadComponent implements OnDestroy {
  @Input() peerId: number;
  @Input() acceptFiles: string;
  @Input() imageChangedEvent: any = '';
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  url: any;
  type: string;
  file: File;
  croppedImage: any = '';
  resizeToWidth = 256;
  cropperMinWidth = 128;

  private subSink = new SubSink();

  constructor(private ngbActiveModal: NgbActiveModal,
              private ipService: ImageProcessorService) {
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  sendMultimedia() {
    if (!this.imageCropper) {
      return;
    }

    const event: ImageCroppedEvent = this.imageCropper.crop('base64');
    this.croppedImage = event.base64;
    const file = this.dataURLtoFile(event.base64, 'avatar.png');
    const type = file.type.split('/')[0];
    if (type === 'image') {
      this.subSink.sink = this.ipService.loadWithOptions(file, {maxWidth: 600})
        .pipe(finalize(() => {
          this.ngbActiveModal.close({file: this.file, type: this.type, url: this.url} as ImageResult);
        }))
        .subscribe((result: { canvas: HTMLCanvasElement, blob: Blob, base64: string, dataUrl: string }) => {
          this.file = new File([result.blob], file.name, {type: type, lastModified: Date.now()});
          this.type = this.file.type.split('/')[0];
          this.url = result.dataUrl;
        });
    }
  }

  dismiss() {
    this.ngbActiveModal.close();
  }

  fileChangeEvent(event: any): void {
    if (!event.target.files.length) {
      return;
    }
    if (!event.target.files[0]) {
      return;
    }
    this.imageChangedEvent = event;
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }
}
