<div class="bit-body bit-body-allow-highlight">
  <bitmark-text [style.display]="!canBeEdited ? '' : 'none'"
                [attr.data-bitmark-node]="'body'"
                [class.text-placeholder]="isBodyEmpty"
                [text]="$any(bit?.bit || bit?.content)"
                [format]="bit?.format">
  </bitmark-text> 
  @if (canBeEdited) {
    <bitmark-reader-text-editor #editorComponent
                                (updateBit)="onUpdate($event)"
                                (click)="enterEditMode()"
                                [bit]="bit"
                                [bitContent]="(bit?.bit || bit?.content) | bitmark:bitFormat:'json'"
                                [isReadOnly]="!canBeEdited"
                                [disableKeyboardInput]="disableKeyboardInput"
                                (created)="editorCreated.emit(editorComponent)"
                                class="w-100">
    </bitmark-reader-text-editor>
  }
</div>
