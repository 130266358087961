<div>
  <div class="padlet-embed wrapper">
    <p>
      <iframe [src]="url"
              frameborder="0"
              allow="camera;microphone;geolocation"
              style="width:100%;height:608px;display:block;padding:0;margin:0">
      </iframe>
    </p>
  </div>
</div>
@if (isBeingEditedByUser) {
  <div class="p-2 edit-container">
    <input type="text" class="form-control mb-1" placeholder="Padlet Id or URL" [(ngModel)]="settingsUrl"/>
    <div class="d-flex justify-content-center m-2 mt-4">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
}
