<div class="reader-shadow bg-white modal-container">
  @if (documentLinkResource) {
    <div class="p-4">
      <bitmark-resource-document-link [bitResource]="documentLinkResource"></bitmark-resource-document-link>
    </div>
  } @else {
    <div class="p-4 document-placeholder">
      <div class="d-flex flex-column align-items-center justify-content-center ">
        <div class="font-weight-bold-sm" [innerText]="'Shared.Document' | translate"></div>
        <div class="btn btn-action bit-button start-button disabled mt-4"
             [innerHTML]="'Shared.Download' | translate">
        </div>
      </div>
    </div>
  }
  <div class="p-2 edit-container">
    <input type="text"
           class="form-control mb-1"
           placeholder="https://..."
           [(ngModel)]="documentLink"
           (ngModelChange)="onLinkChanged()"/>
    <div class="d-flex justify-content-center m-2 mt-4">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
</div>
