<div class="split split-left p-3 d-flex flex-column justify-content-between"
     style="will-change: transform">
  <textarea
    #codeEditor
    class="split-bitmark-editor h-100 flex-grow-1 p-3"
    scrollAntiBlock
    (keydown)="onKeyDown($event)"
    style="overscroll-behavior: contain; will-change: transform">{{ editingBitBitmark }}</textarea>
  <div>
    <div class="pt-2 d-flex align-items-center justify-content-center">
      @if (isEditingWholeBook) {
        <div (click)="showPreview()" class="btn btn-background-calm py-1 px-4 w-auto">
          Show preview
        </div>
      }
      <div (click)="close.emit()" class="mx-4 text-center text-calm cursor-pointer font-weight-bold">
        Cancel
      </div>
      <button (click)="onSave()" class="btn btn-background-calm py-1 px-4 w-auto" [attr.disabled]="saveDisabled || undefined">
        Save
      </button>
    </div>
  </div>
</div>

<div class="split split-right p-3"
     style="overscroll-behavior: contain; will-change: transform"
     scrollAntiBlock>
  @if (parsedBits?.length) {
    @for (bitWrapper of parsedBits; track bitWrapper) {
      <div class="mb-4"
           [class.py-3-]="bitWrapper.bit?.type !== BitType.Page && bitWrapper.bit?.type !== BitType.PreparationNote">
        <!--      <bitmark-bit [hideDefaultActions]="true" [bitWrapper]="bitWrapper"></bitmark-bit>-->
        <div class="reader-content-wrapper">
          <div class="p-2 reader-content">
            <bitmark-bit-wrapper [bitWrapper]="bitWrapper"
                                 context="editor">
            </bitmark-bit-wrapper>
          </div>
        </div>
        @for (err of bitWrapper?.parser?.errors; track err) {
          <strong class='text-red'>{{ err?.message }} - at line {{ err.location?.start?.line }}, column {{ err.location?.start?.column }}</strong>
        }
        @if (bitEditNotSupportedError) {
          <strong class="text-red">{{bitEditNotSupportedError}}</strong>
        }
        <pre class='mt-2' [innerHTML]='bitWrapper | json'></pre>
      </div>
    }
  }
</div>
