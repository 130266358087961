import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LearningEventClassroomEventBit} from './learning-event-classroom-event.models';
import {createEvent, EventAttributes} from 'ics';
import {JSONContent} from '@tiptap/core';
import {BitmarkTextContentService} from '../../../shared';
import {FileUtilsService} from '../../../shared/utils/file-utils.service';
import {ReaderTipTapTapService} from '../../../reader/tiptap/reader-tiptap.service';
import {LearningEventCommon, toggleAnimations} from '../learning-event.common';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {ReaderLeContentService} from '../../../reader/reader-book/reader-content/reader-le-content.service';

@Component({
  selector: 'bitmark-learning-event-classroom-event',
  templateUrl: './learning-event-classroom-event.component.html',
  styleUrls: ['./learning-event-classroom-event.component.scss', '../learning-event.common.scss'],
  animations: toggleAnimations
})
export class LearningEventClassroomEventComponent extends LearningEventCommon implements OnInit {
  @Input() bit?: LearningEventClassroomEventBit;
  @Output() saveInlineBit = new EventEmitter<LearningEventClassroomEventBit>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    if (value) {
      this.bitTime = this.bit.date || null;
      this.bitEndTime = this.bit.dateEnd || null;

      setTimeout(() => {
        this.toggleState = 'expanded';
        this.toggleStateIsDone = 'expanded';
        this.isBodyCollapsed = false;
      });
    }
  }

  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  isLeadEmpty = false;
  isItemEmpty = false;
  isBodyEmpty = false;
  isInPast = false;
  bitTime?: string | null;
  bitEndTime?: string | null;

  constructor(private fileUtilsService: FileUtilsService,
              readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              readerLeContentService: ReaderLeContentService) {
    super(readerTipTapService, bitmarkTextContentService, readerLeContentService);
  }

  ngOnInit() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.isBodyEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.body, this.bit?.format);
    if (this.bit.dateEnd || this.bit.date) {
      this.isInPast = new Date(this.bit.dateEnd || this.bit.date) < new Date();
    }
    this.toggleStateIsDone = this.isInPast ? 'collapsed' : 'expanded';

    this.initExpandedBit(this.bit.id, this.isInPast);
  }

  downloadAddToCalendar() {
    if (!this.bit.date) {
      return;
    }

    const startDate = new Date(this.bit.date);
    const endDate = this.bit.dateEnd ? new Date(this.bit.dateEnd) : null;

    let body = this.bitmarkTextContentService.getTextFromJson(this.bit.body, this.bit.format);

    const event: EventAttributes = {
      start: [startDate.getUTCFullYear(), startDate.getUTCMonth() + 1, startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes()],
      startInputType: 'utc',
      startOutputType: 'local',
      end: endDate ? [endDate.getUTCFullYear(), endDate.getUTCMonth() + 1, endDate.getUTCDate(), endDate.getUTCHours(), endDate.getUTCMinutes()] : undefined,
      endInputType: 'utc',
      endOutputType: 'local',
      title: this.bit.instruction || ' ',
      description: body,
      location: this.bit.location,
      busyStatus: 'BUSY'
    };

    createEvent(event, (error, value) => {
      if (error) {
        console.error(error);
        return;
      }
      this.fileUtilsService.download('meeting.ics', value);
    });
  }

  onUpdateContent(bit: any, bitJson: JSONContent) {
    bit.body = bitJson?.content;
    bit.format = BitmarkFormat.PP;
    this.saveChanges();
  }

  saveChanges() {
    this.isLeadEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.lead, BitmarkFormat.MM);
    this.isItemEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.item, BitmarkFormat.MM);
    this.isBodyEmpty = this.bitmarkTextContentService.isElementEmpty(this.bit?.body, this.bit?.format);

    if (this.bit.date && this.bit) {
      const newDate = new Date(this.bit.date);
      const newTime = new Date(this.bitTime);
      this.bit.date = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newTime.getHours(), newTime.getMinutes(), newTime.getSeconds()).toISOString();
    }

    if (this.bit.date && this.bitEndTime) {
      const newDate = new Date(this.bit.date);
      const newTime = new Date(this.bitEndTime);
      this.bit.dateEnd = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newTime.getHours(), newTime.getMinutes(), newTime.getSeconds()).toISOString();
    }

    this.saveInlineBit.emit(this.bit);
  }
}
