<div class="p-2 learning-path-external-link-header"
     [ngClass]="{
      'learning-path-external-link-header-minimized': isHeaderMinimized,
      'learning-path-external-link-header-only': isHeaderOnly
     }">
  <div class="d-flex flex-row">
    @if (isContentVisible) {
      <div [ngClass]="(imageExists || isBeingEditedByUser) && 'learning-path-bit-image mr-2'">
        @if (imageExists) {
          <div class="position-relative h-100"
               [ngClass]="isBeingEditedByUser && 'image-editable'">
            <div class="image-container">
              <img [src]="bit.resource.image.src"
                   [alt]="bit.resource.image.alt"
                   (error)="handleImageLoadError()"/>
            </div>
            @if (isBeingEditedByUser) {
              <bitmark-icon name="trash-can-outline"
                            class="size-30px remove-image"
                            (click)="removeImage()">
              </bitmark-icon>
            }
          </div>
        } @else if (isBeingEditedByUser) {
          <div class="d-flex align-items-center justify-content-center image-placeholder"
               [ngClass]="isBeingEditedByUser && 'image-editable'"
               (click)="isBeingEditedByUser && fileInput.click()">
            <bitmark-icon name="cloud-upload" class="size-30px"></bitmark-icon>
          </div>
        }
        <input accept="image/*" hidden type="file" [attr.multiple]="null" #fileInput (change)="uploadImage()">
      </div>
    }
    <div class="header-content">
      <div class="text-truncate">
        @if (isBeingEditedByUser) {
          <div class="learning-path-item">
            <bitmark-editable-text
              (onChange)="bit.item = $event"
              [text]="bit.item"
              [placeholder]="'Bits.Item' | translate">
            </bitmark-editable-text>
          </div>
          <div class="font-weight-bold learning-path-instruction">
            <bitmark-editable-text
              (onChange)="bit.instruction = $event"
              [text]="bit.instruction"
              [placeholder]="'Bits.Instruction' | translate">
            </bitmark-editable-text>
          </div>
        } @else {
          <div class="learning-path-item">
            @if (bit.item) {
              <bitmark-text [text]="bit.item">
              </bitmark-text>
            }
          </div>
          <div class="font-weight-bold learning-path-instruction">
            <bitmark-text [text]="bit.instruction"></bitmark-text>
          </div>
        }
      </div>
      <div class="d-flex flex-row learning-path-header-details">
        @if (bit.action || isBeingEditedByUser) {
          <div class="mr-1"
               [ngClass]="!isBeingEditedByUser && 'text-truncate'">
            @if (isBeingEditedByUser) {
              <bitmark-editable-text
                (onChange)="bit.action = $event"
                [text]="bit.action"
                [placeholder]="'Bits.Action' | translate">
              </bitmark-editable-text>
            } @else {
              <bitmark-text [text]="bit.action"
                            [truncate]="true">
              </bitmark-text>
            }
          </div>
        }
        @if ((bit.location && bit.action) || isBeingEditedByUser) {
          <div class="mr-1">
            &bull;
          </div>
        }
        @if (bit.location || isBeingEditedByUser) {
          <div class="mr-1"
               [ngClass]="!isBeingEditedByUser && 'text-truncate'">
            @if (isBeingEditedByUser) {
              <bitmark-editable-text
                (onChange)="bit.location = $event"
                [text]="bit.location"
                [placeholder]="'Bits.Location' | translate">
              </bitmark-editable-text>
            } @else {
              <bitmark-text [text]="bit.location"
                            [truncate]="true">
              </bitmark-text>
            }
          </div>
        }
        @if (((bit.action || bit.location) && bit.duration) || isBeingEditedByUser) {
          <div class="mr-1">&bull;
          </div>
        }
        @if (bit.duration || isBeingEditedByUser) {
          <div class="mr-1 header-duration"
               [ngClass]="!isBeingEditedByUser && 'text-truncate'">
            @if (isBeingEditedByUser) {
              <bitmark-duration-editor ngDefaultControl
                                       [(ngModel)]="bit.duration">
              </bitmark-duration-editor>
            } @else {
              <span>{{ bit.duration | amDuration }}</span>
            }
          </div>
        }
      </div>
    </div>
    @if (bit.answer?.isDone && isTracked && !bit.isInfoOnly) {
      <div class="learning-path-external-link-check d-flex align-items-center"
           (click)="reset($event)">
        <bitmark-icon name="check-circle-fill" class="size-30px"></bitmark-icon>
      </div>
    }
  </div>
</div>
@if (isContentVisible) {
  <div [ngClass]="isBeingEditedByUser && 'body-container-edit-mode'">
    <div>
      @if (bit?.body || isBeingEditedByUser) {
        <div class="bit-body p-2 bit-body-allow-highlight">
          @if (isBeingEditedByUser) {
            <bitmark-reader-text-editor (updateBit)="onUpdateContent(bit, $event)"
                                        [bit]="bit"
                                        [bitContent]="bit.body | bitmark:bit.format:'json'"
                                        [updateContentEvent]="updateContentEventSubject"
                                        [isReadOnly]="false"
                                        [placeholder]="'Bits.PlaceholderBody' | translate"
                                        class="w-100">
            </bitmark-reader-text-editor>
          } @else {
            <bitmark-text [text]="bit?.body"
                          [format]="getBodyFormat(bit.body, bit.format)">
            </bitmark-text>
          }
        </div>
      }
      @if (externalLinkHost) {
        <div class="d-flex justify-content-center px-4 pb-4 font-weight-bold"
             [ngClass]="bit.body ? 'pt-2' : 'pt-4'">{{ externalLinkHost }}
        </div>
      }
      @if (bit.externalLink && !bit.isInfoOnly) {
        <div class="d-flex justify-content-center px-4 pb-4"
             [ngClass]="!externalLinkHost && 'pt-2'">
          <button class="btn btn-action bit-button text--ellipsis d-inline-block"
                  (click)="open()"
                  [innerHTML]="'Shared.Open' | translate">
          </button>
          @if (bit.answer?.hasOpened && !bit.answer?.isDone && isTracked) {
            <button
              class="btn btn-action bit-button text--ellipsis d-inline-block ml-2"
              [innerHTML]="'Shared.MarkAsDone' | translate"
              (click)="markAsDone()">
            </button>
          }
        </div>
      }
    </div>
  </div>
}
@if (isBeingEditedByUser) {
  <div class="p-2 edit-container">
    <input type="text"
           class="form-control mb-1"
           placeholder="https://..."
           [(ngModel)]="bit.externalLink"
           (ngModelChange)="onLinkChanged()"/>
    <!--  <input type="text" class="form-control mb-1" placeholder="www..."-->
    <!--         [(ngModel)]="learningPathExternalLinkBit.externalLinkText"/>-->
    <div class="d-flex justify-content-center m-2 mt-4">
      <button class="btn-action btn-action--transparent mr-2 state-button state-cancel-button"
              [innerHTML]="'Shared.Cancel' | translate"
              (click)="cancelChanges()">
      </button>
      <button class="btn btn-action bit-button text--ellipsis d-inline-block state-button state-save-button"
              [innerHTML]="'Shared.Save' | translate"
              (click)="saveChanges()">
      </button>
    </div>
  </div>
}
