<div class="modal-backdrop show"></div>
<div #wrapper class="dropdown__mobile d-flex flex-column justify-content-end">
  <div class="content mr-2 ml-2 mt-2">
    @if (!additionalContent) {
      @for (action of actions; track action) {
        @if (!action.isHidden) {
          @if (!action.isSeparator) {
            <button (click)="choose(action)"
                    class="dropdown-item-button py-2 px-3">
              @if (action.icon) {
                <app-icon [name]="action.icon"
                          [ngClass]="action.iconCssClass || 'text-black'">
                </app-icon>
              }
              <span class="text-left pl-2"
                    [innerHTML]="hasRawLabels ? action.label : (action.label | translate)">
              </span>
              @if (action.actions) {
                <app-icon name="chevron-right"
                          class="pl-2 align-self-end ml-auto"
                          [ngClass]="action.iconCssClass">
                </app-icon>
              }
            </button>
          }
          @if (action.isSeparator) {
            <div class="dropdown-separator"></div>
          }
        }
      }
    }
    @if (additionalContent) {
      <ng-container *ngTemplateOutlet="additionalContent"></ng-container>
    }
  </div>
  <button class="footer border-0 py-2 m-2" (click)="close()" [innerHTML]="'Shared.Cancel' | translate"></button>
</div>
