import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ReaderFiltersService} from './reader-filters.service';
import {BitbookMqService} from '../../bitbook-mq.service';
import {ReaderLocalContentService} from '../../reader-local-content.service';
import {SubSink} from '../../../shared';

@Component({
  selector: 'bitmark-reader-search-sidebar',
  templateUrl: './reader-search-sidebar.component.html',
  styleUrls: ['./reader-search-sidebar.component.scss']
})
export class ReaderSearchSidebarComponent implements OnInit, OnDestroy {
  @Input() isExternalSearch: boolean;
  @Input() bitBook;
  @Input() bitBookFilters;
  @Output() close = new EventEmitter<any>();

  lastSearchTexts = [];
  defaultFilters = {};
  activeFilters: any = JSON.parse(JSON.stringify(this.defaultFilters));
  filterOriginalNames = {
    g: 'groups',
    quiz: 'quiz',
    rg: 'resourceGroups'
  };
  private sub = new SubSink();

  constructor(private bitbookMqService: BitbookMqService,
              private readerLocalContentService: ReaderLocalContentService,
              private readerFiltersService: ReaderFiltersService) {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.sub.sink = this.bitbookMqService.onSearchMade().subscribe(this.onSearchMade.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderSearchFilterRemoved().subscribe(this.removeFilter.bind(this));
    this.sub.sink = this.bitbookMqService.onReaderSearchFiltersInitialSet().subscribe(this.setFilters.bind(this));

    if (this.isExternalSearch) {
      this.bitBook = {
        externalId: 'workspace',
        id: 'workspace'
      };
      return;
    }
    this.sub.sink = this.readerLocalContentService.getBookFilters(this.bitBook.externalId).subscribe((bitBookFilters) => {
      Object.keys(bitBookFilters).forEach((k) => {
        if (Array.isArray(bitBookFilters[k])) {
          bitBookFilters[k] = bitBookFilters[k].filter((f) => f);
        }
        if(bitBookFilters[k]?.length && typeof bitBookFilters[k][0] === 'string'){
          bitBookFilters[k] = bitBookFilters[k].map((b) => {
            return {
              name: b,
              text: b
            }
          })
        }
      });
      this.bitBookFilters = bitBookFilters;

      const queryFilters = this.getObjectFromQueryString();
      // console.log('Sidebar query filters: ', queryFilters);
      Object.keys(queryFilters).forEach((k) => {
        if (typeof queryFilters[k] === 'object') {
          this.activeFilters[k] = [];
          queryFilters[k].forEach((f) => {
            const propertyName = this.filterOriginalNames[k] ? this.filterOriginalNames[k] : k;
            const fil = this.bitBookFilters[propertyName].find((fl) => fl.name === f);
            if(fil){
              this.activeFilters[k].push(fil);
            }
          });
        } else {
          this.activeFilters[k] = queryFilters[k];
        }
      });
      // console.log('af: ', this.activeFilters);

      // this.readerFiltersService.getActiveFiltersForBook(this.bitBook.externalId).subscribe(localFilters => {
      //   if (localFilters) {
      //     Object.keys(localFilters).forEach((k) => {
      //       if (typeof localFilters[k] === 'object') {
      //         this.activeFilters[k] = [];
      //         localFilters[k].forEach((f) => {
      //           const propertyName = this.filterOriginalNames[k] ? this.filterOriginalNames[k] : k;
      //           this.activeFilters[k].push(this.bitBookFilters[propertyName].find((fl) => fl.name === f.name));
      //         });
      //       } else {
      //         this.activeFilters[k] = localFilters[k];
      //       }
      //     });
      //   }
      // });
    });
    this.sub.sink = this.readerLocalContentService.getSearchHistory(this.bitBook.externalId)
      .subscribe((searchHistory) => this.lastSearchTexts = searchHistory);
  }

  getObjectFromQueryString(): any {
    const queryParams: any = window.location.href.indexOf('?') !== -1 ? window.location.href.slice(window.location.href.indexOf('?') + 1).split('&') : [];
    const obj: any = {};

    queryParams?.forEach(item => {
      let [key, value] = item.split('=');
      key = key.trim().replace(/"/g, ''); // remove quotes and trim spaces
      value = decodeURIComponent(value.trim().replace(/"/g, ''));

      if (obj.hasOwnProperty(key)) {
        if(value.indexOf(',') !== -1){
          obj[key] = value.split(',');
        }
        if (Array.isArray(obj[key])) {
          obj[key].push(value);
        } else {
          obj[key] = [obj[key], value];
        }
      } else {
        if(value.indexOf(',') !== -1){
          obj[key] = value.split(',');
        } else{
          obj[key] = [value];
        }
      }
    });

    delete obj.p;
    delete obj.q;
    return obj;
  }

  onSearchMade() {
    this.sub.sink = this.readerLocalContentService.getSearchHistory(this.bitBook.externalId).subscribe((searchHistory) => {
      this.lastSearchTexts = searchHistory;
    });
  }

  removeFilter(f: any) {
    if (!this.activeFilters[f.parent]) {
      this.activeFilters[f.parent] = [];
    }
    if (this.activeFilters[f.parent].findIndex((v) => v.name === f.name) !== -1) {
      this.activeFilters[f.parent] = this.activeFilters[f.parent].filter((v) => v.name !== f.name);
    } else {
      this.activeFilters[f.parent].push(f);
    }
  }

  toggleFilter(key, value) {
    if (!this.activeFilters[key]) {
      this.activeFilters[key] = [];
    }
    if (typeof this.activeFilters[key] === 'string') {
      this.activeFilters[key] = [this.activeFilters[key]];
    }
    if (this.activeFilters[key].findIndex((v) => v?.name === value?.name) !== -1) {
      this.activeFilters[key] = this.activeFilters[key].filter((v) => v.name !== value.name);
    } else {
      this.activeFilters[key].push(value);
    }

    this.bitbookMqService.notifySearchFiltersChanged(this.getActiveFiltersForApi(this.activeFilters));
    this.readerFiltersService.storeActiveFiltersForBook(this.bitBook.externalId, this.activeFilters).subscribe();
  }

  getActiveFiltersForApi(activeFiltersFromSidebar: any) {
    const activeFiltersApi = {};
    Object.keys(activeFiltersFromSidebar).forEach((k) => {
      if (typeof activeFiltersFromSidebar[k] === 'object' && activeFiltersFromSidebar[k]?.length) {
        activeFiltersApi[k] = [];
        activeFiltersFromSidebar[k].forEach((fil) => {
          if (typeof fil === 'string') {
            activeFiltersApi[k].push(fil);
          } else if (k) {
            activeFiltersApi[k].push(fil.name);
          }
        });
      } else if (typeof activeFiltersFromSidebar[k] === 'string' || typeof activeFiltersFromSidebar[k] === 'number') {
        activeFiltersApi[k] = activeFiltersFromSidebar[k];
      }
    });
    return activeFiltersApi;
  }

  resetSearch() {
    this.activeFilters = JSON.parse(JSON.stringify(this.defaultFilters));
    this.bitbookMqService.notifySearchFiltersChanged(this.activeFilters);
  }

  setFilters(activeFilters: any) {
    Object.keys(activeFilters).forEach((k) => {
      if (typeof activeFilters[k] === 'object') {
        this.activeFilters[k] = [];
        activeFilters[k].forEach((f) => {
          const propertyName = this.filterOriginalNames[k] ? this.filterOriginalNames[k] : k;
          this.activeFilters[k].push(this.bitBookFilters[propertyName].find((fl) => fl.name === f.name));
        });
      } else {
        this.activeFilters[k] = activeFilters[k];
      }
    });
  }

  historyItemClickHandler(historyItem: { searchQuery: string, activeFilters: any }) {
    this.activeFilters = historyItem.activeFilters;
    this.bitbookMqService.notifySearchHistorySelected(historyItem);
  }

  private isAnyFilterActive() {
    const keys = Object.keys(this.activeFilters);
    return keys.some((k) => {
      return typeof this.activeFilters[k] === 'object' && this.activeFilters[k]?.length;
    });
  }
}
