<div #quote class="bit-body"
     [ngClass]="bit.type">
  <blockquote class="my-5 py-4">
    <bitmark-icon name="quote" class="size-60px"></bitmark-icon>
    <p class="mb-1 quote">
      @if (!isBeingEdited) {
        <bitmark-text [text]="bit?.body"
                      (click)="enterEditMode()"
                      [format]="bit.format">
        </bitmark-text>
      } @else {
        <bitmark-editable-text #editableText
                               (onChange)="changeQuote($event)"
                               (onEnterPress)="exitEditMode()"
                               [text]="(bit?.body)"
                               [format]="bit.format">
        </bitmark-editable-text>
      }
    </p>
    @if (bit?.quotedPerson) {
      <p class="m-0 quoted-person">
        –
        @if (!isBeingEdited) {
          <bitmark-text (click)="enterEditMode()"
                        [text]="bit?.quotedPerson"
                        [format]="bit.format">
          </bitmark-text>
        } @else {
          <bitmark-editable-text (onChange)="changeQuotedPerson($event)"
                                 (onEnterPress)="exitEditMode()"
                                 [text]="bit?.quotedPerson"
                                 [format]="bit.format">
          </bitmark-editable-text>
        }
      </p>
    }
  </blockquote>
</div>
@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit.footer"
                [format]="bit.format">
  </bitmark-text>
}
