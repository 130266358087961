import {BitType} from './bits.models';

export const articleBitTypes: Array<BitType> = [BitType.Article,
  BitType.Note, BitType.SideNote, BitType.EditorNote, BitType.Example, BitType.Info, BitType.Remark, BitType.Hint,
  BitType.Help, BitType.Warning, BitType.Danger, BitType.Bug, BitType.StickyNote,
  BitType.Editorial, BitType.BookFrontispiece, BitType.BookTitle, BitType.BookCopyright, BitType.BookDedication,
  BitType.BookForeword, BitType.BookPreface, BitType.BookPrologue, BitType.BookEpilogue, BitType.BookIntroduction,
  BitType.BookIncitingIncident, BitType.BookConclusion, BitType.BookAfterword, BitType.BookPostscript, BitType.BookAppendix,
  BitType.BookAddendum, BitType.BookAcknowledgements, BitType.BookListOfContributors, BitType.BookBibliography, BitType.BookReferenceList,
  BitType.BookEndnotes, BitType.BookNotes, BitType.BookCopyrightPermissions, BitType.BookTeaser, BitType.BookAuthorBio,
  BitType.BookRequestForABookReview, BitType.BookComingSoon, BitType.BookReadMore, BitType.BookSummary, BitType.BookImprint, BitType.Conclusion, BitType.ChapterSubjectMatter,
  BitType.ImageLink, BitType.ImageZoom, BitType.FocusImage, BitType.Photo, BitType.BrowserImage, BitType.ImageSuperWide, BitType.ImagePrototype,
  BitType.ImagePortrait, BitType.ImageLandscape, BitType.ImageOnDevice, BitType.PageBanner,
  BitType.ImageMood, BitType.Figure, BitType.ImageFigure, BitType.ImageBanner, BitType.ImageStyled, BitType.ImageScreenshot, BitType.ImageSeparator, BitType.LifeSkillSticker,
  BitType.AppGetScreenshot, BitType.AppCreateBitsFromImage,
  BitType.Audio, BitType.AudioLink, BitType.AudioEmbed,
  BitType.Video, BitType.VideoLink, BitType.VideoLinkPortrait, BitType.VideoLinkLandscape, BitType.VideoEmbed, BitType.VideoEmbedLandscape, BitType.VideoEmbedPortrait, BitType.VideoPortrait, BitType.VideoLandscape,
  BitType.StillImageFilm, BitType.StillImageFilmLink, BitType.StillImageFilmEmbed,
  BitType.WebsiteLink, BitType.Document, BitType.DocumentLink, BitType.DocumentEmbed, BitType.DocumentDownload, BitType.AppLink,
  BitType.Featured, BitType.NewspaperArticle, BitType.BlogArticle, BitType.BookArticle, BitType.NotebookArticle, BitType.WorkbookArticle,
  BitType.Checklist, BitType.Separator,
  BitType.LangLearningOutcomes, BitType.LangEnablingLanguageSkills, BitType.LangLifeSkills, BitType.LangVocabulary,
  BitType.LangEnglishAroundWorld, BitType.LangGoodToKnow, BitType.LangLearningStrategy, BitType.LangLikeALocal, BitType.LangUsefulPhrases,
  BitType.LangLevelDown, BitType.LangLevelUp, BitType.LangExtraActivity, BitType.LangHomework,
  BitType.LangMaterial, BitType.LangLearningGoal,
  BitType.AiPrompt, BitType.NoteAi, BitType.SummaryAi, BitType.ArticleAi,
  BitType.ReviewNote, BitType.ReviewAuthorNote, BitType.ReviewReviewerNote, BitType.ReviewRequestForReviewNote, BitType.ReviewApprovedNote,
  BitType.Assignment, BitType.LangTeacherNote, BitType.LangTeacherPronunciation,
  BitType.Step, BitType.StepImageScreenshot,
  BitType.IndexTerm, BitType.GlossaryTerm, BitType.DefinitionTerm,
  BitType.CookPreparation, BitType.CookRemark, BitType.CookVariation, BitType.CookInsert, BitType.CookStep,
  BitType.CookArrangement, BitType.CookPracticeAdvise, BitType.CookPlate, BitType.CookRecommendation, BitType.CookPersonalRecommendation,
  BitType.CookSideDish, BitType.CookSideDrink,
  BitType.ExtractorRepeatedText, BitType.ExtractorPageNumber, BitType.ExtractorPageHeader, BitType.ExtractorPageFooter, BitType.PageArticle,
  BitType.NoteAlt, BitType.ExampleAlt, BitType.RemarkAlt, BitType.InfoAlt, BitType.HintAlt, BitType.HelpAlt, BitType.WarningAlt, BitType.DangerAlt, BitType.BugAlt, BitType.ConclusionAlt, BitType.SeparatorAlt,
  BitType.StandardArticleNormative, BitType.StandardArticleNonNormative, BitType.StandardNoteNormative, BitType.StandardNoteNonNormative,
  BitType.StandardExampleNormative, BitType.StandardExampleNonNormative, BitType.StandardRemarkNormative, BitType.StandardRemarkNonNormative,
  BitType.ImageFigureAlt,
  BitType.StandardImageFigureNormative, BitType.StandardImageFigureNonNormative
];
export const articleBitResourceTypes: Array<BitType> = [
  BitType.ImageLink, BitType.ImageZoom, BitType.FocusImage, BitType.Photo, BitType.BrowserImage, BitType.ImageSuperWide, BitType.ImagePrototype,
  BitType.ImagePortrait, BitType.ImageLandscape, BitType.ImageOnDevice, BitType.PageBanner,
  BitType.ImageMood, BitType.Figure, BitType.ImageFigure, BitType.ImageBanner, BitType.ImageStyled, BitType.ImageScreenshot, BitType.ImageSeparator, BitType.LifeSkillSticker,
  BitType.AppGetScreenshot, BitType.AppCreateBitsFromImage,
  BitType.Audio, BitType.AudioLink, BitType.AudioEmbed,
  BitType.Video, BitType.VideoLink, BitType.VideoLinkLandscape, BitType.VideoLinkPortrait, BitType.VideoEmbed, BitType.VideoEmbedLandscape, BitType.VideoEmbedPortrait, BitType.VideoPortrait, BitType.VideoLandscape,
  BitType.StillImageFilm, BitType.StillImageFilmLink, BitType.StillImageFilmEmbed,
  BitType.WebsiteLink, BitType.Document, BitType.DocumentLink, BitType.DocumentEmbed, BitType.DocumentDownload, BitType.AppLink,
  BitType.Step, BitType.StepImageScreenshot,
  BitType.CookStep,
  BitType.ImageFigureAlt,
  BitType.StandardImageFigureNormative, BitType.StandardImageFigureNonNormative
];
export const articleAltBitTypes: Array<BitType> = [BitType.ArticleAlt, BitType.PageArticleAlt];
export const imageBitTypes = [BitType.Image, BitType.Screenshot];
export const cardBitTypes: Array<BitType> = [BitType.Card1, BitType.Question1];
export const quoteBitTypes: Array<BitType> = [BitType.Quote, BitType.BookEpigraph];
export const detailsBitTypes: Array<BitType> = [BitType.Details1, BitType.SampleSolution, BitType.AudioTranscript, BitType.VideoTranscript, BitType.LangVideoScript, BitType.LangAudioScript,
  BitType.Collapsible, BitType.SideNoteCollapsible, BitType.InfoCollapsible, BitType.RemarkCollapsible, BitType.WarningCollapsible, BitType.DangerCollapsible,
  BitType.NoteCollapsible, BitType.ExampleCollapsible, BitType.HintCollapsible, BitType.BugCollapsible
];
export const pageBitTypes: Array<BitType> = [BitType.Page, BitType.ExtractorPage, BitType.ExtractorPageWithBlocks];
export const stepImageScreenshotWithPointerBitTypes: Array<BitType> = [BitType.StepImageScreenshotWithPointer];
export const cookIngredientsBitTypes: Array<BitType> = [BitType.CookIngredients, BitType.Recipe];
export const tableBitTypes: Array<BitType> = [BitType.Table, BitType.TableAlt, BitType.ImageSeparatorAlt, BitType.StandardTableNormative, BitType.StandardTableNonNormative, BitType.StandardRemarkTableNormative, BitType.StandardRemarkTableNonNormative];
export const tableImageBitTypes: Array<BitType> = [BitType.TableImage, BitType.TableImageAlt, BitType.StandardTableImageNormative, BitType.StandardTableImageNonNormative, BitType.StandardRemarkTableImageNormative, BitType.StandardRemarkTableImageNonNormative];
export const pageBuyButtonBitTypes: Array<BitType> = [BitType.PageBuyButton, BitType.PageBuyButtonAlt];
