@if (product) {
  <div class='w-100 page-product-container d-flex flex-row'>
    <div class='page-product d-flex flex-column justify-content-center align-items-center cursor-pointer'
         [ngClass]="{'entry-wide': isWideEntry}">
      <div class='book-cover d-flex align-items-end ml-auto mr-auto position-relative'>
        <bitmark-book-cover (click)='openBook()'
                            (onIsWideImage)='setWideImage()'
                            [coverColor]='product?.coverColor'
                            class='w-100 h-100'
                            [coverImage]="product?.media?.length && product?.media[0].file.url"
                            [isFullyBranded]='true'
                            [isWideCoverAllowed]='true'
                            [title]='title'>
        </bitmark-book-cover>
      </div>
      <div class='mt-2 book-texts px-lg-1 px-0' (click)='openBook()'>
        @if (publisher) {
          <div class='book-publisher mb-0 text-center'
               [innerText]='publisher'>
          </div>
        }
        @if (title) {
          <div class='book-title text-center line-clamp'
               [innerText]='title'>
          </div>
        }
      </div>
      <div class='open-button-container'>
        <button (click)='openBook()'
                class='btn-background-calm btn-background-medium open-button button-caption'
                [innerHTML]="bit?.buttonCaption || ('Shared.Open'| translate)">
        </button>
      </div>
    </div>
  </div>
}
