@if (!isBeingEditedByUser && !canBeEdited) {
  <div class="instruction-positioning instruction-positioning-{{bitTypeBranding}}">
    <div class="item-positioning item-positioning-{{bitTypeBranding}} item-lead-bit-wrapper-{{bitTypeBranding}}">
      @if (!isItemEmpty) {
        <div class="item-wrapper">
          <bitmark-text class="bitmark-item item-bit item-bit-{{bitTypeBranding}}"
                        cssClass="d-inline-block"
                        [text]="bit?.item"
                        [format]="BitmarkFormats.MM">
          </bitmark-text>
          <div class="d-inline-block item-icon item-icon-{{bitTypeBranding}}"></div>
        </div>
      }
      @if (!isItemEmpty && !isLeadEmpty) {
        <div class="lead-dash-before-{{bitTypeBranding}}"></div>
      }
      @if (!isLeadEmpty) {
        <bitmark-text class="bitmark-lead lead-bit lead-bit-{{bitTypeBranding}}"
                      cssClass="d-inline-block w-100"
                      [text]="bit?.lead"
                      [format]="BitmarkFormats.MM">
        </bitmark-text>
        <div class="lead-dash-after-{{bitTypeBranding}}"></div>
      }
    </div>

    @if (!isInstructionEmpty || !isTechnicalTermEmpty) {
      <div
        class="instruction-technical-term-container technical-term-positioning technical-term-positioning-{{bitTypeBranding}} instruction-bit-wrapper-{{bitTypeBranding}}">
        @if (!isInstructionEmpty) {
          <bitmark-text class="bitmark-instruction instruction-bit instruction-bit-{{bitTypeBranding}}"
                        [text]="bit?.instruction"
                        [format]="BitmarkFormats.MM">
          </bitmark-text>
        }

        @if (!isTechnicalTermEmpty) {
          <bitmark-text class="bitmark-instruction technical-term-bit technical-term-bit-{{bitTypeBranding}}"
                        [text]="bit?.technicalTerm?.technicalTerm"
                        [format]="BitmarkFormats.MM">
          </bitmark-text>
        }
      </div>
    }
  </div>
}

@if (canBeEdited && !isBeingEditedByUser) {
  <div class="instruction-positioning instruction-positioning-{{bitTypeBranding}}">
    <div class="item-positioning item-positioning-{{bitTypeBranding}} item-lead-bit-wrapper-{{bitTypeBranding}}">
      @if (!isItemEmpty) {
        <div class="item-wrapper">
          <bitmark-reader-simple-text-editor class="bitmark-item item-bit item-bit-{{bitTypeBranding}}"
                                             [bit]="bit"
                                             [bitContent]="bit?.item | bitmark:BitmarkFormats.MM:'json'"
                                             (updateBit)="onItemChanged(bit, $event)"
                                             [isReadOnly]="false">
          </bitmark-reader-simple-text-editor>
          <div class="d-inline-block item-icon item-icon-{{bitTypeBranding}}"></div>
        </div>
      }

      @if (!isItemEmpty && !isLeadEmpty) {
        <div class="lead-dash-before-{{bitTypeBranding}}"></div>
      }
      @if (!isLeadEmpty) {
        <bitmark-reader-simple-text-editor class="bitmark-lead lead-bit lead-bit-{{bitTypeBranding}}"
                                           [bit]="bit"
                                           [bitContent]="bit?.lead | bitmark:BitmarkFormats.MM:'json'"
                                           (updateBit)="onLeadChanged(bit, $event)"
                                           [isReadOnly]="false">
        </bitmark-reader-simple-text-editor>
        <div class="lead-dash-after-{{bitTypeBranding}}"></div>
      }
    </div>

    @if (!isInstructionEmpty || !isTechnicalTermEmpty) {
      <div
        class="instruction-technical-term-container technical-term-positioning technical-term-positioning-{{bitTypeBranding}} instruction-bit-wrapper-{{bitTypeBranding}}">
        @if (!isInstructionEmpty) {
          <bitmark-reader-simple-text-editor
            class="bitmark-instruction instruction-bit instruction-bit-{{bitTypeBranding}}"
            [bit]="bit"
            [bitContent]="bit?.instruction | bitmark:BitmarkFormats.MM:'json'"
            (updateBit)="onInstructionChanged(bit, $event)"
            [isReadOnly]="false">
          </bitmark-reader-simple-text-editor>
        }

        @if (!isTechnicalTermEmpty) {
          <bitmark-reader-simple-text-editor
            class="bitmark-technical-term technical-term-bit technical-term-bit-{{bitTypeBranding}}"
            [bit]="bit"
            [bitContent]="bit?.technicalTerm?.technicalTerm | bitmark:BitmarkFormats.MM:'json'"
            [isReadOnly]="false"
            (updateBit)="onTechnicalTermChanged(bit, $event)">
          </bitmark-reader-simple-text-editor>
        }
      </div>
    }
  </div>
}

@if (isBeingEditedByUser) {
  <div>
    <div class="instruction-positioning instruction-positioning-{{bitTypeBranding}}">
      <div class="item-positioning item-positioning-{{bitTypeBranding}} item-lead-bit-wrapper-{{bitTypeBranding}}">
        <div class="item-wrapper">
          <bitmark-reader-simple-text-editor class="bitmark-item item-bit item-bit-{{bitTypeBranding}}"
                                             [bit]="bit"
                                             [bitContent]="bit?.item | bitmark:BitmarkFormats.MM:'json'"
                                             (updateBit)="onItemChanged(bit, $event)"
                                             [placeholder]="'Bits.Item' | translate"
                                             [isReadOnly]="false">
          </bitmark-reader-simple-text-editor>
        </div>

        <div class="lead-dash-before-{{bitTypeBranding}}"></div>

        <bitmark-reader-simple-text-editor class="bitmark-lead lead-bit lead-bit-{{bitTypeBranding}}"
                                           [bit]="bit"
                                           [bitContent]="bit?.lead | bitmark:BitmarkFormats.MM:'json'"
                                           (updateBit)="onLeadChanged(bit, $event)"
                                           [isReadOnly]="false"
                                           [placeholder]="'Bits.Lead' | translate">
        </bitmark-reader-simple-text-editor>

        <div class="lead-dash-after-{{bitTypeBranding}}"></div>
      </div>

      <div
        class="instruction-technical-term-container technical-term-positioning technical-term-positioning-{{bitTypeBranding}} instruction-bit-wrapper-{{bitTypeBranding}}">
        <bitmark-reader-simple-text-editor
          class="bitmark-instruction instruction-bit instruction-bit-{{bitTypeBranding}} d-flex"
          [bit]="bit"
          [bitContent]="bit?.instruction | bitmark:BitmarkFormats.MM:'json'"
          [updateContentEvent]="updateInstructionEvent"
          (updateBit)="onInstructionChanged(bit, $event)"
          [isReadOnly]="false"
          [placeholder]="'Bits.Instruction' | translate">
        </bitmark-reader-simple-text-editor>

        @if (!isTechnicalTermEmpty) {
          <bitmark-reader-simple-text-editor
            class="bitmark-technical-term technical-term-bit technical-term-bit-{{bitTypeBranding}}"
            [bit]="bit"
            [bitContent]="bit?.technicalTerm | bitmark:BitmarkFormats.MM:'json'"
            (updateBit)="onTechnicalTermChanged(bit, $event)"
            [isReadOnly]="false">
          </bitmark-reader-simple-text-editor>
        }
      </div>
    </div>
  </div>
}
