<audio #audioElement
       controls
       controlsList="nodownload"
       class="w-100">
  Your browser does not support the audio element.
</audio>
<label class="trying" [ngbPopover]="tiptaTextBubbleMenu" triggers="mouseenter" style="color: gray;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;">audio</label>

<ng-template class="reader-shadow tiptap-text-bubble-menu d-none p-2" #tiptaTextBubbleMenu>
  <div class="d-flex flex-row justify-content-between">
    <span>
      <button class="tiptap-bubble-item d-block" (click)="editor.chain().focus().toggleBold().run()"
              [ngClass]="{ 'is-active': editor.isActive('bold')}">
        <strong>B</strong>
      </button>
    </span>
    <span>
      <button class="tiptap-bubble-item d-block" (click)="editor.chain().focus().toggleItalic().run()"
              [ngClass]="{ 'is-active': editor.isActive('italic')}">
        <em>I</em>
      </button>
    </span>
    <span>
      <button class="tiptap-bubble-item d-block" (click)="editor.chain().focus().setColor('lightgray').run()"
              [ngClass]="{ 'is-active': editor.isActive('textStyle', { color: 'lightgray' })}">
        <span style="color: gray">L</span>
      </button>
    </span>
    <span>
      <button class="tiptap-bubble-item d-block" (click)="editor.chain().focus().toggleStrike().run()"
              [ngClass]="{ 'is-active': editor.isActive('strike')}">
        <span style="text-decoration: line-through">S</span>
      </button>
    </span>
  </div>
</ng-template>
