<div class="d-flex flex-column align-items-center justify-content-center resource-document-wrapper">
  @if (bitResource?.document && !bitResource?.preview?.image?.src) {
    <bitmark-document-icon [document]="bitResource.document"
                           class="resource-document-icon">
    </bitmark-document-icon>
  }
  @if (bitResource?.preview?.image?.src) {
    <img [ngClass]="previewImageClass"
         [src]="bitResource.preview.image.src"
         class="resource-document-icon"/>
  }
  <!--  <div class="resource-document-name" [innerText]="documentTitle"></div>-->
  @if (bitResource?.document) {
    <a [href]="bitResource?.document.url"
       [attr.download]="allowDirectDownload ? '' : null"
       (click)="!allowDirectDownload ? downloadDocument($event) : undefined"
       class="mt-4 text-decoration-none download-btn-container">
      @if (isDownloadLoading) {
        <div>
          <bitmark-progress-spinner [size]="20"></bitmark-progress-spinner>
        </div>
      } @else {
        <div class="btn btn-action bit-button start-button"
             [innerHTML]="'Shared.Download' | translate">
        </div>
      }
    </a>
  }
</div>
