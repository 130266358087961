<div class="bit-body bit-body-allow-highlight">
  <bitmark-text [attr.data-bitmark-node]="'body'"
                [text]="bit?.body"
                [format]="bit.format">
  </bitmark-text>
</div>
@if (bit?.table) {
  <div [class.mt-2]="bit?.body">
    <gridjs-angular #grid
                    [columns]="columns"
                    [data]="asyncData"
                    [height]="bit?.tableHeight ? bit.tableHeight + 'px' : undefined"
                    [fixedHeader]="bit?.tableFixedHeader"
                    [pagination]="paginationConfig"
                    [search]="false"
                    [className]="className"
                    [language]="language"
                    [autoWidth]="false">
    </gridjs-angular>
  </div>
}
@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit.footer"
                [format]="bit.format">
  </bitmark-text>
}
