import {Injectable, RendererFactory2} from '@angular/core';
import {Location} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {forkJoin, Observable, of, tap, throwError} from 'rxjs';
import {catchError, concatMap, map, take} from 'rxjs/operators';
import {throttle} from 'lodash';
import {ImageViewerComponent, ModalTouchSwipeService} from '../../../shared';
import {BitbookMqService} from '../../bitbook-mq.service';
import {BitmarkPagination, BookEntity, ReaderModes} from '../../reader.models';
import {BitApiAnnotation, BitApiWrapper, BitResource, BitsViewPortVisibility, BitType} from '../../../bits/bits.models';
import {ReaderTocService} from '../../reader-toc.service';
import {BookPosition, ReaderTrackingService} from '../../reader-tracking.service';
import {BitbookApiService} from '../../bitbook-api.service';
import {ReaderLocalContentService} from '../../reader-local-content.service';
import {BookEntityToc, BookType} from '../../../shared/models/bitmark.models';
import {DomObserverService} from '../../../shared/dom/dom-observer.service';
import {AnalyticsService} from '../../../shared/analytics/analytics.service';

@Injectable()
export class ReaderContentService {
  constructor(private router: Router,
              private location: Location,
              private rendererFactory: RendererFactory2,
              private ngbModal: NgbModal,
              private modalTouchSwipeService: ModalTouchSwipeService,
              private bitBookApiService: BitbookApiService,
              private bitbookMqService: BitbookMqService,
              private readerTocService: ReaderTocService,
              private readerLocalContentService: ReaderLocalContentService,
              private readerTrackingService: ReaderTrackingService,
              private domObserverService: DomObserverService,
              private analyticsService: AnalyticsService) {
  }

  virtualEndBitIds = ['end', 'mark-as-read', 'hand-in', 'hand-in-review'];
  pageSize = 50;
  isTocSelectingBit = false;

  getBitBookSummaryBit(bitBook: BookEntity): BitApiWrapper {
    return {
      id: 'start',
      index: -1,
      bit: {id: 'start', type: BitType.BitBookSummary, lang: bitBook?.lang || bitBook?.mainLanguage},
      meta: {thisBook: bitBook, chapterPath: []}
    };
  }

  getBitBookMarkAsDoneBit(bitBook: BookEntity, lastBit: BitApiWrapper, index: number): BitApiWrapper | any {
    return {
      id: 'mark-as-done',
      index: index || 1 + bitBook.toc.findIndex((b: BookEntityToc) => b.ref == lastBit?.id),
      bit: {
        id: 'mark-as-done',
        bookId: bitBook?.externalId,
        type: BitType.VirtualMarkBookAsRead,
        hint: bitBook.type,
        lang: bitBook?.lang || bitBook?.mainLanguage
      },
      meta: {chapterPath: lastBit?.chapterPath, originBook: bitBook},
    };
  }

  // getBitBookHandInBit(bitBook: BookEntity, lastBit: BitApiWrapper, index: number): BitApiWrapper | any {
  //   return {
  //     id: 'hand-in',
  //     index: index || 1 + bitBook.toc.findIndex((b: BookEntityToc) => b.ref == lastBit?.id),
  //     isEditNotAllowed: true,
  //     bit: {
  //       id: 'hand-in',
  //       bookId: bitBook?.externalId,
  //       type: BitType.VirtualHandIn,
  //       hint: bitBook.type,
  //       lang: bitBook?.lang || bitBook?.mainLanguage,
  //     },
  //     meta: {
  //       chapterPath: lastBit?.chapterPath,
  //       publisherId: 'system',
  //       themeId: 'hand-in',
  //       thisBook: {theme: 'hand-in'},
  //       originBook: null
  //     },
  //   };
  // }

  // getBitBookHandInReviewBit(bitBook: BookEntity, lastBit: BitApiWrapper, index: number): BitApiWrapper | any {
  //   return {
  //     id: 'hand-in-review',
  //     index: index || 1 + bitBook.toc.findIndex((b: BookEntityToc) => b.ref == lastBit?.id),
  //     bit: {
  //       id: 'hand-in-review',
  //       bookId: bitBook?.externalId,
  //       handIn: bitBook.handIn,
  //       type: BitType.VirtualHandInReview,
  //       hint: bitBook.type,
  //       lang: bitBook?.lang || bitBook?.mainLanguage
  //     },
  //     meta: {
  //       chapterPath: lastBit?.chapterPath,
  //       publisherId: 'system',
  //       themeId: 'hand-in',
  //       thisBook: {theme: 'hand-in'},
  //       originBook: null
  //     },
  //   };
  // }

  getBitBookEndingBit(bitBook: BookEntity, lastBit: BitApiWrapper, index?: number): BitApiWrapper {
    return {
      id: 'end',
      index: index || 1 + bitBook.toc.findIndex((b: BookEntityToc) => b.ref == lastBit?.id),
      bit: {id: 'end', type: BitType.BitBookEnding, hint: bitBook.type, lang: bitBook?.lang || bitBook?.mainLanguage},
      meta: {chapterPath: lastBit?.chapterPath, originBook: bitBook},
    };
  }

  showContentInvalidAlert(bookType: string, bitBook: BookEntity) {
    alert(`This ${bookType} is not readable.\n` +
      'We are sorry for the inconvenience.\n' +
      'We have been informed automatically.\n' +
      'Please come back later.');
    this.bitbookMqService.notifyBookContentInvalid();
    throw new Error(`Book content is invalid for book ${bitBook.id}`);
  }

  loadBit(startingBit: string, bitBook: BookEntity, queryParams?: any): Observable<{
    bitBookContent: Array<BitApiWrapper>,
    bit: BitApiWrapper
  }> {
    // console.log('loading bit: ', startingBit);
    return this.readerTocService.getBitIndexById(bitBook.externalId, startingBit)
      .pipe(concatMap((bitIdx: number) => this.bitBookApiService.getBookContentWithBitInMiddle(bitBook.externalId, startingBit, bitIdx, true, queryParams)))
      .pipe(concatMap((newContent: Array<BitApiWrapper>) => {
        const bit = newContent.find((b: BitApiWrapper) => b.id == startingBit);
        return of({bitBookContent: newContent, bit});
      }))
      .pipe(catchError((err: HttpErrorResponse) => {
        console.error(err);
        if (err.status === 404) {
          this.bitbookMqService.notifyReaderInvalidateBookCache(bitBook.externalId);
        }
        return of({bitBookContent: [], bit: null});
      }));
  }

  loadContent(bitBook: BookEntity, pagination: BitmarkPagination, fragment?: string, resetLastPos = false, queryType?: string, queryParams?: any, additionalOptions?: {
    readerMode?: ReaderModes,
    ignoreStartBit?: boolean
  }): Observable<{
    bitBookContent: Array<BitApiWrapper>,
    lastPos: BookPosition,
    isAtTop: boolean,
    isAtBottom: boolean
  }> {
    let lastBookPos: BookPosition;
    const isShopReader = additionalOptions?.readerMode && [
      ReaderModes.Shop,
      ReaderModes.ShopReadonly,
      ReaderModes.ShopSection,
      ReaderModes.ShopSectionReadonly,
      ReaderModes.ShopPublic
    ].includes(additionalOptions.readerMode);
    const loadLeBits = [ReaderModes.LearningEvents].includes(additionalOptions?.readerMode);
    const loadAllBits = [ReaderModes.LearningEventsReadonly].includes(additionalOptions?.readerMode);
    return (resetLastPos ? this.readerTrackingService.clearLastPositionForBook(bitBook.externalId) : of({}))
      .pipe(concatMap(() => forkJoin([
        this.readerTrackingService.getLastPositionForBook(bitBook.externalId),
        fragment
          ? this.readerTocService.getBitIndexById(bitBook.externalId, fragment).pipe(take(1))
          : of(-1)])))
      .pipe(concatMap((res: [BookPosition, number]) => {
        return !fragment || fragment === res[0]?.bitId?.toString()
          ? of(res[0])
          : of({bitId: +fragment, index: res[1], distance: 0} as BookPosition);
      }))
      .pipe(concatMap((lastPos: BookPosition) => {
        lastBookPos = lastPos;
        const lastPosBitId = this.virtualEndBitIds.indexOf(lastPos?.bitId) !== -1
          ? (bitBook.toc.length ? bitBook.toc[bitBook.toc.length - 1].ref : null)
          : lastPos?.bitId;
        const params = Object.assign({}, queryType ? {type: queryType} : {}, queryParams);
        return additionalOptions?.readerMode === ReaderModes.ShopPublic
          ? this.bitBookApiService.getBookContentPublic(bitBook.externalId, pagination)
          : loadLeBits
            ? this.bitBookApiService.getLeBookContentPage(bitBook.externalId, pagination, params)
            : loadAllBits
              ? this.bitBookApiService.getBookContentPage(bitBook.externalId, pagination, params, false)
              : lastPos?.index && lastPos?.index >= 0
                ? this.bitBookApiService.getBookContentWithBitInMiddle(bitBook.externalId, lastPosBitId, lastPos.index, true, params, pagination)
                : this.bitBookApiService.getBookContentPage(bitBook.externalId, pagination, params, isShopReader);
      }))
      .pipe(concatMap((content: Array<BitApiWrapper>) => {
        if (!content?.length && ![BookType.Collection, BookType.LearningPath].includes(bitBook.type) && !bitBook.isNewRelease && !isShopReader) {
          this.showContentInvalidAlert('book', bitBook);
          return of({bitBookContent: [], lastPos: null, isAtTop: false, isAtBottom: false});
        }
        const lastPosIndex = lastBookPos?.index || -1;
        const isAtTop = !lastBookPos?.bitId || lastPosIndex < (pagination?.pageSize || this.pageSize) / 2 - 1 || lastBookPos?.bitId === 'start';
        const bitsAfterLastPos = content.filter((b: BitApiWrapper) => b.index > lastPosIndex);
        const isAtBottom = bitsAfterLastPos.length < (pagination?.pageSize || this.pageSize) / 2 - 1
          || lastPosIndex === -1 && bitsAfterLastPos.length < (pagination?.pageSize || this.pageSize);
        if (isAtTop && !additionalOptions?.ignoreStartBit) {
          content.unshift(this.getBitBookSummaryBit(bitBook));
        }
        if (isAtBottom) {
          let idx = bitBook.toc.findIndex((b: BookEntityToc) => b.ref == content[content.length - 1]?.id);
          if (bitBook?.hasMarkAsDone) {
            idx += 1;
            content.push(this.getBitBookMarkAsDoneBit(bitBook, content[content.length - 1], idx));
          }
          idx += 1;
          content.push(this.getBitBookEndingBit(bitBook, content[content.length - 1], idx));
        }
        this.readerLocalContentService.storeBookContent(bitBook.externalId, content)
          .subscribe();
        return of({bitBookContent: content, lastPos: lastBookPos, isAtTop, isAtBottom});
      }))
      .pipe(catchError((err: HttpErrorResponse) => {
        console.error(err);
        this.readerTrackingService.clearLastPositionForBook(bitBook.externalId)
          .subscribe();
        if (err.status === 404) {
          this.bitbookMqService.notifyReaderInvalidateBookCache(bitBook.externalId);
        }
        return throwError(() => err);
      }));
  }

  getBitElement(bitId: string): Element {
    return document.querySelector(`#bit-${bitId} .bit-background1`);
  }

  getBitWrapperElement(bitId: string): Element {
    return document.getElementById(`bit-${bitId}`);
  }

  scrollToBookPos(bookPos: BookPosition): Observable<boolean> {
    // console.log('scrolling to book position');
    if (!bookPos) {
      return of(false);
    }
    if ((window as any).annotationId) {
      bookPos.bitId = (window as any).annotationId;
      (window as any).annotationId = null;
    }

    const el: any = this.getBitElement(bookPos?.bitId);
    return new Observable(x => {
      if (el) {
        const newDistance = window.scrollY + el.getBoundingClientRect().top + 1;
        const scrollDistance = (bookPos?.distance || 0) - newDistance;

        const readerContentEl = document.querySelector('.reader-content');
        if (readerContentEl) {
          // console.log('setting scroll top');
          readerContentEl.scroll(0, readerContentEl.scrollTop + (readerContentEl as any).offsetTop - scrollDistance);
        }
        this.bitbookMqService.notifyReaderScrolledToBit(bookPos.bitId, bookPos.index);
        x.next(true);
        x.complete();
      } else {
        setTimeout(() => {
          this.scrollToBookPos(bookPos)
            .subscribe(() => {
              x.next(true);
              x.complete();
            });
        }, 100);
      }
    });
  }

  observeAndScrollToBookPos(bookPos: BookPosition): Observable<boolean> {
    if (!bookPos) {
      return of(false);
    }

    const scrollContainerElem: HTMLElement = document.querySelector('.infinite-scroll-container');
    if (scrollContainerElem) {
      scrollContainerElem.style.overflowY = 'hidden';
    }

    if ((window as any).annotationId) {
      bookPos.bitId = (window as any).annotationId;
      (window as any).annotationId = null;
    }

    return this.domObserverService.observeResize2('.reader-content .bits-wrapper')
      .pipe(concatMap(() => {
        if (scrollContainerElem) {
          scrollContainerElem.style.overflowY = 'scroll';
        }
        return this.scrollToBookPos(bookPos);
      }));
  }

  scrollToLastPosition(bitBookContent: Array<BitApiWrapper>, lastPos: BookPosition) {
    // console.log('scrolling to last position');
    const lastBit = bitBookContent.find((b: BitApiWrapper) => b.id == lastPos?.bitId);
    return lastBit
      ? this.observeAndScrollToBookPos(lastPos)
      : of(false);
  }

  private updateLastPosition(bitBook: BookEntity, bit: BitApiWrapper): Observable<any> {
    if (!bit) {
      return this.readerTrackingService.storeLastPositionForBook(bitBook.externalId, {
        bitId: null,
        index: -1,
        distance: 0,
      });
    }
    const el = this.getBitElement(bit.id);
    const readerContentEl = document.querySelector('.reader-content');
    if (readerContentEl && el) {
      const distance = window.scrollY + el.getBoundingClientRect().top;
      return this.readerTrackingService.storeLastPositionForBook(bitBook.externalId, {
        bitId: bit.id,
        index: bit.index,
        distance,
      });
    }
    return of(null);
  }

  listenToScroll(bitBook: BookEntity, onScroll: () => void, bitBookContentFn: () => Array<BitApiWrapper>) {
    const scrollContainer = document.querySelector('.infinite-scroll-container');
    const renderer = this.rendererFactory.createRenderer(null, null);

    return renderer.listen(scrollContainer, 'scroll', throttle(() => setTimeout(() => {
      if (this.isTocSelectingBit) {
        return;
      }

      requestAnimationFrame(() => {
        this.handleScrollInProgress(bitBook, bitBookContentFn);
        onScroll();
      });
    }), 500));
  }

  listenToScrollEnd(bitBook: BookEntity, onScrollEnd: () => void, bitBookContentFn: () => Array<BitApiWrapper>) {
    const scrollContainer = document.querySelector('.infinite-scroll-container');
    const renderer = this.rendererFactory.createRenderer(null, null);
    return renderer.listen(scrollContainer, 'scrollend', throttle(() => setTimeout(() => {
      requestAnimationFrame(() => {
        this.handleScrollFinished(bitBook, bitBookContentFn);
        onScrollEnd();
      });
    }), 300));
  }

  isBitInViewport(bitId: string, offsetPx = 0): boolean {
    const el = this.getBitElement(bitId);
    if (!el) {
      return false;
    }
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    return rect.top - offsetPx <= windowHeight && rect.bottom + offsetPx > 0;
  }

  isBitInTopViewport(bitId: string, offsetPx = 0): boolean {
    const el = this.getBitElement(bitId);
    if (!el) {
      return false;
    }
    const rect = el.getBoundingClientRect();
    return -offsetPx < rect.top && rect.top < offsetPx;
  }

  private isBitContentInViewport(bitId: string, windowHeight: number): boolean {
    const el = document.querySelector(`#bit-${bitId} .bit-content-end`);
    if (!el) {
      return false;
    }
    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= windowHeight;
  }

  isBitTouchingViewport(bitId: string, windowHeight: number, offsetPx = 0): boolean {
    const el = this.getBitElement(bitId);
    if (!el) {
      return false;
    }
    const rect = el.getBoundingClientRect();
    return rect.top - offsetPx <= windowHeight && rect.bottom + offsetPx > 0;
  }

  private inflateBitsSelection(allBits: Array<BitApiWrapper>, selectedBits: Array<BitApiWrapper>, inflateBy: number): Array<BitApiWrapper> {
    const bitStartIdx = allBits.findIndex((b: BitApiWrapper) => b.id === selectedBits[0].id);
    const bitEndIdx = allBits.findIndex((b: BitApiWrapper) => b.id === selectedBits[selectedBits.length - 1].id);
    const fromIdx = Math.max(0, bitStartIdx - inflateBy);
    const toIdx = Math.min(allBits.length - 1, bitEndIdx + inflateBy);

    return allBits.slice(fromIdx, toIdx);
  }

  private computeBitVisibility(bitId: string, windowHeight: number, offsetPx = 0): {
    isTouchingViewport: boolean,
    isContentInViewport: boolean
  } {
    const wrapperEl = this.getBitWrapperElement(bitId);
    if (!wrapperEl) {
      return {isTouchingViewport: false, isContentInViewport: false};
    }
    const bitEl = wrapperEl.getElementsByClassName('bit-background1')[0];
    const contentEl = wrapperEl.getElementsByClassName('bit-content-end')[0];

    if (!bitEl) {
      return {
        isTouchingViewport: false,
        isContentInViewport: false
      };
    }
    const bitRect = bitEl.getBoundingClientRect();
    const contentREct = contentEl.getBoundingClientRect();

    return {
      isTouchingViewport: bitRect.top - offsetPx <= windowHeight && bitRect.bottom + offsetPx > 0,
      isContentInViewport: (contentREct.top >= 0 && contentREct.bottom <= windowHeight)
        || (bitRect.top < 0 && bitRect.bottom > 0)
        || (bitRect.top > 0 && bitRect.bottom < 0)
    };
  }

  computeBitsVisibility(bitBookContentFn: () => Array<BitApiWrapper>): BitsViewPortVisibility {
    // console.log('computing visibleBits');
    // const startTime = performance.now();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const allBits: Array<BitApiWrapper> = bitBookContentFn() || [];

    // const bitsWithContentInViewPort = allBits.filter((b: BitApiWrapper) => this.isBitContentInViewport(b.id, windowHeight));
    // const bitsTouchingViewPort = allBits.filter((b: BitApiWrapper) => this.isBitTouchingViewport(b.id, windowHeight, 1000));
    // const bitsTouchingViewPortInflated = this.inflateBitsSelection(allBits, bitsTouchingViewPort, 5);
    const allBitsVisibility = allBits.map((b: BitApiWrapper) => Object.assign({}, b, this.computeBitVisibility(b.id, windowHeight, 100)));
    // const editors = document.querySelectorAll('.ProseMirror');

    const allBitAnnotationsVisibility: Array<(BitApiAnnotation & {
      isTouchingViewport: boolean,
      isContentInViewport: boolean
    })> = [];
    allBits.forEach(b => {
      if (b.annotations?.length) {
        allBitAnnotationsVisibility.push(...b.annotations.map(ba => Object.assign({}, ba, this.computeBitVisibility(ba.id.toString(), windowHeight, 100))));
      }
    });
    const bitsWithContentInViewPort = allBitsVisibility.filter(x => x.isContentInViewport);
    const bitsTouchingViewPort = allBitsVisibility.filter(x => x.isTouchingViewport);
    const bitAnnotationsTouchingViewPort = allBitAnnotationsVisibility.filter(x => x.isTouchingViewport);

    // const computeTime = performance.now() - startTime;
    // if ((window as any).lastComputeTime < computeTime) {
    //   (window as any).lastComputeTime = computeTime;
    //   console.log('compute time', computeTime);
    // }

    return {
      bitsWithContentInViewPort,
      bitsTouchingViewPort,
      bitAnnotationsTouchingViewPort,
      stats: {
        computationTimeMs: 0, // performance.now() - startTime,
        activeEditorsCount: 0, // editors.length
      },
    };
    // console.log('end computing visibleBits', visibleBits);
  }

  private handleScrollInProgress(bitBook: BookEntity, bitBookContentFn: () => Array<BitApiWrapper>) {
    const visibleBits = this.computeBitsVisibility(bitBookContentFn);
    this.updateLocationUrl(bitBook, visibleBits);
    this.bitbookMqService.notifyBitsScrollVisibility(visibleBits);
  }

  private handleScrollFinished(bitBook: BookEntity, bitBookContentFn: () => Array<BitApiWrapper>) {
    const visibleBits = this.computeBitsVisibility(bitBookContentFn);
    this.updateLocationUrl(bitBook, visibleBits);
    this.bitbookMqService.notifyBitsVisibility(visibleBits);

    // return this.readerTrackingService.getLastPositionForBook(bitBook.externalId)
    //   .pipe(concatMap((lastPos: BookPosition) => {
    //     if (!lastPos?.bitId) {
    //       return of(null);
    //     }
    //     this.bitbookMqService.notifyReaderContentBitsScrollEnd(lastPos?.index);
    //     const lastBit = bitBookContentFn().find((b: BitApiWrapper) => b.id == lastPos.bitId);
    //     if (!lastBit) {
    //       console.log('lastPos bit not founded in bitBookContent', lastPos);
    //     }
    //     return this.updateLastPosition(bitBook, lastBit);
    //   }));
  }

  private updateLocationUrl(bitBook: BookEntity, visibleBits: BitsViewPortVisibility) {
    const firstVisibleBit = visibleBits.bitsWithContentInViewPort[0] || visibleBits.bitsTouchingViewPort[0];
    if (!firstVisibleBit) {
      return;
    }
    if (document.location.hash !== `#${firstVisibleBit.id}`) {
      // console.log('location change', firstVisibleBit);
      this.location.replaceState(
        this.router.createUrlTree(
          [],
          {fragment: `${firstVisibleBit.id}`, queryParamsHandling: 'merge'})
          .toString(),
      );
    }
    this.updateLastPosition(bitBook, firstVisibleBit)
      .subscribe();
  }

  handleScrollUp(bitBook: BookEntity, bitBookContent: Array<BitApiWrapper>, queryType?: string, queryParams?: any): Observable<{
    newContent: Array<BitApiWrapper>,
    isAtTop: boolean
  }> {
    return new Observable<{ newContent: Array<BitApiWrapper>, isAtTop: boolean }>(x => {
      const firstBit = bitBookContent.find(b => b.id !== 'start');
      if (!firstBit) {
        x.next({newContent: [], isAtTop: false});
        x.complete();
        return;
      }
      if (queryType) {
        queryParams.type = queryType;
      }
      this.bitBookApiService.getBookContentEndingWithBit(bitBook.externalId, firstBit.id, firstBit.index, true, queryParams)
        .subscribe((content: Array<BitApiWrapper>) => {
          let newContent = bitBookContent;
          let isAtTop = true;
          if (content?.length > 1) {
            content.pop();
            isAtTop = content.length < this.pageSize - 1;
            newContent = content.concat(bitBookContent);
            setTimeout(() => this.readerLocalContentService.storeBookContent(bitBook.externalId, content)
              .subscribe());
          }
          if (isAtTop && !newContent.find(c => c.id === 'start')) {
            newContent.unshift(this.getBitBookSummaryBit(bitBook));
          }
          x.next({newContent, isAtTop});
          x.complete();
        }, (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status === 404) {
            this.bitbookMqService.notifyReaderInvalidateBookCache(bitBook.externalId);
          }
          x.next({newContent: [], isAtTop: false});
          x.complete();
        });
    });
  }

  handleScrollDown(bitBook: BookEntity, bitBookContent: Array<BitApiWrapper>, queryType?: string, queryParams?: any): Observable<{
    newContent: Array<BitApiWrapper>,
    isAtBottom: boolean
  }> {
    return new Observable<{ newContent: Array<BitApiWrapper>, isAtBottom: boolean }>(x => {
      let lastBit = bitBookContent.slice().reverse().find(b => this.virtualEndBitIds.indexOf(b.id) === -1);
      if (!lastBit) {
        x.next({newContent: [], isAtBottom: false});
        x.complete();
        return;
      }
      if (queryType) {
        queryParams.type = queryType;
      }
      this.bitBookApiService.getBookContentStartingWithBit(bitBook.externalId, lastBit.id, lastBit.index, true, queryParams, {
        pageSize: this.pageSize,
        startBitId: null,
        pageNumber: null
      })
        .subscribe((content: Array<BitApiWrapper>) => {
          let newContent = bitBookContent;
          let isAtBottom = true;
          if (content?.length > 1) {
            content.shift();
            isAtBottom = false;
            newContent = bitBookContent.concat(content);
            setTimeout(() => this.readerLocalContentService.storeBookContent(bitBook.externalId, content)
              .subscribe());
          }
          if (isAtBottom) {
            lastBit = bitBookContent[bitBookContent.length - 1];
            let idx = bitBook.toc.findIndex((b: BookEntityToc) => b.ref == lastBit?.id);
            if (bitBook?.hasMarkAsDone) {
              idx += 1;
              newContent.push(this.getBitBookMarkAsDoneBit(bitBook, lastBit, idx));
            }
            idx += 1;
            if (lastBit.id !== 'end') {
              newContent.push(this.getBitBookEndingBit(bitBook, lastBit, idx));
            }
          }
          x.next({newContent, isAtBottom});
          x.complete();
        }, (err: HttpErrorResponse) => {
          console.error(err);
          if (err.status === 404) {
            this.bitbookMqService.notifyReaderInvalidateBookCache(bitBook.externalId);
          }
          x.next({newContent: [], isAtBottom: false});
          x.complete();
        });
    });
  }

  handleOpenResource(bitResource: BitResource) {
    if (!bitResource) {
      return;
    }
    const imageUrl = bitResource.image?.src || bitResource.image?.src1x || bitResource.image?.src2x || bitResource.image?.src3x || bitResource.imageLink?.url || bitResource.imageOnline?.url;
    if (!imageUrl) {
      return;
    }
    const width = bitResource.image?.widthNative || bitResource?.private?.uploadDetails?.width || bitResource?.private?.uploadDetails?.displayWidth;
    const height = bitResource.image?.heightNative || bitResource?.private?.uploadDetails?.height || bitResource?.private?.uploadDetails?.displayHeight;

    const modalRef = this.ngbModal.open(ImageViewerComponent, {
      windowClass: 'full-screen-modal light-background-modal',
      backdrop: 'static',
      keyboard: true,
      animation: false,
    });
    const imageViewerComponentInstance = modalRef.componentInstance as ImageViewerComponent;
    imageViewerComponentInstance.imageUrl = imageUrl;
    imageViewerComponentInstance.width = width;
    imageViewerComponentInstance.height = height;
    this.modalTouchSwipeService.applyTouchSwipe(modalRef);
  }

  resetAnswer(bitId: string, bitBookContent: Array<BitApiWrapper>): Observable<any> {
    return this.bitBookApiService.resetAnswer(bitId)
      .pipe(tap((res: BitApiWrapper) => {
        const idx = bitBookContent.findIndex(x => x.id == bitId);
        const bitWrapper = bitBookContent[idx];
        if (bitWrapper) {
          this.analyticsService.record('bit-answer-reset', {
            bitId,
            bitInstanceId: bitWrapper.bitInstanceId,
            bookId: bitWrapper?.meta?.thisBook?.id || bitWrapper?.meta?.originBook?.id,
            bookExternalId: bitWrapper?.meta?.thisBook?.externalId || bitWrapper?.meta?.originBook?.externalId,
            bitType: bitWrapper.bit?.type,
            language: bitWrapper.bit?.lang || bitWrapper.meta?.language,
            learningLanguage: bitWrapper.meta?.learningLanguage,
            chapterPath: bitWrapper.chapterPath,
            tag: bitWrapper.tags,
            analyticsTag: bitWrapper.analyticsTag,
            reductionTag: bitWrapper.reductionTag
          });
        }
        if (idx !== -1) {
          bitBookContent[idx] = res;
          bitBookContent[idx].bitInstanceId = null;
        }
      }))
      .pipe(catchError((err: HttpErrorResponse) => {
        console.error(err);
        alert('Could not reset answer');
        return of(null);
      }));
  }

  debounced(delay, fn): () => void {
    let timerId;
    return (...args) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  }

  handleAnnotationMenuPopupPosition(menuTemplateElem: any, menuPopupElem: any) {
    const popupDistance = 195;

    if (this.isElementInViewport(menuTemplateElem, popupDistance)) {
      menuPopupElem.style.top = `-${popupDistance}px`;
      menuPopupElem.style.bottom = null;
    } else {
      menuPopupElem.style.top = null;
      menuPopupElem.style.bottom = `-${popupDistance / 2}px`;
    }
  }

  isElementInViewport(el, absoluteTopDistance) {
    const rect = el.getBoundingClientRect();
    return rect.top >= absoluteTopDistance && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
  }

  computeAndNotifyBitsScrollVisibility(bitBookContentFn: () => Array<BitApiWrapper>, timeout: number = 0) {
    setTimeout(() => {
      const visibleBits = this.computeBitsVisibility(bitBookContentFn);
      this.bitbookMqService.notifyBitsScrollVisibility(visibleBits);
    }, timeout);
  }

  computeAndNotifyBitsVisibility(bitBookContentFn: () => Array<BitApiWrapper>, timeout: number = 0) {
    setTimeout(() => {
      const visibleBits = this.computeBitsVisibility(bitBookContentFn);
      this.bitbookMqService.notifyBitsVisibility(visibleBits);
    }, timeout);
  }

  getBookBit(bookExternalId: string, reference: string): Observable<BookEntityToc> {
    return this.readerTocService.getBitByReferenceAnchor(bookExternalId, reference)
      .pipe(concatMap((bit: BookEntityToc) => {
        return bit
          ? of(bit)
          : this.bitBookApiService.getBookById(bookExternalId)
            .pipe(map((book: BookEntity) => book.toc.find((item: BookEntityToc) => item.anchor === reference)));
      }));
  }
}
