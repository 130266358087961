<div>
  <div class="module-header">
    <bitmark-module-fields [bit]='moduleBit'
                           [isBeingEditedByUser]='isBeingEditedByUser'
                           (onIsBeingEdited)="onIsBeingEdited.emit($event)"
                           [canBeEdited]='canBeEdited'>
    </bitmark-module-fields>
  </div>
  @if ((moduleBit.resource && !moduleBit?.resource?.article) || isBeingEditedByUser) {
    <div class="position-relative image-container">
      <bitmark-resource [bitResource]="moduleBit.resource"
                        [hostBit]="moduleBit"
                        [isBeingEditedByUser]="isBeingEditedByUser"
                        [ignoreActionsTypes]="['documents']"
                        (open)="openResource.emit($event)"
                        (changed)="updateBitResource($event)"
                        class="d-block resource-image"
                        [ngClass]="[(!(moduleBit.item || moduleBit.lead || moduleBit.instruction) && !isBeingEditedByUser && !isResourceType && moduleBit.resource?.type === BitResourceType.Document) ? 'pt-4' : '',
                    'scrollbar-' + moduleBitSubtype]">
      </bitmark-resource>
    </div>
  }
  <div [style.display]="(!isBodyEmpty || isBitEmpty) && !isBeingEditedByUser && !canBeEdited ? '' : 'none'"
       [ngClass]="moduleBitSubtype"
       [class.mt-4]="hasBitResource || isBeingEditedByUser"
       [class.mb-4]="isBeingEditedByUser"
       [class.text-placeholder]="isBodyEmpty"
       class="bit-body bit-body-allow-highlight">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="isBodyEmpty ? null : moduleBit?.body"
                  [format]="moduleBit.format">
    </bitmark-text>
  </div>
  @if (isBeingEditedByUser || (canBeEdited && (!isBodyEmpty || isBitEmpty))) {
    <bitmark-reader-text-editor #editorComponent
                                class="d-block bit-body"
                                [ngClass]="moduleBitSubtype"
                                [class.mt-4]="hasBitResource || isBeingEditedByUser"
                                [class.mb-4]="isBeingEditedByUser"
                                [bit]="moduleBit"
                                [bitContent]="moduleBit.body | bitmark:bodyFormat:'json'"
                                [updateContentEvent]="updateContentEventSubject"
                                [isReadOnly]="!canBeEdited"
                                [placeholder]="'Bits.PlaceholderBody' | translate"
                                (updateBit)="onUpdateContent(moduleBit, $event)"
                                (click)="enterEditMode()"
                                (created)="editorCreated.emit(editorComponent)">
    </bitmark-reader-text-editor>
  }
  @if (moduleBit?.resource?.article && !isBeingEditedByUser) {
    <div class="position-relative image-container">
      <bitmark-resource-article [bitResourceArticle]="moduleBit?.resource?.article"
                                class="d-block mt-1 resource-image">
      </bitmark-resource-article>
    </div>
  }
  @if (!isRegularUserInAcademy) {
    @if (!isCompleted) {
      <div class="position-relative">
        <div class="d-flex justify-content-center align-items-center flex-wrap ml-auto mr-auto book-progress-container" [@fadeOut]="!isDescriptionOpen ? 'visible' : 'invisible'">
          <div class="d-flex justify-content-start align-items-center flex-wrap ml-auto mr-auto book-progress-container">
            @for (p of productListProgress; track p.externalId) {
              <div class="book-progress-checkbox d-inline">
                <input type="checkbox"
                       disabled
                       [checked]="p?.progress === 100"/>
                <label>
                  <bitmark-text class="d-inline-block m-0 p-0" style="width: 0">
                  </bitmark-text>
                </label>
              </div>
            }
          </div>
        </div>
        @if(products?.length){
          <bitmark-icon (click)='openBook()' style="color: #000"
                        [name]="isDescriptionOpen ? 'chevron-up' : 'chevron-down'"
                        class="flex-shrink-0 cursor-pointer text-black-50 view-resources-toggle"></bitmark-icon>
        }
      </div>
      <div [@openClose]="isDescriptionOpen ? 'open' : 'closed'"
           class='d-flex flex-row flex-wrap page-product-container'>
        @for (product of products; track product) {
          @if (product && isDescriptionOpen) {
            <div class='page-product d-flex flex-column justify-content-start align-items-center cursor-pointer'
                 [ngClass]="{'entry-wide': isWideEntry}">
              <div class='book-cover d-flex align-items-end ml-auto mr-auto position-relative'>
                <bitmark-book-cover (click)='openProductPage(product)'
                                    (onIsWideImage)='setWideImage()'
                                    class='w-100 h-100'
                                    [coverImage]="product?.media?.length && product?.media[0].file.url"
                                    [isFullyBranded]='true'
                                    [isWideCoverAllowed]='true'
                                    [title]='title'>
                </bitmark-book-cover>
              </div>
              <div class='mt-2 book-texts d-flex flex-column justify-content-start align-items-center px-lg-1 px-0'
                   (click)='openProductPage(product)'>
                <div class="book-progress-checkbox d-inline ml-1">
                  <input type="checkbox"
                         disabled
                         [checked]="product?.progress?.progress === 100"/>
                  <label>
                    <bitmark-text class="d-inline-block">
                    </bitmark-text>
                  </label>
                </div>
                @if (product?.publisher) {
                  <div class='book-publisher mb-0 text-center'
                       [innerText]='product.publisher'>
                  </div>
                }
                @if (product?.title) {
                  <div class='book-title text-center line-clamp'
                       [innerText]='product.title'>
                  </div>
                }
                @if (product?.meta) {
                  <div>
                    @if (BookType.Paperbook === product.meta.type) {
                      <div class='text-center book-subtitle'>Paper</div>
                    } @else if (BookType.PDFBook === product.meta.type) {
                      <div class='text-center book-subtitle'>PDF</div>
                    }
                  </div>
                }
              </div>
            </div>
          }
        }
      </div>
    } @else {
      <h3 style="font-size:50px; text-align: center">🎉</h3>
    }
  }
</div>
