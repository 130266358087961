<div class="reader-text-editor" #readerTextEditor [ngClass]="{'focused-editor': isSpellcheckOn}">
  <div class="pos-relative text-editor-actions">
    @if (!disableKeyboardInput) {
      <bitmark-contextual-menu-list (onDeleteNode)="deleteNode($event)"
                                    [editor]="editor">
      </bitmark-contextual-menu-list>
      <bitmark-contextual-menu-image (onDeleteNode)="deleteNode($event)"
                                     (onReplaceImage)='fileReplaceInput.click()'
                                     [editor]="editor">
      </bitmark-contextual-menu-image>
      <bitmark-contextual-menu-code (onDeleteNode)="deleteNode($event)"
                                    [editor]="editor">
      </bitmark-contextual-menu-code>
      <bitmark-contextual-menu-header (onDeleteNode)="deleteNode($event)"
                                      (onClearHeader)="clearHeader()"
                                      [editor]="editor">
      </bitmark-contextual-menu-header>
    }
    <div class="reader-shadow contextual-menu-general" id="contextual-menu-general">
      <div class="tiptap-text-bubble-menu-top d-flex flex-row justify-content-between align-items-center">
        <button class="tiptap-bubble-item" (click)="editor.chain().focus().toggleBold().run()"
                [ngClass]="{ 'is-active-bubble': editor.isActive('bold')}">
          <strong>B</strong>
        </button>
        <button class="tiptap-bubble-item" (click)="editor.chain().focus().toggleItalic().run()"
                [ngClass]="{ 'is-active-bubble': editor.isActive('italic')}">
          <em>I</em>
        </button>
        <button class="tiptap-bubble-item" (click)="editor.chain().focus().toggleMark('light').run()"
                [ngClass]="{ 'is-active-bubble': editor.isActive('light')}">
          <span style="color: gray">L</span>
        </button>
        <button class="tiptap-bubble-item" (click)="editor.chain().focus().toggleStrike().run()"
                [ngClass]="{ 'is-active-bubble': editor.isActive('strike')}">
          <span style="text-decoration: line-through">S</span>
        </button>
        <button style="background-color: #ffff58" class="tiptap-bubble-item"
                (click)="editor.chain().focus().toggleMark('highlight').run()"
                [ngClass]="{ 'is-active-bubble': editor.isActive('mark')}">
          <span>H</span>
        </button>
        <ng-template #dropdownButtonAdvanced>
          <button dropdownButton class="tiptap-bubble-item">
            ...
          </button>
        </ng-template>
        <bitmark-dropdown class="d-inline-block ml-auto"
                          [placement]="['top-left', 'top', 'bottom-left', 'bottom']"
                          [dropdownClass]="'reader-text-editor'"
                          [additionalContent]="tiptapAdvancedBubbleMenu"
                          [dropdownButton]="dropdownButtonAdvanced">
        </bitmark-dropdown>
        <button class="tiptap-bubble-item" (click)="editor.chain().focus().clearNodes().unsetAllMarks().run()">
          <img class="tiptap-bubble-menu-icon" src="assets/images/icon-erase.svg"/>
        </button>
      </div>
    </div>
    <input accept="image/*" hidden type="file" [attr.multiple]="null" #fileInput (change)="uploadImage()">
    <input accept="image/*" hidden type="file" [attr.multiple]="null" #fileReplaceInput (change)="replaceImage()">
  </div>
  <tiptap-editor [editor]="editor" [attr.spellcheck]="isSpellcheckOn"></tiptap-editor>
  <div class="tiptap-bubble-menu-list-empty"></div>
</div>
<ng-template #tiptapAdvancedBubbleMenu>
  <div class="reader-text-editor reader-advanced-bubble-menu">
    <div class="p-1" style="min-width: 250px;">
      <div class="d-flex justify-content-between align-items-center flex-row px-1">
        <div>
          <button class="menu-bubble-item" (click)="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('heading', {level: 1})}">
            <div style="font-weight:700">Title</div>
          </button>
        </div>
        <div>
          <button class="menu-bubble-item" (click)="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('heading', {level: 2})}">
            <div style="font-weight:500">Subtitle</div>
          </button>
        </div>
        <div>
          <button class="menu-bubble-item" (click)="toggleHeader(0)"
                  [ngClass]="{ 'is-active-bubble': !editor.isActive('heading') && !editor.isActive('listItem') && !editor.isActive('codeBlock') && !editor.isActive('blockquote')}">
            <div>Text</div>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center flex-row py-2 px-1">
        <div>
          <button class="menu-bubble-item" (click)="editor.commands.toggleBulletList()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('bulletList') || editor.isActive('orderedList') || editor.isActive('unorderedList') || editor.isActive('noBulletList')}">
            <div>• List</div>
          </button>
        </div>
        <div>
          <button style="background-color: black; color: greenyellow;" class="menu-bubble-item"
                  (click)="editor.commands.toggleCodeBlock()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('codeBlock')}">
            <div>Code</div>
          </button>
        </div>
        <div>
          <button class="menu-bubble-item font-weight-medium" (click)="insertMath()">
            Math ⨍
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center flex-row pb-1 px-1">
        <div>
          <button class="menu-bubble-item" (click)="addLink()"
                  [ngClass]="editor.isActive('link') ? 'is-active-bubble' : ''">
            <span class="text-decoration-link">Link</span>
          </button>
        </div>
        <div>
          <button class="menu-bubble-item text-center px-1" (click)="fileInput.click()"
                  [ngClass]="editor.isActive('image') ? 'is-active-bubble' : ''">
            <img style="height: 22px;" src="assets/images/icon-image.png"/>
          </button>
        </div>
        <div style='width: 70px'>
        </div>
      </div>
    </div>
    <div class="dropdown-separator" style="margin-bottom: 3px;"></div>
    <div class="px-2 py-1">
      <div class="text-left d-flex justify-content-start align-items-center flex-row">
        <button class="ml-0 mr-1 tiptap-bubble-item tiptap-small-bubble-item"
                (click)="editor.commands.toggleSubscript()"
                [ngClass]="{ 'is-active-bubble': editor.isActive('subscript')}">
          <div>S<sub>s</sub></div>
        </button>
        <button class="ml-0 tiptap-bubble-item tiptap-small-bubble-item"
                (click)="editor.commands.toggleSuperscript()"
                [ngClass]="{ 'is-active-bubble': editor.isActive('superscript')}">
          <div>S<sup>s</sup></div>
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #tiptapAdvancedFloatingMenu>
  <div class="reader-text-editor">
    <div class="p-1" style="min-width: 250px;">
      <div class="d-flex justify-content-between align-items-center flex-row px-1">
        <div>
          <button class="menu-bubble-item" (click)="toggleHeader(1)"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('heading', {level: 1})}">
            <div style="font-weight:700">Title</div>
          </button>
        </div>
        <div>
          <button class="menu-bubble-item" (click)="toggleHeader(2)"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('heading', {level: 2})}">
            <div style="font-weight:500">Subtitle</div>
          </button>
        </div>
        <div>
          <button class="menu-bubble-item" (click)="toggleHeader(0)"
                  [ngClass]="{ 'is-active-bubble': !editor.isActive('heading') && !editor.isActive('listItem') && !editor.isActive('codeBlock') && !editor.isActive('blockquote')}">
            <div>Text</div>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center flex-row py-2 px-1">
        <div>
          <button class="menu-bubble-item" (click)="editor.commands.toggleBulletList()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('bulletList') || editor.isActive('orderedList') || editor.isActive('unorderedList') || editor.isActive('noBulletList')}">
            <div>• List</div>
          </button>
        </div>
        <div>
          <button style="background-color: black; color: greenyellow;" class="menu-bubble-item"
                  (click)="editor.commands.toggleCodeBlock()"
                  [ngClass]="{ 'is-active-bubble': editor.isActive('codeBlock')}">
            <div>Code</div>
          </button>
        </div>
        <div>
          <button class="menu-bubble-item text-center px-1" (click)="fileInput.click()"
                  [ngClass]="editor.isActive('image') ? 'is-active-bubble' : ''">
            <img style="height: 22px;" src="assets/images/icon-image.png"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div class="selected-context-menu tiptap-floating-menu">
  <!--  <button class="btn btn-editor-action" (click)="insertMath()">-->
  <!--    math-->
  <!--  </button>-->
  <!--  <button class="btn btn-editor-action" (click)="uploadImage()">-->
  <!--    image-->
  <!--  </button>-->
  <ng-template #dropdownButtonAdvancedFloating class="">
    <button dropdownButton class="tiptap-bubble-item">
      ...
    </button>
  </ng-template>
  <bitmark-dropdown class="d-inline-block ml-auto"
                    [placement]="['top-left', 'top', 'bottom-left', 'bottom']"
                    [dropdownClass]="'reader-text-editor selected-context-menu'"
                    [additionalContent]="tiptapAdvancedFloatingMenu"
                    [dropdownButton]="dropdownButtonAdvancedFloating">
  </bitmark-dropdown>
</div>
