import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output, Renderer2} from '@angular/core';
import {RouterExtService, SubSink} from 'bitmark';
import {filter} from "rxjs/operators";
import {NavigationStart, Router} from "@angular/router";

@Directive({
  selector: '[appScrollMemo]',
})
export class ScrollMemoDirective implements OnDestroy, AfterViewInit {
  private subSink = new SubSink();
  private resizeObserver: ResizeObserver;
  @Output() scrollMemoStart = new EventEmitter();
  @Output() scrollMemoEnd = new EventEmitter();

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              private router: Router,
              private routerExtService: RouterExtService) {
    if (!window['gmb_scrollMemo']) {
      window['gmb_scrollMemo'] = {};
    }
  }

  ngAfterViewInit() {
    this.subSink.sink = this.router.events
      .pipe(filter(ev => ev instanceof NavigationStart))
      .subscribe(() => {
        // console.log('write', this.elementRef.nativeElement.scrollTop);
        window['gmb_scrollMemo'][this.elementRef.nativeElement.ownerDocument.URL] = this.elementRef.nativeElement.scrollTop;
      });

    if (!this.routerExtService.wasNavigationBack()) {
      delete window['gmb_scrollMemo'][this.elementRef.nativeElement.ownerDocument.URL];
      return;
    }

    this.scrollMemoStart.emit();
    this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', '0');
    let timer = null;
    this.resizeObserver = new ResizeObserver((e) => {
      if (timer) {
        clearTimeout(timer);
      }
      this.elementRef.nativeElement.scrollTop = window['gmb_scrollMemo'][this.elementRef.nativeElement.ownerDocument.URL] || 0;
      timer = setTimeout(() => {
        this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', '1');
        this.scrollMemoEnd.emit();
      }, 500);
    });
    this.resizeObserver.observe(this.elementRef.nativeElement.firstElementChild, {
      box: 'content-box'
    });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
