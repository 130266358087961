<div class="row no-gutters mb-2">
  <div class="p-0 w-100">
    <div class="d-inline-flex align-items-center align-content-between bit-dropdown bit-dropdown-with-icon"
         [ngClass]="answerCssClass">
      @if (pair.isExample) {
        <div class="bit-body-text">
          <bitmark-text [text]="pair.example || ''"
                        [format]="format">
          </bitmark-text>
        </div>
      } @else {
        <bitmark-dropdown id="topDropdown"
                          class="flex-grow-1 w-100"
                          container="body"
                          [placement]="['bottom', 'top']"
                          [actions]="selectPlaceholderBitActions"
                          [dropdownButton]="dropdownButton">
        </bitmark-dropdown>
      }
    </div>
  </div>
</div>

<ng-template #dropdownButton>
  <div class="d-flex align-items-center align-content-between cursor-pointer"
       (click)="openDropdown($event)">
    <div class="answer flex-grow-1"
         [title]="selectedAnswer || pair.answer?.text"
         [innerHTML]="selectedAnswer ||  pair.answer?.text || '&nbsp;'">
    </div>
    <bitmark-icon name="chevron-down" class="flex-shrink-0"></bitmark-icon>
  </div>
</ng-template>
