@if (bit?.body) {
  <div class="bit-body mb-2 bit-body-allow-highlight"
       [ngClass]="bit.type">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
@if (wordBankItems?.length) {
  <ul class="solution-instruction-grouped p-2 mb-2">
    @for (item of wordBankItems; track item.text) {
      <li>
        <div class="d-inline cursor-pointer"
             (click)="toggleWordBankSolution(item)"
             [class.used-solution]="item.used">
          <bitmark-text [text]="item.text"
                        [format]="bit.format"
                        [cssClass]="'d-inline-elements'">
          </bitmark-text>
        </div>
      </li>
    }
  </ul>
}
@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    (open)="openResource.emit($event)"
                    [showArticle]="true"
                    class="d-block pb-4 mt-2 mb-2">
  </bitmark-resource>
}
@for (question of bit?.questions; track question; let index = $index) {
  <div class="mb-5">
    <div class="w-100 bit-body-text text-pre-wrap sub-item-positioning">
      @if (question.item) {
        <bitmark-text [text]="question.item"
                      [format]="bit.format"
                      class="sub-item"
                      cssClass="sub-item-interview text-nowrap">
        </bitmark-text>
      }
      <bitmark-text [text]="question?.question"
                    [format]="bit.format">
      </bitmark-text>
    </div>
    @if (question.isExample) {
      <div class="w-100 mt-1 input short correct"
           [class.rows-height]="questionRowsCount[index] && questionRowsCount[index] > 0"
           [class.short]="question?.isShortAnswer">
        <bitmark-text [text]="question.example" [format]="bit.format"></bitmark-text>
      </div>
    } @else {
      <textarea class="w-100 mt-1 input short"
                [class.rows-height]="questionRowsCount[index] && questionRowsCount[index] > 0"
                [class.disabled]="!allowAnswerSubmit"
                [attr.rows]="(questionRowsCount[index] && questionRowsCount[index] > 0) ? questionRowsCount[index] : null"
                [placeholder]="question?.instruction | bitmark:bit.format:'text'"
                [readOnly]="readOnly || !allowAnswerSubmit"
                [value]="question?.answer?.text"
                [class.short]="question?.isShortAnswer"
                (input)="change(question, $event)">
      </textarea>
    }
  </div>
}

@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit?.footer"
                [format]="bit.format">
  </bitmark-text>
}
