import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Portal} from '@angular/cdk/portal';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {BaseBit, BitApiWrapper, BitResource, BitResourceSize, BitType} from '../../bits/bits.models';
import {DropdownItemModel, SubSink} from '../../shared';
import {ChapterBit} from '../../bits/chapter/chapter.models';
import {BrandingRenderService} from '../branding-render.service';
import {TocItem} from '../reader-book/reader-toc-sidebar/reader-toc-sidebar.component';
import {ReaderContext} from '../reader.models';
import {BitPortalService} from '../../shared/portals/bit-portal.service';
import {BitbookMqService} from '../bitbook-mq.service';
import {ReaderTextEditorComponent} from '../reader-editor/reader-text-editor/reader-text-editor.component';
import {
  ReaderSimpleTextEditorComponent
} from '../reader-editor/reader-simple-text-editor/reader-simple-text-editor.component';
import {BitmarkFormat, BookType, ProductFamily} from '../../shared/models/bitmark.models';
import {ArticleBit} from '../../bits/article/article.models';
import {BitComponent} from '../../bits';

@Component({
  selector: 'bitmark-bit-wrapper',
  templateUrl: './bit-wrapper.component.html',
  styleUrls: ['./bit-wrapper.component.scss'],
})
export class BitWrapperComponent implements OnInit, OnDestroy {
  @Input() bitWrapper: BitApiWrapper;
  @Input() bookId?: string;
  @Input() actions: Array<DropdownItemModel> = [];
  @Input() fontScale?: 80 | 100 | 110 | 120 | 140 = null;
  @Input() context: ReaderContext;
  @Input() queryParams: any;
  @Input() isBeingEditedByUser: boolean;
  @Input() hideBitActions: boolean;
  @Input() saveUserEdit: boolean;
  @Input() allowAnswerSubmit = true;
  @Input() options?: any;
  @Input() fullScreen = false;
  @Input() focusedBit: string = null;
  @Input() shouldProtectAgainstUserCopy = false;
  @Input() isNavigateDirectlyToBook = false;
  @Input() peerUser?: any;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() measureDisplaySize = new EventEmitter<BitResourceSize>();
  @Output() toggleTocEvent = new EventEmitter<any>();
  @Output() isBitBeingEdited = new EventEmitter<any>();
  @Output() isBitAnnotationBeingEdited = new EventEmitter<any>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() answerSubmitted = new EventEmitter<BaseBit>();
  @Output() navigateToBook = new EventEmitter<{
    bookId: string,
    fragment: string,
    family?: ProductFamily,
    queryParams?: any
  }>();
  @Output() closeBook = new EventEmitter<any>();
  @Output() isBookUnaccessibleToUser = new EventEmitter<{ bookId: string }>();
  @Output() navigateToProduct = new EventEmitter<{ productId: string, family?: ProductFamily }>();
  @Output() assignHandIn = new EventEmitter<{ handInId: number, isSelfAssign?: boolean, moduleId?: string }>();
  @Output() bitEditInProgress = new EventEmitter<any>();
  @Output() bitEditCompleted = new EventEmitter<{ success: boolean, tocEntry?: TocItem }>();
  @Output() editorCreated = new EventEmitter<ReaderTextEditorComponent | ReaderSimpleTextEditorComponent>();
  @Output() connectWithUser: EventEmitter<{ email: string }> = new EventEmitter<{ email: string }>();

  BitType = BitType;
  actionsCss: string;
  iconActions: Array<DropdownItemModel> = [];
  headerCss: { [key: string]: boolean };
  wrapperCss: { [key: string]: boolean };
  backgroundCss: { [key: string]: boolean };
  childContentCss: { [key: string]: boolean };
  showShadow = false;
  isBitEditable = false;

  portal$: Observable<Portal<any>>;

  @ViewChild('backgroundElement') backgroundElement: ElementRef;
  @ViewChild('bitElement') bitElement: ElementRef;
  @ViewChild('bitmarkBitElement') bitmarkBitElement: BitComponent;

  private bitTypeCssClass: string;
  private subSink = new SubSink();

  constructor(private brandingRenderService: BrandingRenderService,
              protected bitPortalService: BitPortalService,
              private bitbookMqService: BitbookMqService) {
  }

  ngOnInit() {
    this.iconActions = [{
      id: 'hide-hidden-fields',
      icon: 'eye-slash-fill',
      handler: () => {
        if ([BitType.LearningEventClassroomEvent, BitType.LearningEventCompletion, BitType.LearningEventExternalLink, BitType.LearningEventLearningStep, BitType.LearningEventPreparationTask, BitType.LearningEventReadBook, BitType.LearningEventVideoCall, BitType.LearningEventLearningObjectives].includes(this.bitWrapper?.bit?.type)) {
          this.editClosed.emit();
        } else {
          this.bitWrapper.saveUserEdit = true;
        }
      }
    }];

    this.subSink.sink = this.brandingRenderService.applyBitBranding(this.bitWrapper)
      .pipe(filter((res: { themeClass: string, branding: any }) => !!res.themeClass))
      .subscribe((res: { themeClass: string, branding: any }) => {
        this.portal$ = this.bitPortalService.getPortal$(this.bitWrapper?.id);
        const bitType = this.bitWrapper?.bit?.type;
        const isLearningPathBit = [
          BitType.LearningPathLti,
          BitType.LearningPathClosing,
          BitType.LearningPathVideoCall,
          BitType.LearningPathBook,
          BitType.LearningPathClassroomEvent,
          BitType.LearningPathExternalLink,
          BitType.LearningPathStep,
          BitType.LearningPathLearningGoal
        ].includes(bitType);

        const isModuleBit = [
          BitType.Module
        ].includes(bitType);

        this.evaluateBitEditability();

        if (this.bitWrapper?.bit?.type === BitType.Article && (this.bitWrapper?.bit as ArticleBit).isBeingGenerated) {
          const bitGeneratedSub = this.bitbookMqService.onBitGenerated()
            .subscribe((generatedBitId: string) => {
              if (generatedBitId === this.bitWrapper.id) {
                this.evaluateBitEditability();
                bitGeneratedSub.unsubscribe();
              }
            });
        }

        this.bitTypeCssClass = this.computeBitTypeClass();

        this.showShadow = bitType === BitType.Page
          || ((this.context === 'notebook' || this.context === 'editor')
            && ![
              BitType.Chapter,
              BitType.BotActionSend,
              BitType.ButtonCopyText,
              BitType.BitBookSummary,
              BitType.BitBookEnding,
              BitType.AnnotationBookmark,
              BitType.AnnotationFavorite,
              BitType.ConversationLeft1,
              BitType.ConversationLeft1Scream,
              BitType.ConversationLeft1Thought,
              BitType.ConversationRight1,
              BitType.ConversationRight1Scream,
              BitType.ConversationRight1Thought,
              BitType.VirtualMarkBookAsRead,
              BitType.ImagePrototype,
              BitType.PrototypeImages,
              BitType.Flashcard1,
              BitType.DefinitionList
            ].includes(bitType))
          || this.context === 'search'
          || ((this.context === 'basket' || this.context === 'bin') && ![BitType.BotActionSend].includes(bitType));

        this.wrapperCss = {
          'bit1': bitType !== BitType.Page,
          'has-theme-width': (this.context === 'book' || this.context === 'new-release') && bitType !== BitType.Message,
          'is-message1': bitType === BitType.Message,
          'not-in-timeline': bitType === BitType.Message && this.context !== 'timeline',
          'm-2': (this.context === 'book' || this.context === 'new-release') && bitType === BitType.Message,
          'has-margins': this.context === 'book' || this.context === 'new-release' || this.context === 'shop' || this.context === 'shop-readonly' || this.context === 'shop-section' || this.context === 'shop-section-readonly' || this.context === 'self-learning' || this.context === 'self-learning-readonly',
          'is-learning-path': isLearningPathBit,
          'is-module': isModuleBit,
          'is-annotation-note': bitType === BitType.AnnotationNote,
          'is-annotation-handwritten': bitType === BitType.AnnotationHandwritten,
          'is-annotation-favorite': bitType === BitType.AnnotationFavorite,
          'is-annotation-bookmark': bitType === BitType.AnnotationBookmark,
          'is-single-action': [BitType.BotActionSend, BitType.ButtonCopyText].includes(bitType),
          'bit-shadow1': this.showShadow,
          'mb-4': ((this.context === 'book' || this.context === 'new-release') && bitType === BitType.Page)
            || this.context === 'basket'
            || this.context === 'bin',
          'pt-4': ((this.context === 'search' || this.context === 'basket' || this.context === 'bin') && bitType === BitType.PreparationNote),
          'bg-white': ((this.context === 'basket' || this.context === 'bin') && ![BitType.BotActionSend].includes(bitType))
            || (this.context === 'timeline' && [BitType.Audio].includes(bitType)),
          'overflow-hidden': this.context === 'search' || this.context === 'basket' || this.context === 'bin',
          'is-training-chapter': this.context === 'training' && this.bitWrapper?.bit.type === BitType.Chapter,
          'w-100': this.bitWrapper?.bit.type === BitType.BitBookEnding,
          'bit-full-height': [BitType.AppFlashcards].includes(this.bitWrapper?.bit.type) ||
            (this.fullScreen && [BitType.SurveyMatrix, BitType.SurveyMatrixMe].includes(this.bitWrapper?.bit.type))
        };

        this.backgroundCss = {
          'bit-background1': true,
          'bit-external-background': ['timeline', 'notebook', 'draft-book', 'editor'].includes(this.context),
          'bit-transparent-background': [BitType.BotActionSend, BitType.ButtonCopyText].includes(this.bitWrapper?.bit?.type)
        };
        this.childContentCss = {
          'bit-background1': true,
          'bit-external-background': ['timeline', 'notebook', 'draft-book', 'editor'].includes(this.context),
          'bit-transparent-background': [BitType.BotActionSend, BitType.ButtonCopyText].includes(this.bitWrapper?.bit?.type),
          'rounded-bottom': isLearningPathBit
        };
        this.headerCss = {};

        this.wrapperCss[this.bitTypeCssClass]
          = this.wrapperCss[`context-${this.context}`]
          = this.headerCss[this.bitTypeCssClass]
          = this.childContentCss[this.bitTypeCssClass]
          = true;

        this.actionsCss = `${(this.context === 'book' || this.context === 'new-release') ? 'mr-md-1 mt-md-1' : ''} ${bitType === BitType.BotActionSend ? 'mt-1' : ''}`;

        this.wrapperCss[res?.themeClass]
          = this.headerCss [res?.themeClass]
          = this.childContentCss[res?.themeClass]
          = true;
      });
  }

  ngOnDestroy() {
    this.bitPortalService.removePortal(this.bitWrapper?.id);
    this.subSink?.unsubscribe();

    this.bitElement = null;
    this.backgroundElement = null;
    this.bitmarkBitElement = null;
  }

  get hideActions(): boolean {
    return !this.actions || this.hideBitActions
      || this.fullScreen
      || ['timeline', 'training', 'editor', 'shop-readonly', 'shop-section-readonly'].includes(this.context)
      || [BitType.BitBookSummary, BitType.BitBookEnding, BitType.VirtualHandIn, BitType.VirtualHandInResult].includes(this.bitWrapper?.bit?.type);
  }

  get isInvisibleActions(): boolean {
    return ['shop', 'shop-readonly', 'shop-section', 'shop-section-readonly'].includes(this.context) && !this.bitWrapper.showBitActions;
  }

  handleEditorCreated(editor: ReaderTextEditorComponent | ReaderSimpleTextEditorComponent) {
    this.editorCreated.emit(editor);
    this.bitbookMqService.notifyEditorCreated(editor);
  }

  evaluateBitEditability() {
    // if (this.context === 'timeline' && this.bitWrapper.bit.format === BitmarkFormat.Prosemirror) {
    //   this.isBitEditable = true;
    // } else {
    this.isBitEditable = ['notebook', 'draft-book', 'shop', 'shop-section', 'self-learning'].includes(this.context)
      && !this.bitWrapper?.isEditNotAllowed
      && (this.bitWrapper?.meta?.originBook?.type && (this.bitWrapper?.meta?.originBook.externalId || this.bitWrapper?.meta?.originBitId)
        ? ![BookType.Book].includes(this.bitWrapper?.meta.originBook.type)
        : true);
    // }

    if (this.context === 'new-release' && !this.bitWrapper?.isEditNotAllowed) {
      this.isBitEditable = true;
    }

    // console.log('is editable: ', this.isBitEditable);
  }

  onIsBitBeingEdited(event: any) {
    if (event?.bit?.type === BitType.Chapter) {
      const newBitTypeClass = this.computeBitTypeClass();
      if (newBitTypeClass !== this.bitTypeCssClass) {
        this.wrapperCss[this.bitTypeCssClass] = false;
        this.wrapperCss[newBitTypeClass] = true;
        this.bitTypeCssClass = newBitTypeClass;
      }
    }
    this.isBitBeingEdited.emit(event);
  }

  private computeBitTypeClass() {
    return 'bit-type-' +
      (this.bitWrapper?.bit.type === BitType.Chapter
        ? `chapter-l-${(this.bitWrapper?.bit as ChapterBit).level}`
        : this.bitWrapper?.bit.type);
  }

  doRefresh() {
    this.bitmarkBitElement.doRefresh();
  }
}

