<div class="person-card-container" style='min-height: 410px; width: 270px; margin-bottom: 20px'>
  <div class="person-card disable-annotations" style='width: 270px; min-height: 410px'>
    <div class="d-flex flex-column person-card-front position-relative justify-content-start align-items-center w-100">
      <div class='w-100' style='height: 120px; background-image: url(https://d1vdxt1vncz0ge.cloudfront.net/310x210/1700241045942-Ey58ribLlUbBDMO); background-size: cover'>
        <img class='person-avatar' src='https://s3.eu-west-1.amazonaws.com/gmb-storage-cosmic/cosmic-storage-entries/50c33ef0-0f4f-11ee-a2a8-0345b1f18999-avatar.png' />
      </div>
      <div class='pt-2 pl-4 pr-4 pb-5'>
        <div class='d-flex flex-column justify-content-center align-items-center'>
          <div class='text-right w-100 l-h-10 mb-1'>
            <bitmark-icon name="icon-kebab-menu" class="p-0"></bitmark-icon>
          </div>
          <p class='mb-0 mt-2 person-name'>Morgan Sweeney</p>
          <p class='person-occupation'>Ant Collector</p>
          <div class="person-follow mb-2 d-flex flex-row justify-content-around w-100">
            <div>
              <div class='person-follow-value'>12</div>
              <div class='person-follow-label'>Followers</div>
            </div>
            <div>
              <div class='person-follow-value'>1200</div>
              <div class='person-follow-label'>Following</div>
            </div>
          </div>
          <button class='btn-follow-person mt-2 mb-3'>Follow</button>
          <div class='person-description text-left'>
            Morgan has collected ants since they were 6 years old and now has many dozen ants but none in their pants.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
