<div class="d-flex flex-column justify-content-start align-items-center hand-in-container">
  @if (handInBit) {
    @if (status === HandInStatus.NotHandedIn || status === HandInStatus.Rejected) {
      @if(!handInBit.handIn?.deadline){
        {{'HandIn.PleaseHandInTheBookReady' | translate}}<br/>
      }
      @if(handInBit.handIn?.deadline){
        {{'HandIn.PleaseHandInTheBookUntil' | translate}} {{ handInBit.handIn?.deadline | bitmarkDate : 'longDate'}}<br/>
      }
      <bitmark-icon (click)="openAccordion()"
                    [name]="isDescriptionOpen ? 'chevron-down' : 'chevron-right'"
                    class="flex-shrink-0 cursor-pointer text-white view-resources-toggle"></bitmark-icon>
      <div [@openClose]="isDescriptionOpen ? 'open' : 'closed'">
        {{ 'HandIn.YourBookWillBeLocked' | translate }}
        <div class="d-flex align-items-center justify-content-center">
          <div class='buy-button-container'>
            <button (click)='handIn()'
                    class='btn-background-calm btn-background-medium buy-button d-flex flex-row align-items-center justify-content-center'>
              <bitmark-icon name="send" class="mr-1 size-20px"></bitmark-icon>
              {{ 'Shared.HandIn'| translate }}
            </button>
          </div>
        </div>
      </div>
    }
    @if (status === HandInStatus.HandedIn) {
      @if(handInDate){
        <span>{{ 'HandIn.BookWasHandedInSuccessfully' | translate }}
        <bitmark-icon (click)="openAccordion()"
                      [name]="isDescriptionOpen ? 'chevron-down' : 'chevron-right'"
                      class="flex-shrink-0 cursor-pointer text-white view-resources-toggle"></bitmark-icon>
        </span>
        <div [@openClose]="isDescriptionOpen ? 'open' : 'closed'"
             class="d-flex flex-row align-items-center justify-content-between w-100">
          <span class="small-font">🔒&nbsp;{{ 'HandIn.Locked' | translate }}</span>
          <span class="small-font">
          @if(expertUser?.fullName){
            <span>{{expertUser?.fullName}}&nbsp;-&nbsp;</span>
          }
            <span>{{handInDate | smartDate : true}}</span>
        </span>
        </div>
      }
    }
    @if (status === HandInStatus.Approved) {
      @if(handInDate){
        <span>{{ 'HandIn.YourWorkOnThisBookWasApproved' | translate }}</span>

        <div class="d-flex flex-row align-items-center justify-content-between w-100">
          <span class="small-font">🔒&nbsp;{{ 'HandIn.Locked' | translate }}</span>
          <span class="small-font">
          @if(expertUser?.fullName){
            <span>{{expertUser?.fullName}}&nbsp;-&nbsp;</span>
          }
            <span>{{handInDate | smartDate : true}}</span>
        </span>
        </div>
      }
    }
  }
</div>

