@if (bit?.body) {
  <div class="bit-body bit-body-allow-highlight"
       [ngClass]="bit.type">
    <bitmark-text [attr.data-bitmark-node]="'body'"
                  [text]="bit?.body"
                  [format]="bit.format">
    </bitmark-text>
  </div>
}
@if (bit.resource) {
  <bitmark-resource [bitResource]="bit.resource"
                    [hostBit]="bit"
                    [showArticle]="true"
                    (open)="openResource.emit($event)"
                    class="d-block pb-4 pt-2">
  </bitmark-resource>
}
@for (quiz of bit.quizzes; track quiz; let quizIndex = $index) {
  <div class="w-100 bit-body-text text-pre-wrap sub-item-positioning">
    @if (quiz.item) {
      <bitmark-text class="sub-item-multiple-choice quiz-item text-nowrap"
                    cssClass="text-nowrap"
                    [text]="quiz.item"
                    [format]="bit.format">
      </bitmark-text>
    }
    @if (quiz.instruction) {
      <bitmark-text class="d-block"
                    [text]="quiz.instruction"
                    [format]="bit.format">
      </bitmark-text>
    }
  </div>
  <div class="mt-4">
    @for (choice of quiz.choices; track choice; let choiceIndex = $index) {
      <div class="bit-radio mb-2"
           [ngClass]="answersCss[quizIndex*10+choiceIndex]">
        <input [id]="'i' + bitId + '-' + quizIndex + '-' + choiceIndex"
               type="radio"
               [name]="'answer' + bitId + '-' + quizIndex"
               [value]="choice.choice"
               [disabled]="quiz.isExample || readOnly || !allowAnswerSubmit"
               [checked]="choice.choice===quiz.answer?.choice"
               (change)="setQuizAnswer(quiz, choice.choice)"/>
        <label [for]="'i' + bitId + '-' + quizIndex + '-' + choiceIndex"
               class="w-100">
          <bitmark-text [text]="choice.choice"
                        [format]="bit.format">
          </bitmark-text>
        </label>
      </div>
    }
  </div>
}

@if (bit?.footer) {
  <bitmark-text class="d-block bit-footer"
                [text]="bit?.footer"
                [format]="bit.format">
  </bitmark-text>
}
