import {BookEntity} from "bitmark";
import {PeerUserBlockedState} from "main/timeline/timeline.models";

export interface Country {
  name: string;
  code: string;
}

export interface AiConfigOption {
  name: string;
  id: string;
}

export interface BaseUser {
  id?: number;
  botId?: string;
  bookId?: string;
  groupId?: string;
  members?: Array<{ userId: string, groupRole: null | 'admin' }>
  email?: string;
  ssoEmail?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  photoUrl?: string;
  countryCode?: string;
  language?: string;
  needsEmailValidation?: boolean;
  isAvailable?: boolean;
  unreadMessages?: number;
  lastActivityTimestamp?: number;
  fullNameHtml?: string;
  isTyping?: boolean;
  gaUuid?: string;
  ddUuid?: string;
  tbUuid?: string;
  enrollment?: {
    classId?: string;
    className?: string;
  };
  classNameHtml?: string;
}

export class User implements BaseUser {
  id?: number;
  botId?: string;
  bookId?: string;
  groupId?: string;
  members?: Array<{ userId: string, groupRole: null | 'admin' }>
  email?: string;
  ssoEmail?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  photoUrl?: string;
  countryCode?: string;
  language?: string;
  needsEmailValidation?: boolean;
  isAvailable?: boolean;
  unreadMessages?: number;
  lastActivityTimestamp?: number;
  fullNameHtml?: string;
  blockState?: PeerUserBlockedState;
  book?: BookEntity;
  gaUuid?: string;
  ddUuid?: string;
  tbUuid?: string;
  enrollment?: {
    classId?: string;
    className?: string;
  };
  classNameHtml?: string;
  isActive?: boolean;

  constructor(user: User) {
    Object.assign(this, user);
  }

  private _isTyping?: boolean;
  set isTyping(value: boolean) {
    if (value === this._isTyping) {
      return;
    }
    this._isTyping = value;
    if (value && !this.bookId) {
      setTimeout(() => {
        this._isTyping = false;
      }, 5000);
    }
  }

  get isTyping(): boolean {
    return this._isTyping;
  }
}

export interface IdentityUser extends User {
  fixedToken: string;
  refreshToken: string;
  tokenTimeStamp: number;
  emailNewAddress?: string;
  spaces?: Array<string>;
  isProUser?: boolean;
  loginRole?: string;
  loginProvider: 'personal' | 'email' | 'google' | 'facebook' | 'apple' | 'swisslife' | 'iffp' | 'ef';
  claims: {
    logged: boolean;
    loginProvider: 'personal' | 'email' | 'google' | 'facebook' | 'apple' | 'swisslife' | 'iffp' | 'ef';
    spaces: Array<string>;
    academies: Array<string>;
    shops: Array<string>;
    isForcedSpace: boolean;
    isSsoLogin: boolean;
    getMoreBrain: { access: { trainings: Array<string>, books: Array<string> } };
    firstSpace: string;
    loginSpace: string;
    role?: string;
    school?: {
      code?: string;
      name?: string;
    },
    enrollment?: {
      language?: string;
      level?: string;
      classId?: string;
      className?: string;
      campusCode?: string;
      campusSchoolName?: string;
      teacherEmail?: string;
      teacherName?: string;
    };
    hasChatGpt?: boolean;
  };
  aiConfig?: {
    depth: number,
    reasoningFramework: number,
    communication: number,
    learningStyle: number,
    toneStyle: number,
    useEmojis: number
  };
}

export interface ConnectionRequest {
  id: number;
  timestamp: number;
  peerEmail: string;
  peerUserId: number;
  senderId: number;
  senderUser: BaseUser;
  rejectedTimestamp: number;
  revokedTimestamp: number;
  status: 0 | -1 | 1;
}

export interface ShopInfo {
  academyIconUrl?: string;
  shopUrl?: string;
  headerImageUrl?: string;
  name?: string;
  spaceName?: string;
  primaryColor?: string;
  secondaryColor?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  headerBackgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  folderColor?: string;
  folderTitleColor?: string;
  folderTitleSecondaryColor?: string;
  sectionTitleColor?: string;
  sectionTitleSecondaryColor?: string;

  primaryActionButtonColor?: string;
  primaryActionButtonFontColor?: string;
  primaryDropdownBackgroundColor?: string;
  linkColor?: string;

  headerFontColor?: string;
  primaryDropdownFontColor?: string;
  productPageId?: string;
}

export interface SpacesConfig {
  [space: string]: {
    shops: {
      [shop: string]: ShopInfo
    },
    space: {
      name: string,
      code: string
    },
    name: string
  };
}
